import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { fetchAllIndicatore } from '../../redux/indicatori/backendActions';
import { makeStyles } from '@material-ui/core/styles'
import { createTheme, MuiThemeProvider } from '@material-ui/core'
import { useLocation } from 'react-router-dom'
import { resetLastIndicatoriAverages } from '../../redux/valoriindicatori/basicActions'
import { textLabels } from '../../constants/miscConstants'
import { frequencyTypesSelectValues } from '../../constants/indicatoriConstants'
import MUIDataTable from 'mui-datatables'
import AggregatiAverageSearchForm from '../AggregatiAverageSearchForm'
import { defaultRowOptions } from '../../constants/generalConstants'
import { setMainTableRows } from '../../redux/general/basicActions'

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: "100%",
    maxHeight: "100%",
    overflow: "hidden"
  },
  table: {
    marginBottom: "0",
    overflowY: "auto",
    maxHeight: "calc(100% - 100px)",
  },
}));

const getMuiTheme = () =>
  createTheme({
    overrides: {
      MuiToolbar: {
        root: {
          backgroundColor: "#FEFDFD",
        },
      },
      MuiTable: {
        root: {
          backgroundColor: "#FEFDFD",
        },
      },
      MuiTableCell: {
        root: {
          height: "30px",
        },
      },
      MUIDataTableHeadCell: {
        fixedHeader: {
          backgroundColor: "#C4C4C4",
        },
      },
    },
  });

const columns = [
  { name: "codeFlat", label: "Cod." },
  { name: "nameFlat", label: "Nome" },
  { name: "boxFlat", label: "Box" },
  { name: "measureUnit", label: "Unità di misura" },
  { name: "frequency", label: "Periodicità indicatore" },
  { name: "average", label: "Media valori aziende" },
];

function ValoriAggregatiPage(props) {
  const classes = useStyles(props);
  const [data, setData] = useState([]);
  const location = useLocation();
  const setTitle = props.setTitle;
  const resetLastIndicatoriAverages = props.resetLastIndicatoriAverages;
  const fetchAllIndicatore = props.fetchAllIndicatore;
  const dispatch = useDispatch();
  const mainTableRows = useSelector(
    (state) => state.appPreferences.mainWindowTableRows
  );
  const options = {
    elevation: 0,
    selectableRows: "none",
    filter: false,
    print: false,
    download: false,
    search: false,
    viewColumns: false,
    textLabels: textLabels,
    rowsPerPageOptions: defaultRowOptions,
    rowsPerPage: mainTableRows,
    onChangeRowsPerPage: (rows) => dispatch(setMainTableRows(rows)),
    setTableProps: () => {
      return {
        padding: "none",
      };
    },
  };
  useEffect(() => {
    fetchAllIndicatore();
  }, [fetchAllIndicatore]);
  useEffect(() => {
    resetLastIndicatoriAverages();
  }, [location, resetLastIndicatoriAverages]);
  useEffect(() => {
    let newData = props.valori.lastAveragesReceived.map((average) => {
      let indicatore = props.indicatori.indicatoriByIDs[average.tipoIndicatoreId];
      return {
        codeFlat: indicatore.code,
        nameFlat: indicatore.name,
        boxFlat: indicatore.box.name,
        measureUnit: indicatore.measureUnit,
        frequency: frequencyTypesSelectValues[indicatore.frequency],
        average: average.average,
      };
    });
    setData(newData);
  }, [
    props.valori,
    props.indicatori,
  ]);
  useEffect(() => {
    setTitle("Valori aggregati")
  }, [setTitle])
  return (
    <div className={classes.root}>
      <div className={classes.table}>
        <h6>Ricerca</h6>
        <AggregatiAverageSearchForm />
        <h6>Risultati</h6>
        <MuiThemeProvider theme={getMuiTheme()}>
          <MUIDataTable data={data} columns={columns} options={options} />
        </MuiThemeProvider>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    indicatori: state.indicatori,
    valori: state.valori,
  };
};

const mapDispatchToProps = {
  fetchAllIndicatore,
  resetLastIndicatoriAverages
};

export default connect(mapStateToProps, mapDispatchToProps)(ValoriAggregatiPage);