export const textLabels = {
  body: {
    noMatch: "Nessun risultato trovato",
    toolTip: "Ordina",
    columnHeaderTooltip: (column) => `Ordina per ${column.label}`,
  },
  pagination: {
    next: "Pagina successiva",
    previous: "Pagina precedente",
    rowsPerPage: "Righe per pagina:",
    displayRows: "di",
  },
  toolbar: {
    search: "Ricerca",
    downloadCsv: "Download CSV",
    print: "Stampa",
    viewColumns: "Visualizza colonne",
    filterTable: "Filtra tabella",
  },
  filter: {
    all: "Tutti",
    title: "FILTRI",
    reset: "RESET",
  },
  viewColumns: {
    title: "Mostra colonne",
    titleAria: "Mostra/nascondi colonne della tabella",
  },
  selectedRows: {
    text: "righe selezionate",
    delete: "Elimina",
    deleteAria: "Elimina righe selezionate",
  },
};

export const booleanRadioValues = Object.freeze({
  true: true,
  false: false
});

export const booleanRadioLabels = Object.freeze({
  true: "Si",
  false: "No"
});