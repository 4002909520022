export const RECEIVE_ALL_ENTE_FORNITORE = "RECEIVE_ALL_ENTE_FORNITORE";
export const RECEIVE_SINGLE_ENTE_FORNITORE = "RECEIVE_SINGLE_ENTE_FORNITORE";

export function receiveAllEnteFornitore(enti) {
  return { type: RECEIVE_ALL_ENTE_FORNITORE, enti };
}

export function receiveSingleEnteFornitore(ente) {
  return { type: RECEIVE_SINGLE_ENTE_FORNITORE, ente };
}

