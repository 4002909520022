import * as Yup from "yup";
import {
  booleanValuesSelect,
  stringValuesSelect,
  VALUES_BOOLEAN,
  VALUES_STRING,
  valuesStatuses,
} from "./valoriIndicatoriConstants";

export const frequencyTypes = Object.freeze({
  DAILY: "DAILY",
  MONTHLY: "MONTHLY",
  SEASONAL: "SEASONAL",
  YEARLY: "YEARLY",
});

export const frequencyTypesLabels = Object.freeze({
  DAILY: "Giornaliero",
  MONTHLY: "Mensile",
  SEASONAL: "Stagionale",
  YEARLY: "Annuale",
});

export const frequencyTypesSelectValues = Object.freeze({
  DAILY: "Giornaliero",
  MONTHLY: "Mensile",
  SEASONAL: "Stagionale",
  YEARLY: "Annuale",
});

export const thresholdOperatorsTypes = Object.freeze({
  GREATER_THAN: "GREATER_THAN",
  GREATER_THAN_OR_EQUAL_TO: "GREATER_THAN_OR_EQUAL_TO",
  LESS_THAN: "LESS_THAN",
  LESS_THAN_OR_EQUAL_TO: "LESS_THAN_OR_EQUAL_TO",
  EQUAL: "EQUAL",
  NOT_EQUAL: "NOT_EQUAL",
  CLOSE_INTERVAL: "CLOSE_INTERVAL",
  LEFT_CLOSE_INTERVAL: "LEFT_CLOSE_INTERVAL",
  RIGHT_CLOSE_INTERVAL: "RIGHT_CLOSE_INTERVAL",
  OPEN_INTERVAL: "OPEN_INTERVAL",
});

export const thresholdOperatorsTypesLabels = Object.freeze({
  GREATER_THAN: ">",
  GREATER_THAN_OR_EQUAL_TO: "≥",
  LESS_THAN: "<",
  LESS_THAN_OR_EQUAL_TO: "≤",
  EQUAL: "=",
  NOT_EQUAL: "≠",
  CLOSE_INTERVAL: "Intervallo chiuso",
  LEFT_CLOSE_INTERVAL: "Intervallo chiuso a sinistra",
  RIGHT_CLOSE_INTERVAL: "Intervallo chiuso a destra",
  OPEN_INTERVAL: "Intervallo aperto",
});

export const thresholdOperatorsTypesLabelsReversed = Object.freeze({
  GREATER_THAN: "<=",
  GREATER_THAN_OR_EQUAL_TO: "<",
  LESS_THAN: ">=",
  LESS_THAN_OR_EQUAL_TO: ">",
});

export const thresholdOperatorsTypesLabelsForYellow = Object.freeze({
  GREATER_THAN: ">=",
  GREATER_THAN_OR_EQUAL_TO: ">",
  LESS_THAN: "<=",
  LESS_THAN_OR_EQUAL_TO: "<",
});

export const getThresholdLabel = (indicatore, status) => {
  switch (indicatore.valuesType) {
    case valuesTypes.BOOLEAN:
      return getThresholdLabelBoolean(indicatore, status);
    case valuesTypes.STRING:
      return getThresholdLabelString(indicatore, status);
    case valuesTypes.NUMBER:
      return getThresholdLabelNumber(indicatore, status);
    default:
      return "";
  }
};

export const getThresholdLabelBoolean = (indicatore, status) => {
  switch (status) {
    case valuesStatuses.GREEN:
      return findBooleanThresholdCondition(indicatore.greenThresholdConditions);
    case valuesStatuses.YELLOW:
      return findBooleanThresholdCondition(
        indicatore.yellowThresholdConditions
      );
    case valuesStatuses.RED:
      return findBooleanThresholdCondition(indicatore.redThresholdConditions);
    default:
      return "";
  }
};

const findBooleanThresholdCondition = (conditions) => {
  return conditions
    .filter((condition) => {
      return Object.values(VALUES_BOOLEAN).includes(condition.arg1);
    })
    .map((condition) => {
      return booleanValuesSelect[condition.arg1];
    })
    .join(", ");
};

export const getThresholdLabelString = (indicatore, status) => {
  switch (status) {
    case valuesStatuses.GREEN:
      return findStringThresholdCondition(indicatore.greenThresholdConditions);
    case valuesStatuses.YELLOW:
      return findStringThresholdCondition(indicatore.yellowThresholdConditions);
    case valuesStatuses.RED:
      return findStringThresholdCondition(indicatore.redThresholdConditions);
    default:
      return "";
  }
};

const findStringThresholdCondition = (conditions) => {
  return conditions
    .filter((condition) => {
      return Object.values(VALUES_STRING).includes(condition.arg1);
    })
    .map((condition) => {
      return stringValuesSelect[condition.arg1];
    })
    .join(", ");
};

export const getThresholdLabelNumber = (indicatore, status) => {
  switch (status) {
    case valuesStatuses.GREEN:
      return findNumberThresholdCondition(indicatore.greenThresholdConditions);
    case valuesStatuses.YELLOW:
      return findNumberThresholdCondition(indicatore.yellowThresholdConditions);
    case valuesStatuses.RED:
      return findNumberThresholdCondition(indicatore.redThresholdConditions);
    default:
      return "";
  }
};

const findNumberThresholdCondition = (conditions) => {
  return conditions
    .map((condition) => {
      switch (condition.thresholdOperator) {
        case thresholdOperatorsTypes.GREATER_THAN:
        case thresholdOperatorsTypes.GREATER_THAN_OR_EQUAL_TO:
        case thresholdOperatorsTypes.LESS_THAN:
        case thresholdOperatorsTypes.LESS_THAN_OR_EQUAL_TO:
        case thresholdOperatorsTypes.EQUAL:
        case thresholdOperatorsTypes.NOT_EQUAL:
          return (
            thresholdOperatorsTypesLabels[condition.thresholdOperator] +
            " " +
            condition.arg1
          );
        case thresholdOperatorsTypes.CLOSE_INTERVAL:
        case thresholdOperatorsTypes.LEFT_CLOSE_INTERVAL:
        case thresholdOperatorsTypes.RIGHT_CLOSE_INTERVAL:
        case thresholdOperatorsTypes.OPEN_INTERVAL:
          return getThresholdArgFieldString(
            condition.thresholdOperator,
            condition.arg1,
            condition.arg2
          );
        default:
          return "";
      }
    })
    .join(", ");
};

export const getThresholdArgFieldString = (operator, arg1, arg2) => {
  if (operator === thresholdOperatorsTypes.OPEN_INTERVAL) {
    return arg1 + " < x < " + arg2;
  } else if (operator === thresholdOperatorsTypes.RIGHT_CLOSE_INTERVAL) {
    return arg1 + " < x ≤ " + arg2;
  } else if (operator === thresholdOperatorsTypes.LEFT_CLOSE_INTERVAL) {
    return arg1 + " ≤ x < " + arg2;
  } else if (operator === thresholdOperatorsTypes.CLOSE_INTERVAL) {
    return arg1 + " ≤ x ≤ " + arg2;
  } else {
    return arg1;
  }
};

export const valuesTypes = Object.freeze({
  NUMBER: "NUMBER",
  STRING: "STRING",
  BOOLEAN: "BOOLEAN",
});

export const valuesTypesLabels = Object.freeze({
  NUMBER: "Numero",
  STRING: "Lettere",
  BOOLEAN: "Booleano",
});

export const valuesTypesSelect = Object.freeze({
  NUMBER: "Numero",
  STRING: "Lettere",
  BOOLEAN: "Booleano",
});

export const indicatoreSchema = Yup.object().shape({
  code: Yup.string()
    .strict(true)
    .trim("Non sono ammessi spazi")
    .required("Campo obbligatorio"),
  name: Yup.string()
    .strict(true)
    .trim("Non sono ammessi spazi")
    .required("Campo obbligatorio"),
  measureUnit: Yup.string()
    .strict(true)
    .trim("Non sono ammessi spazi")
    .required("Campo obbligatorio"),
  box: Yup.object({
    id: Yup.string().required("Campo obbligatorio"),
  }),
  frequency: Yup.mixed().strict(true).required("Campo obbligatorio"),
  valuesType: Yup.mixed().strict(true).required("Campo obbligatorio"),
  critical: Yup.boolean().strict(true).required("Campo obbligatorio"),
  enabled: Yup.boolean().strict(true).required("Campo obbligatorio"),
});

export class SearchForm {
  constructor() {
    this.code = "";
    this.azienda = "";
    this.fromDay = "";
    this.toDay = "";
  }
}

export const searchFormSchema = Yup.object(
  {
    code: Yup.mixed().required("Campo obbligatorio"),
    fromDay: Yup.date()
      .nullable()
      .when("toDay", (toDay, schema) => {
        if (toDay) {
          return schema.max(toDay, "Inserire una data compatibile");
        } else {
          return schema;
        }
      }),
  },
  [["toDay", "fromDay"]]
);

export class Indicatore {
  constructor() {
    this.id = undefined;
    this.code = "";
    this.name = "";
    this.measureUnit = "";
    this.box = {
      id: "",
      name: "",
    };
    this.frequency = frequencyTypes.DAILY;
    this.greenThresholdConditions = [];
    this.yellowThresholdConditions = [];
    this.redThresholdConditions = [];
    this.defaultThreshold = valuesStatuses.RED;
    this.valuesType = valuesTypes.NUMBER;
    this.critical = false;
    this.enabled = true;
    this.tipoIndicatoreCriticalInfo = new TipoIndicatoreCriticalInfo();
  }
}

export class TipoIndicatoreCriticalInfo {
  constructor() {
    this.id = undefined;
    this.alertMessage = "";
    this.alertProtocol = "";
    this.indicatoriDipendenti = [];
  }
}

export class TipoIndicatoreDipendentePriority {
  constructor() {
    this.id = "";
    this.priorityRating = 3;
    this.tipoIndicatoreDipendente = {
      id: "",
    };
  }
}

export const INDICATORE_DIPENDENTE_MAX_RATING = 1;

export const INDICATORE_DIPENDENTE_MIN_RATING = 3;

export class IndicatoreDipendenteRowData {
  constructor() {
    this.tempId = undefined;
    this.indicatoreIdFlat = "";
    this.codeFlat = "";
    this.nameFlat = "";
    this.boxFlat = "";
    this.priorityRating = 3;
  }
}
export const PriorityRatingOptions = Object.freeze({
  1: "1",
  2: "2",
  3: "3",
});

export const IndicatoreDipendenteRowDataSchema = Yup.object({
  indicatoreIdFlat: Yup.string().required("Campo obbligatorio"),
});

export class ThresholdCondition {
  constructor() {
    this.arg1 = 0;
    this.arg2 = 1;
    this.thresholdOperator = thresholdOperatorsTypes.GREATER_THAN;
  }
}

export const checkThresholdArgs = (arg, operator, schema) => {
  if (!operator) {
    return schema;
  }
  switch (operator) {
    case thresholdOperatorsTypes.CLOSE_INTERVAL:
    case thresholdOperatorsTypes.LEFT_CLOSE_INTERVAL:
    case thresholdOperatorsTypes.RIGHT_CLOSE_INTERVAL:
    case thresholdOperatorsTypes.OPEN_INTERVAL:
      if (!arg) {
        return schema.required("Campo obbligatorio");
      }
      return schema
        .moreThan(arg, `Il valore deve essere maggiore di ${arg}`)
        .required("Campo obbligatorio");
    default:
      return schema;
  }
};

export const ThresholdConditionSchema = Yup.object(
  {
    thresholdOperator: Yup.string().required("Campo obbligatorio"),
    arg1: Yup.number()
      .typeError("Inserire un numero")
      .required("Campo obbligatorio"),
    arg2: Yup.number()
      .typeError("Inserire un numero")
      .when(["arg1", "thresholdOperator"], checkThresholdArgs),
  },
  ["arg1", "arg2"]
);

export const StringThresholdSchema = Yup.object({});

export const BooleanThresholdSchema = Yup.object({});
