import jwtDecode from "jwt-decode";
import {
  failedRefreshTokens,
  loggedOut,
  receiveFreshTokens, refreshingTokens,
} from './auth/basicActions'
import { toast } from "react-toastify";
import { ErrorToast } from "../Common/Toasts/ErrorToast";
import React from "react";
import { BACKEND_URL } from '../constants/generalConstants'
import axios from 'axios'
import { UserRoles } from '../constants/authConstants'

const refreshTokenPath = "/auth/refresh";

export function jwt({ dispatch, getState }) {
  return (next) => (action) => {
    // only worry about expiring token for async actions
    if (typeof action === "function") {
      if (getState().loginData && getState().loginData.isAuthenticated) {
        const accessTokenExpiration = getState().loginData
          .accessTokenExpiration;
        const refreshTokenExpiration = getState().loginData
          .refreshTokenExpiration;
        if (
          accessTokenExpiration &&
          accessTokenExpiration - Math.floor(Date.now() / 1000) < 10
        ) {
          if (
            refreshTokenExpiration &&
            refreshTokenExpiration - Math.floor(Date.now() / 1000) > 20
          ) {
            if (!getState().loginTransientState.refreshTokenPromise) {
              return refreshTokens(getState().loginData.refreshToken, dispatch).then(() => next(action));
            } else {
              return getState().loginTransientState.refreshTokenPromise.then(
                () => next(action)
              );
            }
          }
          dispatch(loggedOut());
          toast.error((<ErrorToast message={"Sessione scaduta, ripetere il login"}/>));
        }
      }
    }
    return next(action);
  };
}

export function refreshTokens(refreshToken, dispatch) {
    const refreshPromise = axios
    .post(BACKEND_URL + refreshTokenPath, {
      refreshToken: refreshToken,
    })
    .then((response) => {
      let accessTokenPayload = jwtDecode(response.data.access_token);
      let role = accessTokenPayload.roles.find((role) =>
        UserRoles.hasOwnProperty(role)
      );
      let refreshTokenPayload = jwtDecode(response.data.refresh_token);
      dispatch(
        receiveFreshTokens(
          role,
          response.data.access_token,
          accessTokenPayload.exp,
          response.data.refresh_token,
          refreshTokenPayload.exp
        ))
        return Promise.resolve()
      }
      ).catch(() => {
        dispatch(failedRefreshTokens());
        toast.error((<ErrorToast message={"Errore durante la comunicazione con il server"}/>));
        dispatch(loggedOut());
        return Promise.reject();
      });
    dispatch(refreshingTokens(refreshPromise));
    return refreshPromise;
}
