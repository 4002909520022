import React, { useEffect } from 'react'
import {connect} from "react-redux";
import { SimpleSelectField } from './SimpleSelectField'
import { fetchAllBoxes } from '../../redux/box/backendActions'

function BoxSelectField(props) {
    const fetchAllBoxes = props.fetchAllBoxes;
    useEffect(() => {
        fetchAllBoxes();
    }, [fetchAllBoxes])
    return (
        <SimpleSelectField
          name={props.name}
          id="boxId"
          className={props.className}
          options={props.box.boxSelectValues}
          disabled={props.disabled}
          allowEmptyValues={props.allowEmptyValues}
        />
    );
}



const mapStateToProps = (state) => {
    return {
        box: state.box
    };
};

const mapDispatchToProps = {
    fetchAllBoxes
};

export default connect(mapStateToProps, mapDispatchToProps)(BoxSelectField);
