import React, { useRef } from 'react'
import { useField } from 'formik'
import {
  getSeason,
  maxYear,
  minYear,
  periodSeasonLabels, periodSeasonValues,
} from '../../constants/valoriIndicatoriConstants'
import { ErrorTooltip } from '../../Common/GenericFormComponents/ErrorTooltip'

export function SeasonSelector(props) {
  const tooltipTarget = useRef(null);
  const [field, meta, helpers] = useField(props.name);
  const { value } = meta;
  const { setValue } = helpers;
  const yearOptionsArray = [];
  for (let i = minYear; i <= maxYear; i++ ){
    yearOptionsArray.push((
        <option key={i} id={i} value={i}>
          {i}
        </option>
      )
    )
  }
  return (
    <div ref={tooltipTarget} className={props.classNameContainer}>
      <select
        name={props.name + "-season"}
        id={props.id + "-season"}
        className={props.classNameSeason}
        disabled={props.disabled}
        value={getSeason(new Date(value))}
        onChange={ e => {
          let year = new Date(value).getFullYear();
          let season;
          switch (e.target.value) {
            case "WINTER":
              season = periodSeasonValues.WINTER;
              break;
            case "SPRING":
              season = periodSeasonValues.SPRING;
              break;
            case "SUMMER":
              season = periodSeasonValues.SUMMER;
              break;
            case "FALL":
              season = periodSeasonValues.FALL;
              break;
            default:
              season = "";
              break;
          }
          setValue(year + "-" + season);
        }}
      >
        <option disabled={!props.allowEmptyValues} value={""}>Seleziona</option>
        {
          Object.keys(periodSeasonLabels).map((key) => {
            return (
              <option key={key} id={key} value={key}>
                {periodSeasonLabels[key]}
              </option>
            )
          })
        }
      </select>
      <select
        name={props.name + "-year"}
        id={props.id + "-year"}
        className={props.classNameYear}
        disabled={props.disabled}
        value={new Date(value).getFullYear().toString()}
        onChange={ e => {
          let season = value.substring(5);
          setValue(e.target.value + "-" + season );
        }}
      >
        <option disabled={!props.allowEmptyValues} value={""}>Seleziona</option>
        {
          yearOptionsArray
        }
      </select>
      <ErrorTooltip
        target={tooltipTarget}
        show={Boolean(meta.error && !props.disableErrors && meta.touched)}
        msg={meta.error}
      />
    </div>
  )
}