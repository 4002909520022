import React, { useState } from "react";
import { resetPassword } from "../redux/auth/backendActions";
import { connect } from "react-redux";
import { Form, Formik } from "formik";
import { authPaths, ResetPasswordResult } from '../constants/authConstants'
import * as Yup from "yup";
import { PasswordResetModalWindow } from "./components/PasswordResetModalWindow";
import qs from "qs";
import { displayToast } from "../redux/general/backendActions";
import { toastTypes } from "../constants/generalConstants";
import { SimpleInputField } from "../Common/GenericFormComponents/SimpleInputField";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography } from "@material-ui/core";
import { Image } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  paper: {
    background: "#FAFAFA",
    borderRadius: "18px",
    width: "100%",
    height: "430px",
    marginTop: "-210px",
    maxWidth: "500px",
    position: "absolute",
    top: "50%",
  },
  container: {
    marginBottom: "10px",
    marginTop: "10px",
  },
  input: {
    height: "2em",
    width: "70%",
  },
  typography: {
    whiteSpace: "pre",
  },
}));

function ResetPassword(props) {
  const classes = useStyles(props);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const checkResult = (result) => {
    switch (result) {
      case ResetPasswordResult.SUCCESS:
        setShowSuccessModal(true);
        props.displayToast(
          toastTypes.SUCCESS,
          "Password modificata con successo"
        );
        break;
      case ResetPasswordResult.GENERIC_ERROR:
      default:
        props.displayToast(
          toastTypes.ERROR,
          "Errore durante il cambio della password: ripetere la richiesta di cambio password"
        );
        break;
    }
  };
  return (
    <Paper elevation={3} className={classes.paper}>
      <Formik
        initialValues={{
          password: "",
          confirmPassword: "",
        }}
        onSubmit={(values, formikHelpers) => {
          const token = qs.parse(props.location.search, {
            ignoreQueryPrefix: true,
          }).token;
          props
            .resetPassword(token, values.password)
            .then((result) => checkResult(result));
          formikHelpers.setSubmitting(false);
        }}
        validationSchema={Yup.object({
          password: Yup.string()
            .strict(true)
            .trim("Non sono ammessi spazi")
            .matches(
              /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[0-9])(?=.*[@$!%*#?&]).{8,}$/,
              "Deve essere lunga almeno 8 caratteri, di cui, almeno: una lettera, un numero ed un carattere speciale (@$!%*#?&)"
            )
            .required("Campo obbligatorio"),
          confirmPassword: Yup.string()
            .strict(true)
            .trim("Non sono ammessi spazi")
            .oneOf([Yup.ref("password"), null], "Le password non corrispondono")
            .required("Campo obbligatorio"),
        })}
      >
        <Form>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justify="center"
            className={classes.container}
          >
            <Grid item container xs={12} justify="center">
              <Image src={"/Logo.png"} />
            </Grid>
            <Grid item container xs={12} justify="center">
              <h5>Cambia password</h5>
            </Grid>
            <Grid item container xs={6} justify="center">
              <Typography>{"Nuova password "}</Typography>
            </Grid>
            <Grid item container xs={6} justify="center">
              <SimpleInputField
                type="password"
                className={classes.input}
                placeholder="Password"
                name="password"
              />
            </Grid>
            <Grid item container xs={6} justify="center">
              <Typography>{"Conferma password "}</Typography>
            </Grid>
            <Grid item container xs={6} justify="center">
              <SimpleInputField
                type="password"
                className={classes.input}
                placeholder="Password"
                name="confirmPassword"
              />
            </Grid>
            <Grid item container xs={12} justify="center">
              <Button variant="contained" color="primary" type="submit">
                Invia
              </Button>
            </Grid>
            <Grid item container xs={12} justify="center">
              <Typography className={classes.typography}>
                {"Torna al "}
              </Typography>
              <Link to={authPaths.login}>login</Link>
            </Grid>
          </Grid>
        </Form>
      </Formik>
      <PasswordResetModalWindow show={showSuccessModal} />
    </Paper>
  );
}

const mapDispatchToProps = {
  resetPassword,
  displayToast,
};

export default connect(null, mapDispatchToProps)(ResetPassword);
