import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { fetchAllBoxes } from "../../redux/box/backendActions";
import { makeStyles } from "@material-ui/core/styles";
import BoxCard from "./BoxCard";
import { Grid } from "@material-ui/core";
import { useHistory, useLocation } from 'react-router-dom'
import { aziendaPaths } from '../../constants/aziendaConstants'
import { fetchAllAlerts } from '../../redux/aziende/backendActions'

const useStyles = makeStyles(() => ({
  singleBox: {
    margin: "10px",
    cursor: "pointer"
  },
}));

function BoxListSection(props) {
  const [boxesWithAlerts, setBoxesWithAlerts] = useState(new Set());
  const history = useHistory();
  const fetchAllBoxes = props.fetchAllBoxes;
  const classes = useStyles();
  const fetchAllAlerts = props.fetchAllAlerts;
  const location = useLocation();
  const selectedAllevamentoId = useSelector(
    (state) => state.loginTransientState.selectedAllevamentoId
  );
  useEffect(() => {
    fetchAllBoxes();
    fetchAllAlerts();
  }, [fetchAllBoxes, fetchAllAlerts, location,selectedAllevamentoId]);
  useEffect(() => {
    let newBoxesWithAlerts = new Set();
    Object.keys(props.aziende.alertsByIDs).forEach( alertId => {
      let alert = props.aziende.alertsByIDs[alertId];
      newBoxesWithAlerts.add(alert.tipoIndicatoreCriticalInfo.tipoIndicatore.box.id.toString());
    });
    setBoxesWithAlerts(newBoxesWithAlerts);
  }, [props.aziende.alertsByIDs]);
  return (
    <>
      <h6>Box</h6>
    <Grid container alignItems="center" justify="center">
      {Object.keys(props.box.boxByIds).map((boxId) => {
        let box = props.box.boxByIds[boxId];
        return (
          <Grid key={boxId} item container lg={4} md={6} xs={12}>
            <BoxCard alertMode={boxesWithAlerts.has(boxId)} className={classes.singleBox} box={box} onClick={() => {history.push(aziendaPaths.boxIndicatori.base + "/" + boxId)}} />
          </Grid>
        );
      })}
    </Grid>
      </>
  );
}

const mapStateToProps = (state) => {
  return {
    box: state.box,
    aziende: state.aziende
  };
};

const mapDispatchToProps = {
  fetchAllBoxes,
  fetchAllAlerts
};

export default connect(mapStateToProps, mapDispatchToProps)(BoxListSection);
