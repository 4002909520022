import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import parseISO from "date-fns/parseISO";
import { getValueLabel } from "../constants/valoriIndicatoriConstants";
import { thresholdOperatorsTypes } from "../constants/indicatoriConstants";
import zoomPlugin from "chartjs-plugin-zoom";
import { v4 as uuidv4 } from "uuid";
import "chart.js/auto";
import "chartjs-adapter-date-fns";
import { Chart } from "chart.js";

Chart.register(zoomPlugin);

const getThresholdFillFirstArg = (cond) => {
  if (
    cond.thresholdOperator === thresholdOperatorsTypes.OPEN_INTERVAL ||
    cond.thresholdOperator === thresholdOperatorsTypes.CLOSE_INTERVAL
  ) {
    return "+2";
  } else if (
    cond.thresholdOperator ===
      thresholdOperatorsTypes.GREATER_THAN_OR_EQUAL_TO ||
    cond.thresholdOperator === thresholdOperatorsTypes.GREATER_THAN
  ) {
    return "end";
  } else if (
    cond.thresholdOperator === thresholdOperatorsTypes.LESS_THAN_OR_EQUAL_TO ||
    cond.thresholdOperator === thresholdOperatorsTypes.LESS_THAN
  ) {
    return "start";
  } else {
    return false;
  }
};

const getThresholdSecondFillFirstArg = (cond) => {
  if (
    cond.thresholdOperator === thresholdOperatorsTypes.OPEN_INTERVAL ||
    cond.thresholdOperator === thresholdOperatorsTypes.CLOSE_INTERVAL
  ) {
    return "end";
  } else if (
    cond.thresholdOperator ===
      thresholdOperatorsTypes.GREATER_THAN_OR_EQUAL_TO ||
    cond.thresholdOperator === thresholdOperatorsTypes.GREATER_THAN
  ) {
    return "start";
  } else if (
    cond.thresholdOperator === thresholdOperatorsTypes.LESS_THAN_OR_EQUAL_TO ||
    cond.thresholdOperator === thresholdOperatorsTypes.LESS_THAN
  ) {
    return "end";
  } else {
    return false;
  }
};


const getThresholdFillSecondArg = (cond) => {
  if (
    cond.thresholdOperator === thresholdOperatorsTypes.OPEN_INTERVAL ||
    cond.thresholdOperator === thresholdOperatorsTypes.CLOSE_INTERVAL
  ) {
    return false;
  } else {
    return false;
  }
};

const getThresholdSecondFillSecondArg = (cond) => {
  if (
    cond.thresholdOperator === thresholdOperatorsTypes.OPEN_INTERVAL ||
    cond.thresholdOperator === thresholdOperatorsTypes.CLOSE_INTERVAL
  ) {
    return "-4";
  } else {
    return false;
  }
};

export function GraficoStoricoValori(props) {
  const [graphData, setGraphData] = useState({
    labels: [],
    datasets: [
      {
        label: "Valore",
        data: [],
      },
    ],
  });
  useEffect(() => {
    if (
      !props.valori.lastValoriFilterResult ||
      props.valori.lastValoriFilterResult.length === 0
    ) {
      return;
    }
    const newGraphData = {
      labels: [],
      id: uuidv4(),
      datasets: [
        {
          label: "Valore",
          borderColor: "dimgrey",
          tension: 0,
          data: [],
        },
      ],
    };
    props.valori.lastValoriFilterResult
      .map((id) => {
        return {
          ...props.valori.valoriByIDs[id],
          period: parseISO(props.valori.valoriByIDs[id].period),
        };
      })
      .sort((a, b) => {
        return a.period - b.period;
      })
      .forEach((valore) => {
        newGraphData.datasets[0].data.push({
          x: valore.period,
          y: getValueLabel(valore.value, valore.tipoIndicatore.valuesType),
        });
      });
    let tipoIndicatore =
      props.valori.valoriByIDs[props.valori.lastValoriFilterResult[0]]
        .tipoIndicatore;
    if (
      tipoIndicatore.yellowThresholdConditions.length > 0 &&
      tipoIndicatore.redThresholdConditions.length > 0 &&
      tipoIndicatore.greenThresholdConditions.length > 0 &&
      !tipoIndicatore.greenThresholdConditions.find(
        (element) =>
          element.thresholdOperator === thresholdOperatorsTypes.EQUAL ||
          element.thresholdOperator === thresholdOperatorsTypes.NOT_EQUAL
      ) &&
      !tipoIndicatore.redThresholdConditions.find(
        (element) =>
          element.thresholdOperator === thresholdOperatorsTypes.EQUAL ||
          element.thresholdOperator === thresholdOperatorsTypes.NOT_EQUAL
      )
    ) {
      let thresholdMinX = new Date(newGraphData.datasets[0].data[0].x);
      let thresholdMaxX = new Date();
      if (
        newGraphData.datasets[0].data[0].x ===
        newGraphData.datasets[0].data.at(-1).x
      ) {
        thresholdMaxX.setTime(
          thresholdMinX.getTime() - 6 * 24 * 60 * 60 * 1000
        );
        thresholdMinX.setTime(
          thresholdMinX.getTime() + 6 * 24 * 60 * 60 * 1000
        );
      } else {
        thresholdMaxX = newGraphData.datasets[0].data.at(-1).x;
      }
      newGraphData.datasets.push({
        label: "Soglia valori ROSSA",
        id: uuidv4(),
        fill: getThresholdFillFirstArg(
          tipoIndicatore.redThresholdConditions[0]
        ),
        lineTension: 0.5,
        borderWidth: 0,
        borderColor: "red",
        pointRadius: 0,
        backgroundColor: "#de9b9b",
        data: [
          {
            x: thresholdMinX,
            y: tipoIndicatore.redThresholdConditions[0].arg1,
          },
          {
            x: thresholdMaxX,
            y: tipoIndicatore.redThresholdConditions[0].arg1,
          },
        ],
      });
      tipoIndicatore.redThresholdConditions.forEach((cond, index) => {
        if (
          cond.thresholdOperator === thresholdOperatorsTypes.CLOSE_INTERVAL ||
          cond.thresholdOperator === thresholdOperatorsTypes.OPEN_INTERVAL
        ) {
          newGraphData.datasets.push({
            label: undefined,
            id: uuidv4(),
            fill: false,
            lineTension: 0.5,
            borderWidth: 0,
            borderColor: "red",
            pointRadius: 0,
            backgroundColor: "#de9b9b",
            data: [
              {
                x: thresholdMinX,
                y: cond.arg2,
              },
              {
                x: thresholdMaxX,
                y: cond.arg2,
              },
            ],
          });
        }
        if (index === 0) {
          return;
        }
        newGraphData.datasets.push({
          label: undefined,
          id: uuidv4(),
          fill: getThresholdFillFirstArg(cond),
          lineTension: 0.5,
          borderWidth: 0,
          borderColor: "red",
          pointRadius: 0,
          backgroundColor: "#de9b9b",
          data: [
            {
              x: thresholdMinX,
              y: cond.arg1,
            },
            {
              x: thresholdMaxX,
              y: cond.arg1,
            },
          ],
        });
      });
      newGraphData.datasets.push({
        label: "Soglia valori VERDE",
        id: uuidv4(),
        fill: getThresholdFillFirstArg(
          tipoIndicatore.greenThresholdConditions[0]
        ),
        lineTension: 0.5,
        borderWidth: 0,
        borderColor: "green",
        pointRadius: 0,
        backgroundColor: "#9bdeb9",
        data: [
          {
            x: thresholdMinX,
            y: tipoIndicatore.greenThresholdConditions[0].arg1,
          },
          {
            x: thresholdMaxX,
            y: tipoIndicatore.greenThresholdConditions[0].arg1,
          },
        ],
      });
      newGraphData.datasets.push({
        label: "Soglia valori GIALLA",
        id: uuidv4(),
        fill: getThresholdSecondFillFirstArg(
          tipoIndicatore.greenThresholdConditions[0]
        ),
        lineTension: 0.5,
        borderWidth: 0,
        borderColor: "yellow",
        pointRadius: 0,
        backgroundColor: "#ffe066",
        data: [
          {
            x: thresholdMinX,
            y: tipoIndicatore.greenThresholdConditions[0].arg1,
          },
          {
            x: thresholdMaxX,
            y: tipoIndicatore.greenThresholdConditions[0].arg1,
          },
        ],
      });
      tipoIndicatore.greenThresholdConditions.forEach((cond, index) => {
        if ((
          cond.thresholdOperator === thresholdOperatorsTypes.CLOSE_INTERVAL ||
          cond.thresholdOperator === thresholdOperatorsTypes.OPEN_INTERVAL
        ) && index === 0) {
          newGraphData.datasets.push({
            label: undefined,
            id: uuidv4(),
            fill: getThresholdFillSecondArg(cond),
            lineTension: 0.5,
            borderWidth: 2,
            borderColor: "green",
            pointRadius: 0,
            backgroundColor: "#9bdeb9",
            data: [
              {
                x: thresholdMinX,
                y: cond.arg2,
              },
              {
                x: thresholdMaxX,
                y: cond.arg2,
              },
            ],
          });
          newGraphData.datasets.push({
            label: undefined,
            id: uuidv4(),
            fill: getThresholdSecondFillSecondArg(cond),
            lineTension: 0.5,
            borderWidth: 0,
            borderColor: "yellow",
            pointRadius: 0,
            backgroundColor: "#ffe066",
            data: [
              {
                x: thresholdMinX,
                y: cond.arg2,
              },
              {
                x: thresholdMaxX,
                y: cond.arg2,
              },
            ],
          });
        }
        newGraphData.datasets.push({
          label: undefined,
          id: uuidv4(),
          fill: getThresholdFillFirstArg(cond),
          lineTension: 0.5,
          borderWidth: 0,
          borderColor: "green",
          pointRadius: 0,
          backgroundColor: "#9bdeb9",
          data: [
            {
              x: thresholdMinX,
              y: cond.arg1,
            },
            {
              x: thresholdMaxX,
              y: cond.arg1,
            },
          ],
        });
        newGraphData.datasets.push({
          label: undefined,
          id: uuidv4(),
          fill: getThresholdSecondFillFirstArg(cond),
          lineTension: 0.5,
          borderWidth: 0,
          borderColor: "yellow",
          pointRadius: 0,
          backgroundColor: "#ffe066",
          data: [
            {
              x: thresholdMinX,
              y: cond.arg1,
            },
            {
              x: thresholdMaxX,
              y: cond.arg1,
            },
          ],
        });
      });
    }
    console.log(newGraphData);
    setGraphData(newGraphData);
  }, [props.valori]);
  return (
    <div className={props.className}>
      <Line
        data={graphData}
        datasetIdKey="id"
        options={{
          maintainAspectRatio: false,
          scales: {
            x: {
              type: "time",
              time: {
                unit: "day",
                tooltipFormat: "dd MMM yyyy",
              },
            },
            y: {},
          },
          plugins: {
            zoom: {
              zoom: {
                wheel: {
                  enabled: true,
                },
                mode: "xy",
                speed: 100,
              },
              pan: {
                enabled: true,
                mode: "xy",
                speed: 100,
              },
            },
            legend: {
              labels: {
                filter: function (label) {
                  if (label.text) return true;
                },
              },
            },
          },
        }}
      />
    </div>
  );
}
