import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfile } from "../redux/auth/backendActions";
import {
  fetchSingleAzienda,
  patchSingleAzienda,
} from "../redux/aziende/backendActions";
import { Form, Formik } from "formik";
import { AlertFieldsSchema } from "../constants/aziendaConstants";
import { ResetForm } from "../Common/GenericFormComponents/ResetForm";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import {
  booleanRadioLabels,
  booleanRadioValues,
} from "../constants/miscConstants";
import { CustomRadioSelectorField } from "../Common/GenericFormComponents/CustomRadioSelectorField";
import { SimpleInputField } from "../Common/GenericFormComponents/SimpleInputField";
import { SubmitButton } from "../Common/GenericFormComponents/SubmitButton";
import { toastTypes } from "../constants/generalConstants";
import { ScrollToError } from "../Common/GenericFormComponents/ScrollToError";
import { Hidden } from "@material-ui/core";

const formInitializer = () => {
  return {
    id: undefined,
    alertEmailEnabled: "",
    alertEmail: "",
  };
};

const useStyles = makeStyles(() => ({
  element: {
    marginBottom: "35px",
  },
  input: {
    boxSizing: "border-box",
    height: "2em",
    width: "150px",
  },
}));

export function AlertFieldsSection(props) {
  const classes = useStyles();
  const [showedValues, setShowedValues] = useState(formInitializer());
  const dispatch = useDispatch();
  const aziende = useSelector((state) => state.aziende);
  const profile = useSelector((state) => state.loginTransientState.profile);
  const [scrollToError, setScrollToError] = useState(false);
  useEffect(() => {
    if (props.aziendaId) {
      dispatch(fetchSingleAzienda(props.aziendaId));
    } else {
      dispatch(fetchProfile());
    }
  }, [dispatch, props.aziendaId]);
  useEffect(() => {
    if (props.aziendaId) {
      if (!aziende.aziendeByIDs[props.aziendaId]) {
        setShowedValues(formInitializer());
      } else {
        setShowedValues({
          id: props.aziendaId,
          alertEmailEnabled:
            aziende.aziendeByIDs[props.aziendaId].alertEmailEnabled,
          alertEmail: aziende.aziendeByIDs[props.aziendaId].alertEmail,
        });
      }
    } else {
      if (!profile) {
        setShowedValues(formInitializer());
      } else {
        setShowedValues({
          id: profile.id,
          alertEmailEnabled: profile.alertEmailEnabled,
          alertEmail: profile.alertEmail,
        });
      }
    }
  }, [aziende, profile, props.aziendaId]);
  return (
    <div className={props.className}>
      <Formik
        initialValues={showedValues}
        onSubmit={(values) => {
          dispatch(patchSingleAzienda(values.id, values)).then(() => {
            if (props.aziendaId) {
              fetchSingleAzienda(props.aziendaId);
            } else {
              fetchProfile();
            }
          });
        }}
        validationSchema={AlertFieldsSchema}
        enableReinitialize
      >
        {(formik) => (
          <Form>
            <ResetForm showedValues={showedValues} />
            <h6> Alert indicatori critici </h6>
            <Grid container alignItems="center" justify="center">
              <Grid className={classes.element} item container md={3} xs={6}>
                Abilita notifiche email indicatori critici
              </Grid>
              <Grid className={classes.element} item container md={3} xs={6}>
                <div className={classes.input}>
                  <CustomRadioSelectorField
                    id={"alertEmailEnabled"}
                    optionsValues={booleanRadioValues}
                    optionsLabels={booleanRadioLabels}
                    field={"alertEmailEnabled"}
                  />
                </div>
              </Grid>
              {!formik.values["alertEmailEnabled"] && (
                <Hidden smDown>
                  <Grid className={classes.element} item container md={6} />
                </Hidden>
              )}
              {formik.values["alertEmailEnabled"] && (
                <>
                  <Grid
                    className={classes.element}
                    item
                    container
                    md={3}
                    xs={6}
                  >
                    Email per invio notifiche
                  </Grid>
                  <Grid
                    className={classes.element}
                    item
                    container
                    md={3}
                    xs={6}
                  >
                    <SimpleInputField
                      name="alertEmail"
                      type="email"
                      className={classes.input}
                    />
                  </Grid>
                </>
              )}
              <Grid
                className={`${classes.element}`}
                item
                container
                justify={"center"}
                xs={12}
              >
                <SubmitButton
                  onFormError={(error) => {
                    props.displayToast(toastTypes.ERROR, error);
                    setScrollToError(!scrollToError);
                  }}
                  label={"Salva impostazioni alert"}
                />
              </Grid>
              <ScrollToError scroll={scrollToError} />
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
}
