export const RECEIVE_ALL_INDICATORE = "RECEIVE_ALL_INDICATORE";
export const RECEIVE_SINGLE_INDICATORE = "RECEIVE_SINGLE_INDICATORE";

export function receiveAllIndicatore(indicatori) {
  return { type: RECEIVE_ALL_INDICATORE, indicatori };
}

export function receiveSingleIndicatore(indicatore) {
  return { type: RECEIVE_SINGLE_INDICATORE, indicatore };
}
