import React from 'react';
import TableCell from '@material-ui/core/TableCell'
import { TableRow } from '@material-ui/core'

export function AttrezzatureStallaRow(props){
  return (
    <TableRow key={props.rowData[0]}>
      <TableCell>{props.rowData[1]}</TableCell>
    </TableRow>
  );
}