import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import {
  Allevamento,
  AllevamentoScheme,
  CodiceSpecieAllevata,
} from "../../constants/aziendaConstants";
import { useDispatch, useSelector } from "react-redux";
import {
  createSingleAllevamento,
  deleteSingleAllevamento,
  editSingleAllevamento,
  fetchSingleAllevamento,
} from "../../redux/aziende/backendActions";
import { Form, Formik } from "formik";
import { ResetForm } from "../GenericFormComponents/ResetForm";
import { Grid, Typography } from "@material-ui/core";
import { SimpleInputField } from "../GenericFormComponents/SimpleInputField";
import { SimpleSelectField } from "../GenericFormComponents/SimpleSelectField";
import { ScrollToError } from "../GenericFormComponents/ScrollToError";
import { FormActionButtons } from "../FormActionButtons";
import { toastTypes } from "../../constants/generalConstants";
import { fetchProfile } from "../../redux/auth/backendActions";
import { displayToast } from "../../redux/general/backendActions";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: "100%",
    maxHeight: "100%",
  },
  form: {
    marginBottom: "0",
    overflowY: "auto",
    maxHeight: "calc(100% - 100px)",
  },
  alwaysOnTopSection: {
    height: "80px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  input: {
    boxSizing: "border-box",
    height: "2em",
    width: "150px",
  },
  select: {
    minHeight: "2em",
    width: "150px",
  },
  element: {
    marginBottom: "35px",
  },
}));

export function SingleAllevamentoPage(props) {
  const location = useLocation();
  const [aziendaId, setAziendaId] = useState(undefined);
  const setTitle = props.setTitle;
  const [editMode, setEditMode] = useState(false);
  const [scrollToError, setScrollToError] = useState(false);
  const [showedAllevamento, setShowedAllevamento] = useState(new Allevamento());
  const dispatch = useDispatch();
  const loginTransientState = useSelector((state) => state.loginTransientState);
  const aziende = useSelector((state) => state.aziende);
  useEffect(() => {
    if (props.createMode) {
      setEditMode(true);
      setTitle("Crea allevamento");
    } else {
      setEditMode(false);
      dispatch(fetchSingleAllevamento(props.match.params.allevamentoId));
    }
  }, [location, props.createMode, setTitle, props.match, dispatch]);
  useEffect(() => {
    if (props.aziendaMode) {
      if (!loginTransientState.profile) {
        dispatch(fetchProfile());
      } else {
        setAziendaId(loginTransientState.profile.id);
      }
    } else {
      setAziendaId(props.match.params.aziendaId);
    }
  }, [props.match, props.aziendaMode, loginTransientState, dispatch]);
  useEffect(() => {
    if (!props.createMode) {
      if (aziende.allevamentiByIDs[props.match.params.allevamentoId]) {
        setShowedAllevamento(
          aziende.allevamentiByIDs[props.match.params.allevamentoId]
        );
        if (props.aziendaMode) {
          setTitle(
            `Allevamento ${
              aziende.allevamentiByIDs[props.match.params.allevamentoId]
                .codiceAziendale
            }`
          );
        } else {
          setTitle(
            `${
              aziende.allevamentiByIDs[props.match.params.allevamentoId].azienda
                .denominazione
            } - allevamento ${
              aziende.allevamentiByIDs[props.match.params.allevamentoId]
                .codiceAziendale
            }`
          );
        }
      } else {
        let allevamento = new Allevamento();
        setShowedAllevamento(allevamento);
      }
    } else {
      let allevamento = new Allevamento();
      allevamento.azienda.id = aziendaId;
      setShowedAllevamento(allevamento);
    }
  }, [
    location,
    aziende,
    props.match,
    props.createMode,
    setTitle,
    aziendaId,
    props.aziendaMode,
  ]);
  const classes = useStyles();
  return (
    <Formik
      initialValues={showedAllevamento}
      onSubmit={(values) => {
        if (props.createMode) {
          dispatch(createSingleAllevamento(values)).then(() => {
            if (props.match.params.aziendaId) {
              props.onCreation(props.match.params.aziendaId);
            } else {
              props.onCreation();
            }
          });
        } else {
          dispatch(editSingleAllevamento(values.id, values)).then(() => {
            dispatch(fetchSingleAllevamento(props.match.params.allevamentoId));
            setEditMode(false);
          });
        }
      }}
      validationSchema={AllevamentoScheme}
      enableReinitialize
    >
      <Form className={classes.root}>
        <ResetForm showedValues={showedAllevamento} />
        <div className={classes.form}>
          <h6>Dati stalla</h6>
          <Grid container alignItems="center" justify="center">
            <Grid className={classes.element} item container md={3} xs={6}>
              <Typography>Codice aziendale</Typography>
            </Grid>
            <Grid
              className={classes.element}
              item
              container
              md={3}
              xs={6}
              justify="center"
            >
              <SimpleInputField
                type="text"
                className={classes.input}
                name="codiceAziendale"
                disabled={!editMode}
              />
            </Grid>
            <Grid className={classes.element} item container md={3} xs={6}>
              <Typography>Identificatore fiscale</Typography>
            </Grid>
            <Grid
              className={classes.element}
              item
              container
              md={3}
              xs={6}
              justify="center"
            >
              <SimpleInputField
                type="text"
                className={classes.input}
                name="identificatoreFiscale"
                disabled={!editMode}
              />
            </Grid>
            <Grid className={classes.element} item container md={3} xs={6}>
              <Typography>Codice specie</Typography>
            </Grid>
            <Grid
              className={classes.element}
              item
              container
              md={3}
              xs={6}
              justify="center"
            >
              <SimpleSelectField
                name={"codiceSpecie"}
                id="codiceSpecie"
                className={classes.input}
                options={CodiceSpecieAllevata}
                disabled={!editMode}
              />
            </Grid>
            <Grid className={classes.element} item container md={3} xs={6}>
              <Typography>Note</Typography>
            </Grid>
            <Grid
              className={classes.element}
              item
              container
              md={3}
              xs={6}
              justify="center"
            >
              <SimpleInputField
                type="text"
                className={classes.input}
                name="note"
                disabled={!editMode}
              />
            </Grid>
          </Grid>
        </div>
        <div className={classes.alwaysOnTopSection}>
          <ScrollToError scroll={scrollToError} />
          <FormActionButtons
            createMode={props.createMode}
            enableDelete
            editMode={editMode}
            onEditModeChange={(newValue) => {
              setEditMode(newValue);
              if (newValue === false) {
                dispatch(
                  fetchSingleAllevamento(props.match.params.allevamentoId)
                );
              }
            }}
            onCancelCreate={() => {
              if (props.match.params.aziendaId) {
                props.onCreation(props.match.params.aziendaId);
              } else {
                props.onCreation();
              }
            }}
            onFormError={(error) => {
              dispatch(displayToast(toastTypes.ERROR, error));
              setScrollToError(!scrollToError);
            }}
            onDelete={() => {
              if (props.aziendaMode){
                if (Object.keys(loginTransientState.currentUserAllevamentiByIds).length > 1){
                  dispatch(
                    deleteSingleAllevamento(props.match.params.allevamentoId)
                  ).then(props.onDelete());
                } else {
                  dispatch(displayToast(toastTypes.ERROR, "L'azienda deve avere almeno un allevamento"))
                }
              } else {
                if (Object.keys(aziende.aziendeByIDs[aziendaId].allevamenti).length > 1){
                  dispatch(
                    deleteSingleAllevamento(props.match.params.allevamentoId)
                  ).then(props.onDelete());
                } else {
                  dispatch(displayToast(toastTypes.ERROR, "L'azienda deve avere almeno un allevamento"))
                }
              }
            }}
          />
        </div>
      </Form>
    </Formik>
  );
}
