import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { FileSelectorSection } from "./FileSelectorSection";
import { FileErrorSection } from "./FileErrorSection";
import { StagingSection } from "./StagingSection";
import {
  commitStagingArea,
  fetchStagedValore,
  fetchValoriStagingArea,
  patchStagedValore,
  uploadCsvValoriFile,
} from "../../redux/valoriindicatori/backendActions";
import { CommitOverviewSection } from "./CommitOverviewSection";
import { fetchSingleIndicatore } from "../../redux/indicatori/backendActions";
import { fetchSingleAzienda } from "../../redux/aziende/backendActions";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: "100%",
    maxHeight: "100%",
  },
  mainSection: {
    marginBottom: "0",
    overflowY: "auto",
    maxHeight: "calc(100% - 100px)",
  },
  alwaysOnTopSection: {
    height: "80px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  subSection: {
    marginBottom: "15px",
  },
  actionButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    margin: "15px",
  },
}));

const PAGE_STATUS = Object.freeze({
  NO_FILE_SELECTED: 1,
  FILE_WITH_ERRORS: 2,
  FILE_LOADED: 3,
  FILE_COMMITTED: 4,
});

function CaricaValoriIndicatoriPage(props) {
  const [pageStatus, setPageStatus] = useState(PAGE_STATUS.NO_FILE_SELECTED);
  const [filename, setFilename] = useState("");
  const [enableSelectFile, setEnableSelectFile] = useState(true);
  const classes = useStyles();
  const setTitle = props.setTitle;
  useEffect(() => {
    switch (pageStatus) {
      default:
      case PAGE_STATUS.NO_FILE_SELECTED:
        setFilename("");
        setEnableSelectFile(true);
        break;
      case PAGE_STATUS.FILE_WITH_ERRORS:
        setEnableSelectFile(true);
        break;
      case PAGE_STATUS.FILE_LOADED:
      case PAGE_STATUS.FILE_COMMITTED:
        setEnableSelectFile(false);
        break;
    }
  }, [pageStatus]);
  useEffect(() => {
    setTitle("Carica valori indicatori");
  }, [setTitle]);
  return (
    <div className={classes.root}>
      <div className={classes.mainSection}>
        {pageStatus !== PAGE_STATUS.FILE_COMMITTED ? (
          <>
            <FileSelectorSection
              className={classes.subSection}
              enableSelectFile={enableSelectFile}
              filename={filename}
              onClickLoad={(file) => {
                return props
                  .uploadCsvValoriFile(file)
                  .then(() => {
                    setFilename(file.name);
                    setPageStatus(PAGE_STATUS.FILE_LOADED);
                    return Promise.resolve();
                  })
                  .catch((error) => {
                    setPageStatus(PAGE_STATUS.FILE_WITH_ERRORS);
                    return Promise.reject(error);
                  });
              }}
            />
            {pageStatus >= PAGE_STATUS.FILE_WITH_ERRORS && (
              <FileErrorSection
                className={classes.subSection}
                errors={props.valori.lastCsvError}
              />
            )}
            {pageStatus >= PAGE_STATUS.FILE_LOADED && (
              <StagingSection
                className={classes.subSection}
                editStagedValoreFunc={props.patchStagedValore}
                fetchStagedValore={props.fetchStagedValore}
                fetchValoriStagingArea={props.fetchValoriStagingArea}
                lastStagingAreaId={props.valori.lastStagingAreaId}
                valoriStagingAreaByIDs={props.valori.valoriStagingAreaByIDs}
                stagedValoriByIDs={props.valori.stagedValoriByIDs}
                fetchSingleIndicatore={props.fetchSingleIndicatore}
              />
            )}
          </>
        ) : (
          <CommitOverviewSection />
        )}
      </div>
      <div className={`${classes.alwaysOnTopSection} ${classes.actionButtons}`}>
        {pageStatus === PAGE_STATUS.FILE_LOADED && (
          <>
            <Button
              className={classes.button}
              key="cancel"
              variant="contained"
              color="primary"
              type="button"
              onClick={() => {
                setPageStatus(PAGE_STATUS.NO_FILE_SELECTED);
              }}
            >
              Annulla
            </Button>
            <Button
              className={classes.button}
              key="commit"
              variant="contained"
              color="primary"
              type="button"
              onClick={() => {
                props
                  .commitStagingArea(props.valori.lastStagingAreaId)
                  .then(() => setPageStatus(PAGE_STATUS.FILE_COMMITTED));
              }}
            >
              Inserisci valori
            </Button>
          </>
        )}
        {pageStatus === PAGE_STATUS.FILE_COMMITTED && (
          <Button
            className={classes.button}
            key="back"
            variant="contained"
            color="primary"
            type="button"
            onClick={() => {
              setPageStatus(PAGE_STATUS.NO_FILE_SELECTED);
            }}
          >
            Torna alla selezione file
          </Button>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    valori: state.valori,
    indicatori: state.indicatori,
    aziende: state.aziende,
  };
};

const mapDispatchToProps = {
  uploadCsvValoriFile,
  patchStagedValore,
  fetchStagedValore,
  fetchValoriStagingArea,
  fetchSingleIndicatore,
  commitStagingArea,
  fetchSingleAzienda,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CaricaValoriIndicatoriPage);
