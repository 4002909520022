import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from 'react-redux'
import {
  fetchAndSetAsShowedSingleAlert,
  setAlertProtocolStatus,
} from "../../redux/aziende/backendActions";
import MUIDataTable from "mui-datatables";
import { createTheme, MuiThemeProvider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ProtocolloDialog from "../ProtocolloDialog";
import { IndicatoreCriticoOverviewSection } from "../IndicatoreCriticoOverviewSection";
import { fetchAllLastValoreOfIndicatoreAsAzienda } from "../../redux/valoriindicatori/backendActions";
import {
  getPeriodLabel,
  getValueLabel,
  getValueState,
  ValoreIndicatore,
} from "../../constants/valoriIndicatoriConstants";
import { TipoIndicatoreCriticalInfo } from "../../constants/indicatoriConstants";
import { textLabels } from "../../constants/miscConstants";
import { StatusValoreVisualizer } from "../../Common/StatusValoreVisualizer";
import { useHistory } from 'react-router-dom'
import { aziendaPaths } from '../../constants/aziendaConstants'
import { defaultRowOptions } from '../../constants/generalConstants'
import { setMainTableRows } from '../../redux/general/basicActions'

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: "100%",
    maxHeight: "100%",
  },
  table: {
    marginBottom: "0",
    overflowY: "auto",
    maxHeight: "calc(100% - 100px)",
  },
  alwaysOnTopSection: {
    height: "80px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  overview: {
    marginBottom: "15px",
  },
  alertRowText: {
    color: "red",
  },
  alertText: {
    color: "white",
    backgroundColor: "red",
    marginLeft: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
    fontWeight: "bold",
  },
  alertValueField: {
    display: "flex",
    flexDirection: "row",
  },
}));

const getMuiTheme = () =>
  createTheme({
    overrides: {
      MuiToolbar: {
        root: {
          backgroundColor: "#FEFDFD",
        },
      },
      MuiTable: {
        root: {
          backgroundColor: "#FEFDFD",
        },
      },
      MuiTableCell: {
        root: {
          height: "30px",
        },
      },
      MUIDataTableBodyRow: {
        root: {
          cursor: "pointer",
        },
      },
      MUIDataTableHeadCell: {
        fixedHeader: {
          backgroundColor: "#C4C4C4",
        },
      },
    },
  });

function AlertPage(props) {
  const history = useHistory();
  const classes = useStyles();
  const setTitle = props.setTitle;
  const fetchAllLastValoreOfIndicatoreAsAzienda =
    props.fetchAllLastValoreOfIndicatoreAsAzienda;
  const fetchAndSetAsShowedSingleAlert = props.fetchAndSetAsShowedSingleAlert;
  const [data, setData] = useState([]);
  const [criticalInfo, setCriticalInfo] = useState(
    new TipoIndicatoreCriticalInfo()
  );
  const [lastValoreCritical, setLastValoreCritical] = useState(
    new ValoreIndicatore()
  );
  const [dialogOpen, setDialogOpen] = useState(false);
  const selectedAllevamentoId = useSelector(
    (state) => state.loginTransientState.selectedAllevamentoId
  );
  const dispatch = useDispatch();
  const mainTableRows = useSelector(
    (state) => state.appPreferences.mainWindowTableRows
  );
  useEffect(() => {
    history.push(aziendaPaths.boxIndicatori.base);
  }, [history, selectedAllevamentoId])
  useEffect(() => {
    fetchAllLastValoreOfIndicatoreAsAzienda();
  }, [fetchAllLastValoreOfIndicatoreAsAzienda]);
  useEffect(() => {
    fetchAndSetAsShowedSingleAlert(props.match.params.id);
  }, [props.match.params.id, fetchAndSetAsShowedSingleAlert]);
  useEffect(() => {
    if (props.aziende.alertsByIDs[props.match.params.id]) {
      setCriticalInfo(
        props.aziende.alertsByIDs[props.match.params.id]
          .tipoIndicatoreCriticalInfo
      );
    }
  }, [props.match.params.id, props.aziende]);
  useEffect(() => {
    if (criticalInfo.tipoIndicatore) {
      setTitle(
        `Indicatore ${criticalInfo.tipoIndicatore.code} - Dettaglio alert`
      );
    }
  }, [criticalInfo, setTitle]);
  useEffect(() => {
    if (criticalInfo.tipoIndicatore) {
      let tipoIndicatoreId = criticalInfo.tipoIndicatore.id;
      if (props.valori.lastValoreByTipoIndicatoreId[tipoIndicatoreId]) {
        setLastValoreCritical(props.valori.valoriByIDs[tipoIndicatoreId]);
      }
    }
  }, [criticalInfo, props.valori]);
  useEffect(() => {
    let newData = criticalInfo.indicatoriDipendenti.map(
      (indicatoreDipendente) => {
        let tipoIndicatore = indicatoreDipendente.tipoIndicatoreDipendente;
        let valoreIndDipId =
          props.valori.lastValoreByTipoIndicatoreId[tipoIndicatore.id];
        if (!valoreIndDipId) {
          return {
            id: tipoIndicatore.id,
            codeFlat: tipoIndicatore.code,
            nameFlat: tipoIndicatore.name,
            boxFlat: tipoIndicatore.box.name,
            period: "",
            value: "Nessun valore disponibile",
            state: "",
            priority: "",
          };
        }
        let valoreIndDip = props.valori.valoriByIDs[valoreIndDipId];
        return {
          id: tipoIndicatore.id,
          codeFlat: tipoIndicatore.code,
          nameFlat: tipoIndicatore.name,
          boxFlat: tipoIndicatore.box.name,
          period: getPeriodLabel(valoreIndDip, tipoIndicatore),
          value: getValueLabel(valoreIndDip.value, tipoIndicatore.valuesType),
          state: getValueState(valoreIndDip, tipoIndicatore),
          priority: indicatoreDipendente.priorityRating,
        };
      }
    );
    setData(newData);
  }, [criticalInfo, props.valori]);
  const options = {
    elevation: 0,
    selectableRows: "none",
    filter: false,
    print: false,
    download: false,
    search: false,
    viewColumns: false,
    textLabels: textLabels,
    rowsPerPageOptions: defaultRowOptions,
    rowsPerPage: mainTableRows,
    onChangeRowsPerPage: (rows) => dispatch(setMainTableRows(rows)),
    sortOrder: {
      name: "priority",
      direction: "asc",
    },
    setTableProps: () => {
      return {
        padding: "none",
      };
    },
  };
  const columns = [
    {
      name: "id",
      options: {
        display: "excluded",
      },
    },
    {
      name: "codeFlat",
      label: "Cod.",
    },
    {
      name: "nameFlat",
      label: "Nome",
    },
    {
      name: "boxFlat",
      label: "Box",
    },
    {
      name: "period",
      label: "Periodo ultimo valore",
    },
    {
      name: "value",
      label: "Ultimo valore",
    },
    {
      name: "priority",
      label: "Priorità",
    },
    {
      name: "state",
      label: "Stato",
      options: {
        customBodyRenderLite: (dataIndex) => {
          if (data[dataIndex].state === "") {
            return null;
          } else {
            return <StatusValoreVisualizer status={data[dataIndex].state} />;
          }
        },
      },
    },
  ];
  return (
    <div className={classes.root}>
      <div className={classes.table}>
        <IndicatoreCriticoOverviewSection
          valore={lastValoreCritical}
          criticalInfo={criticalInfo}
          className={classes.overview}
        />
        <h6>Indicatori dipendenti</h6>
        <MuiThemeProvider theme={getMuiTheme()}>
          <MUIDataTable data={data} columns={columns} options={options} />
        </MuiThemeProvider>
      </div>
      <div className={classes.alwaysOnTopSection}>
        <Button
          variant="contained"
          color="primary"
          type="button"
          onClick={() => setDialogOpen(true)}
        >
          Visualizza protocollo alert
        </Button>
      </div>
      <ProtocolloDialog
        open={dialogOpen}
        alert={props.aziende.alertsByIDs[props.match.params.id]}
        criticalInfo={criticalInfo}
        onClose={() => setDialogOpen(false)}
        setAlertProtocolStatus={(id, values) => {
          setDialogOpen(false);
          props.setAlertProtocolStatus(id, values).then(() => {
            fetchAndSetAsShowedSingleAlert(props.match.params.id);
          });
        }}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    valori: state.valori,
    aziende: state.aziende,
  };
};

const mapDispatchToProps = {
  fetchAndSetAsShowedSingleAlert,
  fetchAllLastValoreOfIndicatoreAsAzienda,
  setAlertProtocolStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertPage);
