import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import React from "react";
import ImpostazioniUtentePage from "../Common/Pages/ImpostazioniUtentePage";
import { adminPaths } from "../constants/adminConstants";
import { BoxListPage } from "./Pages/BoxListPage";
import { UserListPage } from "./Pages/UserListPage";
import { SingleUserPage } from "./Pages/SingleUserPage";
import { CreateUserPage } from "./Pages/CreateUserPage";
import ListaStallePage from "../Common/Pages/ListaStallePage";
import { ListaAllevamentiPage } from "../Common/Pages/ListaAllevamentiPage";
import SingleStallaPage from "../Common/Pages/SingleStallaPage";
import { SingleAllevamentoPage } from "../Common/Pages/SingleAllevamentoPage";
import { useDispatch } from "react-redux";
import {
  fetchSingleAzienda,
} from "../redux/aziende/backendActions";

export function AdminDashboard(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <Switch>
      <Route
        exact
        path={`${adminPaths.listaUtenti}`}
        render={() => {
          return <UserListPage setTitle={props.setTitle} />;
        }}
      />
      <Route
        exact
        path={`${adminPaths.listaUtenti}/:id`}
        render={(routeProps) => {
          return <SingleUserPage setTitle={props.setTitle} {...routeProps} />;
        }}
      />
      <Route
        exact
        path={`${adminPaths.azienda}/:id${adminPaths.stalleSubpath}`}
        render={(routeProps) => {
          return (
            <ListaStallePage
              setTitle={props.setTitle}
              enableSelectStalla
              onRowClick={(stallaId) =>
                history.push(
                  `${adminPaths.azienda}/${routeProps.match.params.id}${adminPaths.stalleSubpath}/${stallaId}`
                )
              }
              onCreateStallaClick={() =>
                history.push(
                  adminPaths.azienda +
                    "/" +
                    routeProps.match.params.id +
                    adminPaths.creaStallaSubPath
                )
              }
              {...routeProps}
            />
          );
        }}
      />
      <Route
        exact
        path={`${adminPaths.azienda}/:id${adminPaths.allevamentiSubpath}`}
        render={(routeProps) => {
          return (
            <ListaAllevamentiPage
              setTitle={props.setTitle}
              onRowClick={(allevamentoId) =>
                history.push(
                  `${adminPaths.azienda}/${routeProps.match.params.id}${adminPaths.allevamentiSubpath}/${allevamentoId}`
                )
              }
              onCreateAllevamentoClick={() =>
                history.push(
                  adminPaths.azienda +
                    "/" +
                    routeProps.match.params.id +
                    adminPaths.creaAllevamentoSubPath
                )
              }
              {...routeProps}
            />
          );
        }}
      />
      <Route
        exact
        path={`${adminPaths.azienda}/:aziendaId${adminPaths.stalleSubpath}/:stallaId`}
        render={(routeProps) => {
          return (
            <SingleStallaPage
              setTitle={props.setTitle}
              {...routeProps}
              onDelete={() => {
                history.push(
                  `${adminPaths.azienda}/${routeProps.match.params.aziendaId}${adminPaths.stalleSubpath}`
                );
                dispatch(fetchSingleAzienda(routeProps.match.params.aziendaId));
              }}
            />
          );
        }}
      />
      <Route
        exact
        path={`${adminPaths.azienda}/:aziendaId${adminPaths.allevamentiSubpath}/:allevamentoId`}
        render={(routeProps) => {
          return (
            <SingleAllevamentoPage
              setTitle={props.setTitle}
              {...routeProps}
              onDelete={() => {
                history.push(
                  `${adminPaths.azienda}/${routeProps.match.params.aziendaId}${adminPaths.allevamentiSubpath}`
                );
                dispatch(fetchSingleAzienda(routeProps.match.params.aziendaId));
              }}
            />
          );
        }}
      />
      <Route
        exact
        path={`${adminPaths.azienda}/:aziendaId${adminPaths.creaStallaSubPath}`}
        render={(routeProps) => {
          return (
            <SingleStallaPage
              setTitle={props.setTitle}
              createMode
              onCreation={() =>
                history.push(
                  `${adminPaths.azienda}/${routeProps.match.params.aziendaId}${adminPaths.stalleSubpath}`
                )
              }
              {...routeProps}
            />
          );
        }}
      />
      <Route
        exact
        path={`${adminPaths.azienda}/:aziendaId${adminPaths.creaAllevamentoSubPath}`}
        render={(routeProps) => {
          return (
            <SingleAllevamentoPage
              setTitle={props.setTitle}
              createMode
              onCreation={() =>
                history.push(
                  `${adminPaths.azienda}/${routeProps.match.params.aziendaId}${adminPaths.allevamentiSubpath}`
                )
              }
              {...routeProps}
            />
          );
        }}
      />
      <Route
        exact
        path={`${adminPaths.creaUtente}`}
        render={(routeProps) => {
          return <CreateUserPage setTitle={props.setTitle} {...routeProps} />;
        }}
      />
      <Route
        exact
        path={`${adminPaths.box}`}
        render={(routeProps) => {
          return <BoxListPage setTitle={props.setTitle} {...routeProps} />;
        }}
      />
      <Route
        exact
        path={`${adminPaths.impostazioniUtente}`}
        render={() => {
          return <ImpostazioniUtentePage setTitle={props.setTitle} />;
        }}
      />
      <Redirect to={adminPaths.listaUtenti} />
    </Switch>
  );
}
