import React, { useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import {
  stagedValoreIndicatoreSchema,
} from '../../constants/valoriIndicatoriConstants'
import { IconButton, TableRow } from '@material-ui/core'
import TableCell from '@material-ui/core/TableCell'
import PeriodSelectorField from '../Valori/PeriodSelectorField'
import ValueField from '../Valori/ValueField'
import EditIcon from '@material-ui/icons/Edit'
import CheckIcon from '@material-ui/icons/Check'
import CancelIcon from '@material-ui/icons/Cancel'

const dataInit = () => {
  return {
    id: "",
    period: "",
    value: "",
  };
};

export function StagingAreaRow(props) {
  const [data, setData] = useState(dataInit());
  const [editMode, setEditMode] = useState(false);
  useEffect(() => {
    let newData = dataInit();
    newData.period = props.valoreRaw.period;
    newData.id = props.valoreRaw.id;
    newData.value = props.valoreRaw.value;
    setData(newData);
  }, [props.rowData, props.valoreRaw]);
  return (
    <Formik
      key={props.rowData[0]}
      initialValues={data}
      onSubmit={(values) => {
        props.patchValore(values.id, values);
        setEditMode(false);
      }}
      validationSchema={stagedValoreIndicatoreSchema}
      enableReinitialize
    >
      <TableRow key={props.rowData[0]}>
        {editMode ? <Form id={"form-" + props.rowData[0]} /> : null}
        <TableCell>
          {editMode ? (
            <PeriodSelectorField
              className={props.inputClassName}
              name={"period"}
              indicatoreId={props.valoreRaw.tipoIndicatore.id}
              form={"form-" + props.rowData[0]}
            />
          ) : (
            props.rowData[1]
          )}
        </TableCell>
        <TableCell>
          {editMode ? (
            <ValueField
              className={props.inputClassName}
              name={"value"}
              indicatoreId={props.valoreRaw.tipoIndicatore.id}
              form={"form-" + props.rowData[0]}
            />
          ) : (
            props.rowData[2]
          )}
        </TableCell>
        <TableCell>
          {!editMode ? (
            <>
              <IconButton
                key={"edit-" + props.valoreRaw.id}
                type={"button"}
                onClick={() => setEditMode(true)}
              >
                <EditIcon />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton
                key={"save-" + props.valoreRaw.id}
                type={"submit"}
                form={"form-" + props.rowData[0]}
              >
                <CheckIcon />
              </IconButton>
              <IconButton
                key={"cancel-" + props.valoreRaw.id}
                type={"button"}
                onClick={() => {
                  setEditMode(false);
                  props.onReset();
                }}
              >
                <CancelIcon />
              </IconButton>
            </>
          )}
        </TableCell>
      </TableRow>
    </Formik>
  );
}