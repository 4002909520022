import React from 'react'
import {
  TextField,
} from '@material-ui/core'
import { useSelector } from 'react-redux'

export function UserSelector(props) {
  const user = useSelector(
    (state) => state.loginData.user
  );
  return (
    <TextField
      className={props.className}
      disabled
      label={"Utente attuale"}
      value={user}
    />
  )
}