import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import { fetchProfile } from "../../redux/auth/backendActions";
import {
  editSingleEnteFornitore,
  fetchSingleEnteFornitore,
} from "../../redux/entefornitore/backendActions";
import {
  EnteFornitore,
  enteFornitoreSchema,
} from "../../constants/enteFornitoreConstants";
import { Form, Formik } from "formik";
import { Grid, Typography } from "@material-ui/core";
import { SimpleInputField } from "../GenericFormComponents/SimpleInputField";
import { ScrollToError } from "../GenericFormComponents/ScrollToError";
import { toastTypes } from "../../constants/generalConstants";
import ProvinciaComuneSelectFields from "../GenericFormComponents/ProvinciaComuneSelectFields";
import { AnagraficaActionButtons } from "../AnagraficaActionButtons";
import { displayToast } from "../../redux/general/backendActions";
import { ResetForm } from "../GenericFormComponents/ResetForm";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: "100%",
    maxHeight: "100%",
  },
  form: {
    marginBottom: "0",
    overflowY: "auto",
    maxHeight: "100%",
  },
  actionButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "5px",
  },
  input: {
    boxSizing: "border-box",
    height: "2em",
    width: "150px",
  },
  select: {
    minHeight: "2em",
    width: "150px",
  },
  element: {
    marginBottom: "35px",
  },
  operatorField: {
    minHeight: "2em",
    minWidth: "150px",
  },
  operator: {
    minHeight: "2em",
    width: "50px",
  },
  threshold: {
    boxSizing: "border-box",
    height: "2em",
    width: "120px",
  },
}));

function AnagraficaEnteFornitorePage(props) {
  const classes = useStyles();
  const setTitle = props.setTitle;
  const location = useLocation();
  const [editMode, setEditMode] = useState(false);
  const [scrollToError, setScrollToError] = useState(false);
  const [showedEnte, setShowedEnte] = useState(new EnteFornitore());
  useEffect(() => {
    setTitle("Anagrafica ente fornitore");
  }, [setTitle]);
  const fetchProfile = props.fetchProfile;
  const fetchSingleEnteFornitore = props.fetchSingleEnteFornitore;
  useEffect(() => {
    if (!props.enteFornitoreMode) {
      if (props.enteFornitoreId) {
        fetchSingleEnteFornitore(props.enteFornitoreId);
      }
    } else {
      fetchProfile();
    }
  }, [
    location,
    fetchProfile,
    props.enteFornitoreId,
    fetchSingleEnteFornitore,
    props.enteFornitoreMode,
  ]);
  useEffect(() => {
    if (props.enteFornitoreId) {
      if (!props.enteFornitore.enteFornitoreByIDs[props.enteFornitoreId]) {
        setShowedEnte(new EnteFornitore());
      } else {
        setShowedEnte(
          props.enteFornitore.enteFornitoreByIDs[props.enteFornitoreId]
        );
      }
    } else {
      if (!props.loginTransientState.profile) {
        setShowedEnte(new EnteFornitore());
      } else {
        setShowedEnte(props.loginTransientState.profile);
      }
    }
  }, [
    location,
    props.loginTransientState.profile,
    props.enteFornitoreId,
    props.enteFornitore.enteFornitoreByIDs,
  ]);
  return (
    <Formik
      initialValues={showedEnte}
      onSubmit={(values) => {
        props.editSingleEnteFornitore(values.id, values).then(() => {
          setEditMode(false);
          if (props.enteFornitoreId) {
            fetchSingleEnteFornitore(props.enteFornitoreId);
          } else {
            fetchProfile();
          }
        });
      }}
      validationSchema={enteFornitoreSchema}
      enableReinitialize
    >
      <Form className={classes.root}>
        <ResetForm showedValues={showedEnte} />
        <div className={classes.form}>
          <h6>Dati Ente</h6>
          <Grid container alignItems="center" justify="center">
            <Grid className={classes.element} item container md={3} xs={6}>
              <Typography>Denominazione ente</Typography>
            </Grid>
            <Grid
              className={classes.element}
              item
              container
              md={3}
              xs={6}
              justify="center"
            >
              <SimpleInputField
                type="text"
                className={classes.input}
                name="denominazione"
                disabled={!editMode}
              />
            </Grid>
            <Grid className={classes.element} item container md={3} xs={6}>
              <Typography>Partita IVA</Typography>
            </Grid>
            <Grid
              className={classes.element}
              item
              container
              md={3}
              xs={6}
              justify="center"
            >
              <SimpleInputField
                type="text"
                className={classes.input}
                name="partitaIva"
                disabled={!editMode}
              />
            </Grid>
            <Grid className={classes.element} item container md={3} xs={6}>
              <Typography>Codice fiscale</Typography>
            </Grid>
            <Grid
              className={classes.element}
              item
              container
              md={3}
              xs={6}
              justify="center"
            >
              <SimpleInputField
                type="text"
                className={classes.input}
                name="codiceFiscale"
                disabled={!editMode}
              />
            </Grid>
            <Grid className={classes.element} item container md={3} xs={6}>
              <Typography>N. Telefono</Typography>
            </Grid>
            <Grid
              className={classes.element}
              item
              container
              md={3}
              xs={6}
              justify="center"
            >
              <SimpleInputField
                type="text"
                className={classes.input}
                name="telefonoAzienda"
                disabled={!editMode}
              />
            </Grid>
            <Grid className={classes.element} item container md={3} xs={6}>
              <Typography>Email ente</Typography>
            </Grid>
            <Grid
              className={classes.element}
              item
              container
              md={3}
              xs={6}
              justify="center"
            >
              <SimpleInputField
                type="text"
                className={classes.input}
                name="emailAzienda"
                disabled={!editMode}
              />
            </Grid>
            <Grid className={classes.element} item container xs={6} />
          </Grid>
          <h6>Indirizzo Ente</h6>
          <Grid container alignItems="center" justify="center">
            <ProvinciaComuneSelectFields
              gridClassName={classes.element}
              inputClassName={classes.input}
              selectClassName={classes.select}
              comuneIDField={"comune.id"}
              provinciaIDField={"comune.provincia.id"}
              disabled={!editMode}
            />
            <Grid className={classes.element} item container md={3} xs={6}>
              <Typography>Indirizzo</Typography>
            </Grid>
            <Grid
              className={classes.element}
              item
              container
              md={3}
              xs={6}
              justify="center"
            >
              <SimpleInputField
                type="text"
                className={classes.input}
                name="indirizzo"
                disabled={!editMode}
              />
            </Grid>
            <Grid className={classes.element} item container md={3} xs={6}>
              <Typography>CAP</Typography>
            </Grid>
            <Grid
              className={classes.element}
              item
              container
              md={3}
              xs={6}
              justify="center"
            >
              <SimpleInputField
                type="text"
                className={classes.input}
                name="cap"
                disabled={!editMode}
              />
            </Grid>
          </Grid>
          <h6>Referente Ente</h6>
          <Grid container alignItems="center" justify="center">
            <Grid className={classes.element} item container md={3} xs={6}>
              <Typography>Nome</Typography>
            </Grid>
            <Grid
              className={classes.element}
              item
              container
              md={3}
              xs={6}
              justify="center"
            >
              <SimpleInputField
                type="text"
                className={classes.input}
                name="nomeReferente"
                disabled={!editMode}
              />
            </Grid>
            <Grid className={classes.element} item container md={3} xs={6}>
              <Typography>Cognome</Typography>
            </Grid>
            <Grid
              className={classes.element}
              item
              container
              md={3}
              xs={6}
              justify="center"
            >
              <SimpleInputField
                type="text"
                className={classes.input}
                name="cognomeReferente"
                disabled={!editMode}
              />
            </Grid>
            <Grid className={classes.element} item container md={3} xs={6}>
              <Typography>Email referente</Typography>
            </Grid>
            <Grid
              className={classes.element}
              item
              container
              md={3}
              xs={6}
              justify="center"
            >
              <SimpleInputField
                type="text"
                className={classes.input}
                name="emailReferente"
                disabled={!editMode}
              />
            </Grid>
            <Grid className={classes.element} item container md={3} xs={6}>
              <Typography>Telefono referente</Typography>
            </Grid>
            <Grid
              className={classes.element}
              item
              container
              md={3}
              xs={6}
              justify="center"
            >
              <SimpleInputField
                type="text"
                className={classes.input}
                name="telefonoReferente"
                disabled={!editMode}
              />
            </Grid>
          </Grid>
        </div>
        <div className={classes.actionButtons}>
          <ScrollToError scroll={scrollToError} />
          <AnagraficaActionButtons
            disableStalleAllevamentiEdit
            editMode={editMode}
            onEditModeChange={(newValue) => {
              setEditMode(newValue);
              if (newValue === false) {
                if (props.enteFornitoreId) {
                  fetchSingleEnteFornitore(props.enteFornitoreId);
                } else {
                  fetchProfile();
                }
              }
            }}
            onFormError={(error) => {
              props.displayToast(toastTypes.ERROR, error);
              setScrollToError(!scrollToError);
            }}
          />
        </div>
      </Form>
    </Formik>
  );
}

const mapStateToProps = (state) => {
  return {
    loginTransientState: state.loginTransientState,
    enteFornitore: state.enteFornitore,
  };
};

const mapDispatchToProps = {
  fetchProfile,
  editSingleEnteFornitore,
  fetchSingleEnteFornitore,
  displayToast,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnagraficaEnteFornitorePage);
