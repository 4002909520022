import React, { useCallback, useEffect, useState } from 'react'
import { Grid, Typography } from "@material-ui/core";
import { SimpleSelectField } from "../../Common/GenericFormComponents/SimpleSelectField";
import {
  BooleanThresholdSchema, thresholdOperatorsTypes,
} from '../../constants/indicatoriConstants'
import { Formik, useFormikContext } from 'formik'
import {
  VALUES_BOOLEAN,
  valuesStatuses,
  valuesStatusesLabels,
} from '../../constants/valoriIndicatoriConstants'

export function BooleanThresholdConditionsFields(props) {
  const formik = useFormikContext();
  const setFieldValue = formik.setFieldValue;
  const [fieldStatus, setFieldStatus] = useState({
    TRUE: valuesStatuses.GREEN,
    FALSE: valuesStatuses.RED,
  });
  const greenFieldValue = formik.values["greenThresholdConditions"];
  const yellowFieldValue = formik.values["yellowThresholdConditions"];
  const redFieldValue = formik.values["redThresholdConditions"];
  const formConditionToSubForm = useCallback((status, condition) => {
    switch (condition.arg1) {
      case VALUES_BOOLEAN.TRUE:
        if (condition.thresholdOperator === thresholdOperatorsTypes.EQUAL){
          setFieldStatus( oldStatus => {
            return {
              ...oldStatus,
              TRUE: status
            }
          })
        }
        break;
      case VALUES_BOOLEAN.FALSE:
        if (condition.thresholdOperator === thresholdOperatorsTypes.EQUAL){
          setFieldStatus( oldStatus => {
            return {
              ...oldStatus,
              FALSE: status
            }
          })
        }
        break;
      default:
        break;
    }
  }, [])
  useEffect(() => {
    greenFieldValue.forEach(formConditionToSubForm.bind(null,valuesStatuses.GREEN));
    yellowFieldValue.forEach(formConditionToSubForm.bind(null, valuesStatuses.YELLOW));
    redFieldValue.forEach(formConditionToSubForm.bind(null, valuesStatuses.RED))
  }, [greenFieldValue, yellowFieldValue, redFieldValue, formConditionToSubForm]);
  const subFormToForm = useCallback((values) => {
    const greenConditions = [];
    const yellowConditions = [];
    const redConditions = [];
    Object.keys(values).forEach((key) => {
      switch(values[key]){
        case valuesStatuses.GREEN:
          greenConditions.push({
            arg1: VALUES_BOOLEAN[key],
            arg2: 1,
            thresholdOperator: thresholdOperatorsTypes.EQUAL
          });
          break;
        case valuesStatuses.YELLOW:
          yellowConditions.push({
            arg1: VALUES_BOOLEAN[key],
            arg2: 1,
            thresholdOperator: thresholdOperatorsTypes.EQUAL
          });
          break;
        case valuesStatuses.RED:
          redConditions.push({
            arg1: VALUES_BOOLEAN[key],
            arg2: 1,
            thresholdOperator: thresholdOperatorsTypes.EQUAL
          })
          break;
        default:
          break;
      }
      setFieldValue("greenThresholdConditions", greenConditions);
      setFieldValue("yellowThresholdConditions", yellowConditions);
      setFieldValue("redThresholdConditions", redConditions);
      setFieldValue("defaultThreshold", valuesStatuses.RED);
    })
  }, [setFieldValue])
  return (
    <>
      <Formik
        initialValues={fieldStatus}
        onSubmit={(values) => subFormToForm(values)}
        validationSchema={BooleanThresholdSchema}
        enableReinitialize
      >
        {(subFormik) => (
          <>
              <Grid className={props.elementClass} item container md={3} xs={6}>
                <Typography>
                  Status associato al valore Vero/Positivo
                </Typography>
              </Grid>
              <Grid
                className={props.elementClass}
                item
                container
                md={3} xs={6}
                justify="center"
              >
                <div className={props.inputClass}>
                  <SimpleSelectField
                    name={"TRUE"}
                    id="TRUE"
                    className={props.selectClass}
                    options={valuesStatusesLabels}
                    disabled={!props.editMode}
                    onChange={() => subFormik.submitForm()}
                  />
                </div>
              </Grid>
              <Grid className={props.elementClass} item container xs={3}>
                <Typography>
                  Status associato al valore Falso/Negativo
                </Typography>
              </Grid>
              <Grid
                className={props.elementClass}
                item
                container
                md={3} xs={6}
                justify="center"
              >
                <div className={props.inputClass}>
                  <SimpleSelectField
                    name={"FALSE"}
                    id="FALSE"
                    className={props.selectClass}
                    options={valuesStatusesLabels}
                    disabled={!props.editMode}
                    onChange={() => subFormik.submitForm()}
                  />
                </div>
              </Grid>
          </>
        )}
      </Formik>
    </>
  );
}
