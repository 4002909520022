import React from "react";
import { TableRow } from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import { getThresholdArgFieldString } from '../../constants/indicatoriConstants'

export function NumericThresholdConditionRow(props) {
  return (
    <TableRow key={props.rowData[0]}>
      <TableCell>{getThresholdArgFieldString(props.rowData[3], props.rowData[1], props.rowData[2])}</TableCell>
      <TableCell>{props.rowData[4]}</TableCell>
    </TableRow>
  );
}
