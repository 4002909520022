import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from 'react-redux'
import {
  createTheme,
  MuiThemeProvider,
  Table, TableCell,
  TableRow,
} from '@material-ui/core'
import { fetchSingleIndicatore } from "../../redux/indicatori/backendActions";
import { fetchFilteredValori } from "../../redux/valoriindicatori/backendActions";
import { StatusValoreVisualizer } from "../../Common/StatusValoreVisualizer";
import { textLabels } from "../../constants/miscConstants";
import MUIDataTable from "mui-datatables";
import {
  getModifiedOnLabel,
  getPeriodLabel,
  getValueLabel,
  getValueState,
  valorePeriodSortCompare,
  valoreStateSortCompare,
  valoreValueSortCompare, valuesStatuses,
} from '../../constants/valoriIndicatoriConstants'
import { GraficoStoricoValori } from "../GraficoStoricoValori";
import { makeStyles } from "@material-ui/core/styles";
import {
  getThresholdLabel,
  Indicatore,
} from '../../constants/indicatoriConstants'
import { defaultRowOptions } from '../../constants/generalConstants'
import { setMainTableRows } from '../../redux/general/basicActions'

const useStyles = makeStyles(() => ({
  graph: {
    height: "300px",
  },
}));

const getMuiTheme = () =>
  createTheme({
    overrides: {
      MuiToolbar: {
        root: {
          backgroundColor: "#FEFDFD",
        },
      },
      MuiTable: {
        root: {
          backgroundColor: "#FEFDFD",
        },
      },
      MuiTableCell: {
        root: {
          height: "30px",
        },
      },
      MUIDataTableHeadCell: {
        fixedHeader: {
          backgroundColor: "#C4C4C4",
        },
      },
    },
  });

function StoricoIndicatorePage(props) {
  const classes = useStyles();
  const setTitle = props.setTitle;
  const fetchSingleIndicatore = props.fetchSingleIndicatore;
  const fetchFilteredValori = props.fetchFilteredValori;
  const [data, setData] = useState([]);
  const [tipoIndicatoreResultsIds, setTipoIndicatoreResultsIds] = useState([]);
  const [code, setCode] = useState("");
  const dispatch = useDispatch();
  const mainTableRows = useSelector(
    (state) => state.appPreferences.mainWindowTableRows
  );
  const [showedTipoIndicatore, setShowedTipoIndicatore] = useState(new Indicatore());
  const selectedAllevamentoId = useSelector(
    (state) => state.loginTransientState.selectedAllevamentoId
  );
  useEffect(() => {
    fetchSingleIndicatore(props.match.params.id);
  }, [props.match.params.id, fetchSingleIndicatore]);
  useEffect(() => {
    let indicatore = props.indicatori.indicatoriByIDs[props.match.params.id];
    if (indicatore) {
      setTitle("Storico indicatore " + indicatore.code + " " + indicatore.name);
      setCode(indicatore.code);
      setShowedTipoIndicatore(indicatore);
    }
  }, [
    setTitle,
    props.match.params.id,
    props.indicatori,
  ]);
  useEffect(() => {
    if (code){
      fetchFilteredValori(
        undefined,
        selectedAllevamentoId,
        undefined,
        undefined,
        code
      );
    }
  }, [code, fetchFilteredValori, selectedAllevamentoId])
  useEffect(() => {
    let idSet = new Set();
    props.valori.lastValoriFilterResult.forEach((id) => {
      let valore = props.valori.valoriByIDs[id];
      idSet.add(valore.tipoIndicatore.id);
    });
    setTipoIndicatoreResultsIds((oldArray) => {
      let newArray = Array.from(idSet);
      if (oldArray.includes(newArray)) {
        return oldArray;
      } else {
        return newArray;
      }
    });
  }, [props.valori]);
  useEffect(() => {
    tipoIndicatoreResultsIds.forEach((id) => fetchSingleIndicatore(id));
  }, [tipoIndicatoreResultsIds, fetchSingleIndicatore]);
  useEffect(() => {
    let newData = props.valori.lastValoriFilterResult.map((id) => {
      let valore = props.valori.valoriByIDs[id];
      let indicatore =
        props.indicatori.indicatoriByIDs[valore.tipoIndicatore.id];
      if (indicatore) {
        return {
          id: id,
          rawValore: valore,
          fonteFlat: indicatore.enteFornitore.denominazione,
          period: getPeriodLabel(valore, indicatore),
          modifiedOn: getModifiedOnLabel(valore.modifiedOn),
          value: getValueLabel(valore.value, indicatore.valuesType),
          state: getValueState(valore, indicatore),
        };
      } else {
        return {
          id: id,
          rawValore: valore,
          fonteFlat: "",
          period: "",
          modifiedOn: getModifiedOnLabel(valore.modifiedOn),
          value: "",
          state: "",
        };
      }
    });
    setData(newData);
  }, [props.valori, props.indicatori]);
  const columns = [
    {
      name: "id",
      options: {
        display: "excluded",
      },
    },
    {
      name: "rawValore",
      options: {
        display: "excluded",
      },
    },
    {
      name: "fonteFlat",
      label: "Fonte",
    },
    {
      name: "period",
      label: "Periodo valore",
      options: {
        sortCompare: valorePeriodSortCompare(1),
      },
    },
    { name: "modifiedOn", label: "Data ultima modifica" },
    {
      name: "value",
      label: "Valore",
      options: {
        sortCompare: valoreValueSortCompare(1),
      },
    },
    {
      name: "state",
      label: "Stato",
      options: {
        sortCompare: valoreStateSortCompare,
        customBodyRenderLite: (dataIndex) => (
          <StatusValoreVisualizer status={data[dataIndex].state} />
        ),
      },
    },
  ];
  const options = {
    elevation: 0,
    selectableRows: "none",
    filter: false,
    print: false,
    download: false,
    search: false,
    viewColumns: false,
    textLabels: textLabels,
    rowsPerPageOptions: defaultRowOptions,
    rowsPerPage: mainTableRows,
    onChangeRowsPerPage: (rows) => dispatch(setMainTableRows(rows)),
    sortOrder: {
      name: "period",
      direction: "desc",
    },
    setTableProps: () => {
      return {
        padding: "none",
      };
    },
  };
  return (
    <div>
      <h6>Storico valori</h6>
      <Table size="small">
        <TableRow>
          <TableCell>
            <b>Codice indicatore</b>
          </TableCell>
          <TableCell>
            {code}
          </TableCell>
          <TableCell>
            <b>Nome indicatore</b>
          </TableCell>
          <TableCell>
            {showedTipoIndicatore.name}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <b>Box indicatore</b>
          </TableCell>
          <TableCell>
            {showedTipoIndicatore.box.name}
          </TableCell>
          <TableCell>
            <b>Unità di misura</b>
          </TableCell>
          <TableCell>
            {showedTipoIndicatore.measureUnit}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <b>Valore soglia ROSSO </b>
          </TableCell>
          <TableCell>
            {getThresholdLabel(
            showedTipoIndicatore,
            valuesStatuses.RED
          )}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <b>Valore soglia GIALLO </b>
          </TableCell>
          <TableCell>
            {getThresholdLabel(
              showedTipoIndicatore,
              valuesStatuses.YELLOW
            )}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <b>Valore soglia VERDE </b>
          </TableCell>
          <TableCell>
            {getThresholdLabel(
              showedTipoIndicatore,
              valuesStatuses.GREEN
            )}
          </TableCell>
        </TableRow>
      </Table>
      <GraficoStoricoValori className={classes.graph} valori={props.valori} />
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable data={data} columns={columns} options={options} />
      </MuiThemeProvider>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    valori: state.valori,
    indicatori: state.indicatori,
    loginTransientState: state.loginTransientState,
  };
};

const mapDispatchToProps = {
  fetchSingleIndicatore,
  fetchFilteredValori,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StoricoIndicatorePage);
