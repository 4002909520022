import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from "@material-ui/core/Dialog";
import { DialogActions, DialogContent } from '@material-ui/core'
import Button from '@material-ui/core/Button'

export function UserDeleteConfirmDialog(props){
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Conferma eliminazione utente
      </DialogTitle>
      <DialogContent>
        <p>Confermi l'eliminazione dell'utente <b>{props.username}</b>?</p>
        <p>TUTTI i dati ad esso associati (valori, ecc) verrano eliminati.</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          Annulla
        </Button>
        <Button onClick={props.onConfirm} color="primary">
          Conferma
        </Button>
      </DialogActions>
    </Dialog>
  )
}