import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { aziendaPaths } from "../constants/aziendaConstants";
import BoxOverviewPage from "./Box/BoxOverviewPage";
import SingleBoxPage from "./Box/SingleBoxPage";
import IndicatoriOverviewPage from "./Pages/IndicatoriOverviewPage";
import StoricoIndicatorePage from "./Pages/StoricoIndicatorePage";
import AnagraficaIndicatoriPage from "../Common/Pages/AnagraficaIndicatoriPage";
import AnagraficaAzienda from "../Common/Pages/AnagraficaAzienda";
import ListaStallePage from "../Common/Pages/ListaStallePage";
import ImpostazioniUtentePage from "../Common/Pages/ImpostazioniUtentePage";
import SingleStallaPage from "../Common/Pages/SingleStallaPage";
import { makeStyles } from "@material-ui/core/styles";
import AvvisiSection from "./AvvisiSection";
import AlertPage from "./Pages/AlertPage";
import { fetchProfile } from "../redux/auth/backendActions";
import { ListaAllevamentiPage } from "../Common/Pages/ListaAllevamentiPage";
import { SingleAllevamentoPage } from "../Common/Pages/SingleAllevamentoPage";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: "100%",
    maxHeight: "100%",
  },
  mainContent: {
    marginBottom: "0",
    overflowY: "auto",
    height: "calc(100% - 180px)",
  },
  alwaysOnTopSection: {
    minHeight: "180px",
    overflowY: "auto",
    display: "flex",
    alignItems: "flexStart",
    justifyContent: "center",
  },
  aziendaOverview: {
    marginBottom: "15px",
  },
}));

export function AziendaDashboard(props) {
  const classes = useStyles();
  const selectedAllevamentoId = useSelector(
    (state) => state.loginTransientState.selectedAllevamentoId
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchProfile());
  }, [dispatch]);
  const history = useHistory();
  if (!selectedAllevamentoId) {
    return null;
  } else {
    return (
      <div className={classes.root}>
        <div className={classes.mainContent}>
          <Switch>
            <Route
              exact
              path={aziendaPaths.boxIndicatori.base}
              render={() => {
                return <BoxOverviewPage setTitle={props.setTitle} />;
              }}
            />
            <Route
              exact
              path={`${aziendaPaths.boxIndicatori.base}/:id`}
              render={(routeProps) => {
                return (
                  <SingleBoxPage setTitle={props.setTitle} {...routeProps} />
                );
              }}
            />
            <Route
              exact
              path={aziendaPaths.indicatori}
              render={() => {
                return <IndicatoriOverviewPage setTitle={props.setTitle} />;
              }}
            />
            <Route
              exact
              path={`${aziendaPaths.indicatori}/:id`}
              render={(routeProps) => {
                return (
                  <StoricoIndicatorePage
                    setTitle={props.setTitle}
                    {...routeProps}
                  />
                );
              }}
            />
            <Route
              exact
              path={aziendaPaths.anagraficaIndicatori}
              render={() => {
                return (
                  <AnagraficaIndicatoriPage
                    setTitle={props.setTitle}
                    aziendaMode
                  />
                );
              }}
            />
            <Route
              exact
              path={aziendaPaths.anagraficaAzienda.base}
              render={() => {
                return (
                  <AnagraficaAzienda setTitle={props.setTitle} aziendaMode />
                );
              }}
            />
            <Route
              exact
              path={aziendaPaths.anagraficaAzienda.allevamenti}
              render={() => {
                return (
                  <ListaAllevamentiPage
                    setTitle={props.setTitle}
                    onRowClick={(allevamentoId) =>
                      history.push(
                        `${aziendaPaths.anagraficaAzienda.allevamenti}/${allevamentoId}`
                      )
                    }
                    onCreateAllevamentoClick={() =>
                      history.push(
                        aziendaPaths.anagraficaAzienda.creaAllevamenti
                      )
                    }
                  />
                );
              }}
            />
            <Route
              exact
              path={`${aziendaPaths.anagraficaAzienda.allevamenti}/:allevamentoId`}
              render={(routeProps) => {
                return (
                  <SingleAllevamentoPage
                    setTitle={props.setTitle}
                    aziendaMode
                    onDelete={() => {
                      history.push(aziendaPaths.anagraficaAzienda.allevamenti);
                      dispatch(fetchProfile());
                    }}
                    {...routeProps}
                  />
                );
              }}
            />
            <Route
              exact
              path={`${aziendaPaths.anagraficaAzienda.creaAllevamenti}`}
              render={(routeProps) => {
                return (
                  <SingleAllevamentoPage
                    setTitle={props.setTitle}
                    createMode
                    aziendaMode
                    onCreation={() =>
                      history.push(aziendaPaths.anagraficaAzienda.allevamenti)
                    }
                    {...routeProps}
                  />
                );
              }}
            />
            <Route
              exact
              path={aziendaPaths.anagraficaAzienda.stalle}
              render={() => {
                return (
                  <ListaStallePage
                    setTitle={props.setTitle}
                    onRowClick={(stallaId) =>
                      history.push(
                        `${aziendaPaths.anagraficaAzienda.stalle}/${stallaId}`
                      )
                    }
                    onCreateStallaClick={() =>
                      history.push(aziendaPaths.anagraficaAzienda.creaStalla)
                    }
                    enableSelectStalla
                  />
                );
              }}
            />
            <Route
              exact
              path={`${aziendaPaths.anagraficaAzienda.stalle}/:stallaId`}
              render={(routeProps) => {
                return (
                  <SingleStallaPage
                    setTitle={props.setTitle}
                    aziendaMode
                    onDelete={() =>
                      history.push(aziendaPaths.anagraficaAzienda.stalle)
                    }
                    {...routeProps}
                  />
                );
              }}
            />
            <Route
              exact
              path={`${aziendaPaths.anagraficaAzienda.creaStalla}`}
              render={(routeProps) => {
                return (
                  <SingleStallaPage
                    setTitle={props.setTitle}
                    createMode
                    aziendaMode
                    onCreation={() =>
                      history.push(aziendaPaths.anagraficaAzienda.stalle)
                    }
                    {...routeProps}
                  />
                );
              }}
            />
            <Route
              exact
              path={aziendaPaths.impostazioniUtente}
              render={() => {
                return (
                  <ImpostazioniUtentePage
                    aziendaMode
                    setTitle={props.setTitle}
                  />
                );
              }}
            />
            <Route
              exact
              path={`${aziendaPaths.alert}/:id`}
              render={(routeProps) => {
                return <AlertPage setTitle={props.setTitle} {...routeProps} />;
              }}
            />
            <Redirect to={aziendaPaths.boxIndicatori.base} />
          </Switch>
        </div>
        <div className={classes.alwaysOnTopSection}>
          <AvvisiSection />
        </div>
      </div>
    );
  }
}
