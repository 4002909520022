import React from "react";
import {
  Hidden,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
import {
  getModifiedOnLabel,
  valuesStatuses,
} from '../../constants/valoriIndicatoriConstants'
import { StatusValoreVisualizer } from "../../Common/StatusValoreVisualizer";

export function SingleBoxOverviewSection(props) {
  return (
    <div className={props.className}>
      <h6>{`Box ${props.box ? props.box.name : ""}`}</h6>
      <Table size="small">
        <Hidden xsDown>
          <TableBody>
            <TableRow>
              <TableCell>Numero parametri:</TableCell>
              <TableCell>
                {props.box
                  ? props.greenStatuses +
                    props.yellowStatuses +
                    props.redStatuses
                  : ""}
              </TableCell>
              <TableCell>Ultimo aggiornamento:</TableCell>
              <TableCell>{getModifiedOnLabel(props.lastUpdate)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}>
                {"Indicatori "}
                <StatusValoreVisualizer status={valuesStatuses.GREEN} />:
              </TableCell>
              <TableCell colSpan={2}>{props.greenStatuses}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}>
                {"Indicatori "}
                <StatusValoreVisualizer status={valuesStatuses.YELLOW} />:
              </TableCell>
              <TableCell colSpan={2}>{props.yellowStatuses}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}>
                {"Indicatori "}
                <StatusValoreVisualizer status={valuesStatuses.RED} />:
              </TableCell>
              <TableCell colSpan={2}>{props.redStatuses}</TableCell>
            </TableRow>
          </TableBody>
        </Hidden>
        <Hidden smUp>
          <TableBody>
            <TableRow>
              <TableCell>Numero parametri:</TableCell>
              <TableCell>
                {props.box ? props.box.indicatori.length : ""}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Ultimo aggiornamento:</TableCell>
              <TableCell>{props.lastUpdate.toLocaleString()}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {"Indicatori "}
                <StatusValoreVisualizer status={valuesStatuses.GREEN} />:
              </TableCell>
              <TableCell>{props.greenStatuses}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {"Indicatori "}
                <StatusValoreVisualizer status={valuesStatuses.YELLOW} />:
              </TableCell>
              <TableCell>{props.yellowStatuses}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {"Indicatori "}
                <StatusValoreVisualizer status={valuesStatuses.RED} />:
              </TableCell>
              <TableCell>{props.redStatuses}</TableCell>
            </TableRow>
          </TableBody>
        </Hidden>
      </Table>
    </div>
  );
}
