import axios from "axios";
import {
  BACKEND_URL,
  genericCallStatus,
  toastTypes,
} from "../../constants/generalConstants";
import {
  LoginStateTypes,
  ResetPasswordResult,
  UserRoles,
} from "../../constants/authConstants";
import {
  loggedIn,
  loggedOut,
  loggingIn,
  loginFailed,
  receiveCurrentUser,
  receiveProfile,
  setIsWaiting,
  setSelectedAllevamento,
} from "./basicActions";
import { displayToast, getStandardConfig } from "../general/backendActions";
import jwtDecode from "jwt-decode";
import { refreshTokens } from "../middleware";

const Paths = Object.freeze({
  login: "/auth/login",
  logout: "/auth/logout",
  askResetPassword: "/auth/forgotPassword",
  resetPassword: "/auth/changePassword",
  changePassword: "/auth/changePasswordWhenLoggedIn",
  userProfile: "/user/profile",
  currentUser: "/user/current",
  changeUsername: "/user/change-username",
});

/** Login
 *
 * @param {string} username
 * @param {string} password
 * @returns {function(*=, *): Promise<{}>} | {loginState: string, userType: string}
 */
export function doLogin(username, password) {
  return (dispatch, getState) => {
    dispatch(loggingIn());
    dispatch(setIsWaiting(true));
    const data = {
      username: username,
      password: password,
    };
    const result = {};
    return (
      axios
        .post(BACKEND_URL + Paths.login, data)
        .then((response) => {
          let accessTokenPayload = jwtDecode(response.data.access_token);
          let role = accessTokenPayload.roles.find((role) =>
            UserRoles.hasOwnProperty(role)
          );
          let refreshTokenPayload = jwtDecode(response.data.refresh_token);
          dispatch(setIsWaiting(false));
          return dispatch(
            loggedIn(
              username,
              role,
              response.data.access_token,
              accessTokenPayload.exp,
              response.data.refresh_token,
              refreshTokenPayload.exp
            )
          );
        })
        // Return success if all went well
        .then(() => {
          if (getState().loginData.userType === UserRoles.ROLE_AZIENDA) {
            dispatch(fetchProfile());
          }
          result.loginState = LoginStateTypes.SUCCESS;
          return result;
        })
        .catch((error) => {
          dispatch(setIsWaiting(false));
          // TODO More granular failure check
          if (error.response) {
            if (error.response.data) {
              if (error.response.data.message) {
                if (error.response.data.message === "non_active_account") {
                  dispatch(
                    loginFailed(LoginStateTypes.NON_ACTIVE, "Non active error")
                  );
                  result.loginState = LoginStateTypes.NON_ACTIVE;
                  return result;
                }
              }
            }
          }
          dispatch(loginFailed(LoginStateTypes.GENERIC_ERROR, "Generic error"));
          result.loginState = LoginStateTypes.GENERIC_ERROR;
          return result;
        })
    );
  };
}

/** Logout
 *
 * @returns {function(*, *): Promise<{loginState: string}>}
 */
export function doLogout() {
  return (dispatch, getState) => {
    return axios
      .post(BACKEND_URL + Paths.logout, {
        refreshToken: getState().loginData.refreshToken,
      })
      .finally(() => {
        dispatch(loggedOut());
        return Promise.resolve({
          loginState: LoginStateTypes.SUCCESS,
        });
      });
  };
}

export function askPasswordReset(email, recaptcha) {
  return () => {
    return axios
      .post(BACKEND_URL + Paths.askResetPassword, { email: email, recaptchaResponse: recaptcha })
      .then(() => {
        return Promise.resolve(ResetPasswordResult.SUCCESS);
      })
      .catch(() => {
        return Promise.reject(ResetPasswordResult.GENERIC_ERROR);
      });
  };
}

export function resetPassword(token, newPassword) {
  return () => {
    return axios
      .post(BACKEND_URL + Paths.resetPassword, {
        newPassword: newPassword,
        resetToken: token,
      })
      .then(() => {
        return ResetPasswordResult.SUCCESS;
      })
      .catch(() => {
        return ResetPasswordResult.GENERIC_ERROR;
      });
  };
}

export function changePassword(oldPassword, newPassword) {
  return (dispatch, getState) => {
    const data = {
      oldPassword: oldPassword,
      newPassword: newPassword,
    };
    return axios
      .post(
        BACKEND_URL + Paths.changePassword,
        data,
        getStandardConfig(getState)
      )
      .then(() => {
        dispatch(
          displayToast(toastTypes.INFO, "Password cambiata correttamente")
        );
        return Promise.resolve(genericCallStatus.SUCCESS);
      })
      .catch((err) => {
        if (err.response && err.response.status === 403) {
          dispatch(
            displayToast(toastTypes.ERROR, "Password inserita non valida")
          );
        } else {
          dispatch(
            displayToast(
              toastTypes.ERROR,
              "Errore durante il cambio della password"
            )
          );
        }
        return Promise.reject(genericCallStatus.GENERIC_ERROR);
      });
  };
}

export function fetchProfile() {
  return (dispatch, getState) => {
    return axios
      .get(BACKEND_URL + Paths.userProfile, getStandardConfig(getState))
      .then((response) => {
        dispatch(receiveProfile(response.data));
        // If the user is an Azienda and has allevamenti, set an allevamento as selected, if none is selected
        if (response.data.allevamenti) {
          if (!response.data.allevamenti.find(allevamento => allevamento.id.toString() === getState().loginTransientState.selectedAllevamentoId)){
            dispatch(setSelectedAllevamento(response.data.allevamenti[0].id));
          }
        }
      })
      .then(() => {
        return Promise.resolve(genericCallStatus.SUCCESS);
      })
      .catch(() => {
        dispatch(
          displayToast(toastTypes.ERROR, "Errore nello scaricamento dati")
        );
        return Promise.reject(genericCallStatus.GENERIC_ERROR);
      });
  };
}

export function fetchCurrentUser() {
  return (dispatch, getState) => {
    return axios
      .get(BACKEND_URL + Paths.currentUser, getStandardConfig(getState))
      .then((response) => {
        dispatch(receiveCurrentUser(response.data));
      })
      .then(() => {
        return Promise.resolve(genericCallStatus.SUCCESS);
      })
      .catch(() => {
        dispatch(
          displayToast(toastTypes.ERROR, "Errore nello scaricamento dati")
        );
        return Promise.reject(genericCallStatus.GENERIC_ERROR);
      });
  };
}

export function changeUsername(newUsername) {
  return (dispatch, getState) => {
    const data = {
      username: newUsername,
    };
    return axios
      .post(
        BACKEND_URL + Paths.changeUsername,
        data,
        getStandardConfig(getState)
      )
      .then(() => {
        dispatch(
          displayToast(toastTypes.INFO, "Username cambiato correttamente")
        );
        return Promise.resolve(genericCallStatus.SUCCESS);
      })
      .then(() => {
        return refreshTokens(getState().loginData.refreshToken, dispatch);
      })
      .then(() => {
        dispatch(fetchCurrentUser());
        return Promise.resolve(genericCallStatus.SUCCESS);
      })
      .catch((error) => {
        if (error.response && error.response.status === 409) {
          dispatch(
            displayToast(toastTypes.ERROR, "Username già presente nel sistema")
          );
        } else {
          dispatch(
            displayToast(
              toastTypes.ERROR,
              "Errore durante il cambio dell'username"
            )
          );
        }
        dispatch(fetchCurrentUser());
        return Promise.reject(genericCallStatus.GENERIC_ERROR);
      });
  };
}