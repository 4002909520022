import React from "react";
import {
  Grid,
  Hidden,
  Table,
  TableBody, TableCell,
  TableRow,
  Typography,
} from '@material-ui/core'
import { StatusValoreVisualizer } from "../Common/StatusValoreVisualizer";
import {
  getPeriodLabel,
  getValueLabel,
  getValueState,
} from "../constants/valoriIndicatoriConstants";

export function IndicatoreCriticoOverviewSection(props) {
  if (!props.criticalInfo.tipoIndicatore) {
    return null;
  }
  return (
    <div className={props.className}>
      <h6>{"Indicatore"}</h6>
      <Table size="small">
        <Hidden xsDown>
          <TableBody>
            <TableRow>
              <TableCell>
                  Nome indicatore:
              </TableCell>
              <TableCell>
                {props.criticalInfo.tipoIndicatore.name}
              </TableCell>
              <TableCell>
                Stato:
              </TableCell>
              <TableCell>
                <StatusValoreVisualizer
                  status={getValueState(
                    props.valore,
                    props.criticalInfo.tipoIndicatore
                  )}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Valore:
              </TableCell>
              <TableCell>
                {getValueLabel(
                  props.valore.value,
                  props.criticalInfo.tipoIndicatore.valuesType
                )}
              </TableCell>
              <TableCell>
                Box:
              </TableCell>
              <TableCell>
                {props.criticalInfo.tipoIndicatore.box.name}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Periodo ultimo valore:
              </TableCell>
              <TableCell>
                {getPeriodLabel(props.valore, props.criticalInfo.tipoIndicatore)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Hidden>
        <Hidden smUp>
          <TableBody>
            <TableRow>
              <TableCell>
                Nome indicatore:
              </TableCell>
              <TableCell>
                {props.criticalInfo.tipoIndicatore.name}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Stato:
              </TableCell>
              <TableCell>
                <StatusValoreVisualizer
                  status={getValueState(
                    props.valore,
                    props.criticalInfo.tipoIndicatore
                  )}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Valore:
              </TableCell>
              <TableCell>
                {getValueLabel(
                  props.valore.value,
                  props.criticalInfo.tipoIndicatore.valuesType
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Box:
              </TableCell>
              <TableCell>
                {props.criticalInfo.tipoIndicatore.box.name}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Periodo ultimo valore:
              </TableCell>
              <TableCell>
                {getPeriodLabel(props.valore, props.criticalInfo.tipoIndicatore)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Hidden>
      </Table>
    </div>
  );
}
