import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import React from "react";
import { serviziAssistenzaPaths } from "../constants/ServiziAssistenzaConstants";
import VisualizzaAnagraficaAziendePage from "../Common/Pages/VisualizzaAnagraficaAziendePage";
import AnagraficaAzienda from "../Common/Pages/AnagraficaAzienda";
import AnagraficaIndicatoriPage from "../Common/Pages/AnagraficaIndicatoriPage";
import VisualizzaValoriIndicatoriPage from "../Common/Pages/VisualizzaValoriIndicatoriPage";
import ValoriAggregatiPage from "../Common/Pages/ValoriAggregatiPage";
import ImpostazioniUtentePage from "../Common/Pages/ImpostazioniUtentePage";
import ListaStallePage from "../Common/Pages/ListaStallePage";
import SingleStallaPage from "../Common/Pages/SingleStallaPage";
import { ListaAllevamentiPage } from "../Common/Pages/ListaAllevamentiPage";
import { SingleAllevamentoPage } from "../Common/Pages/SingleAllevamentoPage";
import { useDispatch } from "react-redux";
import { fetchSingleAzienda } from "../redux/aziende/backendActions";

export function ServiziAssistenzaDashboard(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <Switch>
      <Route
        exact
        path={`${serviziAssistenzaPaths.anagraficaAziende}/:id${serviziAssistenzaPaths.stalleSubpath}`}
        render={(routeProps) => {
          return (
            <ListaStallePage
              setTitle={props.setTitle}
              enableSelectStalla
              onRowClick={(stallaId) =>
                history.push(
                  `${serviziAssistenzaPaths.anagraficaAziende}/${routeProps.match.params.id}${serviziAssistenzaPaths.stalleSubpath}/${stallaId}`
                )
              }
              onCreateStallaClick={() =>
                history.push(
                  serviziAssistenzaPaths.anagraficaAziende +
                    "/" +
                    routeProps.match.params.id +
                    serviziAssistenzaPaths.creaStallaSubPath
                )
              }
              {...routeProps}
            />
          );
        }}
      />
      <Route
        exact
        path={`${serviziAssistenzaPaths.anagraficaAziende}/:id${serviziAssistenzaPaths.allevamentiSubpath}`}
        render={(routeProps) => {
          return (
            <ListaAllevamentiPage
              setTitle={props.setTitle}
              onRowClick={(allevamentoId) =>
                history.push(
                  `${serviziAssistenzaPaths.anagraficaAziende}/${routeProps.match.params.id}${serviziAssistenzaPaths.stalleSubpath}/${allevamentoId}`
                )
              }
              onCreateAllevamentoClick={() =>
                history.push(
                  serviziAssistenzaPaths.anagraficaAziende +
                    "/" +
                    routeProps.match.params.id +
                    serviziAssistenzaPaths.creaAllevamentoSubPath
                )
              }
              {...routeProps}
            />
          );
        }}
      />
      <Route
        exact
        path={serviziAssistenzaPaths.anagraficaAziende}
        render={() => {
          return (
            <VisualizzaAnagraficaAziendePage
              enableSelectAzienda
              setTitle={props.setTitle}
              singleAziendaUrl={serviziAssistenzaPaths.anagraficaAziende}
            />
          );
        }}
      />
      <Route
        exact
        path={`${serviziAssistenzaPaths.anagraficaAziende}/:id`}
        render={(routeProps) => {
          return (
            <AnagraficaAzienda
              enableStalleEdit
              enableAllevamentiEdit
              setTitle={props.setTitle}
              aziendaId={routeProps.match.params.id}
              onEditStallaClick={() => {
                history.push(
                  `${serviziAssistenzaPaths.anagraficaAziende}/${routeProps.match.params.id}${serviziAssistenzaPaths.stalleSubpath}`
                );
              }}
              onEditAllevamentoClick={() => {
                history.push(
                  `${serviziAssistenzaPaths.anagraficaAziende}/${routeProps.match.params.id}${serviziAssistenzaPaths.allevamentiSubpath}`
                );
              }}
              {...routeProps}
            />
          );
        }}
      />
      <Route
        exact
        path={`${serviziAssistenzaPaths.anagraficaAziende}/:aziendaId${serviziAssistenzaPaths.stalleSubpath}/:stallaId`}
        render={(routeProps) => {
          return (
            <SingleStallaPage
              setTitle={props.setTitle}
              {...routeProps}
              onDelete={() => {
                history.push(
                  `${serviziAssistenzaPaths.anagraficaAziende}/${routeProps.match.params.aziendaId}/${serviziAssistenzaPaths.stalleSubpath}`
                );
                dispatch(fetchSingleAzienda(routeProps.match.params.aziendaId));
              }}
            />
          );
        }}
      />
      <Route
        exact
        path={`${serviziAssistenzaPaths.anagraficaAziende}/:aziendaId${serviziAssistenzaPaths.allevamentiSubpath}/:allevamentoId`}
        render={(routeProps) => {
          return (
            <SingleAllevamentoPage
              setTitle={props.setTitle}
              {...routeProps}
              onDelete={() => {
                history.push(
                  `${serviziAssistenzaPaths.anagraficaAziende}/${routeProps.match.params.aziendaId}/${serviziAssistenzaPaths.allevamentiSubpath}`
                );
                dispatch(fetchSingleAzienda(routeProps.match.params.aziendaId));
              }}
            />
          );
        }}
      />
      <Route
        exact
        path={`${serviziAssistenzaPaths.anagraficaAziende}/:aziendaId${serviziAssistenzaPaths.creaStallaSubPath}`}
        render={(routeProps) => {
          return (
            <SingleStallaPage
              setTitle={props.setTitle}
              createMode
              onCreation={() =>
                history.push(
                  `${serviziAssistenzaPaths.anagraficaAziende}/${routeProps.match.params.aziendaId}/${serviziAssistenzaPaths.stalleSubpath}`
                )
              }
              {...routeProps}
            />
          );
        }}
      />
      <Route
        exact
        path={`${serviziAssistenzaPaths.anagraficaAziende}/:aziendaId${serviziAssistenzaPaths.creaAllevamentoSubPath}`}
        render={(routeProps) => {
          return (
            <SingleAllevamentoPage
              setTitle={props.setTitle}
              createMode
              onCreation={() =>
                history.push(
                  `${serviziAssistenzaPaths.anagraficaAziende}/${routeProps.match.params.aziendaId}/${serviziAssistenzaPaths.allevamentiSubpath}`
                )
              }
              {...routeProps}
            />
          );
        }}
      />
      <Route
        exact
        path={serviziAssistenzaPaths.anagraficaIndicatori}
        render={() => {
          return <AnagraficaIndicatoriPage setTitle={props.setTitle} />;
        }}
      />
      <Route
        exact
        path={serviziAssistenzaPaths.valori}
        render={() => {
          return <VisualizzaValoriIndicatoriPage setTitle={props.setTitle} />;
        }}
      />
      <Route
        exact
        path={`${serviziAssistenzaPaths.valoriAggregati}`}
        render={() => {
          return <ValoriAggregatiPage setTitle={props.setTitle} createMode />;
        }}
      />
      <Route
        exact
        path={`${serviziAssistenzaPaths.impostazioniUtente}`}
        render={() => {
          return <ImpostazioniUtentePage setTitle={props.setTitle} />;
        }}
      />
      <Redirect to={serviziAssistenzaPaths.anagraficaAziende} />
    </Switch>
  );
}
