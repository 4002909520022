import axios from "axios";
import {
  BACKEND_URL,
  genericCallStatus,
  toastTypes,
} from "../../constants/generalConstants";
import { displayToast, getStandardConfig } from "../general/backendActions";
import {
  receiveAllEnteFornitore,
  receiveSingleEnteFornitore,
} from "./basicActions";

const Paths = Object.freeze({
  enteFornitore: "/entefornitore/",
});

export function fetchAllEnteFornitore() {
  return (dispatch, getState) => {
    return axios
      .get(BACKEND_URL + Paths.enteFornitore, getStandardConfig(getState))
      .then((response) => {
        dispatch(receiveAllEnteFornitore(response.data));
        return Promise.resolve(genericCallStatus.SUCCESS);
      })
      .catch((error) => {
        dispatch(
          displayToast(toastTypes.ERROR, "Errore nello scaricamento dati")
        );
        return Promise.reject(genericCallStatus.GENERIC_ERROR);
      });
  };
}

export function fetchSingleEnteFornitore(id) {
  return (dispatch, getState) => {
    return axios
      .get(BACKEND_URL + Paths.enteFornitore + id, getStandardConfig(getState))
      .then((response) => {
        dispatch(receiveSingleEnteFornitore(response.data));
      })
      .then(() => {
        return Promise.resolve(genericCallStatus.SUCCESS);
      })
      .catch((error) => {
        dispatch(
          displayToast(toastTypes.ERROR, "Errore nello scaricamento dati")
        );
        return Promise.reject(genericCallStatus.GENERIC_ERROR);
      });
  };
}

export function editSingleEnteFornitore(id, enteFornitore) {
  return (dispatch, getState) => {
    if (enteFornitore.tipoIndicatoreList) {
      delete enteFornitore.tipoIndicatoreList;
    }
    return axios
      .put(
        BACKEND_URL + Paths.enteFornitore + id,
        enteFornitore,
        getStandardConfig(getState)
      )
      .then(() => {
        dispatch(
          displayToast(toastTypes.INFO, "Dati modificati correttamente")
        );
        return Promise.resolve(genericCallStatus.SUCCESS);
      })
      .catch((error) => {
        dispatch(
          displayToast(
            toastTypes.ERROR,
            "Errore nella modifica dell'ente fornitore"
          )
        );
        return Promise.reject(genericCallStatus.GENERIC_ERROR);
      });
  };
}
