import React from "react";
import { connect } from "react-redux";
import Login from "./Auth/Login";
import { makeStyles } from "@material-ui/core/styles";
import MainPage from "./MainPage";
import { Redirect, Route, Switch } from "react-router-dom";
import { enteFornitorePaths } from './constants/enteFornitoreConstants'
import SingleIndicatorePage
  from './EnteFornitore/Indicatori/SingleIndicatorePage'
import ResetPassword from './Auth/ResetPassword'
import ForgotPassword from './Auth/ForgotPassword'

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
}));

function PageSelector(props) {
  const classes = useStyles(props);
  return (
    <div className={classes.container}>
      {props.loginData.isAuthenticated ? (
        <MainPage />
      ) : (
        <>
          <Switch>
            <Route
              exact
              path={`/`}
              render={() => {
                return <Login />;
              }}
            />
            <Route
              exact
              path={`/reset-password`}
              render={(routeProps) => {
                return <ResetPassword {...routeProps}/>;
              }}
            />
            <Route
              exact
              path={`/forgot-password`}
              render={(routeProps) => {
                return <ForgotPassword {...routeProps}/>;
              }}
            />
            <Redirect to={"/"} />
          </Switch>
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loginData: state.loginData,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PageSelector);
