import React from "react";
import TableCell from "@material-ui/core/TableCell";
import { TableRow } from "@material-ui/core";
import { StatusValoreVisualizer } from "./StatusValoreVisualizer";

export function VisualizzaValoriIndicatoriRow(props) {
  return (
    <TableRow key={props.rowData[0]}>
      <TableCell>{props.rowData[1]}</TableCell>
      <TableCell>{props.rowData[2]}</TableCell>
      <TableCell>{props.rowData[3]}</TableCell>
      <TableCell>{props.rowData[4]}</TableCell>
      <TableCell>{props.rowData[5]}</TableCell>
      <TableCell>{props.rowData[6]}</TableCell>
      <TableCell>{props.rowData[7]}</TableCell>
      <TableCell>{props.rowData[8]}</TableCell>
      <TableCell>{props.rowData[9]}</TableCell>
      <TableCell>{props.rowData[10]}</TableCell>
      <TableCell>
        <StatusValoreVisualizer status={props.rowData[11]} />
      </TableCell>
    </TableRow>
  );
}
