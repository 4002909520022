import React, { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Hidden, List } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import Logout from "../Auth/Logout";
import { UserRoles } from "../constants/authConstants";
import { AllevamentoSelector } from "../Azienda/AllevamentoSelector";
import { useSelector } from "react-redux";
import { UserSelector } from "./UserSelector";

const sidebarStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(6)
  },
  nestedText: {
      fontSize: "0.85em"
  },
  allevamentoSelector: {
    marginRight: "10px",
    minWidth: "80px",
    maxWidth: "200px",
  },
  childList: {
    background: "#f2f2f2"
  }
}));

export function DashboardSidebar(props) {
  const [childrenOpenStatus, setChildrenOpenStatus] = useState({});
  const [showLogout, setShowLogout] = useState(false);
  const userType = useSelector((state) => state.loginData.userType);
  const classes = sidebarStyles();
  const location = useLocation();
  const history = useHistory();
  const handleParentClick = (id) => {
    setChildrenOpenStatus((oldChildrenOpenStatus) => {
      if (oldChildrenOpenStatus[id]) {
        let newChildrenOpenStatus = JSON.parse(
          JSON.stringify(oldChildrenOpenStatus)
        );
        delete newChildrenOpenStatus[id];
        return newChildrenOpenStatus;
      } else {
        let newChildrenOpenStatus = JSON.parse(
          JSON.stringify(oldChildrenOpenStatus)
        );
        newChildrenOpenStatus[id] = true;
        return newChildrenOpenStatus;
      }
    });
  };
  let Datalist = props.data.map((val, i) => {
    if (val.children) {
      let childrenListElements = val.children.map((child, j) => {
        let selected =
          location.pathname === child.sidebarListLink ||
          (child.hasChild === true &&
            location.pathname.startsWith(child.sidebarListLink));
        if (child.type && child.type === "external") {
          return (
            <ListItem
              key={j}
              className={classes.nested}
              button
              selected={selected}
            >
              <ListItemText
                primary={
                  <a href={child.sidebarListLink}>{child.sidebarListText}</a>
                }
              />
            </ListItem>
          );
        } else {
          return (
            <ListItem
              key={j}
              className={classes.nested}
              button
              onClick={() => {
                history.push(child.sidebarListLink);
                if (props.onSetDrawerOpen) {
                  props.onSetDrawerOpen(false);
                }
              }}
              selected={selected}
            >
              <ListItemText>
                <div className={classes.nestedText}>
                  {child.sidebarListText}
                </div>
              </ListItemText>
            </ListItem>
          );
        }
      });
      let selected =
        location.pathname === val.sidebarListLink ||
        (val.hasChild === true &&
          location.pathname.startsWith(val.sidebarListLink));
      return (
        <div key={i}>
          <ListItem
            button
            onClick={() => {
              handleParentClick(val.id);
              history.push(val.sidebarListLink);
              if (props.onSetDrawerOpen && val.sidebarListLink !== "") {
                props.onSetDrawerOpen(false);
              }
            }}
            selected={selected}
          >
            <ListItemText >
              <b>{val.sidebarListText}</b>
            </ListItemText>
            {childrenOpenStatus[val.id] ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse
            in={childrenOpenStatus[val.id]}
            timeout="auto"
            unmountOnExit
          >
            <List className={classes.childList} component="div" disablePadding>
              {childrenListElements}
            </List>
          </Collapse>
        </div>
      );
    } else {
      let selected =
        location.pathname === val.sidebarListLink ||
        (val.hasChild === true &&
          location.pathname.startsWith(val.sidebarListLink));
      return (
        <ListItem
          key={i}
          button
          onClick={() => {
            history.push(val.sidebarListLink);
            if (props.onSetDrawerOpen) {
              props.onSetDrawerOpen(false);
            }
          }}
          selected={selected}
        >
          <ListItemText >
            <b>{val.sidebarListText}</b>
          </ListItemText>
        </ListItem>
      );
    }
  });
  return (
    <List className={props.className} disablePadding>
      {userType === UserRoles.ROLE_AZIENDA && (
        <Hidden smUp>
          <ListItem>
            <UserSelector />
          </ListItem>
          <ListItem>
            <AllevamentoSelector className={classes.allevamentoSelector} />
          </ListItem>
        </Hidden>
      )}
      {Datalist}
      <Hidden smUp>
        <ListItem button onClick={() => setShowLogout(true)}>
          Logout
        </ListItem>
      </Hidden>
      {showLogout ? <Logout /> : null}
    </List>
  );
}
