import React from "react";
import { SubmitButton } from "./GenericFormComponents/SubmitButton";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  button: {
    marginLeft: "20px",
    marginRight: "20px",
  },
}));

export function AnagraficaActionButtons(props) {
  const classes = useStyles();
  if (props.editMode) {
    return (
      <>
        <SubmitButton
          key="save"
          className={classes.button}
          variant="contained"
          color="primary"
          type="submit"
          onFormError={props.onFormError}
          label={"Salva"}
        />
        <Button
          key="cancelSave"
          className={classes.button}
          variant="contained"
          color="primary"
          type="button"
          onClick={() => {
            props.onEditModeChange(false);
          }}
        >
          Annulla
        </Button>
      </>
    );
  } else {
    return (
      <>
        <Button
          className={classes.button}
          key="edit"
          variant="contained"
          color="primary"
          type="button"
          onClick={() => {
            props.onEditModeChange(true);
          }}
        >
          Modifica
        </Button>
        {props.disableStalleAllevamentiEdit ? null : (
          <>
            <Button
              className={classes.button}
              key="stalle"
              variant="contained"
              color="primary"
              type="button"
              onClick={() => {
                props.onEditStallaClick();
              }}
            >
              Visualizza lista stalle
            </Button>
            <Button
              className={classes.button}
              key="allevamenti"
              variant="contained"
              color="primary"
              type="button"
              onClick={() => {
                props.onEditAllevamentoClick();
              }}
            >
              Visualizza lista allevamenti
            </Button>
          </>
        )}
      </>
    );
  }
}
