import React, { useEffect, useState } from "react";
import {
  ThresholdCondition,
  ThresholdConditionSchema,
  thresholdOperatorsTypesLabels,
} from "../../constants/indicatoriConstants";
import { IconButton, TableCell, TableRow } from "@material-ui/core";
import { Form, Formik } from "formik";
import { ResetForm } from "../../Common/GenericFormComponents/ResetForm";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckIcon from "@material-ui/icons/Check";
import CancelIcon from "@material-ui/icons/Cancel";
import { SimpleSelectField } from "../../Common/GenericFormComponents/SimpleSelectField";
import { ThresholdArgsField } from './ThresholdArgsField'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  group : {
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "flex-start",
    alignItems: "baseline",
  },
}));


export function NumericThresholdConditionRowEditable(props) {
  const classes = useStyles();
  const [editMode, setEditMode] = useState(false);
  const [data, setData] = useState(new ThresholdCondition());
  useEffect(() => {
    let thresholdCondition = new ThresholdCondition();
    thresholdCondition.tempId = props.rowData[0];
    thresholdCondition.arg1 = props.rowData[1];
    thresholdCondition.arg2 = props.rowData[2]
    thresholdCondition.thresholdOperator = props.rowData[3];
    setData(thresholdCondition);
  }, [props.rowData]);
  return (
    <Formik
      key={props.rowData[0]}
      initialValues={data}
      onSubmit={(values) => {
        props.onRowChange(values);
        setEditMode(false);
      }}
      validationSchema={ThresholdConditionSchema}
      enableReinitialize
    >
      {(subFormik) => (
        <TableRow key={props.rowData[0]}>
          {editMode ? <Form id={"form-" + props.rowData[0]} /> : null}
          <TableCell>
            <ResetForm showedValues={data} />
            <ThresholdArgsField
              editMode={editMode}
              rowData={props.rowData}
              className={props.inputClassName}
            />
          </TableCell>
          <TableCell>
            {editMode ? (
              <SimpleSelectField
                className={props.inputClassName}
                name={"thresholdOperator"}
                options={thresholdOperatorsTypesLabels}
              />
            ) : (
              props.rowData[4]
            )}
          </TableCell>
          <TableCell>
            {!editMode ? (
              <div className={classes.group}>
                <IconButton
                  key={"edit-" + props.rowData[0]}
                  type={"button"}
                  onClick={() => setEditMode(true)}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  key={"delete-" + props.rowData[0]}
                  type={"button"}
                  onClick={() => {
                    props.onDelete();
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            ) : (
              <div className={classes.group}>
                <IconButton
                  key={"save-" + props.rowData[0]}
                  type={"button"}
                  form={"form-" + props.rowData[0]}
                  onClick={() => {
                    subFormik.submitForm();
                  }}
                >
                  <CheckIcon />
                </IconButton>
                <IconButton
                  key={"cancel-" + props.rowData[0]}
                  type={"button"}
                  onClick={() => {
                    setEditMode(false);
                    props.onReset();
                  }}
                >
                  <CancelIcon />
                </IconButton>
              </div>
            )}
          </TableCell>
        </TableRow>
      )}
    </Formik>
  );
}
