import React, { useState } from "react";
import { Form, Formik } from "formik";
import { SubmitButton } from "../../Common/GenericFormComponents/SubmitButton";
import { SimpleInputField } from "../../Common/GenericFormComponents/SimpleInputField";
import { connect } from "react-redux";
import { changePassword } from "../../redux/auth/backendActions";
import { ChangePasswordFormSchema } from "../../constants/authConstants";
import { displayToast } from "../../redux/general/backendActions";
import { ScrollToError } from "../../Common/GenericFormComponents/ScrollToError";
import { toastTypes } from "../../constants/generalConstants";
import { ResetForm } from "../../Common/GenericFormComponents/ResetForm";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  element: {
    marginBottom: "35px",
  },
  input: {
    boxSizing: "border-box",
    height: "2em",
    width: "150px",
  },
}));

function ChangePasswordSection(props) {
  const classes = useStyles();
  const [scrollToError, setScrollToError] = useState(false);
  const [showedValues, setShowedValues] = useState({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });
  return (
    <div className={props.className}>
      <Formik
        initialValues={showedValues}
        onSubmit={(values, formikHelpers) => {
          props.changePassword(values.oldPassword, values.newPassword);
          setShowedValues({
            oldPassword: "",
            newPassword: "",
            confirmNewPassword: "",
          });
          formikHelpers.setSubmitting(false);
        }}
        validationSchema={ChangePasswordFormSchema}
        enableReinitialize
      >
        <Form>
          <ResetForm showedValues={showedValues} />
          <h6> Modifica password </h6>
          <Grid
            container
            alignItems="center"
            justify="center"
            className={classes.container}
          >
            <Grid className={classes.element} item container md={3} xs={6}>
              Vecchia password
            </Grid>
              <Grid className={classes.element} item container md={3} xs={6}>
              <SimpleInputField
                name="oldPassword"
                type="password"
                placeholder="Vecchia password"
                className={classes.input}
              />
            </Grid>
            <Grid className={classes.element} item container md={6}/>
            <Grid className={classes.element} item container md={3} xs={6}>
              Nuova password
            </Grid>
            <Grid className={classes.element} item container md={3} xs={6}>
              <SimpleInputField name="newPassword" type="password" className={classes.input} />
            </Grid>
            <Grid className={classes.element} item container md={3} xs={6}>
              Conferma nuova password
            </Grid>
            <Grid className={classes.element} item container md={3} xs={6}>
              <SimpleInputField name="confirmNewPassword" type="password" className={classes.input}/>
            </Grid>
          </Grid>
            <Grid className={`${classes.element}`} item container  justify={"center"} xs={12}>
          <SubmitButton
            onFormError={(error) => {
              props.displayToast(toastTypes.ERROR, error);
              setScrollToError(!scrollToError);
            }}
            label={"Cambia password"}
          />
            </Grid>
          <ScrollToError scroll={scrollToError} />
        </Form>
      </Formik>
    </div>
  );
}

const mapDispatchToProps = {
  changePassword,
  displayToast,
};

export default connect(null, mapDispatchToProps)(ChangePasswordSection);
