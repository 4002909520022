import React, { useEffect, useState } from "react";
import { createTheme, MuiThemeProvider } from "@material-ui/core";
import { textLabels } from "../../constants/miscConstants";
import MUIDataTable from "mui-datatables";
import {
  getModifiedOnLabel,
  getPeriodLabel,
  getValueLabel,
} from '../../constants/valoriIndicatoriConstants'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchAllIndicatore,
  fetchSingleIndicatore,
} from '../../redux/indicatori/backendActions'
import {
  fetchAllAzienda,
  fetchSingleAzienda,
} from '../../redux/aziende/backendActions'
import { defaultRowOptions } from '../../constants/generalConstants'
import { setMainTableRows } from '../../redux/general/basicActions'

const getMuiTheme = () =>
  createTheme({
    overrides: {
      MuiToolbar: {
        root: {
          backgroundColor: "#FEFDFD",
        },
      },
      MuiTable: {
        root: {
          backgroundColor: "#FEFDFD",
        },
      },
      MuiTableCell: {
        root: {
          height: "30px",
          padding: "0px 4px 0px 4px"
        },
      },
      MUIDataTableHeadCell: {
        fixedHeader: {
          backgroundColor: "#C4C4C4",
        },
      },
    },
  });

export function CommitOverviewSection(props) {
  const valori = useSelector(state => state.valori);
  const indicatori = useSelector(state => state.indicatori);
  const aziende = useSelector(state => state.aziende);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const mainTableRows = useSelector(
    (state) => state.appPreferences.mainWindowTableRows
  );
  const columns = [
    {
      name: "id",
      options: {
        display: "excluded",
      },
    },
    {
      name: "codiceAziendale",
      label: "Codice aziendale",
    },
    {
      name: "identificatoreFiscale",
      label: "Identificatore fiscale",
    },
    {
      name: "codiceSpecie",
      label: "Codice specie",
    },
    { name: "codeFlat", label: "Cod. Ind." },
    { name: "nameFlat", label: "Nome" },
    { name: "boxFlat", label: "Box" },
    { name: "measureFlat", label: "Unità di misura" },
    { name: "lastModifiedOn", label: "Data inserimento" },
    { name: "period", label: "Periodo del valore" },
    { name: "value", label: "Valore" },
  ];
  const options = {
    elevation: 0,
    selectableRows: "none",
    filter: false,
    print: false,
    download: false,
    search: false,
    viewColumns: false,
    textLabels: textLabels,
    rowsPerPageOptions: defaultRowOptions,
    rowsPerPage: mainTableRows,
    onChangeRowsPerPage: (rows) => dispatch(setMainTableRows(rows)),
  };
  useEffect(() => {
    const aziendeToFetch = new Set();
    const indicatoriToFetch = new Set();
    valori.lastCommittedValoriIDs.forEach((id) => {
      let valore = valori.valoriByIDs[id];
      aziendeToFetch.add(valore.allevamento.azienda.id);
      indicatoriToFetch.add(valore.tipoIndicatore.id);
    });
    if (aziendeToFetch.size > 5) {
      dispatch(fetchAllAzienda());
    } else {
      aziendeToFetch.forEach((id) => {
        dispatch(fetchSingleAzienda(id));
      });
    }
    if (indicatoriToFetch.size > 5) {
      dispatch(fetchAllIndicatore())
    } else {
      indicatoriToFetch.forEach((id) => {
        dispatch(fetchSingleIndicatore(id));
      });
    }
  }, [valori, dispatch]);
  useEffect(() => {
    const newData = valori.lastCommittedValoriIDs.map((id) => {
      let valore = valori.valoriByIDs[id];
      let indicatore =
        indicatori.indicatoriByIDs[valore.tipoIndicatore.id];
      return {
        id: valore.id,
        codiceAziendale: valore.allevamento.codiceAziendale,
        identificatoreFiscale: valore.allevamento.identificatoreFiscale,
        codiceSpecie: valore.allevamento.codiceSpecie,
        codeFlat: indicatore ? indicatore.code : "",
        nameFlat: indicatore ? indicatore.name : "",
        boxFlat: indicatore ? indicatore.box.name : "",
        measureFlat: indicatore ? indicatore.measureUnit : "",
        lastModifiedOn: getModifiedOnLabel(valore.modifiedOn),
        period: indicatore ? getPeriodLabel(valore, indicatore) : "",
        value: indicatore
          ? getValueLabel(valore.value, indicatore.valuesType)
          : "",
      };
    });
    setData(newData);
  }, [valori, indicatori, aziende]);
  return (
    <div className={`${props.className}`}>
      <h6>Riassunto valori inseriti</h6>
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable data={data} columns={columns} options={options} />
      </MuiThemeProvider>
    </div>
  );
}
