import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import ChangePasswordSection from "../../Auth/components/ChangePasswordSection";
import ChangeUsernameSection from "../../Auth/components/ChangeUsernameSection";
import { UserRoles } from "../../constants/authConstants";
import { AlertFieldsSection } from "../../Azienda/AlertFieldsSection";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: "100%",
    maxHeight: "100%",
  },
  mainContent: {
    marginBottom: "0",
    overflowY: "auto",
  },
}));

function ImpostazioniUtentePage(props) {
  const classes = useStyles();
  const setTitle = props.setTitle;
  useEffect(() => {
    setTitle("Impostazioni utente");
  }, [setTitle]);
  const userType = useSelector((state) => state.loginData.userType);
  return (
    <div className={classes.root}>
      <div className={classes.mainContent}>
        <ChangeUsernameSection />
        <ChangePasswordSection />
        {userType === UserRoles.ROLE_AZIENDA && <AlertFieldsSection />}
      </div>
    </div>
  );
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImpostazioniUtentePage);
