import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import EnteFornitoreDashboard from "./EnteFornitore/EnteFornitoreDashboard";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Grid, Hidden } from "@material-ui/core";
import { TopBar } from "./Common/TopBar";
import { DashboardSidebar } from "./Common/DashboardSidebar";
import { enteFornitoreSidebarData } from "./constants/enteFornitoreConstants";
import { UserRoles } from "./constants/authConstants";
import Logout from "./Auth/Logout";
import { aziendaPaths, aziendaSidebarData } from "./constants/aziendaConstants";
import { AziendaDashboard } from "./Azienda/AziendaDashboard";
import { fetchAllBoxes } from "./redux/box/backendActions";
import { serviziAssistenzaSidebarData } from "./constants/ServiziAssistenzaConstants";
import { enteControlloSidebarData } from "./constants/enteControlloConstants";
import { ServiziAssistenzaDashboard } from "./ServiziAssistenza/ServiziAssistenzaDashboard";
import { EnteControlloDashboard } from "./EnteControllo/EnteControlloDashboard";
import { adminSidebarData } from './constants/adminConstants'
import { AdminDashboard } from './Admin/AdminDashboard'

const drawerWidth = 250;

const useStyles = makeStyles(() => ({
  paper: {
    background: "#FAFAFA",
    borderRadius: "18px",
    minWidth: "96vw",
    minHeight: "96vh",
    marginTop: "2vh",
    marginBottom: "2vh",
    marginLeft: "2vw",
    marginRight: "2vw"
  },
  topBar: {
    borderBottom: "1px solid #C4C4C4",
  },
  typography: {
    whiteSpace: "pre",
  },
  sideBar: {
    width: `${drawerWidth}px`,
    borderRight: "1px solid #C4C4C4",
  },
  sideBarMobile: {
    width: `100%`,
    height: "100%",
    borderRight: "1px solid #C4C4C4",
  },
  mainSection: {
    minHeight: "calc(95vh - 165px)",
    width: "100%",
    maxWidth: "100%",
    overflowY: "hidden",
    marginBottom: "auto"
  },
  mainContent: {
    marginLeft: "5px",
    marginRight: "5px",
    width: `calc(100% - ${drawerWidth + 10}px)`,
  },
  mainContentMobile: {
    marginLeft: "5px",
    marginRight: "5px",
    width: "100%",
  },
}));

function MainPage(props) {
  const classes = useStyles(props);
  const [sidebarData, setSidebarData] = useState(enteFornitoreSidebarData);
  const [dashBoard, setDashBoard] = useState(null);
  const [title, setTitle] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const fetchAllBoxes = props.fetchAllBoxes;
  useEffect(() => {
    switch (props.loginData.userType) {
      case UserRoles.ROLE_ENTE_FORNITORE:
        setSidebarData(enteFornitoreSidebarData);
        setDashBoard(<EnteFornitoreDashboard setTitle={setTitle} />);
        break;
      case UserRoles.ROLE_AZIENDA:
        fetchAllBoxes();
        setSidebarData(aziendaSidebarData);
        setDashBoard(<AziendaDashboard setTitle={setTitle} />);
        break;
      case UserRoles.ROLE_SERVIZIO_ASSISTENZA:
        setSidebarData(serviziAssistenzaSidebarData);
        setDashBoard(<ServiziAssistenzaDashboard setTitle={setTitle} />);
        break;
      case UserRoles.ROLE_ENTE_CONTROLLO:
        setSidebarData(enteControlloSidebarData);
        setDashBoard(<EnteControlloDashboard setTitle={setTitle} />);
        break;
      case UserRoles.ROLE_ADMIN:
        setSidebarData(adminSidebarData);
        setDashBoard(<AdminDashboard setTitle={setTitle}/>);
        break;
      default:
        setDashBoard(<Logout />);
        break;
    }
  }, [props.loginData, fetchAllBoxes]);
  useEffect(() => {
    if (props.loginData.userType === UserRoles.ROLE_AZIENDA) {
      let newAziendaSidebarData = JSON.parse(
        JSON.stringify(aziendaSidebarData)
      );
      Object.keys(props.box.boxByIds).forEach((boxId) => {
        let box = props.box.boxByIds[boxId];
        newAziendaSidebarData[1].children.push({
          sidebarListLink: aziendaPaths.boxIndicatori.base + "/" + boxId,
          sidebarListText: box.name,
        });
      });
      setSidebarData(newAziendaSidebarData);
    }
  }, [props.loginData, props.box]);
  return (
    <Paper elevation={3} className={classes.paper}>
      <Grid container alignItems="stretch">
        <TopBar
          className={classes.topBar}
          title={title}
          draweropen={drawerOpen}
          onSetDrawerOpen={(state) => setDrawerOpen(state)}
          setTitle={setTitle}
        />
        <Hidden xsDown>
          <Grid container item xs={12} className={classes.mainSection}>
            <Grid item className={classes.sideBar}>
              <DashboardSidebar data={sidebarData} />
            </Grid>
            <Grid item className={classes.mainContent}>
              {dashBoard}
            </Grid>
          </Grid>
        </Hidden>
        <Hidden smUp>
          <Grid container item xs={12} className={classes.mainSection}>
            {drawerOpen ? (
              <Grid item className={classes.sideBarMobile}>
                <DashboardSidebar
                  data={sidebarData}
                  onSetDrawerOpen={(state) => setDrawerOpen(state)}
                />
              </Grid>
            ) : (
              <Grid item className={classes.mainContentMobile}>
                {dashBoard}
              </Grid>
            )}
          </Grid>
        </Hidden>
      </Grid>
    </Paper>
  );
}

const mapStateToProps = (state) => {
  return {
    loginData: state.loginData,
    box: state.box,
  };
};

const mapDispatchToProps = {
  fetchAllBoxes,
};

export default connect(mapStateToProps, mapDispatchToProps)(MainPage);
