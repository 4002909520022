import React, { useEffect, useState } from "react";
import { connect, useSelector } from 'react-redux'
import { fetchAllLastValoreOfIndicatoreAsAzienda } from "../../redux/valoriindicatori/backendActions";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent, CardHeader, CardMedia,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core'
import {
  getValueState,
  valuesStatuses,
} from "../../constants/valoriIndicatoriConstants";
import { StatusValoreVisualizer } from "../../Common/StatusValoreVisualizer";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles(() => ({
  card: {
    width: "100%",
    height: "100%",
  },
  titleSection: {
    marginRight: "auto",
    display: "flex",
    flexDirection: "row",
  },
  titleRed: {
    color: "red",
    marginRight: "10px",
  },
  generalStatus: {
    marginLeft: "auto",
    width: "6.8em",
    height: "3.3em",
  },
  element: {
    marginBottom: "10px",
  },
  boxHeader: {
    padding: "16px",
    borderBottom: "solid 1px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
  },
  cardTitle: {
    margin: "0px",
    overflow: "ellipsis"
  },
  alertCard: {
    border: "solid red 4px",
  },
  alertText: {
    color: "white",
    backgroundColor: "red",
    paddingLeft: "10px",
    paddingRight: "10px",
    fontWeight: "bold",
  },
}));

function BoxCard(props) {
  const classes = useStyles();
  const fetchAllLastValoreOfIndicatoreAsAzienda =
    props.fetchAllLastValoreOfIndicatoreAsAzienda;
  const [overallStatus, setOverallStatus] = useState(valuesStatuses.GREEN);
  const [greenStatuses, setGreenStatuses] = useState(0);
  const [yellowStatuses, setYellowStatuses] = useState(0);
  const [redStatuses, setRedStatuses] = useState(0);
  const [cardClassNames, setCardClassNames] = useState(
    `${classes.card} ${props.className}`
  );
  const location = useLocation();
  const selectedAllevamentoId = useSelector(
    (state) => state.loginTransientState.selectedAllevamentoId
  );
  useEffect(() => {
    if (props.alertMode) {
      setCardClassNames(
        `${classes.card} ${props.className} ${classes.alertCard}`
      );
    } else {
      setCardClassNames(`${classes.card} ${props.className}`);
    }
  }, [props.alertMode, props.className, classes.alertCard, classes.card]);
  useEffect(() => {
    fetchAllLastValoreOfIndicatoreAsAzienda();
  }, [fetchAllLastValoreOfIndicatoreAsAzienda, location, selectedAllevamentoId]);
  useEffect(() => {
    let greens = 0;
    let yellows = 0;
    let reds = 0;
    let indicatore;
    for (let i = 0; i < props.box.indicatori.length; i++) {
      indicatore = props.box.indicatori[i];
      if (props.valori.lastValoreByTipoIndicatoreId[indicatore.id]) {
        let valore =
          props.valori.valoriByIDs[
            props.valori.lastValoreByTipoIndicatoreId[indicatore.id]
          ];
        switch (getValueState(valore, props.box.indicatori[i])) {
          case valuesStatuses.GREEN:
            greens = greens + 1;
            break;
          case valuesStatuses.YELLOW:
            yellows = yellows + 1;
            break;
          case valuesStatuses.RED:
            reds = reds + 1;
            break;
          default:
            break;
        }
      }
    }
    setGreenStatuses(greens);
    setRedStatuses(reds);
    setYellowStatuses(yellows);
    if (reds > 0) {
      setOverallStatus(valuesStatuses.RED);
    } else if (yellows > 0) {
      setOverallStatus(valuesStatuses.YELLOW);
    } else {
      setOverallStatus(valuesStatuses.GREEN);
    }
  }, [props.box, props.valori]);
  return (
    <Card className={cardClassNames} onClick={props.onClick}>
      <CardMedia>
        <Grid item container xs={12} className={`${classes.element} ${classes.boxHeader}`}>
          <div className={classes.titleSection}>
            {props.alertMode ? (
              <>
                <h4 className={`${classes.titleRed} ${classes.cardTitle}`}>{props.box.name}</h4>
                <h4 className={`${classes.alertText} ${classes.cardTitle}`}>Alert</h4>
              </>
            ) : (
              <h4 className={`${classes.cardTitle}`}>{props.box.name}</h4>
            )}
          </div>
          <StatusValoreVisualizer
            className={classes.generalStatus}
            status={overallStatus}
          />
        </Grid>
      </CardMedia>
      <CardContent>
        <Grid container alignItems="center" justify="center">
          <Grid item container xs={12} className={classes.element}>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell>
                    {"Indicatori "}
                    <StatusValoreVisualizer status={valuesStatuses.GREEN} />:
                  </TableCell>
                  <TableCell>
                    <b>{greenStatuses}</b>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {"Indicatori "}
                    <StatusValoreVisualizer status={valuesStatuses.YELLOW} />:
                  </TableCell>
                  <TableCell>
                    <b>{yellowStatuses}</b>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {"Indicatori "}
                    <StatusValoreVisualizer status={valuesStatuses.RED} />:
                  </TableCell>
                  <TableCell>
                    <b>{redStatuses}</b>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    valori: state.valori,
  };
};

const mapDispatchToProps = {
  fetchAllLastValoreOfIndicatoreAsAzienda,
};

export default connect(mapStateToProps, mapDispatchToProps)(BoxCard);
