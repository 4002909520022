import React, {useRef} from "react"
import {Field} from "formik";
import {ErrorTooltip} from "./ErrorTooltip";

export function TextAreaField(props) {
    const tooltipTarget = useRef(null);
    return (
        <Field name={props.name}>
            {({field, meta, form}) => (
                <>
                    <textarea
                        ref={tooltipTarget}
                        {...props}
                        {...field}
                    />
                    <ErrorTooltip
                        target={tooltipTarget}
                        show={Boolean(meta.touched && meta.error)}
                        msg={meta.error}
                    />
                </>
            )}
        </Field>
    );
}