import { RECEIVE_ALL_BOX, RECEIVE_SINGLE_BOX } from './basicActions'
import { LOGGED_OUT } from '../auth/basicActions'

export function box(
  state = {
    boxByIds: {},
    boxSelectValues: {}
  },
  action
) {
  switch (action.type) {
    case RECEIVE_ALL_BOX:
      let newBox = action.boxes.reduce(
        (obj, item) => Object.assign(obj, { [item.id]: item }),
        {}
      );
      let newBoxValues = action.boxes.reduce(
        (obj, item) => Object.assign(obj, { [item.id]: item.name }),
        {}
      );
      return {
        ...state,
        boxByIds: newBox,
        boxSelectValues: newBoxValues
      };
    case RECEIVE_SINGLE_BOX:
      return {
        ...state,
        boxByIds: {
          ...state.boxByIds,
          [action.box.id]: action.box,
        },
        boxSelectValues: {
          ...state.boxSelectValues,
          [action.box.id]: action.box.name,
        }
      };
    case LOGGED_OUT:
      return {
        boxByIds: {},
        boxSelectValues: {}
      }
    default:
      return state;
  }
}
