import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { createTheme, Fab, MuiThemeProvider } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  createBox,
  deleteSingleBox,
  editBox,
  fetchAllBoxes,
  fetchSingleBox,
} from "../../redux/box/backendActions";
import { textLabels } from "../../constants/miscConstants";
import { BoxRowEditable } from "../components/BoxRowEditable";
import MUIDataTable from "mui-datatables";
import AddIcon from "@material-ui/icons/Add";
import { CreateNewBoxDialog } from "../components/CreateNewBoxDialog";
import { defaultRowOptions } from '../../constants/generalConstants'
import { setMainTableRows } from '../../redux/general/basicActions'

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  table: {
    marginBottom: "0",
    overflowY: "auto",
  },
  fab: {
    position: "fixed",
    bottom: "50px",
    right: "8vw",
  },
  rowInput: {
    boxSizing: "border-box",
    height: "100%",
    width: "100%",
  },
}));

const getMuiTheme = () =>
  createTheme({
    overrides: {
      MuiToolbar: {
        root: {
          backgroundColor: "#FEFDFD",
        },
      },
      MuiTable: {
        root: {
          backgroundColor: "#FEFDFD",
        },
      },
      MuiTableCell: {
        root: {
          height: "30px",
          padding: "0px 4px 0px 4px",
        },
      },
      MUIDataTableHeadCell: {
        fixedHeader: {
          backgroundColor: "#C4C4C4",
        },
      },
    },
  });

export function BoxListPage(props) {
  const location = useLocation();
  const [data, setData] = useState([]);
  const mainTableRows = useSelector(
    (state) => state.appPreferences.mainWindowTableRows
  );
  const [showNewBoxDialog, setShowNewBoxDialog] = useState(false);
  const box = useSelector((state) => state.box);
  const classes = useStyles();
  const setTitle = props.setTitle;
  const dispatch = useDispatch();
  useEffect(() => {
    setTitle("Lista box");
  }, [setTitle]);
  useEffect(() => {
    dispatch(fetchAllBoxes());
  }, [location, dispatch]);
  useEffect(() => {
    let newData = Object.keys(box.boxByIds).map((id) => {
      let singleBox = box.boxByIds[id];
      return {
        id: id,
        name: singleBox.name,
        totIndicatori: singleBox.indicatori.length,
        enabled: singleBox.enabled,
      };
    });
    setData(newData);
  }, [box]);
  const columns = [
    {
      name: "id",
      options: {
        display: "excluded",
      },
    },
    { name: "name", label: "Nome box" },
    { name: "totIndicatori", label: "Numero indicatori associati" },
    {
      name: "enabled",
      label: "Abilitato",
    },
    {
      name: "actions",
      label: "Azioni",
    },
  ];
  const options = {
    elevation: 0,
    selectableRows: "none",
    filter: false,
    print: false,
    download: false,
    search: false,
    viewColumns: false,
    textLabels: {
      ...textLabels,
      body: {
        ...textLabels.body,
        noMatch: "Nessun box presente",
      },
    },
    rowsPerPageOptions: defaultRowOptions,
    rowsPerPage: mainTableRows,
    onChangeRowsPerPage: (rows) => dispatch(setMainTableRows(rows)),
    customRowRender: (row) => {
      return (
        <BoxRowEditable
          inputClassName={classes.rowInput}
          key={row[0]}
          rowData={row}
          onRowChange={(values) => {
            dispatch(editBox(values.id, values)).then(() =>
              dispatch(fetchSingleBox(values.id))
            );
          }}
          onDelete={() => {
            dispatch(deleteSingleBox(row[0])).then(() =>
              dispatch(fetchAllBoxes())
            );
          }}
          onReset={() => {
            dispatch(fetchSingleBox(row[0]));
          }}
        />
      );
    },
  };
  return (
    <div className={classes.root}>
      <div className={classes.table}>
        <h6>Lista box</h6>
        <MuiThemeProvider theme={getMuiTheme()}>
          <MUIDataTable data={data} columns={columns} options={options} />
        </MuiThemeProvider>
        <Fab
          color="primary"
          variant={"extended"}
          className={classes.fab}
          onClick={() => setShowNewBoxDialog(true)}
        >
          <AddIcon />
          Crea box
        </Fab>
        <CreateNewBoxDialog
          open={showNewBoxDialog}
          onClose={() => setShowNewBoxDialog(false)}
          createBox={(values) => {
            let newBox = JSON.parse(JSON.stringify(values));
            delete newBox.totIndicatori;
            delete newBox.id;
            dispatch(createBox(newBox)).then(() => dispatch(fetchAllBoxes()));
            setShowNewBoxDialog(false);
          }}
        />
      </div>
    </div>
  );
}
