import React, { useEffect } from 'react'
import { useFormikContext } from 'formik'
import {
  ThresholdCondition, thresholdOperatorsTypes,
  valuesTypes,
} from '../../constants/indicatoriConstants'
import { NumericThresholdConditionsFields } from './NumericThresholdConditionsFields'
import {
  VALUES_BOOLEAN, VALUES_STRING,
  valuesStatuses,
} from '../../constants/valoriIndicatoriConstants'
import { StringThresholdConditionsFields } from './StringThresholdConditionsFields'
import { BooleanThresholdConditionsFields } from './BooleanThresholdConditionsFields'

export function ThresholdConditionsFields(props) {
  const formik = useFormikContext();
  const type = formik.values["valuesType"];
  const setFieldValue = formik.setFieldValue;
  useEffect(() => {
    switch (type) {
      case valuesTypes.BOOLEAN:
        const condTrue = new ThresholdCondition();
        condTrue.thresholdOperator = thresholdOperatorsTypes.EQUAL;
        condTrue.arg1 = VALUES_BOOLEAN.TRUE;
        const condFalse = new ThresholdCondition();
        condFalse.thresholdOperator = thresholdOperatorsTypes.EQUAL;
        condFalse.arg1 = VALUES_BOOLEAN.FALSE;
        setFieldValue("greenThresholdConditions", [condTrue]);
        setFieldValue("yellowThresholdConditions", []);
        setFieldValue("redThresholdConditions", [condFalse]);
        break;
      case valuesTypes.STRING:
        const condPos = new ThresholdCondition();
        condPos.thresholdOperator = thresholdOperatorsTypes.EQUAL;
        condPos.arg1 = VALUES_STRING.POSITIVA
        const condMigl = new ThresholdCondition();
        condMigl.thresholdOperator = thresholdOperatorsTypes.EQUAL;
        condMigl.arg1 = VALUES_STRING.MIGLIORABILE;
        const condNeg = new ThresholdCondition();
        condNeg.thresholdOperator = thresholdOperatorsTypes.EQUAL;
        condNeg.arg1 = VALUES_STRING.NEGATIVA;
        setFieldValue("greenThresholdConditions", [condPos]);
        setFieldValue("yellowThresholdConditions", [condMigl]);
        setFieldValue("redThresholdConditions", [condNeg]);
        break;
      case valuesTypes.NUMBER:
      default:
        setFieldValue("greenThresholdConditions", []);
        setFieldValue("yellowThresholdConditions", []);
        setFieldValue("redThresholdConditions", []);
        break;
    }
    setFieldValue("defaultThreshold", valuesStatuses.RED)
  }, [type, setFieldValue]);
  switch (type){
    case valuesTypes.NUMBER:
      return (
        <NumericThresholdConditionsFields
          editMode={props.editMode}
          elementClass={props.elementClass}
          inputClass={props.inputClass}
        />
      )
    case valuesTypes.STRING:
      return (
        <StringThresholdConditionsFields
          editMode={props.editMode}
          elementClass={props.elementClass}
          inputClass={props.inputClass}
          selectClass={props.selectClass}
        />
      )
    case valuesTypes.BOOLEAN:
      return (
        <BooleanThresholdConditionsFields
          editMode={props.editMode}
          elementClass={props.elementClass}
          inputClass={props.inputClass}
          selectClass={props.selectClass}
        />
      )
    default:
      return null
  }
}