import React, { useEffect, useRef, useState } from 'react'
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { askPasswordReset } from "../redux/auth/backendActions";
import { connect } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import { RECAPTCHA_API_KEY, toastTypes } from "../constants/generalConstants";
import { displayToast } from "../redux/general/backendActions";
import { SimpleInputField } from "../Common/GenericFormComponents/SimpleInputField";
import { ErrorTooltip } from "../Common/GenericFormComponents/ErrorTooltip";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography } from "@material-ui/core";
import { Image } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import { Link } from 'react-router-dom'
import { authPaths } from '../constants/authConstants'
import { ForgotPasswordModalWindow } from './components/ForgotPasswordModalWindow'
import { ResetForm } from '../Common/GenericFormComponents/ResetForm'

const useStyles = makeStyles((theme) => ({
  paper: {
    background: "#FAFAFA",
    borderRadius: "18px",
    width: "100%",
    height: "480px",
    marginTop: "-210px",
    maxWidth: "500px",
    position: "absolute",
    top: "50%",
  },
  container: {
    marginBottom: "10px",
    marginTop: "10px",
  },
  input: {
    height: "2em",
    width: "70%",
  },
  typography: {
    whiteSpace: "pre",
  },
}));

const formInit = Object.freeze({
  email: "",
  confirmEmail: "",
  recaptcha: "",
});

function ForgotPassword(props) {
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState("")
  const [showedValues, setShowedValues] = useState(formInit);
  const recaptchaRef = useRef();
  const classes = useStyles(props);
  const reCAPTCHATooltipTarget = useRef(null);
  useEffect(() => {
    setEmail("");
  }, [setEmail])
  return (
    <Paper elevation={3} className={classes.paper}>
      <Formik
        initialValues={showedValues}
        onSubmit={(values, formikHelpers) => {
          props.askPasswordReset(values.email, values.recaptcha)
          .then(() => {
            formikHelpers.setSubmitting(false);
            props.displayToast(
              toastTypes.SUCCESS,
              "Email inviata a " +
              values.email +
              ", se presente"
            );
            setEmail(values.email)
            setShowModal(true);
          })
          .catch(() => {
            props.displayToast(
              toastTypes.ERROR,
              "Errore durante la richiesta di ripristino password: ripetere l'operazione se necessario"
            );
            setEmail("");
            recaptchaRef.current.reset();
            setShowedValues(formInit);
          })
          formikHelpers.setSubmitting(false);
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .strict(true)
            .trim("Non sono ammessi spazi")
            .email("Inserire una email valida")
            .required("Campo obbligatorio"),
          confirmEmail: Yup.string()
            .strict(true)
            .trim("Non sono ammessi spazi")
            .email("Inserire una email valida")
            .oneOf([Yup.ref("email"), null], "Le email non corrispondono")
            .required("Campo obbligatorio"),
          recaptcha: Yup.string()
            .strict(true)
            .nullable()
            .required('Cliccare su "Non sono un robot"'),
        })}
        enableReinitialize
      >
        <Form>
          <ResetForm showedValues={showedValues} />
          <Grid
            container
            spacing={2}
            alignItems="center"
            justify="center"
            className={classes.container}
          >
            <Grid item container xs={12} justify="center">
              <Image src={"/Logo.png"} />
            </Grid>
            <Grid item container xs={12} justify="center">
              <h5>Recupera la password</h5>
            </Grid>
            <Grid item container xs={6} justify="center">
              <Typography>{"Indirizzo email "}</Typography>
            </Grid>
            <Grid item container xs={6} justify="center">
              <SimpleInputField
                type="email"
                className={classes.input}
                placeholder="Email"
                name="email"
              />
            </Grid>
            <Grid item container xs={6} justify="center">
              <Typography>{"Conferma indirizzo email "}</Typography>
            </Grid>
            <Grid item container xs={6} justify="center">
              <SimpleInputField
                type="email"
                className={classes.input}
                placeholder="Ripeti email"
                name="confirmEmail"
              />
            </Grid>
            <Grid
              item
              container
              xs={12}
              justify="center"
              ref={reCAPTCHATooltipTarget}
            >
              <Field name="recaptcha">
                {({ field, meta, form }) => (
                  <>
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={RECAPTCHA_API_KEY}
                      onChange={(token) => {
                        form.setFieldValue("recaptcha", token);
                      }}
                    />
                    <ErrorTooltip
                      target={reCAPTCHATooltipTarget}
                      show={Boolean(meta.touched && meta.error)}
                      msg={meta.error}
                    />
                  </>
                )}
              </Field>
            </Grid>
            <Grid item container xs={12} justify="center">
              <Button variant="contained" color="primary" type="submit">
                Invia
              </Button>
            </Grid>
            <Grid item container xs={12} justify="center">
              <Typography className={classes.typography}>
                {"Torna al "}
              </Typography>
              <Link to={authPaths.login}>login</Link>
            </Grid>
          </Grid>
        </Form>
      </Formik>
      <ForgotPasswordModalWindow show={showModal} email={email} onClose={() => {
        setShowModal(false)
        setEmail("");
        recaptchaRef.current.reset();
        setShowedValues(formInit);
      }} />
    </Paper>
  );
}

const mapDispatchToProps = {
  askPasswordReset,
  displayToast,
};

export default connect(null, mapDispatchToProps)(ForgotPassword);
