import axios from "axios";
import {
  BACKEND_URL,
  genericCallStatus,
  toastTypes,
} from "../../constants/generalConstants";
import { displayToast, getStandardConfig } from "../general/backendActions";
import { receiveAllBox, receiveSingleBox } from './basicActions'

const Paths = Object.freeze({
  box: "/box/",
});

export function fetchAllBoxes() {
  return (dispatch, getState) => {
    return axios
      .get(BACKEND_URL + Paths.box, getStandardConfig(getState))
      .then((response) => {
        dispatch(receiveAllBox(response.data));
        return Promise.resolve(genericCallStatus.SUCCESS);
      })
      .catch(() => {
        dispatch(
          displayToast(toastTypes.ERROR, "Errore nello scaricamento dati")
        );
        return Promise.reject(genericCallStatus.GENERIC_ERROR);
      });
  };
}

export function fetchSingleBox(boxId) {
  return (dispatch, getState) => {
    return axios
      .get(
        BACKEND_URL + Paths.box + boxId,
        getStandardConfig(getState)
      )
      .then((response) => {
        dispatch(receiveSingleBox(response.data));
      })
      .then(() => {
        return Promise.resolve(genericCallStatus.SUCCESS);
      })
      .catch(() => {
        dispatch(
          displayToast(toastTypes.ERROR, "Errore nello scaricamento dati")
        );
        return Promise.reject(genericCallStatus.GENERIC_ERROR);
      });
  };
}

export function deleteSingleBox(boxId) {
  return (dispatch, getState) => {
    return axios
    .delete(
      BACKEND_URL + Paths.box + boxId,
      getStandardConfig(getState)
    )
    .then(() => {
      dispatch(
        displayToast(toastTypes.SUCCESS, "Box eliminato con successo")
      );
      return Promise.resolve(genericCallStatus.SUCCESS);
    })
    .catch(() => {
      dispatch(
        displayToast(toastTypes.ERROR, "Errore durante l'eliminazione del box")
      );
      return Promise.reject(genericCallStatus.GENERIC_ERROR);
    });
  };
}

export function createBox(box) {
  return (dispatch, getState) => {
    return axios
      .post(
        BACKEND_URL + Paths.box,
        box,
        getStandardConfig(getState)
      )
      .then(() => {
        dispatch(
          displayToast(toastTypes.SUCCESS, "Box creato con successo")
        );
        return Promise.resolve(genericCallStatus.GENERIC_ERROR);
      })
      .catch(() => {
        dispatch(
          displayToast(
            toastTypes.ERROR,
            "Errore durante la creazione del box"
          )
        );
        return Promise.reject(genericCallStatus.GENERIC_ERROR);
      });
  };
}

export function editBox(id, box) {
  return (dispatch, getState) => {
    return axios
      .put(
        BACKEND_URL + Paths.box + id,
        box,
        getStandardConfig(getState)
      )
      .then(() => {
        dispatch(
          displayToast(toastTypes.SUCCESS, "Modifiche al box salvate con successo")
        );
        return Promise.resolve(genericCallStatus.GENERIC_ERROR);
      })
      .catch(() => {
        dispatch(
          displayToast(toastTypes.ERROR, "Errore durante il salvataggio delle modifiche")
        );
        return Promise.reject(genericCallStatus.GENERIC_ERROR);
      });
  };
}
