import React, { useRef } from 'react'
import { Field } from "formik";
import PropTypes from "prop-types";
import { ErrorTooltip } from "./ErrorTooltip";

function CustomRadioSelectorField(props) {
  const tooltipTarget = useRef(null);
  return (
    <Field name={props.field}>
      {({ field, meta, form }) => (
        <div ref={tooltipTarget}>
          {Object.keys(props.optionsValues).map((key) => {
            return (
              <div className="form-check" key={key}>
                <input
                  className="form-check-input"
                  disabled={props.disabled}
                  type="radio"
                  name={key + props.field}
                  id={key + props.field}
                  key={key + props.field}
                  value={props.optionsValues[key]}
                  checked={field.value === props.optionsValues[key]}
                  onChange={async (event) => {
                    if (event.target.checked) {
                      await form.setFieldValue(
                        props.field,
                        props.optionsValues[key]
                      );
                      if (props.onFieldChange) {
                        props.onFieldChange(props.optionsValues[key]);
                      }
                    }
                  }}
                />
                <label className="form-check-label" htmlFor="privat-client">
                  {props.optionsLabels[key]}
                </label>
              </div>
            );
          })}
          <ErrorTooltip
            target={tooltipTarget}
            show={Boolean(meta.error && meta.touched)}
            msg={meta.error}
          />
        </div>
      )}
    </Field>
  );
}

CustomRadioSelectorField.propTypes = {
  optionsValues: PropTypes.object.isRequired,
  optionsLabels: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
  onFieldChange: PropTypes.func,
};

export { CustomRadioSelectorField };
