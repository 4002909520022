import React, { useEffect, useState } from "react";
import {
  createTheme,
  MuiThemeProvider,
  Typography,
} from "@material-ui/core";
import { textLabels } from "../../constants/miscConstants";
import { makeStyles } from "@material-ui/core/styles";
import { StagingAreaRow } from "./StagingAreaRow";
import MUIDataTable from "mui-datatables";
import {
  getPeriodLabel,
  getValueLabel,
} from "../../constants/valoriIndicatoriConstants";
import { allevamentoString } from "../../constants/aziendaConstants";

const columns = [
  {
    name: "id",
    options: {
      display: "excluded",
    },
  },
  { name: "period", label: "Periodo" },
  { name: "value", label: "Valore" },
  { name: "actions", label: "Azioni" },
];

const getMuiTheme = () =>
  createTheme({
    overrides: {
      MuiToolbar: {
        root: {
          backgroundColor: "#FEFDFD",
        },
      },
      MuiTable: {
        root: {
          backgroundColor: "#FEFDFD",
        },
      },
      MuiTableCell: {
        root: {
          height: "30px",
        },
      },
      MUIDataTableHeadCell: {
        fixedHeader: {
          backgroundColor: "#C4C4C4",
        },
      },
    },
  });

const useStyles = makeStyles(() => ({
  rowInput: {
    boxSizing: "border-box",
    height: "100%",
    width: "100%",
  },
}));

export function StagingAreaIndicatoreView(props) {
  const [data, setData] = useState([]);
  const [allevamentoLabel, setAllevamentoLabel] = useState("");
  const [indicatoreCode, setIndicatoreCode] = useState("");
  const classes = useStyles();
  const editStagedValoreFunc = props.editStagedValoreFunc;
  const fetchStagedValore = props.fetchStagedValore;
  const options = {
    elevation: 0,
    selectableRows: "none",
    filter: false,
    print: false,
    download: false,
    search: false,
    viewColumns: false,
    textLabels: textLabels,
    rowsPerPageOptions: [10, 20, 50, 100],
    setTableProps: () => {
      return {
        padding: "none",
      };
    },
    customRowRender: (data) => {
      return (
        <StagingAreaRow
          inputClassName={classes.rowInput}
          key={data[0]}
          rowData={data}
          valoreRaw={props.stagedValoriByIDs[data[0]]}
          patchValore={(id, valore) => {
            editStagedValoreFunc(id, valore).then(() => fetchStagedValore(id));
          }}
          onReset={() => {
            fetchStagedValore(props.stagedValoriByIDs[data[0]].id);
          }}
        />
      );
    },
  };
  useEffect(() => {
    if (!props.selectedIndicatoreId || !props.selectedAllevamentoId) {
      return;
    }
    const filteredValori = props.stagingArea.valori
      .map((valore) => {
        return props.stagedValoriByIDs[valore.id];
      })
      .filter((valore) => {
        return (
          valore.allevamento.id.toString() === props.selectedAllevamentoId &&
          valore.tipoIndicatore.id.toString() === props.selectedIndicatoreId
        );
      });
    const tempData = filteredValori.map((filteredValori) => {
      return {
        id: filteredValori.id,
        period: getPeriodLabel(filteredValori, filteredValori.tipoIndicatore),
        value: getValueLabel(
          filteredValori.value,
          filteredValori.tipoIndicatore.valuesType
        ),
      };
    });
    setData(tempData);
    if (filteredValori.length === 0){
      return;
    }
    setAllevamentoLabel(allevamentoString(filteredValori[0].allevamento));
    setIndicatoreCode(filteredValori[0].tipoIndicatore.code);
  }, [
    props.selectedAllevamentoId,
    props.selectedIndicatoreId,
    props.stagedValoriByIDs,
    props.stagingArea,
  ]);
  return (
    <div className={props.className}>
      {!props.selectedIndicatoreId || !props.selectedAllevamentoId ? (
        <Typography>Nessun indicatore selezionato</Typography>
      ) : (
        <>
          <Typography>{`${allevamentoLabel} - Indicatore ${indicatoreCode}`}</Typography>
          <MuiThemeProvider theme={getMuiTheme()}>
            <MUIDataTable data={data} columns={columns} options={options} />
          </MuiThemeProvider>
        </>
      )}
    </div>
  );
}
