import React, { useEffect } from 'react'
import {
  valuesTypes,
} from '../../constants/indicatoriConstants'
import { SimpleInputField } from '../../Common/GenericFormComponents/SimpleInputField'
import { fetchSingleIndicatore } from '../../redux/indicatori/backendActions'
import { connect } from 'react-redux'
import { SimpleSelectField } from '../../Common/GenericFormComponents/SimpleSelectField'
import {
  booleanValuesSelect,
  stringValuesSelect,
} from '../../constants/valoriIndicatoriConstants'

function ValueField(props){
  const fetchSingleIndicatore = props.fetchSingleIndicatore;
  useEffect(() => {
    if (props.indicatoreId !== "") {
      fetchSingleIndicatore(props.indicatoreId)
    }
  }, [props.indicatoreId, fetchSingleIndicatore]);
  if (props.indicatoreId === ""){
    return (
      <SimpleInputField
        type="text"
        name={props.name}
        form={props.form}
        className={props.className}
      />
    )
  }
  switch (props.indicatori.indicatoriByIDs[props.indicatoreId].valuesType){
    case valuesTypes.NUMBER:
      return (
        <SimpleInputField
          type="text"
          name={props.name}
          form={props.form}
          className={props.className}
        />
      )
    case valuesTypes.STRING:
      return (
        <SimpleSelectField className={props.className} name={props.name} options={stringValuesSelect}/>
      )
    case valuesTypes.BOOLEAN:
      return (
        <SimpleSelectField className={props.className} name={props.name} options={booleanValuesSelect} />
      )
    default:
      return null;
  }
}

const mapDispatchToProps = {
  fetchSingleIndicatore
};

const mapStateToProps = (state) => {
  return {
    indicatori: state.indicatori,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ValueField);