import React, { useEffect } from "react";
import {
  Grid,
  Hidden,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
import { connect } from "react-redux";
import { fetchProfile } from "../../redux/auth/backendActions";

function AziendaOverviewSection(props) {
  const fetchProfile = props.fetchProfile;
  useEffect(() => {
    fetchProfile();
  }, [fetchProfile]);
  if (!props.loginTransientState.profile) {
    return null;
  }
  return (
    <div className={props.className}>
      <h6>Dati allevatore</h6>
      <Grid container alignItems="center" justify="center">
        <Grid className={props.elementClassname} item container sm={12} xs={12}>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell colSpan={4}>
                  <b>{props.loginTransientState.profile.denominazione}</b>
                </TableCell>
              </TableRow>
              <Hidden smDown>
                <TableRow>
                  <TableCell>
                    <b>Email azienda:</b>
                  </TableCell>
                  <TableCell>
                    {props.loginTransientState.profile.emailAzienda}
                  </TableCell>
                  <TableCell>
                    <b>Partita IVA:</b>
                  </TableCell>
                  <TableCell>
                    {props.loginTransientState.profile.partitaIva}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Indirizzo:</b>
                  </TableCell>
                  <TableCell colSpan={3}>
                    {props.loginTransientState.profile.indirizzo +
                      ", " +
                      props.loginTransientState.profile.comune.name +
                      ", " +
                      props.loginTransientState.profile.comune.provincia.code}
                  </TableCell>
                </TableRow>
              </Hidden>
              <Hidden mdUp>
                <TableRow>
                  <TableCell>
                    <b>Email azienda:</b>
                  </TableCell>
                  <TableCell>
                    {props.loginTransientState.profile.emailAzienda}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Partita IVA:</b>
                  </TableCell>
                  <TableCell>
                    {props.loginTransientState.profile.partitaIva}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Indirizzo:</b>
                  </TableCell>
                  <TableCell>
                    {props.loginTransientState.profile.indirizzo +
                      ", " +
                      props.loginTransientState.profile.comune.name +
                      ", " +
                      props.loginTransientState.profile.comune.provincia.code}
                  </TableCell>
                </TableRow>
              </Hidden>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loginTransientState: state.loginTransientState,
  };
};

const mapDispatchToProps = {
  fetchProfile,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AziendaOverviewSection);
