import React, { useCallback, useEffect, useState } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfile } from "../redux/auth/backendActions";
import { setSelectedAllevamento } from "../redux/auth/basicActions";
import {
  allevamentoStringSelector,
} from "../constants/aziendaConstants";
import { displayToast } from "../redux/general/backendActions";
import { toastTypes } from "../constants/generalConstants";

export function AllevamentoSelector(props) {
  const dispatch = useDispatch();
  const allevamenti = useSelector(
    (state) => state.loginTransientState.currentUserAllevamentiByIds
  );
  const selectedAllevamentoId = useSelector(
    (state) => state.loginTransientState.selectedAllevamentoId
  );
  const [menuItems, setMenuItems] = useState([]);
  useEffect(() => {
    dispatch(fetchProfile());
  }, [dispatch]);
  useEffect(() => {
    const newMenuItems = Object.keys(allevamenti).map((id) => (
      <MenuItem key={id} value={id}>
        {allevamentoStringSelector(allevamenti[id])}
      </MenuItem>
    ));
    setMenuItems(newMenuItems);
    if (!allevamenti[selectedAllevamentoId]){
      dispatch(setSelectedAllevamento(Object.keys(allevamenti)[0]));
    }
  }, [allevamenti, dispatch, selectedAllevamentoId]);
  const handleChange = useCallback(
    (event) => {
      let id = event.target.value;
      dispatch(setSelectedAllevamento(id));
      let label = allevamentoStringSelector(allevamenti[id]);
      dispatch(
        displayToast(toastTypes.INFO, "Allevamento " + label + " selezionato")
      );
    },
    [dispatch, allevamenti]
  );
  if (!selectedAllevamentoId) {
    return null;
  } else {
    return (
      <FormControl>
        <InputLabel id="allevamento-label">Allevamento</InputLabel>
        <Select
          className={props.className}
          onChange={handleChange}
          name={"label"}
          value={selectedAllevamentoId}
          labelId={"allevamento-label"}
        >
          {menuItems}
        </Select>
      </FormControl>
    );
  }
}
