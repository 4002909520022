import React, { useRef } from 'react'
import { useField } from 'formik'
import { ErrorTooltip } from '../../Common/GenericFormComponents/ErrorTooltip'
import {
  maxYear,
  minYear,
  monthSelectOptions,
} from '../../constants/valoriIndicatoriConstants'

export function MonthSelector(props) {
  const tooltipTarget = useRef(null);
  const [field, meta, helpers] = useField(props.name);
  const { value } = meta;
  const { setValue } = helpers;
  const yearOptionsArray = [];
  for (let i = minYear; i <= maxYear; i++ ){
    yearOptionsArray.push((
        <option key={i} id={i} value={i}>
          {i}
        </option>
      )
    )
  }
  return (
        <div className={props.classNameContainer} ref={tooltipTarget}>
          <select
            name={props.name + "-month"}
            id={props.id + "-month"}
            className={props.classNameMonth}
            disabled={props.disabled}
            value={(new Date(value).getMonth() + 1).toString()}
            onChange={ e => {
              let year = new Date(value).getFullYear();
              setValue(year + "-" + e.target.value.padStart(2, '0') + "-01");
            }}
          >
            <option disabled={!props.allowEmptyValues} value={""}>Seleziona</option>
            {
              Object.keys(monthSelectOptions).map((key) => {
                return (
                  <option key={key} id={key} value={key}>
                    {monthSelectOptions[key]}
                  </option>
                )
              })
            }
          </select>
          <select
            name={props.name + "-year"}
            id={props.id + "-year"}
            className={props.classNameYear}
            disabled={props.disabled}
            value={new Date(value).getFullYear().toString()}
            onChange={ e => {
              let month = (new Date(value).getMonth() + 1).toString().padStart(2, '0');
              setValue(e.target.value + "-" + month + "-01");
            }}
          >
            <option disabled={!props.allowEmptyValues} value={""}>Seleziona</option>
            {
              yearOptionsArray
            }
          </select>
          <ErrorTooltip
            target={tooltipTarget}
            show={Boolean(meta.error && !props.disableErrors && meta.touched)}
            msg={meta.error}
          />
        </div>
  )
}