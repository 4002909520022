import {useEffect, useState} from "react";
import {useFormikContext} from 'formik';

export const ScrollToError = (props) => {
    const formik = useFormikContext();
    const [, setScroll] = useState(props.scroll);
    useEffect(() => {
        let keys = Object.keys(formik.errors);
        setScroll( oldScroll => {
            if (oldScroll !== props.scroll) {
                if (keys.length > 0) {
                    const selector = `[name=${keys[0]}]`;
                    let errorElement = null;
                    errorElement = document.querySelector(selector);
                    if (!errorElement) {
                        errorElement = document.getElementById(keys[0]);
                    }
                    if (!errorElement) {
                        return;
                    }
                    const yOffset = -130;
                    const y = errorElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    const isSmoothScrollSupported = "scrollBehavior" in document.documentElement.style;
                    if (isSmoothScrollSupported) {
                        // not on IE/Edge
                        window.scrollTo({top: y, behavior: "smooth"});
                    } else {
                        // for Edge
                        document.body.scrollTop = y;
                        // use an offset to compensate for the fixed header
                        document.documentElement.scrollTop = y;
                    }
                    setTimeout(() => {
                        errorElement.focus();
                    }, 500);
                }
            }
            return props.scroll;
        })

    }, [props.scroll, formik.errors]);
    return null;
};