import React, { useRef } from 'react'
import { useField } from 'formik'
import {
  getSeason,
  maxYear,
  minYear, periodSeasonLabels, periodSeasonValues,
} from '../../constants/valoriIndicatoriConstants'
import { ErrorTooltip } from '../../Common/GenericFormComponents/ErrorTooltip'

export function YearSelector(props){
  const tooltipTarget = useRef(null);
  const [field, meta, helpers] = useField(props.name);
  const { value } = meta;
  const { setValue } = helpers;
  const yearOptionsArray = [];
  for (let i = minYear; i <= maxYear; i++ ){
    yearOptionsArray.push((
        <option key={i} id={i} value={i}>
          {i}
        </option>
      )
    )
  }
  return (
    <div ref={tooltipTarget}>
      <select
        name={props.name + "-year"}
        id={props.id + "-year"}
        className={props.className}
        disabled={props.disabled}
        value={new Date(value).getFullYear().toString()}
        onChange={ e => {
          setValue(e.target.value + "-01-01");
        }}
      >
        <option disabled={!props.allowEmptyValues} value={""}>Seleziona</option>
        {
          yearOptionsArray
        }
      </select>
      <ErrorTooltip
        target={tooltipTarget}
        show={Boolean(meta.error && !props.disableErrors && meta.touched)}
        msg={meta.error}
      />
    </div>
  )
}