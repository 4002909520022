import axios from "axios";
import {
  BACKEND_URL,
  genericCallStatus,
  toastTypes,
} from "../../constants/generalConstants";
import { displayToast, getStandardConfig } from "../general/backendActions";
import {
  receiveAllLastValoreOfIndicatore,
  receiveCommittedValori,
  receiveCsvError,
  receiveFilteredValori,
  receiveIndicatoriAverages,
  receiveLastValoreOfIndicatore,
  receiveSingleValore,
  receiveStagedValore,
  receiveValoriStagingArea,
} from "./basicActions";
import { setIsWaiting } from '../auth/basicActions'

const Paths = Object.freeze({
  valori: "/valori",
  indicatore: "/indicatore",
  profile: "/user/profile",
  stagingArea: "/staging-area/",
  caricamentoCsv: "/caricamento/file-staging",
  averages: "/aggregate/average",
  allevamento: "/allevamento/"
});

export function fetchFilteredValori(indicatoreId, allevamentoId, fromDay, toDay, indicatoreCode) {
  return (dispatch, getState) => {
    let query = new URLSearchParams();
    if (indicatoreId) {
      query.append("tipoId", indicatoreId);
    }
    if (allevamentoId) {
      query.append("allevamentoId", allevamentoId);
    }
    if (fromDay) {
      query.append("fromPeriod", fromDay);
    }
    if (toDay) {
      query.append("toPeriod", toDay);
    }
    if (indicatoreCode) {
      query.append("tipoCode", indicatoreCode);
    }
    return axios
      .get(
        BACKEND_URL + Paths.valori + "?" + query.toString(),
        getStandardConfig(getState)
      )
      .then((response) => {
        dispatch(receiveFilteredValori(response.data));
        return Promise.resolve(genericCallStatus.SUCCESS);
      })
      .catch(() => {
        dispatch(
          displayToast(toastTypes.ERROR, "Errore nello scaricamento dati")
        );
        return Promise.reject(genericCallStatus.GENERIC_ERROR);
      });
  };
}

export function fetchSingleValore(id) {
  return (dispatch, getState) => {
    return axios
      .get(BACKEND_URL + Paths.valori + "/" + id, getStandardConfig(getState))
      .then((response) => {
        dispatch(receiveSingleValore(response.data));
      })
      .then(() => {
        return Promise.resolve(genericCallStatus.SUCCESS);
      })
      .catch(() => {
        dispatch(
          displayToast(toastTypes.ERROR, "Errore nello scaricamento dati")
        );
        return Promise.reject(genericCallStatus.GENERIC_ERROR);
      });
  };
}

export function fetchLastValoreOfIndicatore(tipoIndicatoreId) {
  return (dispatch, getState) => {
    return axios
      .get(
        BACKEND_URL + Paths.indicatore + "/" + tipoIndicatoreId + "/last",
        getStandardConfig(getState)
      )
      .then((response) => {
        dispatch(
          receiveLastValoreOfIndicatore(tipoIndicatoreId, response.data)
        );
      })
      .then(() => {
        return Promise.resolve(genericCallStatus.SUCCESS);
      })
      .catch(() => {
        dispatch(
          displayToast(toastTypes.ERROR, "Errore nello scaricamento dati")
        );
        return Promise.reject(genericCallStatus.GENERIC_ERROR);
      });
  };
}

export function fetchAllLastValoreOfIndicatoreAsAzienda() {
  return (dispatch, getState) => {
    return axios
      .get(BACKEND_URL + Paths.allevamento + getState().loginTransientState.selectedAllevamentoId, getStandardConfig(getState))
      .then((response) => {
        dispatch(
          receiveAllLastValoreOfIndicatore(
            response.data.ultimiValoriIndicatori
          )
        );
      })
      .then(() => {
        return Promise.resolve(genericCallStatus.SUCCESS);
      })
      .catch(() => {
        dispatch(
          displayToast(toastTypes.ERROR, "Errore nello scaricamento dati")
        );
        return Promise.reject(genericCallStatus.GENERIC_ERROR);
      });
  };
}

export function createValore(valore) {
  return (dispatch, getState) => {
    return axios
      .post(BACKEND_URL + Paths.valori, valore, getStandardConfig(getState))
      .then(() => {
        dispatch(
          displayToast(toastTypes.SUCCESS, "Valore inserito con successo")
        );
        return Promise.resolve(genericCallStatus.GENERIC_ERROR);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 409) {
            dispatch(displayToast(toastTypes.ERROR, "Valore già esistente"));
            return Promise.reject(genericCallStatus.GENERIC_ERROR);
          }
        }
        dispatch(
          displayToast(
            toastTypes.ERROR,
            "Errore durante l'inserimento del valore"
          )
        );
        return Promise.reject(genericCallStatus.GENERIC_ERROR);
      });
  };
}

export function patchValore(id, valore) {
  return (dispatch, getState) => {
    return axios
      .patch(
        BACKEND_URL + Paths.valori + "/" + id,
        valore,
        getStandardConfig(getState)
      )
      .then(() => {
        dispatch(
          displayToast(toastTypes.INFO, "Modifiche salvate con successo")
        );
        return Promise.resolve(genericCallStatus.GENERIC_ERROR);
      })
      .catch(() => {
        dispatch(
          displayToast(
            toastTypes.ERROR,
            "Errore durante il salvataggio delle modifiche"
          )
        );
        return Promise.reject(genericCallStatus.GENERIC_ERROR);
      });
  };
}

export function deleteSingleValore(id) {
  return (dispatch, getState) => {
    return axios
      .delete(
        BACKEND_URL + Paths.valori + "/" + id,
        getStandardConfig(getState)
      )
      .then(() => {
        dispatch(
          displayToast(toastTypes.INFO, "Valore eliminato correttamente")
        );
        return Promise.resolve(genericCallStatus.SUCCESS);
      })
      .catch(() => {
        dispatch(
          displayToast(toastTypes.ERROR, "Errore nell'eliminazione del valore")
        );
        return Promise.reject(genericCallStatus.GENERIC_ERROR);
      });
  };
}

export function fetchValoriStagingArea(id) {
  return (dispatch, getState) => {
    return axios
      .get(BACKEND_URL + Paths.stagingArea + id, getStandardConfig(getState))
      .then((response) => {
        dispatch(receiveValoriStagingArea(response.data));
      })
      .then(() => {
        return Promise.resolve(genericCallStatus.SUCCESS);
      })
      .catch(() => {
        dispatch(
          displayToast(toastTypes.ERROR, "Errore nello scaricamento dati")
        );
        return Promise.reject(genericCallStatus.GENERIC_ERROR);
      });
  };
}

export function fetchStagedValore(id) {
  return (dispatch, getState) => {
    return axios
      .get(
        BACKEND_URL +
          Paths.stagingArea +
          getState().valori.lastStagingAreaId +
          "/valore/" +
          id,
        getStandardConfig(getState)
      )
      .then((response) => {
        dispatch(receiveStagedValore(response.data));
      })
      .then(() => {
        return Promise.resolve(genericCallStatus.SUCCESS);
      })
      .catch(() => {
        dispatch(
          displayToast(toastTypes.ERROR, "Errore nello scaricamento dati")
        );
        return Promise.reject(genericCallStatus.GENERIC_ERROR);
      });
  };
}

const UPLOAD_STATUS = Object.freeze({
  UPLOAD_SUCCESS: "SUCCESS",
  UPLOAD_ERROR: "UPLOAD_ERROR",
  FILE_TOO_BIG: "FILE_TOO_BIG",
  FILE_WITH_ERRORS: "FILE_WITH_ERRORS",
});

export function uploadCsvValoriFile(file) {
  return (dispatch, getState) => {
    const formdata = new FormData();
    formdata.append("file", file);
    let config = getStandardConfig(getState);
    config.timeout = 0;
    dispatch(setIsWaiting(true));
    return axios
      .post(
        BACKEND_URL + Paths.caricamentoCsv,
        formdata,
        config
      )
      .then((response) => {
        dispatch(setIsWaiting(false))
        return dispatch(fetchValoriStagingArea(response.data.id));
      })
      .then(() => {
        dispatch(displayToast(toastTypes.INFO, "File caricato"));
        return Promise.resolve(UPLOAD_STATUS.UPLOAD_SUCCESS);
      })
      .catch((error) => {
      dispatch(setIsWaiting(false));
        if (error.response.status === 400) {
          dispatch(receiveCsvError(error.response.data));
          dispatch(displayToast(toastTypes.ERROR, "Errori presenti nel file"));
          return Promise.reject(UPLOAD_STATUS.FILE_WITH_ERRORS);
        } else if (error.response.status === 417) {
          dispatch(receiveCsvError(error.response.data));
          dispatch(
            displayToast(toastTypes.ERROR, "File selezionato troppo grande")
          );
          return Promise.reject(UPLOAD_STATUS.FILE_TOO_BIG);
        } else {
          dispatch(receiveCsvError(error.response.data));
          dispatch(
            displayToast(toastTypes.ERROR, "Errore nel caricamento del file")
          );
          return Promise.reject(UPLOAD_STATUS.UPLOAD_ERROR);
        }
      });
  };
}

export function patchStagedValore(id, valore) {
  return (dispatch, getState) => {
    return axios
      .patch(
        BACKEND_URL +
          Paths.stagingArea +
          getState().valori.lastStagingAreaId +
          "/valore/" +
          id,
        valore,
        getStandardConfig(getState)
      )
      .then(() => {
        return Promise.resolve(genericCallStatus.GENERIC_ERROR);
      })
      .catch(() => {
        dispatch(
          displayToast(
            toastTypes.ERROR,
            "Errore durante il salvataggio delle modifiche"
          )
        );
        return Promise.reject(genericCallStatus.GENERIC_ERROR);
      });
  };
}

export function commitStagingArea(id) {
  return (dispatch, getState) => {
    let config = getStandardConfig(getState);
    config.timeout = 0;
    dispatch(setIsWaiting(true));
    return axios
      .post(
        BACKEND_URL + Paths.stagingArea + id + "/commit",
        undefined,
      config
      )
      .then((response) => {
        dispatch(setIsWaiting(false));
        dispatch(receiveCommittedValori(response.data));
      })
      .then(() => {
        return Promise.resolve(genericCallStatus.SUCCESS);
      })
      .catch(() => {
        dispatch(setIsWaiting(false));
        dispatch(
          displayToast(toastTypes.ERROR, "Errore nel salvataggio dei valori")
        );
        return Promise.reject(genericCallStatus.GENERIC_ERROR);
      });
  };
}

export function fetchIndicatoriAverages(
  tipoId,
  boxId,
  fromPeriod,
  toPeriod,
  provinciaId,
  comuneId
) {
  return (dispatch, getState) => {
    let query = new URLSearchParams();
    if (tipoId) {
      query.append("tipoId", tipoId);
    }
    if (boxId) {
      query.append("boxId", boxId);
    }
    if (fromPeriod) {
      query.append("fromPeriod", fromPeriod);
    }
    if (toPeriod) {
      query.append("toPeriod", toPeriod);
    }
    if (provinciaId) {
      query.append("provinciaId", provinciaId);
    }
    if (comuneId) {
      query.append("comuneId", comuneId);
    }
    return axios
      .get(
        BACKEND_URL + Paths.averages + "?" + query.toString(),
        getStandardConfig(getState)
      )
      .then((response) => {
        dispatch(receiveIndicatoriAverages(response.data));
        return Promise.resolve(genericCallStatus.SUCCESS);
      })
      .catch(() => {
        dispatch(
          displayToast(toastTypes.ERROR, "Errore nello scaricamento dati")
        );
        return Promise.reject(genericCallStatus.GENERIC_ERROR);
      });
  };
}
