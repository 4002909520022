import React, { useEffect } from "react";
import { connect } from "react-redux";
import { fetchSingleIndicatore } from "../../redux/indicatori/backendActions";
import { frequencyTypes } from "../../constants/indicatoriConstants";
import { SimpleInputField } from "../../Common/GenericFormComponents/SimpleInputField";
import { MonthSelector } from "./MonthSelector";
import { SeasonSelector } from "./SeasonSelector";
import { YearSelector } from "./YearSelector";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  halfSelect: {
    width: "50%",
    height: "100%",
  },
}));

function PeriodSelectorField(props) {
  const classes = useStyles();
  const fetchSingleIndicatore = props.fetchSingleIndicatore;
  useEffect(() => {
    if (props.indicatoreId !== "") {
      fetchSingleIndicatore(props.indicatoreId);
    }
  }, [props.indicatoreId, fetchSingleIndicatore]);
  if (props.indicatoreId === "") {
    return (
      <SimpleInputField
        type="date"
        className={props.className}
        name={props.name}
        form={props.form}
      />
    );
  }
  let forceDaily = frequencyTypes.DAILY
  switch (forceDaily) {
    case frequencyTypes.DAILY:
      return (
        <SimpleInputField
          type="date"
          className={props.className}
          name={props.name}
          form={props.form}
        />
      );
    case frequencyTypes.MONTHLY:
      return (
        <MonthSelector
          classNameContainer={props.className}
          classNameMonth={classes.halfSelect}
          classNameYear={classes.halfSelect}
          name={props.name}
        />
      );
    case frequencyTypes.SEASONAL:
      return (
        <SeasonSelector
          classNameContainer={props.className}
          classNameSeason={classes.halfSelect}
          classNameYear={classes.halfSelect}
          name={props.name}
        />
      );
    case frequencyTypes.YEARLY:
      return <YearSelector className={props.className} name={props.name} />;
    default:
      return null;
  }
}

const mapDispatchToProps = {
  fetchSingleIndicatore,
};

const mapStateToProps = (state) => {
  return {
    indicatori: state.indicatori,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PeriodSelectorField);
