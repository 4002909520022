import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ScrollToTop } from "./Common/ScrollToTop";
import { makeStyles, MuiThemeProvider } from "@material-ui/core/styles";
import createTheme from "@material-ui/core/styles/createTheme";
import PageSelector from "./PageSelector";
import "react-toastify/dist/ReactToastify.css";
import { WaitBackdrop } from "./Common/WaitBackdrop";

const theme = createTheme({
  palette: {
    primary: {
      light: "#7580e5",
      main: "#4054b2",
      dark: "#002c82",
      contrastText: "#ffffff",
    },
    secondary: {
      light: "#57d5ce",
      main: "#01a39d",
      dark: "#00746f",
      contrastText: "#000000",
    },
  },
});

const rootStyles = makeStyles(() => ({
  root: {
    background: "#1CDDC7",
    minHeight: "100vh",
    minWidth: "100vw",
  },
}));

function App() {
  const classes = rootStyles();
  const handlePageChange = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className={classes.root}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter basename={"/"} render={handlePageChange}>
            <ToastContainer
              position="bottom-right"
              autoClose={5000}
              hideProgressBar
              newestOnTop
              closeOnClick
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <MuiThemeProvider theme={theme}>
              <ScrollToTop />
              <WaitBackdrop />
              <PageSelector />
            </MuiThemeProvider>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
