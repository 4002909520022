import {
    RECEIVE_COMUNE_DATA,
    RECEIVE_PROVINCE_DATA,
    RECEIVE_REGIONI_DATA, SET_MAIN_TABLE_ROWS,
} from './basicActions'
import { LOGGED_OUT } from '../auth/basicActions'

export function provinceComuneSelectorData(state = {
    provincesByIDs: {},
    comuneByIDs: {},
    comuneIDsByProvinceIDs: {},
    regioniByIDs: {}
}, action){
    switch (action.type) {
        case RECEIVE_PROVINCE_DATA:
            let newProvincesByIDs = {};
            action.provinceData.forEach(province => {
                newProvincesByIDs[province.id] = province;
            });
            return {
                ...state,
                provincesByIDs: {
                    ...newProvincesByIDs
                },
            };
        case RECEIVE_COMUNE_DATA:
            let newComuneByIDs = {};
            action.comuneData.forEach(comune => {
                newComuneByIDs[comune.id] = comune;
            });
            return {
                ...state,
                comuneByIDs: {
                    ...state.comuneByIDs,
                    ...newComuneByIDs
                },
                comuneIDsByProvinceIDs: {
                    ...state.comuneIDsByProvinceIDs,
                    [action.provinceID]: Object.keys(newComuneByIDs),
                }
            };
        case RECEIVE_REGIONI_DATA:
            let newRegioniByIDs = {};
            action.regioniData.forEach(regione => {
                newRegioniByIDs[regione.id] = regione;
            });
            return {
                ...state,
                regioniByIDs: {
                    ...newRegioniByIDs
                }
            }
        case LOGGED_OUT:
            return {
                provincesByIDs: {},
                comuneByIDs: {},
                comuneIDsByProvinceIDs: {},
                regioniByIDs: {}
            }
        default:
            return state;
    }
}

export function appPreferences(state = {
    mainWindowTableRows: 20,
}, action){
    switch (action.type) {
        case SET_MAIN_TABLE_ROWS:
            return {
                ...state,
                mainWindowTableRows: action.rowNumber
            };
        default:
            return state;
    }
}
