import React, {useRef} from "react";
import {Field} from "formik";
import {ErrorTooltip} from "./ErrorTooltip";

export function SimpleInputField(props) {
    const tooltipTarget = useRef(null);
    return (
        <Field className={props.className} name={props.name}>
            {({field, meta, form}) => (
                <>
                    <input name={props.name}
                           type={props.type}
                           className={props.className}
                           ref={tooltipTarget}
                           {...field}
                           {...props}
                    />
                    <ErrorTooltip
                        target={tooltipTarget}
                        show={Boolean(meta.touched && meta.error)}
                        msg={meta.error}
                    />
                </>
            )}
        </Field>
    );
}