import React, { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import {
  createTheme,
  MuiThemeProvider,
} from "@material-ui/core";
import { textLabels } from "../constants/miscConstants";
import MUIDataTable from "mui-datatables";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import { AttrezzatureStallaRow } from "./AttrezzatureStallaRow";
import { AttrezzatureStallaRowEditable } from "./AttrezzatureStallaRowEditable";
import { makeStyles } from "@material-ui/core/styles";
import { AttrezzaturaStalla } from '../constants/aziendaConstants'

const getMuiTheme = () =>
  createTheme({
    overrides: {
      MuiToolbar: {
        root: {
          backgroundColor: "#FEFDFD",
        },
      },
      MuiTable: {
        root: {
          backgroundColor: "#FEFDFD",
        },
      },
      MuiTableCell: {
        root: {
          height: "30px",
        },
      },
      MUIDataTableHeadCell: {
        fixedHeader: {
          backgroundColor: "#C4C4C4",
        },
      },
    },
  });

const useStyles = makeStyles(() => ({
  rowInput: {
    boxSizing: "border-box",
    height: "100%",
    width: "100%",
  },
}));

export function AttrezzatureStallaField(props) {
  const classes = useStyles(props);
  const formik = useFormikContext();
  const [data, setData] = useState([]);
  const [options, setOptions] = useState({});
  const [columns, setColumns] = useState([]);
  const fieldValue = formik.values[props.name];
  const setFieldValue = formik.setFieldValue;
  useEffect(() => {
    if (props.editMode) {
      const newColumns = [
        {
          name: "tempId",
          options: {
            display: "excluded",
          },
        },
        { name: "nomeAttrezzatura", label: "Nome attrezzatura." },
        {
          name: "actions",
          label: "Azioni",
        },
      ];
      setColumns(newColumns);
      setOptions({
        elevation: 0,
        selectableRows: "none",
        filter: false,
        print: false,
        download: false,
        search: false,
        viewColumns: false,
        pagination: false,
        rowsPerPageOptions: [10, 20, 50, 100],
        setTableProps: () => {
          return {
            padding: "none",
          };
        },
        textLabels: {
          ...textLabels,
          body: {
            ...textLabels.body,
            noMatch: "Nessuna attrezzatura presente",
          },
        },
        customRowRender: (row, index) => {
          return (
            <AttrezzatureStallaRowEditable
              inputClassName={classes.rowInput}
              key={index}
              rowData={row}
              onRowChange={(values) => {
                let newData = JSON.parse(JSON.stringify(data));
                newData[index] = values;
                setFieldValue(props.name, newData);
              }}
              onDelete={() => {
                let newData = JSON.parse(JSON.stringify(data));
                newData.splice(index, 1);
                setFieldValue(props.name, newData);
              }}
              onReset={() => {
                let newData = fieldValue.map((attrezzatura) => {
                  return {
                    tempId: attrezzatura.id,
                    id: attrezzatura.id,
                    nomeAttrezzatura: attrezzatura.nomeAttrezzatura,
                  };
                });
                setData(newData);
              }}
            />
          );
        },
      });
    } else {
      const newColumns = [
        {
          name: "tempId",
          options: {
            display: "excluded",
          },
        },
        { name: "nomeAttrezzatura", label: "Nome attrezzatura" },
      ];
      setColumns(newColumns);
      setOptions({
        elevation: 0,
        selectableRows: "none",
        filter: false,
        print: false,
        download: false,
        search: false,
        viewColumns: false,
        textLabels: textLabels,
        rowsPerPageOptions: [10, 20, 50, 100],
        setTableProps: () => {
          return {
            padding: "none",
          };
        },
        customRowRender: (data, index) => {
          return <AttrezzatureStallaRow key={index} rowData={data} />;
        },
      });
    }
  }, [props.editMode, fieldValue, setFieldValue, props.name, data, classes]);
  useEffect(() => {
    let newData = JSON.parse(JSON.stringify(fieldValue));
    let fixedNewData = newData.map(row => {
      if (!row.tempId){
        row.tempId = row.id;
      }
      return row;
    })
    setData(fixedNewData);
  }, [fieldValue]);
  return (
    <>
      <h6>Attrezzature</h6>
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable data={data} columns={columns} options={options} />
      </MuiThemeProvider>
      {props.editMode ? (
        <Button
          variant="contained"
          color="primary"
          type="button"
          className={classes.rowInput}
          onClick={() => {
            let newData = JSON.parse(JSON.stringify(data));
            let newRow = new AttrezzaturaStalla();
            newRow.stalla.id = formik.values.id;
            newRow.tempId = newData.length + 1;
            while (newData.find(row => row.id === newRow.tempId)){
              newRow.tempId = newRow.tempId + 1;
            }
            newRow.nomeAttrezzatura = "Nuova attrezzatura";
            newData.push(newRow);
            setFieldValue(props.name, newData);
          }}
        >
          <AddIcon />
          Aggiungi attrezzatura
        </Button>
      ) : null}
    </>
  );
}
