import React, { useState, useEffect, useRef } from "react";
import { Field } from "formik";
import { ErrorTooltip } from "./ErrorTooltip";

// TODO Values can be only string
export function SimpleSelectField(props) {
  const [keys, setKeys] = useState([]);
  const tooltipTarget = useRef(null);
  useEffect(() => {
    if (props.sortByLabel) {
      let swappedOptions = {};
      Object.keys(props.options).forEach((key) => {
        swappedOptions[props.options[key]] = key;
      });
      let newKeys = [];
      Object.values(props.options)
        .sort()
        .forEach((key) => {
          newKeys.push(swappedOptions[key]);
        });
      setKeys(newKeys);
    } else if (props.sortByArray) {
      setKeys(props.sortingArray);
    } else if (props.sortByValue) {
      setKeys(Object.keys(props.options).sort());
    } else {
      setKeys(Object.keys(props.options));
    }
  }, [
    props.options,
    props.sortByLabel,
    props.sortByArray,
    props.sortByValue,
    props.sortingArray,
  ]);
  return (
    <Field name={props.name}>
      {({ field, meta, form }) => (
        <>
          <select
            ref={tooltipTarget}
            name={props.name}
            id={props.id}
            className={props.className}
            disabled={props.disabled}
            {...field}
            onChange={async (event) => {
              await field.onChange(event);
              if (props.onChange) {
                props.onChange(event);
              }
            }}
          >
            <option disabled={!props.allowEmptyValues} value={""}>
              Seleziona
            </option>
            {props.options &&
              keys.map((key) => {
                return (
                  <option key={key} id={key} value={key}>
                    {props.options[key]}
                  </option>
                );
              })}
          </select>
          <ErrorTooltip
            target={tooltipTarget}
            show={Boolean(meta.error && !props.disableErrors && meta.touched)}
            msg={meta.error}
          />
        </>
      )}
    </Field>
  );
}
