import React, { useState } from "react";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { TopBarControls } from "./TopBarControls";
import Button from "@material-ui/core/Button";
import Logout from "../Auth/Logout";
import { useSelector } from "react-redux";
import { UserRoles } from "../constants/authConstants";
import { AllevamentoSelector } from "../Azienda/AllevamentoSelector";
import { Hidden, IconButton } from "@material-ui/core";
import { Image } from "react-bootstrap";
import CloseIcon from "@material-ui/icons/Close";
import { UserSelector } from "./UserSelector";

const useStyles = makeStyles((theme) => ({
  bar: {
    width: "100%",
    height: "90px",
    overflow: "hidden",
    [theme.breakpoints.down("xs")]: {
      height: "85px",
    },
  },

  topBarControls: {
    marginRight: "auto",
  },

  titleSection: {
    marginLeft: "auto",
    marginBottom: "0",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0",
    },
  },

  title: {
    overflow: "ellipsis",
    maxWidth: "270px",
  },

  logoutButton: {
    marginLeft: "auto",
    marginRight: "20px",
    display: "flex",
  },

  userSelector: {
    marginRight: "10px",
    maxWidth: "120px",
  },

  allevamentoSelector: {
    marginRight: "10px",
    minWidth: "80px",
    maxWidth: "200px",
  },

  imageDiv: {
    marginLeft: "20px",
  },

  pimba: {
    width: "100%",
  },

  logoSection: {
    height: "80px",
    backgroundColor: "#FFFFFF",
    marginTop: "auto",
  },
  hide: {
    visibility: "hidden",
  },
}));

// Need css cleanup
export function TopBar(props) {
  const classes = useStyles(props);
  const [showLogout, setShowLogout] = useState(false);
  const userType = useSelector((state) => state.loginData.userType);
  return (
    <Grid
      className={`${props.className}`}
      container
      direction="column"
      justify="flex-start"
      alignItems="center"
      wrap={"nowrap"}
    >
      <Grid
        className={`${classes.bar}`}
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        wrap={"nowrap"}
      >
        <Hidden smUp>
          {props.draweropen ? (
            <IconButton
              color="primary"
              onClick={() => {
                props.onSetDrawerOpen(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : (
            <IconButton
              color="primary"
              onClick={() => {
                props.onSetDrawerOpen(true);
                props.setTitle("Menù");
              }}
            >
              <MenuIcon />
            </IconButton>
          )}
        </Hidden>
        <Hidden xsDown>
          <div className={classes.imageDiv}>
            <Image src={"/Logo-transparent.png"} width="80px" height="80px" />
          </div>
        </Hidden>
        <Hidden xsDown>
          <TopBarControls className={classes.topBarControls} />
        </Hidden>
        <div className={classes.titleSection}>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            wrap={"nowrap"}
          >
            <h5 className={classes.title}>Livestock smart farming</h5>
            <h6 className={classes.title}>{props.title}</h6>
          </Grid>
        </div>

        <Hidden xsDown>
          <div className={classes.logoutButton}>
            <UserSelector className={classes.userSelector} />
            {userType === UserRoles.ROLE_AZIENDA && (
              <AllevamentoSelector className={classes.allevamentoSelector} />
            )}
            <Button
              variant="contained"
              color="primary"
              type="button"
              onClick={() => setShowLogout(true)}
            >
              LOGOUT
            </Button>
          </div>

          {showLogout ? <Logout /> : null}
        </Hidden>
      </Grid>
      <Hidden xsDown>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          wrap={"nowrap"}
          className={classes.logoSection}
        >
          <Hidden xsDown>
            <Image src={"/loghiVari.jpg"} height="80px" />
          </Hidden>
          <div className={classes.pimba}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              wrap={"nowrap"}
            >
              <h6>P.I.M.B.A.</h6>
              <div>Piattaforma Informatica Monitoraggio Benessere Animale</div>
            </Grid>
          </div>
        </Grid>
      </Hidden>
    </Grid>
  );
}
