import {
  frequencyTypes,
  thresholdOperatorsTypes,
  valuesTypes,
} from "./indicatoriConstants";
import parseISO from "date-fns/parseISO";
import * as Yup from "yup";

export const VALUES_BOOLEAN = Object.freeze({
  TRUE: 1,
  FALSE: 0,
});

export const VALUES_BOOLEAN_LABELS = Object.freeze({
  TRUE: "Vero/Positivo",
  FALSE: "Falso/Negativo",
});

export const VALUES_STRING = Object.freeze({
  POSITIVA: 2,
  MIGLIORABILE: 1,
  NEGATIVA: 0,
});

export const VALUES_STRING_LABELS = Object.freeze({
  POSITIVA: "M/Ottimo/Positiva",
  MIGLIORABILE: "I/Sufficiente/Migliorabile",
  NEGATIVA: "P/Insufficiente/Negativa",
});

export const booleanValuesSelect = Object.freeze({
  1: "Vero/Positivo",
  0: "Falso/Negativo",
});

export const stringValuesSelect = Object.freeze({
  2: "M/Ottimo/Positiva",
  1: "I/Sufficiente/Migliorabile",
  0: "P/Insufficiente/Negativa",
});

export const periodSeasonValues = Object.freeze({
  WINTER: "12-23",
  SPRING: "03-21",
  SUMMER: "06-22",
  FALL: "09-23",
});

export const periodSeasonLabels = Object.freeze({
  WINTER: "Inverno",
  SPRING: "Primavera",
  SUMMER: "Estate",
  FALL: "Autunno",
});

export const valuesStatuses = Object.freeze({
  GREEN: "GREEN",
  YELLOW: "YELLOW",
  RED: "RED",
});

export const valuesStatusesLabels = Object.freeze({
  GREEN: "Verde",
  YELLOW: "Giallo",
  RED: "Rosso",
});

export const monthSelectOptions = Object.freeze({
  1: "Gennaio",
  2: "Febbraio",
  3: "Marzo",
  4: "Aprile",
  5: "Maggio",
  6: "Giugno",
  7: "Luglio",
  8: "Agosto",
  9: "Settembre",
  10: "Ottobre",
  11: "Novembre",
  12: "Dicembre",
});

export const minYear = 1900;

export const maxYear = 2100;

export const getSeason = (date) => {
  switch (date.getMonth()) {
    case 0:
    case 1:
      return "WINTER";
    case 2:
      if (date.getDate() >= 21) {
        return "SPRING";
      } else {
        return "WINTER";
      }
    case 3:
    case 4:
      return "SPRING";
    case 5:
      if (date.getDate() >= 22) {
        return "SUMMER";
      } else {
        return "SPRING";
      }
    case 6:
    case 7:
      return "SUMMER";
    case 8:
      if (date.getDate() >= 23) {
        return "FALL";
      } else {
        return "SUMMER";
      }
    case 9:
    case 10:
      return "FALL";
    case 11:
      if (date.getDate() >= 23) {
        return "WINTER";
      } else {
        return "FALL";
      }
    default:
      return "";
  }
};

export const getPeriodLabel = (valore, indicatore) => {
  let period;
  if (!valore.period instanceof Date) {
    period = parseISO(valore.period);
  } else {
    period = valore.period;
  }
  let forceDaily = frequencyTypes.DAILY;
  switch (forceDaily) {
    case frequencyTypes.DAILY:
      return new Date(valore.period).toLocaleString("default", {
        dateStyle: "long",
      });
    case frequencyTypes.MONTHLY:
      return period.toLocaleString("default", {
        month: "long",
        year: "numeric",
      });
    case frequencyTypes.SEASONAL:
      return (
        periodSeasonLabels[getSeason(period)] +
        " " +
        period.toLocaleString("default", { year: "numeric" })
      );
    case frequencyTypes.YEARLY:
      return period.getFullYear();
    default:
      return "";
  }
};

export const getModifiedOnLabel = (data) => {
  return new Date(data).toLocaleString("default", {
    dateStyle: "long",
  });
}

export const getValueState = (valore, indicatore) => {
  if (
    evaluateThresholdConditions(valore, indicatore.greenThresholdConditions)
  ) {
    return valuesStatuses.GREEN;
  } else if (
    evaluateThresholdConditions(valore, indicatore.yellowThresholdConditions)
  ) {
    return valuesStatuses.YELLOW;
  } else if (
    evaluateThresholdConditions(valore, indicatore.redThresholdConditions)
  ) {
    return valuesStatuses.RED;
  } else {
    return indicatore.defaultThreshold;
  }
};

export const getValueLabel = (value, valuesType) => {
  switch (valuesType) {
    case valuesTypes.NUMBER:
      return `${value}`;
    case valuesTypes.BOOLEAN:
      if (value === VALUES_BOOLEAN.TRUE) {
        return booleanValuesSelect["1"];
      } else {
        return booleanValuesSelect["0"];
      }
    case valuesTypes.STRING:
      switch (value) {
        case VALUES_STRING.MIGLIORABILE:
          return stringValuesSelect["1"];
        case VALUES_STRING.POSITIVA:
          return stringValuesSelect["2"];
        case VALUES_STRING.NEGATIVA:
          return stringValuesSelect["0"];
        default:
          return "";
      }
    default:
      return "";
  }
};

const evaluateThresholdConditions = (valore, conditions) => {
  for (const condition of conditions) {
    if (
      condition.thresholdOperator === thresholdOperatorsTypes.CLOSE_INTERVAL
    ) {
      return evaluateCloseIntervalThreshold(valore, condition);
    }
    if (
      condition.thresholdOperator ===
      thresholdOperatorsTypes.LEFT_CLOSE_INTERVAL
    ) {
      return evaluateLeftCloseIntervalThreshold(valore, condition);
    }
    if (
      condition.thresholdOperator ===
      thresholdOperatorsTypes.RIGHT_CLOSE_INTERVAL
    ) {
      return evaluateRightCloseIntervalThreshold(valore, condition);
    }
    if (condition.thresholdOperator === thresholdOperatorsTypes.OPEN_INTERVAL) {
      return evaluateOpenIntervalThreshold(valore, condition);
    }
    if (
      evaluateThreshold(
        condition.thresholdOperator,
        condition.arg1,
        valore.value
      )
    ) {
      return true;
    }
  }
  return false;
};

const evaluateCloseIntervalThreshold = (valore, condition) => {
  return valore.value >= condition.arg1 && valore.value <= condition.arg2;
};

const evaluateLeftCloseIntervalThreshold = (valore, condition) => {
  return valore.value >= condition.arg1 && valore.value < condition.arg2;
};

const evaluateRightCloseIntervalThreshold = (valore, condition) => {
  return valore.value > condition.arg1 && valore.value <= condition.arg2;
};

const evaluateOpenIntervalThreshold = (valore, condition) => {
  return valore.value > condition.arg1 && valore.value < condition.arg2;
};

const evaluateThreshold = (operator, threshold, value) => {
  switch (operator) {
    case thresholdOperatorsTypes.LESS_THAN_OR_EQUAL_TO:
      return value <= threshold;
    case thresholdOperatorsTypes.GREATER_THAN_OR_EQUAL_TO:
      return value >= threshold;
    case thresholdOperatorsTypes.GREATER_THAN:
      return value > threshold;
    case thresholdOperatorsTypes.LESS_THAN:
      return value < threshold;
    case thresholdOperatorsTypes.EQUAL:
      return value === threshold;
    case thresholdOperatorsTypes.NOT_EQUAL:
      return value !== threshold;
    default:
      return false;
  }
};

export class ValoreIndicatore {
  constructor() {
    this.id = undefined;
    this.tipoIndicatore = {
      id: "",
    };
    this.allevamento = {
      id: "",
    };
    let date = new Date(Date.now());
    this.period =
      date.getFullYear() + "-01-01"
    this.value = 0;
  }
}

export const valoreIndicatoreSchema = Yup.object({
  tipoIndicatore: Yup.object({
    id: Yup.string().required("Campo obbligatorio"),
  }),
  allevamento: Yup.object({
    id: Yup.string().required("Campo obbligatorio"),
  }),
  period: Yup.string().required("Campo obbligatorio"),
  value: Yup.number()
    .typeError("Inserire un numero")
    .required("Campo obbligatorio"),
});

export const stagedValoreIndicatoreSchema = Yup.object({
  period: Yup.string().required("Campo obbligatorio"),
  value: Yup.number()
    .typeError("Inserire un numero")
    .required("Campo obbligatorio"),
});

export class AverageSearchForm {
  constructor() {
    this.tipoId = "";
    this.box = "";
    this.fromPeriod = "";
    this.toPeriod = "";
    this.provinciaId = "";
    this.comuneId = "";
  }
}

export const averageSearchFormSchema = Yup.object(
  {
    fromDay: Yup.date()
      .nullable()
      .when("toDay", (toDay, schema) => {
        if (toDay) {
          return schema.max(toDay, "Inserire una data compatibile");
        } else {
          return schema;
        }
      }),
  },
  [["toDay", "fromDay"]]
);

export const valorePeriodSortCompare = (rawValoreIndex) => {
  return (order) => {
    return (a, b) => {
      if (order === "asc") {
        return (
          parseISO(a.rowData[rawValoreIndex].period) - parseISO(b.rowData[rawValoreIndex].period)
        );
      } else {
        return (
          parseISO(b.rowData[rawValoreIndex].period) - parseISO(a.rowData[rawValoreIndex].period)
        );
      }
    };
  };
}

export const valoreValueSortCompare = (rawValoreIndex) => {
  return (order) => {
    return (a, b) => {
      if (order === "asc") {
        return (
          parseFloat(a.rowData[rawValoreIndex].value) - parseFloat(b.rowData[rawValoreIndex].value)
        );
      } else {
        return (
          parseFloat(b.rowData[rawValoreIndex].value) - parseFloat(a.rowData[rawValoreIndex].value)
        );
      }
    };
  };
}

export const valoreStateSortCompare = (order) => {
  return (a, b) => {
    let res;
    switch (a.data){
      case valuesStatuses.GREEN:
        res = 1;
        break;
      case valuesStatuses.YELLOW:
        if (b.data === valuesStatuses.GREEN){
          res = -1;
        } else {
          res = 1;
        }
        break;
      case valuesStatuses.RED:
        res = -1;
        break;
      default:
        res = 0;
    }
    if (order === "asc") {
      return res;
    } else {
      return -res;
    }
  };
};