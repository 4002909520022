import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchAllAlerts } from "../redux/aziende/backendActions";
import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { aziendaPaths } from "../constants/aziendaConstants";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
  },
  mainCell: {
    display: "flex",
    flexDirection: "row",
    border: "none"
  },
  red: {
    color: "red",
  },
  row: {
    borderBottom: "1px solid #C4C4C4",
    borderTop: "1px solid #C4C4C4",
    cursor: "pointer",
  },
  dettagliText: {
    boxSizing: "borderBox",
    color: "white",
    backgroundColor: "red",
    marginLeft: "10px",
    paddingLeft: "8px",
    paddingRight: "8px",
    fontWeight: "bold",
  },
  iconCell: {
    maxWidth: "15px",
  },
}));

function AvvisiSection(props) {
  const history = useHistory();
  const classes = useStyles();
  const fetchAllAlerts = props.fetchAllAlerts;
  useEffect(() => {
    fetchAllAlerts();
  }, [fetchAllAlerts]);
  return (
    <div className={`${classes.container} ${props.className}`}>
      <h6>Avvisi</h6>
      <Table size="small">
        <TableBody>
          {Object.keys(props.aziende.alertsByIDs).length === 0 && (
            <TableRow className={classes.row}>
              <TableCell>Nessun avviso presente</TableCell>
            </TableRow>
          )}
          {Object.keys(props.aziende.alertsByIDs).map((id) => (
            <TableRow
              className={classes.row}
              key={id}
              onClick={() => {
                history.push(aziendaPaths.alert + "/" + id);
              }}
            >
              <TableCell align={"left"} className={classes.iconCell}>
                <WarningIcon className={classes.red} />
              </TableCell>
              <TableCell align={"left"} className={classes.mainCell}>
                {
                  !props.aziende.alertsByIDs[id].alertShowed ? (
                    <b>
                      {props.aziende.alertsByIDs[id].tipoIndicatoreCriticalInfo
                      .alertMessage}
                    </b>
                  ) : (
                    <>
                    {props.aziende.alertsByIDs[id].tipoIndicatoreCriticalInfo
                        .alertMessage}
                        </>
                  )
                }
                <div>

                </div>
                <div className={classes.dettagliText}>Dettagli</div>
              </TableCell>
              <TableCell align={"right"}>
                {new Date(
                  props.aziende.alertsByIDs[id].modifiedOn
                ).toLocaleString()}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    aziende: state.aziende,
  };
};

const mapDispatchToProps = {
  fetchAllAlerts,
};

export default connect(mapStateToProps, mapDispatchToProps)(AvvisiSection);
