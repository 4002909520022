import { Redirect, Route, Switch } from "react-router-dom";
import React from "react";
import { enteControlloPaths } from "../constants/enteControlloConstants";
import VisualizzaAnagraficaAziendePage from "../Common/Pages/VisualizzaAnagraficaAziendePage";
import AnagraficaIndicatoriPage from '../Common/Pages/AnagraficaIndicatoriPage'
import ValoriAggregatiPage from '../Common/Pages/ValoriAggregatiPage'
import ImpostazioniUtentePage
  from '../Common/Pages/ImpostazioniUtentePage'

export function EnteControlloDashboard(props) {
  return (
    <Switch>
      <Route
        exact
        path={enteControlloPaths.anagraficaAziende}
        render={() => {
          return <VisualizzaAnagraficaAziendePage setTitle={props.setTitle} />;
        }}
      />
      <Route
        exact
        path={enteControlloPaths.anagraficaIndicatori}
        render={() => {
          return <AnagraficaIndicatoriPage setTitle={props.setTitle} />;
        }}
      />
      <Route
        exact
        path={`${enteControlloPaths.valoriAggregati}`}
        render={() => {
          return <ValoriAggregatiPage setTitle={props.setTitle} createMode />;
        }}
      />
      <Route
        exact
        path={`${enteControlloPaths.impostazioniUtente}`}
        render={() => {
          return <ImpostazioniUtentePage setTitle={props.setTitle} />;
        }}
      />
      <Redirect to={enteControlloPaths.anagraficaAziende} />
    </Switch>
  );
}
