import React, { useEffect, useState } from "react";
import TableCell from "@material-ui/core/TableCell";
import { IconButton, TableRow } from "@material-ui/core";
import { Form, Formik } from "formik";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { ResetForm } from "./GenericFormComponents/ResetForm";
import CheckIcon from "@material-ui/icons/Check";
import CancelIcon from "@material-ui/icons/Cancel";
import PeriodSelectorField from "../EnteFornitore/Valori/PeriodSelectorField";
import ValueField from "../EnteFornitore/Valori/ValueField";
import { StatusValoreVisualizer } from "./StatusValoreVisualizer";
import { valoreIndicatoreSchema } from "../constants/valoriIndicatoriConstants";
import { ShowField } from './ShowField'
import { AllevamentoField } from './AllevamentoField'

const dataInit = () => {
  return {
    id: "",
    allevamento: {
      id: "",
      codiceAziendale: "",
      identificatoreFiscale: "",
      codiceSpecie: "",
      azienda: {
        id: "",
        denominazione: ""
      }
    },
    tipoIndicatore: { id: "" },
    period: "",
    value: "",
  };
};

export function VisualizzaValoriIndicatoriEditableRow(props) {
  const [data, setData] = useState(dataInit());
  const [editMode, setEditMode] = useState(false);
  useEffect(() => {
    let newData = dataInit();
    newData.period = props.valoreRaw.period;
    newData.allevamento.id = props.valoreRaw.allevamento.id;
    newData.tipoIndicatore.id = props.valoreRaw.tipoIndicatore.id;
    newData.id = props.valoreRaw.id;
    newData.value = props.valoreRaw.value;
    setData(newData);
  }, [props.rowData, props.valoreRaw]);
  return (
    <Formik
      key={props.rowData[0]}
      initialValues={data}
      onSubmit={(values) => {
        props.patchValore(values.id, values);
        setEditMode(false);
      }}
      validationSchema={valoreIndicatoreSchema}
      enableReinitialize
    >
      <TableRow key={props.rowData[0]}>
        {editMode ? <Form id={"form-" + props.rowData[0]} /> : null}
        <TableCell>
          <ResetForm showedValues={data} />
          {props.rowData[1]}
        </TableCell>
        <TableCell>{props.rowData[2]}</TableCell>
        <TableCell>
          {editMode ? (
            <ShowField name={"allevamento.azienda.denominazione"}/>
          ) : (
            props.rowData[3]
          )}
        </TableCell>
        {
          editMode ? (
            <TableCell colSpan={3}>
              <AllevamentoField
                className={props.inputClassName}
                name={"allevamento.id"}
                id="allevamento"
                allevamentoOptions={props.allevamentoOptions}
                form={"form-" + props.rowData[0]}
                denominazioneAziendaField={"allevamento.azienda.denominazione"}
              />
          </TableCell>
          ) : (
          <>
          <TableCell>{props.rowData[4]}</TableCell>
          <TableCell>{props.rowData[5]}</TableCell>
          <TableCell>{props.rowData[6]}</TableCell>
          </>
          )
        }
        <TableCell>{props.rowData[7]}</TableCell>
        <TableCell>{props.rowData[8]}</TableCell>
        <TableCell>
          {editMode ? (
            <PeriodSelectorField
              className={props.inputClassName}
              name={"period"}
              indicatoreId={props.valoreRaw.tipoIndicatore.id}
              form={"form-" + props.rowData[0]}
            />
          ) : (
            props.rowData[9]
          )}
        </TableCell>
        <TableCell>
          {editMode ? (
            <ValueField
              className={props.inputClassName}
              name={"value"}
              indicatoreId={props.valoreRaw.tipoIndicatore.id}
              form={"form-" + props.rowData[0]}
            />
          ) : (
            props.rowData[10]
          )}
        </TableCell>
        <TableCell>
          <StatusValoreVisualizer status={props.rowData[11]} />
        </TableCell>
        <TableCell>
          {!editMode ? (
            <>
              <IconButton
                key={"edit-" + props.valoreRaw.id}
                type={"button"}
                onClick={() => setEditMode(true)}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                key={"delete-" + props.valoreRaw.id}
                type={"button"}
                onClick={() => props.onDelete(props.valoreRaw.id)}
              >
                <DeleteIcon />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton
                key={"save-" + props.valoreRaw.id}
                type={"submit"}
                form={"form-" + props.rowData[0]}
              >
                <CheckIcon />
              </IconButton>
              <IconButton
                key={"cancel-" + props.valoreRaw.id}
                type={"button"}
                onClick={() => {
                  setEditMode(false);
                  props.onReset();
                }}
              >
                <CancelIcon />
              </IconButton>
            </>
          )}
        </TableCell>
      </TableRow>
    </Formik>
  );
}
