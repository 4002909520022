import * as Yup from "yup";

export const aziendaPaths = Object.freeze({
  boxIndicatori: {
    base: "/box",
  },
  indicatori: "/indicatori",
  anagraficaIndicatori: "/anagrafica-indicatori",
  anagraficaAzienda: {
    base: "/azienda",
    allevamenti: "/azienda/allevamenti",
    creaAllevamenti: "/azienda/crea-allevamento",
    stalle: "/azienda/stalle",
    creaStalla: "/azienda/crea-stalla",
  },
  impostazioniUtente: "/impostazioni",
  alert: "/alert",
});

export const aziendaExternalLinks = Object.freeze({
  siAlleva: "http://www.sialleva.it",
  costoMalattie:
    "https://www.umbriasmartfarming.it/wp-content/uploads/2021/12/Costomalattie_v3.0_ITA-1.xlsm",
  sensoriUniPG: "http://lssf.diei.unipg.it/grafana/invite/1fD20nQvSEaR8oJHLuGe1rrKLYCOr4"
});

export const aziendaSidebarData = Object.freeze([
  {
    sidebarListLink: aziendaPaths.boxIndicatori.base,
    sidebarListText: "Vista generale box",
  },
  {
    sidebarListLink: "",
    sidebarListText: "Box indicatori",
    id: "1",
    children: [],
  },
  {
    sidebarListLink: aziendaPaths.indicatori,
    sidebarListText: "Visualizza gli indicatori dell'azienda",
  },
  {
    sidebarListLink: aziendaPaths.anagraficaIndicatori,
    sidebarListText: "Legenda indicatori",
  },
  {
    sidebarListLink: "",
    sidebarListText: "Anagrafica azienda",
    id: "2",
    children: [
      {
        sidebarListLink: aziendaPaths.anagraficaAzienda.base,
        sidebarListText: "Dati generali",
      },
      {
        sidebarListLink: aziendaPaths.anagraficaAzienda.allevamenti,
        sidebarListText: "Lista allevamenti",
      },
      {
        sidebarListLink: aziendaPaths.anagraficaAzienda.stalle,
        sidebarListText: "Lista stalle",
      },
    ],
  },
  {
    sidebarListLink: aziendaPaths.impostazioniUtente,
    sidebarListText: "Impostazioni utente",
  },
  {
    sidebarListLink: "",
    sidebarListText: "Link esterni",
    id: "3",
    children: [
      {
        sidebarListLink: aziendaExternalLinks.siAlleva,
        sidebarListText: "SiAlleva",
        type: "external",
      },
    ],
  },
]);

export class Azienda {
  constructor() {
    this.id = undefined;
    this.denominazione = "";
    this.partitaIva = "";
    this.codiceFiscale = "";
    this.telefonoAzienda = "";
    this.emailAzienda = "";
    this.comune = {
      id: "",
      name: "",
      provincia: {
        id: "",
        name: "",
      },
    };
    this.indirizzo = "";
    this.cap = "";
    this.nomeReferente = "";
    this.cognomeReferente = "";
    this.telefonoReferente = "";
    this.emailReferente = "";
  }
}

export const aziendaSchema = Yup.object().shape({
  denominazione: Yup.string()
    .strict(true)
    .trim("Non sono ammessi spazi")
    .required("Campo obbligatorio"),
  partitaIva: Yup.string()
    .strict(true)
    .trim("Non sono ammessi spazi")
    .matches(/^[0-9]*$/, "Solo numeri sono ammessi")
    .matches(/^.{11}$/, "La partita iva deve essere di 11 caratteri")
    .required("Campo obbligatorio"),
  codiceFiscale: Yup.string()
    .strict(true)
    .trim("Non sono ammessi spazi")
    .matches(/^[a-zA-Z0-9]*$/, "Solo lettere o numeri sono ammessi")
    .matches(
      /^(?:.{11}|.{16})$/,
      "Il codice fiscale deve essere di 11 o 16 caratteri"
    )
    .required("Campo obbligatorio"),
  telefonoAzienda: Yup.number()
    .positive("Inserire un numero telefonico valido (solo numeri ammessi)")
    .typeError("Inserire un numero telefonico valido (solo numeri ammessi)")
    .required("Campo obbligatorio"),
  emailAzienda: Yup.string()
    .strict(true)
    .trim("Non sono ammessi spazi")
    .email("Inserire una email valida")
    .required("Campo obbligatorio"),
  comune: Yup.object({
    id: Yup.string().required("Campo obbligatorio"),
    provincia: Yup.object({
      id: Yup.string().required("Campo obbligatorio"),
    }),
  }),
  indirizzo: Yup.string()
    .strict(true)
    .trim("Non sono ammessi spazi")
    .required("Campo obbligatorio"),
  cap: Yup.number()
    .positive("Inserire un CAP valido (solo numeri ammessi)")
    .typeError("Inserire un CAP valido (solo numeri ammessi)")
    .required("Campo obbligatorio"),
  nomeReferente: Yup.string()
    .strict(true)
    .trim("Non sono ammessi spazi")
    .required("Campo obbligatorio"),
  cognomeReferente: Yup.string()
    .strict(true)
    .trim("Non sono ammessi spazi")
    .required("Campo obbligatorio"),
  telefonoReferente: Yup.number()
    .positive("Inserire un numero telefonico valido (solo numeri ammessi)")
    .typeError("Inserire un numero telefonico valido (solo numeri ammessi)")
    .required("Campo obbligatorio"),
  emailReferente: Yup.string()
    .strict(true)
    .trim("Non sono ammessi spazi")
    .email("Inserire una email valida")
    .required("Campo obbligatorio"),
});

export class Stalla {
  constructor() {
    this.id = undefined;
    this.codiceStalla = "";
    this.codiceAzienda = "";
    this.identificativoProprietario = "";
    this.codiceSpecieAllevata = "";
    this.comune = {
      id: "",
      name: "",
      provincia: {
        id: "",
        name: "",
      },
    };
    this.indirizzo = "";
    this.dimensioni = 0;
    this.numeroCapi = 0;
    this.azienda = {
      id: undefined,
    };
    this.attrezzatureStalla = [];
  }
}

export class Allevamento {
  constructor() {
    this.id = undefined;
    this.identificatoreFiscale = "";
    this.codiceAziendale = "";
    this.codiceSpecie = "";
    this.note = "";
    this.azienda = {
      id: undefined,
    };
  }
}

export const AllevamentoScheme = Yup.object({
  identificatoreFiscale: Yup.string()
    .strict(true)
    .trim("Non sono ammessi spazi")
    .required("Campo obbligatorio"),
  codiceAziendale: Yup.string()
    .strict(true)
    .trim("Non sono ammessi spazi")
    .required("Campo obbligatorio"),
  codiceSpecie: Yup.string()
    .strict(true)
    .trim("Non sono ammessi spazi")
    .required("Campo obbligatorio"),
  note: Yup.string().strict(true).trim("Non sono ammessi spazi"),
});

export const StallaScheme = Yup.object({
  codiceStalla: Yup.string()
    .strict(true)
    .trim("Non sono ammessi spazi")
    .required("Campo obbligatorio"),
  codiceAzienda: Yup.string()
    .strict(true)
    .trim("Non sono ammessi spazi")
    .required("Campo obbligatorio"),
  identificativoProprietario: Yup.string()
    .strict(true)
    .trim("Non sono ammessi spazi")
    .required("Campo obbligatorio"),
  codiceSpecieAllevata: Yup.string()
    .strict(true)
    .trim("Non sono ammessi spazi")
    .required("Campo obbligatorio"),
  comune: Yup.object({
    id: Yup.string().required("Campo obbligatorio"),
    provincia: Yup.object({
      id: Yup.string().required("Campo obbligatorio"),
    }),
  }),
  indirizzo: Yup.string()
    .strict(true)
    .trim("Non sono ammessi spazi")
    .required("Campo obbligatorio"),
  dimensioni: Yup.number()
    .positive("Inserire un numero valido (solo numeri positivi ammessi")
    .typeError("Inserire un numero valido")
    .required("Campo obbligatorio"),
  numeroCapi: Yup.number()
    .positive("Inserire un numero valido (solo numeri positivi ammessi")
    .typeError("Inserire un numero valido")
    .required("Campo obbligatorio"),
});

export class AttrezzaturaStalla {
  constructor() {
    this.id = undefined;
    this.nomeAttrezzatura = "";
    this.stalla = {
      id: undefined,
    };
  }
}

export const AttrezzaturaStallaScheme = Yup.object({
  nomeAttrezzatura: Yup.string()
    .strict(true)
    .trim("Non sono ammessi spazi")
    .required("Campo obbligatorio"),
});

export const ProtocolDialogScheme = Yup.object({
  protocolExecuted: Yup.bool().required("Campo obbligatorio"),
});

export const CodiceSpecieAllevata = Object.freeze({
  BOVINI: "BOVINI",
  OVINI: "OVINI",
  SUINI: "SUINI",
});

export const allevamentoString = (allevamento) => {
  return (
    allevamento.codiceAziendale +
    " - " +
    allevamento.identificatoreFiscale +
    " - " +
    allevamento.codiceSpecie
  );
};

export const allevamentoStringSelector = (allevamento) => {
  return allevamento.note
    ? allevamento.codiceAziendale +
        " - " +
        allevamento.codiceSpecie +
        " - " +
        allevamento.note
    : allevamento.codiceAziendale + " - " + allevamento.codiceSpecie;
};

export const AlertFieldsSchema = Yup.object({
  alertEmailEnabled: Yup.bool().required("Campo obbligatorio"),
  alertEmail: Yup.string()
    .strict(true)
    .trim("Non sono ammessi spazi")
    .email("Inserire una email valida")
    .when("alertEmailEnabled", (alertEmailEnabled, schema) => {
      if (alertEmailEnabled) {
        return schema.required("Campo obbligatorio");
      } else {
        return schema;
      }
    }),
});
