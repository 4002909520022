import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Form, Formik } from 'formik'
import {
  ChangeUsernameFormSchema,
} from '../../constants/authConstants'
import { ResetForm } from '../../Common/GenericFormComponents/ResetForm'
import { Grid, Typography } from '@material-ui/core'
import { SimpleInputField } from '../../Common/GenericFormComponents/SimpleInputField'
import { SubmitButton } from '../../Common/GenericFormComponents/SubmitButton'
import { toastTypes } from '../../constants/generalConstants'
import { ScrollToError } from '../../Common/GenericFormComponents/ScrollToError'
import {
  changeUsername,
  fetchCurrentUser,
} from '../../redux/auth/backendActions'
import { displayToast } from '../../redux/general/backendActions'

const useStyles = makeStyles(() => ({
  element: {
    marginBottom: "35px",
  },
  input: {
    boxSizing: "border-box",
    height: "2em",
    width: "150px",
  },
}));

function ChangeUsernameSection(props) {
  const classes = useStyles();
  const [scrollToError, setScrollToError] = useState(false);
  const [showedValues, setShowedValues] = useState({
    username: "",
    confirmUsername: ""
  });
  const [currentUsername, setCurrentUsername] = useState("");
  const fetchCurrentUser = props.fetchCurrentUser;
  useEffect(() => {
    if (props.loginTransientState.user){
      setCurrentUsername(props.loginTransientState.user.username);
    }
  }, [props.loginTransientState.user])
  useEffect(() => {
    fetchCurrentUser();
  }, [fetchCurrentUser]);
  return (
    <div className={props.className}>
      <Formik
        initialValues={showedValues}
        onSubmit={(values, formikHelpers) => {
          props.changeUsername(values.username);
          setShowedValues({
            username: "",
            confirmUsername: ""
          });
          formikHelpers.setSubmitting(false);
        }}
        validationSchema={ChangeUsernameFormSchema}
        enableReinitialize
      >
        <Form>
          <ResetForm showedValues={showedValues} />
          <h6> Email utenza </h6>
          <Typography className={classes.element}>Questo indirizzo email è utilizzato per l’accesso al sistema e tutte le eventuali comunicazioni.</Typography>
          <Grid
            container
            alignItems="center"
            justify="center"
          >
            <Grid className={classes.element} item container md={3} xs={6}>
              Email utenza attuale
            </Grid>
            <Grid className={classes.element} item container md={3} xs={6}>
              <input type={"text"} value={currentUsername} className={classes.input} disabled/>
            </Grid>
            <Grid className={classes.element} item container md={6} xs={12} />
            <Grid className={classes.element} item container md={3} xs={6}>
              Nuova email utenza
            </Grid>
            <Grid className={classes.element} item container md={3} xs={6}>
              <SimpleInputField name="username" type="text" className={classes.input}/>
            </Grid>
            <Grid className={classes.element} item container md={3} xs={6}>
              Conferma nuova email utenza
            </Grid>
            <Grid className={classes.element} item container md={3} xs={6}>
              <SimpleInputField name="confirmUsername" type="text" className={classes.input}/>
            </Grid>
          </Grid>
          <Grid className={`${classes.element}`} item container  justify={"center"} xs={12}>
            <SubmitButton
              onFormError={(error) => {
                props.displayToast(toastTypes.ERROR, error);
                setScrollToError(!scrollToError);
              }}
              label={"Cambia email utenza"}
            />
          </Grid>
          <ScrollToError scroll={scrollToError} />
        </Form>
      </Formik>
    </div>
  );
}


const mapStateToProps = (state) => {
  return {
    loginTransientState: state.loginTransientState
  };
};

const mapDispatchToProps = {
  fetchCurrentUser,
  changeUsername,
  displayToast
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeUsernameSection);
