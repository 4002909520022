import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { SubmitButton } from './GenericFormComponents/SubmitButton'

const useStyles = makeStyles(() => ({
  button: {
    marginLeft: "20px",
    marginRight: "20px",
  },
}));

export function FormActionButtons(props) {
  const classes = useStyles();
  if (props.createMode) {
    return (
      <div>
        <SubmitButton
          key="create"
          className={classes.button}
          variant="contained"
          color="primary"
          type="submit"
          onFormError={props.onFormError}
          label={"Crea"}
        />
        <Button
          key="cancelCreate"
          className={classes.button}
          variant="contained"
          color="primary"
          type="button"
          onClick={() => {
            props.onCancelCreate();
          }}
        >
          Annulla
        </Button>
      </div>
    );
  } else {
    if (props.editMode) {
      return (
        <div>
          <SubmitButton
            key="save"
            className={classes.button}
            variant="contained"
            color="primary"
            type="submit"
            onFormError={props.onFormError}
            label={"Salva"}
          />
          <Button
            key="cancelSave"
            className={classes.button}
            variant="contained"
            color="primary"
            type="button"
            onClick={() => {
              props.onEditModeChange(false);
            }}
          >
            Annulla
          </Button>
        </div>
      );
    } else {
      return (
        <div>
          <Button
            className={classes.button}
            key="edit"
            variant="contained"
            color="primary"
            type="button"
            onClick={() => {
              props.onEditModeChange(true);
            }}
          >
            Modifica
          </Button>
          {
            props.enableDelete && (
              <Button
                className={classes.button}
                key="delete"
                variant="contained"
                color="primary"
                type="button"
                onClick={() => {
                  props.onDelete()
                }}
              >
                Elimina
              </Button>
            )
          }
        </div>
      );
    }
  }
}
