import React, { useCallback, useEffect, useState } from "react";
import {
  createTheme,
  MuiThemeProvider,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useFormikContext } from "formik";
import { textLabels } from "../../constants/miscConstants";
import {
  ThresholdCondition,
  thresholdOperatorsTypesLabels,
} from "../../constants/indicatoriConstants";
import { v4 as uuidv4 } from "uuid";
import { NumericThresholdConditionRowEditable } from "./NumericThresholdConditionRowEditable";
import { NumericThresholdConditionRow } from "./NumericThresholdConditionRow";
import MUIDataTable from "mui-datatables";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";

const getMuiTheme = () =>
  createTheme({
    overrides: {
      MuiToolbar: {
        root: {
          backgroundColor: "#FEFDFD",
        },
      },
      MuiTable: {
        root: {
          backgroundColor: "#FEFDFD",
        },
      },
      MuiTableCell: {
        root: {
          height: "30px",
          padding: "0px 4px 0px 4px"
        },
      },
      MUIDataTableHeadCell: {
        fixedHeader: {
          backgroundColor: "#C4C4C4",
        },
      },
    },
  });

const useStyles = makeStyles(() => ({
  table: {
    marginRight: "10px",
  },
  rowInput: {
    boxSizing: "border-box",
    height: "30px",
    width: "100%",
  },
}));

export function NumericThresholdConditionsField(props) {
  const classes = useStyles(props);
  const formik = useFormikContext();
  const [data, setData] = useState([]);
  const [options, setOptions] = useState({});
  const [columns, setColumns] = useState([]);
  const [thresholdFieldValue, setThresholdFieldValue] = useState([]);
  const setFieldValue = formik.setFieldValue;
  useEffect(() => {
    let newFieldValue = props.name
      .split(".")
      .reduce((o, i) => o[i], formik.values);
    setThresholdFieldValue(newFieldValue);
  }, [props.name, formik.values]);
  const mapFieldToTableData = useCallback((condition) => {
    let tempId;
    if (!condition.id) {
      tempId = uuidv4();
    } else {
      tempId = condition.id;
    }
    return {
      tempId: tempId,
      arg1: condition.arg1,
      arg2: condition.arg2,
      thresholdOperator: condition.thresholdOperator,
      thresholdOperatorFlat:
        thresholdOperatorsTypesLabels[condition.thresholdOperator],
    };
  }, []);
  const mapTableDataToField = useCallback((conditionRow) => {
    return {
      arg1: conditionRow.arg1,
      arg2: conditionRow.arg2,
      thresholdOperator: conditionRow.thresholdOperator,
    };
  }, []);
  useEffect(() => {
    if (props.editMode) {
      const newColumns = [
        {
          name: "tempId",
          options: {
            display: "excluded",
          },
        },
        { name: "arg1", label: "Valori soglia" },
        {
          name: "arg2",
          options: {
            display: "excluded",
          },
        },
        {
          name: "thresholdOperator",
          options: {
            display: "excluded",
          },
        },
        { name: "thresholdOperatorFlat", label: "Condizione soglia" },
        {
          name: "actions",
          label: "Azioni",
        },
      ];
      setColumns(newColumns);
      setOptions({
        elevation: 0,
        selectableRows: "none",
        filter: false,
        print: false,
        download: false,
        search: false,
        viewColumns: false,
        pagination: false,
        textLabels: {
          ...textLabels,
          body: {
            ...textLabels.body,
            noMatch: "Nessuna condizione presente",
          },
        },
        rowsPerPageOptions: [10, 20, 50, 100],
        customRowRender: (row, index) => {
          return (
            <NumericThresholdConditionRowEditable
              inputClassName={classes.rowInput}
              key={row[0]}
              rowData={row}
              onRowChange={(values) => {
                let newData = JSON.parse(JSON.stringify(data));
                newData[index] = values;
                let newField = newData.map(mapTableDataToField);
                setFieldValue(props.name, newField);
              }}
              onDelete={() => {
                let newData = JSON.parse(JSON.stringify(data));
                newData.splice(index, 1);
                let newField = newData.map(mapTableDataToField);
                setFieldValue(props.name, newField);
              }}
              onReset={() => {
                let newData = thresholdFieldValue.map(mapFieldToTableData);
                setData(newData);
              }}
            />
          );
        },
      });
    } else {
      const newColumns = [
        {
          name: "tempId",
          options: {
            display: "excluded",
          },
        },
        { name: "arg1", label: "Valori soglia" },
        {
          name: "arg2",
          options: {
            display: "excluded",
          },
        },
        {
          name: "thresholdOperator",
          options: {
            display: "excluded",
          },
        },
        { name: "thresholdOperatorFlat", label: "Condizione soglia" },
      ];
      setColumns(newColumns);
      setOptions({
        elevation: 0,
        selectableRows: "none",
        filter: false,
        print: false,
        download: false,
        search: false,
        viewColumns: false,
        pagination: false,
        textLabels: {
          ...textLabels,
          body: {
            ...textLabels.body,
            noMatch: "Nessuna condizione presente",
          },
        },
        rowsPerPageOptions: [10, 20, 50, 100],
        customRowRender: (row) => {
          return <NumericThresholdConditionRow key={row[0]} rowData={row} />;
        },
      });
    }
  }, [
    props.editMode,
    setFieldValue,
    props.name,
    data,
    classes,
    mapFieldToTableData,
    mapTableDataToField,
    thresholdFieldValue,
  ]);
  useEffect(() => {
    if (thresholdFieldValue) {
      let newData = JSON.parse(JSON.stringify(thresholdFieldValue));
      let fixedNewData = newData.map(mapFieldToTableData);
      setData(fixedNewData);
    }
  }, [thresholdFieldValue, mapFieldToTableData]);
  return (
    <>
      <Typography>{props.label}</Typography>
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          className={classes.table}
          data={data}
          columns={columns}
          options={options}
        />
      </MuiThemeProvider>
      {props.editMode ? (
        <Button
          className={classes.table}
          variant="contained"
          color="primary"
          type="button"
          onClick={() => {
            let newFieldValue = JSON.parse(JSON.stringify(thresholdFieldValue));
            let newIndDip = new ThresholdCondition();
            newFieldValue.push(newIndDip);
            setFieldValue(props.name, newFieldValue);
          }}
        >
          <AddIcon />
          Aggiungi condizione
        </Button>
      ) : null}
    </>
  );
}
