export const RECEIVE_PROVINCE_DATA = "RECEIVE_PROVINCE_DATA";
export const RECEIVE_COMUNE_DATA = "RECEIVE_COMUNE_DATA";
export const RECEIVE_REGIONI_DATA = "RECEIVE_REGIONI_DATA";
export const SET_MAIN_TABLE_ROWS = "SET_MAIN_TABLE_ROWS";

export function receiveProvinceData(provinceData) {
    return {type: RECEIVE_PROVINCE_DATA, provinceData}
}

export function receiveComuneData(provinceID, comuneData) {
    return {type: RECEIVE_COMUNE_DATA, provinceID, comuneData}
}

export function receiveRegioniData(regioniData) {
    return {type: RECEIVE_REGIONI_DATA, regioniData}
}

export function setMainTableRows(rowNumber){
    return {type: SET_MAIN_TABLE_ROWS, rowNumber}
}

