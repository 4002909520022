import * as Yup from "yup";

export const LoginStateTypes = Object.freeze({
  SUCCESS: "login_successful",
  NON_ACTIVE: "non_active_account",
  GENERIC_ERROR: "generic_error",
});

export const UserRoles = Object.freeze({
  ROLE_ADMIN: "ROLE_ADMIN",
  ROLE_AZIENDA: "ROLE_AZIENDA",
  ROLE_ENTE_FORNITORE: "ROLE_ENTE_FORNITORE",
  ROLE_SERVIZIO_ASSISTENZA: "ROLE_SERVIZIO_ASSISTENZA",
  ROLE_ENTE_CONTROLLO: "ROLE_ENTE_CONTROLLO",
});

export const authPaths = Object.freeze({
  login: "/login",
  logout: "/logout",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",
  backToSite: "https://www.umbriasmartfarming.it",
});

export const authMsgClassInfos = Object.freeze({
  SUCCESS: "alert alert-success",
  ERROR: "alert alert-danger",
});

export const ResetPasswordResult = Object.freeze({
  SUCCESS: "reset_successful",
  GENERIC_ERROR: "generic_error",
});

export const ChangePasswordResult = Object.freeze({
  SUCCESS: "password_changed",
  OLD_PASSWORD_WRONG: "old_password_wrong",
  GENERIC_ERROR: "generic_error",
});

export const ChangePasswordFormSchema = Yup.object({
  oldPassword: Yup.string().required("Campo obbligatorio"),
  newPassword: Yup.string()
    .strict(true)
    .trim("Non sono ammessi spazi")
    .matches(
      /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[0-9])(?=.*[@$!%*#?&]).{8,}$/,
      "Deve essere lunga almeno 8 caratteri, di cui, almeno: una lettera, un numero ed un carattere speciale (@$!%*#?&)"
    )
    .required("Campo obbligatorio"),
  confirmNewPassword: Yup.string()
    .strict(true)
    .trim("Non sono ammessi spazi")
    .oneOf([Yup.ref("newPassword"), null], "Le password non corrispondono")
    .required("Campo obbligatorio"),
});

export const ChangeUsernameFormSchema = Yup.object({
  username: Yup.string()
    .strict(true)
    .trim("Non sono ammessi spazi")
    .email("Inserire una email valida")
    .required("Campo obbligatorio"),
  confirmUsername: Yup.string()
  .strict(true)
  .trim("Non sono ammessi spazi")
  .oneOf([Yup.ref("username"), null], "Le email non corrispondono")
  .required("Campo obbligatorio"),
});
