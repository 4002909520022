import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from 'react-redux'
import { makeStyles } from "@material-ui/core/styles";
import { createTheme, MuiThemeProvider } from "@material-ui/core";
import { textLabels } from "../../constants/miscConstants";
import { fetchAllAzienda } from "../../redux/aziende/backendActions";
import { useLocation, useHistory } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { defaultRowOptions } from '../../constants/generalConstants'
import { setMainTableRows } from '../../redux/general/basicActions'

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: "100%",
    maxHeight: "100%",
  },
  table: {
    marginBottom: "0",
    overflowY: "auto",
    maxHeight: "100%",
  },
}));

function VisualizzaAnagraficaAziendePage(props) {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles(props);
  const setTitle = props.setTitle;
  const [data, setData] = useState([]);
  const [theme, setTheme] = useState({});
  const [options,setOptions] = useState({});
  const dispatch = useDispatch();
  const mainTableRows = useSelector(
    (state) => state.appPreferences.mainWindowTableRows
  );
  const columns = [
    {
      name: "id",
      options: {
        display: "excluded",
      },
    },
    { name: "denominazione", label: "Denominazione" },
    { name: "partitaIva", label: "P. IVA" },
    { name: "emailAzienda", label: "Email azienda" },
    { name: "comuneFlat", label: "Comune" },
    { name: "provinciaFlat", label: "Provincia" },
    { name: "indirizzo", label: "Indirizzo" },
    { name: "nomeReferente", label: "Nome referente" },
    { name: "cognomeReferente", label: "Cognome referente" },
    { name: "emailReferente", label: "Email referente" },
  ];
  useEffect(() => {
    if (props.enableSelectAzienda){
      setOptions({
        elevation: 0,
        selectableRows: "none",
        filter: false,
        print: false,
        download: false,
        search: false,
        viewColumns: false,
        textLabels: textLabels,
        rowsPerPageOptions: defaultRowOptions,
        rowsPerPage: mainTableRows,
        onChangeRowsPerPage: (rows) => dispatch(setMainTableRows(rows)),
        setTableProps: () => {
          return {
            padding: "none",
          };
        },
        onRowClick: (rowData) => {
          history.push(`${props.singleAziendaUrl}/${rowData[0]}`);
        },
      });
    } else {
      setOptions({
        elevation: 0,
        selectableRows: "none",
        filter: false,
        print: false,
        download: false,
        search: false,
        viewColumns: false,
        textLabels: textLabels,
        rowsPerPageOptions: defaultRowOptions,
        rowsPerPage: mainTableRows,
        onChangeRowsPerPage: (rows) => dispatch(setMainTableRows(rows)),
      });
    }
  }, [dispatch, history, mainTableRows, props.enableSelectAzienda, props.singleAziendaUrl])
  useEffect(() => {
    if (props.enableSelectAzienda) {
      setTheme(
        createTheme({
          overrides: {
            MuiToolbar: {
              root: {
                backgroundColor: "#FEFDFD",
              },
            },
            MuiTable: {
              root: {
                backgroundColor: "#FEFDFD",
              },
            },
            MuiTableCell: {
              root: {
                height: "30px",
                padding: "0px 4px 0px 4px"
              },
            },
            MUIDataTableBodyRow: {
              root: {
                cursor: "pointer",
              },
            },
            MUIDataTableHeadCell: {
              fixedHeader: {
                backgroundColor: "#C4C4C4",
              },
            },
          },
        })
      );
    } else {
      setTheme(
        createTheme({
          overrides: {
            MuiToolbar: {
              root: {
                backgroundColor: "#FEFDFD",
              },
            },
            MuiTable: {
              root: {
                backgroundColor: "#FEFDFD",
              },
            },
            MuiTableCell: {
              root: {
                height: "30px",
                padding: "0px 4px 0px 4px"
              },
            },
            MUIDataTableHeadCell: {
              fixedHeader: {
                backgroundColor: "#C4C4C4",
              },
            },
          },
        })
      );
    }
  }, [props.enableSelectAzienda]);
  const fetchAllAzienda = props.fetchAllAzienda;
  useEffect(() => {
    setTitle("Visualizza anagrafica aziende");
  }, [setTitle]);
  useEffect(() => {
    fetchAllAzienda();
  }, [location, fetchAllAzienda]);
  useEffect(() => {
    const newData = Object.keys(props.aziende.aziendeByIDs).map((id) => {
      return {
        id: id,
        denominazione: props.aziende.aziendeByIDs[id].denominazione,
        partitaIva: props.aziende.aziendeByIDs[id].partitaIva,
        emailAzienda: props.aziende.aziendeByIDs[id].emailAzienda,
        comuneFlat: props.aziende.aziendeByIDs[id].comune.name,
        provinciaFlat: props.aziende.aziendeByIDs[id].comune.provincia.name,
        indirizzo: props.aziende.aziendeByIDs[id].indirizzo,
        nomeReferente: props.aziende.aziendeByIDs[id].nomeReferente,
        cognomeReferente: props.aziende.aziendeByIDs[id].cognomeReferente,
        emailReferente: props.aziende.aziendeByIDs[id].emailReferente,
      };
    });
    setData(newData);
  }, [props.aziende]);
  return (
    <div className={classes.root}>
      <div className={classes.table}>
        <h6>Aziende</h6>
        <MuiThemeProvider theme={theme}>
          <MUIDataTable data={data} columns={columns} options={options} />
        </MuiThemeProvider>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    aziende: state.aziende,
  };
};

const mapDispatchToProps = {
  fetchAllAzienda,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VisualizzaAnagraficaAziendePage);
