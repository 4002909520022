import React, { useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import AziendaOverviewSection from "./AziendaOverviewSection";
import BoxListSection from "./BoxListSection";
import { ExternalLinksButtons } from '../ExternalLinksButtons'

const useStyles = makeStyles((theme) => ({
  aziendaOverview: {
    marginBottom: "15px",
  },
  buttonSection: {
    height: "80px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
}));

function BoxOverviewPage(props) {
  const classes = useStyles();
  const setTitle = props.setTitle;
  useEffect(() => {
    setTitle("Vista generale box");
  }, [setTitle]);
  return (
    <div>
      <AziendaOverviewSection className={classes.aziendaOverview} />
      <BoxListSection />
      <ExternalLinksButtons className={classes.buttonSection} />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(BoxOverviewPage);
