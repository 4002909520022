import React, { useEffect, useState } from "react";
import {
  createSingleStalla, deleteSingleStalla,
  editSingleStalla,
  fetchSingleStalla,
} from '../../redux/aziende/backendActions'
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import { Stalla, StallaScheme } from "../../constants/aziendaConstants";
import { Form, Formik } from "formik";
import { ResetForm } from "../GenericFormComponents/ResetForm";
import { Grid, Typography } from '@material-ui/core'
import { SimpleInputField } from "../GenericFormComponents/SimpleInputField";
import { ScrollToError } from "../GenericFormComponents/ScrollToError";
import { toastTypes } from "../../constants/generalConstants";
import { FormActionButtons } from "../FormActionButtons";
import ProvinciaComuneSelectFields from "../GenericFormComponents/ProvinciaComuneSelectFields";
import { AttrezzatureStallaField } from "../AttrezzatureStallaField";
import { fetchProfile } from "../../redux/auth/backendActions";
import { displayToast } from "../../redux/general/backendActions";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: "100%",
    maxHeight: "100%",
  },
  form: {
    marginBottom: "0",
    overflowY: "auto",
    maxHeight: "calc(100% - 100px)",
  },
  alwaysOnTopSection: {
    height: "80px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  input: {
    boxSizing: "border-box",
    height: "2em",
    width: "150px",
  },
  select: {
    minHeight: "2em",
    width: "150px",
  },
  element: {
    marginBottom: "35px",
  },
}));

function SingleStallaPage(props) {
  const location = useLocation();
  const [aziendaId, setAziendaId] = useState(undefined);
  const setTitle = props.setTitle;
  const [editMode, setEditMode] = useState(false);
  const [scrollToError, setScrollToError] = useState(false);
  const [showedStalla, setShowedStalla] = useState(new Stalla());
  const fetchSingleStalla = props.fetchSingleStalla;
  useEffect(() => {
    if (props.createMode) {
      setEditMode(true);
      setTitle("Crea stalla");
    } else {
      setEditMode(false);
      fetchSingleStalla(props.match.params.stallaId);
    }
  }, [location, props.createMode, setTitle, props.match, fetchSingleStalla]);
  const fetchProfile = props.fetchProfile;
  useEffect(() => {
    if (props.aziendaMode) {
      if (!props.loginTransientState.profile) {
        fetchProfile();
      } else {
        setAziendaId(props.loginTransientState.profile.id);
      }
    } else {
      setAziendaId(props.match.params.aziendaId);
    }
  }, [props.match, props.aziendaMode, props.loginTransientState, fetchProfile]);
  useEffect(() => {
    if (!props.createMode) {
      if (props.aziende.stalleByIDs[props.match.params.stallaId]) {
        setShowedStalla(props.aziende.stalleByIDs[props.match.params.stallaId]);
        setTitle(
          `${
            props.aziende.stalleByIDs[props.match.params.stallaId].azienda
              .denominazione
          } - stalla ${
            props.aziende.stalleByIDs[props.match.params.stallaId].codiceStalla
          }`
        );
      } else {
        let stalla = new Stalla();
        setShowedStalla(stalla);
      }
    } else {
      let stalla = new Stalla();
      stalla.azienda.id = aziendaId;
      setShowedStalla(stalla);
    }
  }, [
    location,
    props.aziende,
    props.match,
    props.createMode,
    setTitle,
    aziendaId,
  ]);
  const classes = useStyles();
  return (
    <Formik
      initialValues={showedStalla}
      onSubmit={(values) => {
        if (props.createMode) {
          props.createSingleStalla(values).then(() => {
            if (props.match.params.aziendaId) {
              props.onCreation(props.match.params.aziendaId);
            } else {
              props.onCreation();
            }
          });
        } else {
          props.editSingleStalla(values.id, values).then(() => {
            fetchSingleStalla(props.match.params.stallaId);
            setEditMode(false);
          });
        }
      }}
      validationSchema={StallaScheme}
      enableReinitialize
    >
      <Form className={classes.root}>
        <ResetForm showedValues={showedStalla} />
        <div className={classes.form}>
          <h6>Dati stalla</h6>
          <Grid
            container
            alignItems="center"
            justify="center"
          >
            <Grid className={classes.element} item container md={3} xs={6}>
              <Typography>Codice stalla</Typography>
            </Grid>
            <Grid
              className={classes.element}
              item
              container
              md={3} xs={6}
              justify="center"
            >
              <SimpleInputField
                type="text"
                className={classes.input}
                name="codiceStalla"
                disabled={!props.createMode}
              />
            </Grid>
            <Grid className={classes.element} item container md={3} xs={6}>
              <Typography>Codice azienda</Typography>
            </Grid>
            <Grid
              className={classes.element}
              item
              container
              md={3} xs={6}
              justify="center"
            >
              <SimpleInputField
                type="text"
                className={classes.input}
                name="codiceAzienda"
                disabled={!editMode}
              />
            </Grid>
            <Grid className={classes.element} item container md={3} xs={6}>
              <Typography>Identificativo proprietario</Typography>
            </Grid>
            <Grid
              className={classes.element}
              item
              container
              md={3} xs={6}
              justify="center"
            >
              <SimpleInputField
                type="text"
                className={classes.input}
                name="identificativoProprietario"
                disabled={!editMode}
              />
            </Grid>
            <Grid className={classes.element} item container md={3} xs={6}>
              <Typography>Codice specie allevata</Typography>
            </Grid>
            <Grid
              className={classes.element}
              item
              container
              md={3} xs={6}
              justify="center"
            >
              <SimpleInputField
                type="text"
                className={classes.input}
                name="codiceSpecieAllevata"
                disabled={!editMode}
              />
            </Grid>
            <ProvinciaComuneSelectFields
              gridClassName={classes.element}
              inputClassName={classes.input}
              selectClassName={classes.select}
              comuneIDField={"comune.id"}
              provinciaIDField={"comune.provincia.id"}
              disabled={!editMode}
            />
            <Grid className={classes.element} item container md={3} xs={6}>
              <Typography>Indirizzo</Typography>
            </Grid>
            <Grid
              className={classes.element}
              item
              container
              md={3} xs={6}
              justify="center"
            >
              <SimpleInputField
                type="text"
                className={classes.input}
                name="indirizzo"
                disabled={!editMode}
              />
            </Grid>
            <Grid className={classes.element} item container md={6} />
            <Grid className={classes.element} item container md={3} xs={6}>
              <Typography>Dimensioni (mq)</Typography>
            </Grid>
            <Grid
              className={classes.element}
              item
              container
              md={3} xs={6}
              justify="center"
            >
              <SimpleInputField
                type="text"
                className={classes.input}
                name="dimensioni"
                disabled={!editMode}
              />
            </Grid>
            <Grid className={classes.element} item container md={3} xs={6}>
              <Typography>Numero capi</Typography>
            </Grid>
            <Grid
              className={classes.element}
              item
              container
              md={3} xs={6}
              justify="center"
            >
              <SimpleInputField
                type="text"
                className={classes.input}
                name="numeroCapi"
                disabled={!editMode}
              />
            </Grid>
          </Grid>
          <AttrezzatureStallaField
            name={"attrezzatureStalla"}
            editMode={editMode}
          />
        </div>
        <div className={classes.alwaysOnTopSection}>
          <ScrollToError scroll={scrollToError} />
          <FormActionButtons
            createMode={props.createMode}
            editMode={editMode}
            enableDelete
            onEditModeChange={(newValue) => {
              setEditMode(newValue);
              if (newValue === false) {
                props.fetchSingleStalla(props.match.params.stallaId);
              }
            }}
            onCancelCreate={() => {
              if (props.match.params.aziendaId) {
                props.onCreation(props.match.params.aziendaId);
              } else {
                props.onCreation();
              }
            }}
            onFormError={(error) => {
              props.displayToast(toastTypes.ERROR, error);
              setScrollToError(!scrollToError);
            }}
            onDelete={() => {
              props.deleteSingleStalla(props.match.params.stallaId)
                .then(props.onDelete());
            }}
          />
        </div>
      </Form>
    </Formik>
  );
}

const mapStateToProps = (state) => {
  return {
    aziende: state.aziende,
    loginTransientState: state.loginTransientState,
  };
};

const mapDispatchToProps = {
  fetchSingleStalla,
  editSingleStalla,
  createSingleStalla,
  fetchProfile,
  displayToast,
  deleteSingleStalla
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleStallaPage);
