import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import { textLabels } from "../../constants/miscConstants";
import { UserRoles } from "../../constants/authConstants";
import { useDispatch, useSelector } from "react-redux";
import { createTheme, Fab, MuiThemeProvider } from "@material-ui/core";
import { fetchSingleAzienda } from "../../redux/aziende/backendActions";
import { fetchProfile } from "../../redux/auth/backendActions";
import MUIDataTable from "mui-datatables";
import { defaultRowOptions } from '../../constants/generalConstants'
import { setMainTableRows } from '../../redux/general/basicActions'

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: "100%",
    maxHeight: "100%",
  },
  table: {
    marginBottom: "0",
    overflowY: "auto",
    maxHeight: "100%",
  },
  fab: {
    position: "fixed",
    bottom: "50px",
    right: "8vw",
  },
}));

const getMuiTheme = () =>
  createTheme({
    overrides: {
      MuiToolbar: {
        root: {
          backgroundColor: "#FEFDFD",
        },
      },
      MuiTable: {
        root: {
          backgroundColor: "#FEFDFD",
        },
      },
      MuiTableCell: {
        root: {
          height: "30px",
        },
      },
      MUIDataTableBodyRow: {
        root: {
          cursor: "pointer",
        },
      },
      MUIDataTableHeadCell: {
        fixedHeader: {
          backgroundColor: "#C4C4C4",
        },
      },
    },
  });

export function ListaAllevamentiPage(props) {
  const location = useLocation();
  const classes = useStyles(props);
  const setTitle = props.setTitle;
  const mainTableRows = useSelector(
    (state) => state.appPreferences.mainWindowTableRows
  );
  const [data, setData] = useState([]);
  const loginData = useSelector((state) => state.loginData);
  const loginTransientState = useSelector((state) => state.loginTransientState);
  const aziende = useSelector((state) => state.aziende);
  const dispatch = useDispatch();
  const columns = [
    {
      name: "id",
      options: {
        display: "excluded",
      },
    },
    { name: "identificatoreFiscale", label: "Id. Fiscale" },
    { name: "codiceAziendale", label: "Codice aziendale" },
    { name: "codiceSpecie", label: "Codice specie" },
    { name: "note", label: "Note" },
  ];
  const onRowClick = props.onRowClick;
  const options = {
    elevation: 0,
    selectableRows: "none",
    filter: false,
    print: false,
    download: false,
    search: false,
    viewColumns: false,
    textLabels: {
      ...textLabels,
      body: {
        ...textLabels.body,
        noMatch: "Nessun allevamento presente",
      },
    },
    rowsPerPageOptions: defaultRowOptions,
    rowsPerPage: mainTableRows,
    onChangeRowsPerPage: (rows) => dispatch(setMainTableRows(rows)),
    setTableProps: () => {
      return {
        padding: "none",
      };
    },
    onRowClick: (rowData) => {
      onRowClick(rowData[0]);
    },
  };
  useEffect(() => {
    if (loginData.userType !== UserRoles.ROLE_AZIENDA) {
      if (aziende.aziendeByIDs[props.match.params.id]) {
        setTitle(
          aziende.aziendeByIDs[props.match.params.id].denominazione +
            " - Lista allevamenti"
        );
      }
    } else {
      setTitle("Lista allevamenti");
    }
  }, [setTitle, aziende, loginData, props.match]);
  useEffect(() => {
    if (loginData.userType !== UserRoles.ROLE_AZIENDA) {
      console.log("fetch")
      dispatch(fetchSingleAzienda(props.match.params.id));
    } else {
      dispatch(fetchProfile());
    }
  }, [location, dispatch, loginData, props.match]);
  useEffect(() => {
    if (loginData.userType === UserRoles.ROLE_AZIENDA) {
      if (loginTransientState.profile) {
        dispatch(fetchSingleAzienda(loginTransientState.profile.id));
      }
    }
  }, [loginData, loginTransientState, dispatch]);
  useEffect(() => {
    let id;
    if (loginData.userType !== UserRoles.ROLE_AZIENDA) {
      id = props.match.params.id;
    } else {
      if (loginTransientState.profile) {
        id = loginTransientState.profile.id;
      } else {
        return;
      }
    }
    if (!aziende.aziendeByIDs[id]) {
      return;
    }
    const newData = aziende.aziendeByIDs[id].allevamenti.map((allevamento) => {
      return {
        id: allevamento.id,
        identificatoreFiscale: allevamento.identificatoreFiscale,
        codiceAziendale: allevamento.codiceAziendale,
        codiceSpecie: allevamento.codiceSpecie,
        note: allevamento.note,
      };
    });
    setData(newData);
  }, [props.aziende, loginTransientState, props.match, loginData, aziende]);
  return (
    <div className={classes.root}>
      <div className={classes.table}>
        <h6>Allevamenti</h6>
        <MuiThemeProvider theme={getMuiTheme()}>
          <MUIDataTable data={data} columns={columns} options={options} />
        </MuiThemeProvider>
      </div>
      <Fab
        color="primary"
        variant={"extended"}
        className={classes.fab}
        onClick={props.onCreateAllevamentoClick}
      >
        Crea allevamento
      </Fab>
    </div>
  );
}
