import React, { useCallback, useEffect, useState } from "react";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { TreeItem } from "@material-ui/lab";
import { makeStyles } from '@material-ui/core/styles'
import { allevamentoString } from '../../constants/aziendaConstants'

const useStyles = makeStyles((theme) => ({
  treeView: {
    backgroundColor: "white",
    border: "1px solid black",
    minHeight: "200px",
    textOverflow: "ellipsis"
  },
}));


export function StagingAreaTreeView(props) {
  const classes = useStyles();
  const [selectedNode, setSelectedNode] = useState("");
  const [treeNodes, setTreeNodes] = useState(null);
  useEffect(() => {
    if (!props.stagingArea){
      return
    }
    const allevamenti = {};
    props.stagingArea.valori.forEach((stagedValore) => {
      if (!allevamenti[stagedValore.allevamento.id]) {
        allevamenti[stagedValore.allevamento.id] = {
          label: allevamentoString(stagedValore.allevamento),
          indicatori: {},
        };
      }
      allevamenti[stagedValore.allevamento.id].indicatori[
        stagedValore.tipoIndicatore.id
      ] = {
        code: stagedValore.tipoIndicatore.code,
      };
    });
    const newTreeNodes = Object.keys(allevamenti).map((allevamentoId) => {
      const parentId = "A" + allevamentoId
      return (
        <TreeItem
          key={parentId}
          nodeId={parentId}
          label={allevamenti[allevamentoId].label}>
          {Object.keys(allevamenti[allevamentoId].indicatori).map((id) => {
            const childId = "I" + id + "." + parentId;
            return (
              <TreeItem
                key={childId}
                nodeId={childId}
                label={"Indicatore " + allevamenti[allevamentoId].indicatori[id].code}
              />
            )
          })}
        </TreeItem>
      )
    });
    setTreeNodes(newTreeNodes);
  }, [classes.treeView, props.stagingArea]);
  const onSelectedIndicatoreId = props.onSelectedIndicatoreId;
  const onSelectedAllevamentoId = props.onSelectedAllevamentoId;
  const onNodeSelect = useCallback((event, value) => {
    if (value.charAt(0) === "I"){
      setSelectedNode(value)
      const splitValues = value.split(".");
      onSelectedIndicatoreId(splitValues[0].substring(1));
      onSelectedAllevamentoId(splitValues[1].substring(1));
    }
  }, [
    onSelectedIndicatoreId,
    onSelectedAllevamentoId,
  ]);
  return (
    <div className={props.className}>
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        selected={selectedNode}
        onNodeSelect={onNodeSelect}
      >
        <TreeItem
          nodeId={"root"}
          label={"File selezionato"}
          className={classes.treeView}
        >
          {treeNodes}
        </TreeItem>
      </TreeView>
    </div>
  );
}
