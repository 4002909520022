import React, { useEffect } from 'react'
import { useFormikContext } from 'formik'

export function ResetForm(props) {
  const {resetForm} = useFormikContext();
  useEffect(() => {
    if (props.showedValues) {
      resetForm(props.showedValues);
    }
  }, [props.showedValues, resetForm]);
  return (<></>);
}