import React, { useEffect, useState } from 'react'
import { Formik, Form } from "formik";
import { Typography } from "@material-ui/core";
import { SimpleFileInputField } from "../../Common/GenericFormComponents/SimpleFileInputField";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { ResetForm } from "../../Common/GenericFormComponents/ResetForm";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  pickFileButtonArea: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  pickFileButton: {
    width: "fitContent",
  },
}));

export function FileSelectorSection(props) {
  const [selectedFile, setSelectedFile] = useState({
    file: "",
  });
  const [value, setValue] = useState("");
  const classes = useStyles();
  return (
    <div className={`${classes.container} ${props.className}`}>
      <h6>Seleziona file</h6>
      {props.enableSelectFile ? (
        <Formik
          initialValues={selectedFile}
          onSubmit={(values) => {
            props.onClickLoad(values.file).finally(() => {
              setValue("");
              setSelectedFile({file: ""});
            });
          }}
          enableReinitialize
        >
          {(formik) => (
            <Form>
              <ResetForm showedValues={selectedFile} />
              <SimpleFileInputField
                id={"file"}
                name={"file"}
                accept=".csv"
                value={value}
                onChange={(event) => {
                  setValue(event.target.value);
                }}
              />
              <div className={classes.pickFileButtonArea}>
                <Button
                  className={classes.pickFileButton}
                  color="primary"
                  variant={"contained"}
                  type={"submit"}
                  disabled={!formik.values["file"]}
                >
                  Carica file
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <Typography>{"File selezionato: " + props.filename}</Typography>
      )}
    </div>
  );
}
