import * as Yup from "yup";

export const adminPaths = Object.freeze({
  listaUtenti: "/utenti",
  creaUtente: "/crea-utente",
  box: "/box",
  impostazioniUtente: "/impostazioni",
  azienda: "/azienda",
  stalleSubpath: "/stalle",
  creaStallaSubPath: "/crea-stalla",
  allevamentiSubpath: "/allevamenti",
  creaAllevamentoSubPath: "/crea-allevamenti",
});

export const adminSidebarData = [
  {
    sidebarListLink: adminPaths.listaUtenti,
    sidebarListText: "Lista utenti",
  },
  {
    sidebarListLink: adminPaths.box,
    sidebarListText: "Box indicatori",
  },
  {
    sidebarListLink: adminPaths.impostazioniUtente,
    sidebarListText: "Impostazioni utente",
  },
];

export const UserRolesLabels = Object.freeze({
  ROLE_ADMIN: "Amministratore",
  ROLE_AZIENDA: "Azienda",
  ROLE_ENTE_FORNITORE: "Ente fornitore",
  ROLE_SERVIZIO_ASSISTENZA: "Servizio assistenza",
  ROLE_ENTE_CONTROLLO: "Ente controllo",
});

export class User {
  constructor() {
    this.username = "";
    this.password = "";
    this.userRole = "";
    this.enabled = true;
  }
}

export const UserSchema = Yup.object().shape({
  username: Yup.string()
    .strict(true)
    .trim("Non sono ammessi spazi")
    .email("Inserire una email valida")
    .required("Campo obbligatorio"),
  password: Yup.string()
    .strict(true)
    .trim("Non sono ammessi spazi")
    /*   .matches(
      /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[0-9])(?=.*[@$!%*#?&]).{8,}$/,
      "Deve essere lunga almeno 8 caratteri, di cui, almeno: una lettera, un numero ed un carattere speciale (@$!%*#?&)"
    )*/
    .required("Campo obbligatorio"),
  userRole: Yup.string().strict(true).required("Campo obbligatorio"),
  enabled: Yup.boolean().strict(true).required("Campo obbligatorio"),
});

export const AdminChangePasswordFormSchema = Yup.object({
  newPassword: Yup.string()
    .strict(true)
    .trim("Non sono ammessi spazi")
    .matches(
      /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[0-9])(?=.*[@$!%*#?&]).{8,}$/,
      "Deve essere lunga almeno 8 caratteri, di cui, almeno: una lettera, un numero ed un carattere speciale (@$!%*#?&)"
    )
    .required("Campo obbligatorio"),
  confirmNewPassword: Yup.string()
    .strict(true)
    .trim("Non sono ammessi spazi")
    .oneOf([Yup.ref("newPassword"), null], "Le password non corrispondono")
    .required("Campo obbligatorio"),
});

export class BoxRow {
  constructor() {
    this.id = "";
    this.name = "";
    this.totIndicatori = 0;
    this.enabled = true;
  }
}

export const BoxRowSchema = Yup.object().shape({
  name: Yup.string()
    .strict(true)
    .trim("Non sono ammessi spazi")
    .required("Campo obbligatorio"),
});
