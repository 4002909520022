import React from 'react';
import { Backdrop, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export function WaitBackdrop(props) {
  const isWaiting = useSelector(
    (state) => state.loginTransientState.isWaiting
  );
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open={isWaiting}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}