export const RECEIVE_VALORI_STAGING_AREA = "RECEIVE_VALORI_STAGING_AREA";
export const RECEIVE_STAGED_VALORE = "RECEIVE_STAGED_VALORE";
export const RECEIVE_FILTERED_VALORI = "RECEIVE_FILTERED_VALORI";
export const RECEIVE_SINGLE_VALORE = "RECEIVE_SINGLE_VALORE";
export const RESET_SEARCH_RESULTS = "RESET_SEARCH_RESULTS";
export const RECEIVE_LAST_VALORE_OF_INDICATORE =
  "RECEIVE_LAST_VALORE_OF_INDICATORE";
export const RECEIVE_ALL_LAST_VALORE_OF_INDICATORE =
  "RECEIVE_ALL_LAST_VALORE_OF_INDICATORE";
export const RECEIVE_CSV_ERROR = "RECEIVE_CSV_ERROR";
export const RECEIVE_COMMITTED_VALORI = "RECEIVE_COMMITTED_VALORI";
export const RECEIVE_INDICATORI_AVERAGES = "RECEIVE_INDICATORI_AVERAGES";
export const RESET_LAST_INDICATORI_AVERAGES = "RESET_LAST_INDICATORI_AVERAGES";

export function receiveFilteredValori(valori) {
  return { type: RECEIVE_FILTERED_VALORI, valori };
}

export function receiveSingleValore(valore) {
  return { type: RECEIVE_SINGLE_VALORE, valore };
}

export function resetSearchResults() {
  return { type: RESET_SEARCH_RESULTS };
}

export function receiveLastValoreOfIndicatore(tipoIndicatoreId, valore) {
  return { type: RECEIVE_LAST_VALORE_OF_INDICATORE, tipoIndicatoreId, valore };
}

export function receiveAllLastValoreOfIndicatore(ultimiValori) {
  return { type: RECEIVE_ALL_LAST_VALORE_OF_INDICATORE, ultimiValori };
}

export function receiveValoriStagingArea(stagingArea) {
  return {
    type: RECEIVE_VALORI_STAGING_AREA,
    stagingArea,
  };
}

export function receiveStagedValore(valore) {
  return {
    type: RECEIVE_STAGED_VALORE,
    valore,
  };
}

export function receiveCsvError(error) {
  return {
    type: RECEIVE_CSV_ERROR,
    error,
  };
}

export function receiveCommittedValori(valori) {
  return {
    type: RECEIVE_COMMITTED_VALORI,
    valori,
  };
}

export function receiveIndicatoriAverages(averages) {
  return {
    type: RECEIVE_INDICATORI_AVERAGES,
    averages,
  };
}

export function resetLastIndicatoriAverages(){
  return {
    type: RESET_LAST_INDICATORI_AVERAGES
  }
}
