import React, { useState } from "react";
import { Form, Formik } from "formik";
import { SubmitButton } from "../../Common/GenericFormComponents/SubmitButton";
import { SimpleInputField } from "../../Common/GenericFormComponents/SimpleInputField";
import { useDispatch } from 'react-redux'
import { displayToast } from "../../redux/general/backendActions";
import { ScrollToError } from "../../Common/GenericFormComponents/ScrollToError";
import { toastTypes } from "../../constants/generalConstants";
import { ResetForm } from "../../Common/GenericFormComponents/ResetForm";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { changePasswordAsAdmin } from '../../redux/admin/backendActions'
import { AdminChangePasswordFormSchema } from '../../constants/adminConstants'

const useStyles = makeStyles(() => ({
  element: {
    marginBottom: "35px",
  },
  input: {
    boxSizing: "border-box",
    height: "2em",
    width: "150px",
  },
}));

export function AdminChangePasswordSection(props) {
  const classes = useStyles();
  const [scrollToError, setScrollToError] = useState(false);
  const [showedValues, setShowedValues] = useState({
    newPassword: "",
    confirmNewPassword: "",
  });
  const dispatch = useDispatch();
  return (
    <div className={props.className}>
      <Formik
        initialValues={showedValues}
        onSubmit={(values, formikHelpers) => {
          dispatch(changePasswordAsAdmin(props.userId, values.newPassword));
          setShowedValues({
            newPassword: "",
            confirmNewPassword: "",
          });
          formikHelpers.setSubmitting(false);
        }}
        validationSchema={AdminChangePasswordFormSchema}
        enableReinitialize
      >
        <Form>
          <ResetForm showedValues={showedValues} />
          <h6> Modifica password </h6>
          <Grid
            container
            alignItems="center"
            justify="center"
          >
            <Grid className={classes.element} item container md={3} xs={6}>
              Nuova password
            </Grid>
            <Grid className={classes.element} item container md={3} xs={6}>
              <SimpleInputField name="newPassword" type="password" className={classes.input} />
            </Grid>
            <Grid className={classes.element} item container md={3} xs={6}>
              Conferma nuova password
            </Grid>
            <Grid className={classes.element} item container md={3} xs={6}>
              <SimpleInputField name="confirmNewPassword" type="password" className={classes.input}/>
            </Grid>
          </Grid>
            <Grid className={`${classes.element}`} item container  justify={"center"} xs={12}>
          <SubmitButton
            onFormError={(error) => {
              dispatch(displayToast(toastTypes.ERROR, error));
              setScrollToError(!scrollToError);
            }}
            label={"Cambia password"}
          />
            </Grid>
          <ScrollToError scroll={scrollToError} />
        </Form>
      </Formik>
    </div>
  );
}
