import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { BoxRow, BoxRowSchema } from '../../constants/adminConstants'
import { Formik,Form } from 'formik'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import { ResetForm } from '../../Common/GenericFormComponents/ResetForm'
import { Dialog, Grid, Typography } from '@material-ui/core'
import { SimpleSelectField } from '../../Common/GenericFormComponents/SimpleSelectField'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import { SubmitButton } from '../../Common/GenericFormComponents/SubmitButton'
import { toastTypes } from '../../constants/generalConstants'
import { SimpleInputField } from '../../Common/GenericFormComponents/SimpleInputField'
import { booleanRadioLabels } from '../../constants/miscConstants'
import { useDispatch } from 'react-redux'
import { displayToast } from '../../redux/general/backendActions'

const useStyles = makeStyles(() => ({
  input: {
    boxSizing: "border-box",
    height: "2em",
    width: "150px",
  },
  select: {
    minHeight: "2em",
    width: "150px",
  },
  element: {
    marginBottom: "35px",
  },
}));

export function CreateNewBoxDialog(props) {
  const classes = useStyles();
  const [showedBox, setShowedBox] = useState(new BoxRow());
  const dispatch = useDispatch();
  useEffect(() => {
    setShowedBox(new BoxRow());
  }, [props.open]);
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
    >
      <Formik
        initialValues={showedBox}
        validationSchema={BoxRowSchema}
        onSubmit={(values) => {
          props.createBox(values);
        }}
        enableReinitialize
      >
        {() => (
          <Form>
            <DialogTitle id="form-dialog-title">
              Crea nuovo box
            </DialogTitle>
            <DialogContent>
              <ResetForm showedValues={showedBox} />
              <Grid
                container
                alignItems="center"
                justify="center"
              >
                <Grid className={classes.element} item container xs={6}>
                  <Typography>Nome box</Typography>
                </Grid>
                <Grid
                  className={classes.element}
                  item
                  container
                  xs={6}
                  justify="center"
                >
                  <SimpleInputField name="name" type="text" />
                </Grid>
                <Grid className={classes.element} item container xs={6}>
                  <Typography>Abilitato?</Typography>
                </Grid>
                <Grid
                  className={classes.element}
                  item
                  container
                  xs={6}
                  justify="center"
                >
                  <SimpleSelectField
                    name={"enabled"}
                    options={booleanRadioLabels}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={props.onClose} color="primary">
                Annulla
              </Button>
              <SubmitButton
                onClick={props.onClose}
                variant={"text"}
                color="primary"
                onFormError={(error) => {
                  dispatch(displayToast(toastTypes.ERROR, error));
                }}
                label={"Crea box"}
              />
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}