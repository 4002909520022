export const serviziAssistenzaPaths = Object.freeze({
  anagraficaIndicatori: "/indicatori",
  valori: "/valori",
  anagraficaAziende: "/aziende",
  valoriAggregati: "/aggregati",
  impostazioniUtente: "/impostazioni",
  stalleSubpath: "/stalle",
  creaStallaSubPath: "/crea-stalla",
  allevamentiSubpath: "/allevamenti",
  creaAllevamentoSubPath: "/crea-allevamenti"
});

export const serviziAssistenzaSidebarData = [
  {
    sidebarListLink: serviziAssistenzaPaths.anagraficaIndicatori,
    sidebarListText: "Anagrafica indicatori",
  },
  {
    sidebarListLink: serviziAssistenzaPaths.valori,
    sidebarListText: "Visualizza valori indicatori",
  },
  {
    sidebarListLink: serviziAssistenzaPaths.valoriAggregati,
    sidebarListText: "Visualizza valori aggregati",
  },
  {
    sidebarListLink: serviziAssistenzaPaths.anagraficaAziende,
    sidebarListText: "Visualizza anagrafiche aziende",
  },
  {
    sidebarListLink: serviziAssistenzaPaths.impostazioniUtente,
    sidebarListText: "Impostazioni utente",
  },
];