import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { createTheme, Fab, MuiThemeProvider } from "@material-ui/core";
import { textLabels } from "../../constants/miscConstants";
import MUIDataTable from "mui-datatables";
import AddIcon from "@material-ui/icons/Add";
import ValoriSearchForm from "../ValoriSearchForm";
import {
  patchValore,
  fetchSingleValore,
  deleteSingleValore,
  createValore,
} from "../../redux/valoriindicatori/backendActions";
import {
  getModifiedOnLabel,
  getPeriodLabel,
  getValueLabel,
  getValueState,
  valorePeriodSortCompare,
  valoreStateSortCompare,
  valoreValueSortCompare,
} from "../../constants/valoriIndicatoriConstants";
import { useLocation } from "react-router-dom";
import { resetSearchResults } from "../../redux/valoriindicatori/basicActions";
import { VisualizzaValoriIndicatoriRow } from "../VisualizzaValoriIndicatoriRow";
import { fetchAllAzienda } from "../../redux/aziende/backendActions";
import { fetchAllIndicatore } from "../../redux/indicatori/backendActions";
import { CreateNewValueDialog } from "../CreateNewValueDialog";
import { displayToast } from "../../redux/general/backendActions";
import { VisualizzaValoriIndicatoriEditableRow } from "../VisualizzaValoriIndicatoriEditableRow";
import { allevamentoString } from "../../constants/aziendaConstants";
import { defaultRowOptions } from "../../constants/generalConstants";
import { setMainTableRows } from "../../redux/general/basicActions";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: "100%",
    maxHeight: "100%",
    overflow: "hidden",
  },
  table: {
    marginBottom: "0",
    overflowY: "auto",
    maxHeight: "calc(100% - 100px)",
  },
  fab: {
    position: "fixed",
    bottom: "50px",
    right: "8vw",
  },
  rowInput: {
    boxSizing: "border-box",
    width: "100%",
    minWidth: "30px",
  },
}));

const getMuiTheme = () =>
  createTheme({
    overrides: {
      MuiToolbar: {
        root: {
          backgroundColor: "#FEFDFD",
        },
      },
      MuiTable: {
        root: {
          backgroundColor: "#FEFDFD",
        },
      },
      MuiTableCell: {
        root: {
          height: "30px",
          padding: "0px 4px 0px 4px",
        },
      },
      MUIDataTableHeadCell: {
        fixedHeader: {
          backgroundColor: "#C4C4C4",
        },
      },
    },
  });

function VisualizzaValoriIndicatoriPage(props) {
  const classes = useStyles(props);
  const setTitle = props.setTitle;
  const [data, setData] = useState([]);
  const location = useLocation();
  const resetSearchResults = props.resetSearchResults;
  const fetchAllAzienda = props.fetchAllAzienda;
  const fetchAllIndicatore = props.fetchAllIndicatore;
  const [codeOptions, setCodeOptions] = useState({});
  const [aziendaOptions, setAziendaOptions] = useState({});
  const [allevamentoOptions, setAllevamentoOptions] = useState({});
  const [resubmitFlag, setResubmitFlag] = useState(false);
  const [showNewValueDialog, setShowNewValueDialog] = useState(false);
  const [columns, setColumns] = useState([]);
  const [options, setOptions] = useState({});
  const dispatch = useDispatch();
  const mainTableRows = useSelector(
    (state) => state.appPreferences.mainWindowTableRows
  );
  useEffect(() => {
    fetchAllIndicatore();
    fetchAllAzienda();
  }, [fetchAllIndicatore, fetchAllAzienda]);
  useEffect(() => {
    const newOptions = {};
    Object.values(props.indicatori.indicatoriByIDs).forEach((indicatore) => {
      newOptions[indicatore.id] = indicatore.code + " - " + indicatore.name;
    });
    setCodeOptions(newOptions);
  }, [props.indicatori]);
  useEffect(() => {
    const newOptions = {};
    Object.values(props.aziende.aziendeByIDs).forEach((azienda) => {
      newOptions[azienda.id] = azienda.denominazione;
    });
    setAziendaOptions(newOptions);
  }, [props.aziende]);
  useEffect(() => {
    const newOptions = {};
    Object.values(props.aziende.aziendeByIDs).forEach((azienda) => {
      azienda.allevamenti.forEach((allevamento) => {
        newOptions[allevamento.id] = allevamentoString(allevamento);
      });
    });
    setAllevamentoOptions(newOptions);
  }, [props.aziende]);
  useEffect(() => {
    resetSearchResults();
  }, [location, resetSearchResults]);
  useEffect(() => {
    let newData = props.valori.lastValoriFilterResult.map((id) => {
      let valore = props.valori.valoriByIDs[id];
      let indicatore =
        props.indicatori.indicatoriByIDs[valore.tipoIndicatore.id];
      return {
        id: id,
        codeFlat: indicatore.code,
        nameFlat: indicatore.name,
        companyFlat: props.aziende.aziendeByIDs[valore.allevamento.azienda.id]
          ? props.aziende.aziendeByIDs[valore.allevamento.azienda.id]
              .denominazione
          : "",
        codiceAziendale: valore.allevamento.codiceAziendale,
        identificatoreFiscale: valore.allevamento.identificatoreFiscale,
        codiceSpecie: valore.allevamento.codiceSpecie,
        measureUnit: indicatore.measureUnit,
        modifiedOn: getModifiedOnLabel(valore.modifiedOn),
        period: getPeriodLabel(valore, indicatore),
        value: getValueLabel(valore.value, indicatore.valuesType),
        state: getValueState(valore, indicatore),
        rawValore: valore,
      };
    });
    setData(newData);
  }, [
    props.valori.lastValoriFilterResult,
    props.valori.valoriByIDs,
    props.indicatori.indicatoriByIDs,
    props.aziende.aziendeByIDs,
  ]);
  const fetchSingleValore = props.fetchSingleValore;
  const patchValore = props.patchValore;
  const deleteSingleValore = props.deleteSingleValore;
  useEffect(() => {
    if (props.editMode) {
      const newColumns = [
        {
          name: "id",
          options: {
            display: "excluded",
          },
        },
        { name: "codeFlat", label: "Cod." },
        { name: "nameFlat", label: "Nome" },
        { name: "companyFlat", label: "Azienda" },
        { name: "codiceAziendale", label: "Codice aziendale" },
        { name: "identificatoreFiscale", label: "Identificatore fiscale" },
        { name: "codiceSpecie", label: "Codice specie" },
        { name: "measureUnit", label: "Unità di misura" },
        { name: "modifiedOn", label: "Data inserimento" },
        {
          name: "period",
          label: "Periodo del valore",
          options: {
            sortCompare: valorePeriodSortCompare(13),
          },
        },
        {
          name: "value",
          label: "Valore",
          options: {
            sortCompare: valoreValueSortCompare(13),
          },
        },
        {
          name: "state",
          label: "Stato",
          options: {
            sortCompare: valoreStateSortCompare,
          },
        },
        { name: "actions", label: "Azioni" },
        {
          name: "rawValore",
          options: {
            display: "excluded",
          },
        },
      ];
      const newOptions = {
        elevation: 0,
        selectableRows: "none",
        filter: false,
        print: false,
        download: false,
        search: false,
        viewColumns: false,
        textLabels: textLabels,
        rowsPerPageOptions: defaultRowOptions,
        rowsPerPage: mainTableRows,
        onChangeRowsPerPage: (rows) => dispatch(setMainTableRows(rows)),
        customRowRender: (data) => {
          return (
            <VisualizzaValoriIndicatoriEditableRow
              inputClassName={classes.rowInput}
              key={data[0]}
              rowData={data}
              valoreRaw={props.valori.valoriByIDs[data[0]]}
              codeOptions={codeOptions}
              aziendaOptions={aziendaOptions}
              allevamentoOptions={allevamentoOptions}
              patchValore={(id, valore) => {
                patchValore(id, valore).then(() => fetchSingleValore(id));
              }}
              onReset={() => {
                fetchSingleValore(props.valori.valoriByIDs[data[0]].id);
              }}
              onDelete={(id) => {
                deleteSingleValore(id).then(() => {
                  setResubmitFlag((oldValue) => !oldValue);
                });
              }}
            />
          );
        },
      };
      setOptions(newOptions);
      setColumns(newColumns);
    } else {
      const newColumns = [
        {
          name: "id",
          options: {
            display: "excluded",
          },
        },
        { name: "codeFlat", label: "Cod." },
        { name: "nameFlat", label: "Nome" },
        { name: "companyFlat", label: "Azienda" },
        { name: "codiceAziendale", label: "Codice aziendale" },
        { name: "identificatoreFiscale", label: "Identificatore fiscale" },
        { name: "codiceSpecie", label: "Codice specie" },
        { name: "measureUnit", label: "Unità di misura" },
        { name: "modifiedOn", label: "Data inserimento" },
        {
          name: "period",
          label: "Periodo del valore",
          options: {
            sortCompare: valorePeriodSortCompare(12),
          },
        },
        {
          name: "value",
          label: "Valore",
          options: {
            sortCompare: valoreValueSortCompare(12),
          },
        },
        {
          name: "state",
          label: "Stato",
          options: {
            sortCompare: valoreStateSortCompare,
          },
        },
        {
          name: "rawValore",
          options: {
            display: "excluded",
          },
        },
      ];
      const newOptions = {
        elevation: 0,
        selectableRows: "none",
        filter: false,
        print: false,
        download: false,
        search: false,
        viewColumns: false,
        textLabels: textLabels,
        rowsPerPageOptions: defaultRowOptions,
        rowsPerPage: mainTableRows,
        onChangeRowsPerPage: (rows) => dispatch(setMainTableRows(rows)),
        customRowRender: (data) => {
          return <VisualizzaValoriIndicatoriRow key={data[0]} rowData={data} />;
        },
      };
      setOptions(newOptions);
      setColumns(newColumns);
    }
  }, [
    aziendaOptions,
    allevamentoOptions,
    classes.rowInput,
    codeOptions,
    fetchSingleValore,
    patchValore,
    props.valori,
    props.editMode,
    deleteSingleValore,
    mainTableRows,
    dispatch,
  ]);
  useEffect(() => {
    setTitle("Visualizza valori indicatori");
  }, [setTitle]);
  return (
    <div className={classes.root}>
      <div className={classes.table}>
        <h6>Ricerca</h6>
        <ValoriSearchForm resubmitFlag={resubmitFlag} />
        <h6>Risultati</h6>
        <MuiThemeProvider theme={getMuiTheme()}>
          <MUIDataTable data={data} columns={columns} options={options} />
        </MuiThemeProvider>
      </div>
      {props.editMode ? (
        <Fab
          color="primary"
          variant={"extended"}
          className={classes.fab}
          onClick={() => setShowNewValueDialog(true)}
        >
          <AddIcon />
          Aggiungi valore indicatore
        </Fab>
      ) : null}
      {props.editMode ? (
        <CreateNewValueDialog
          open={showNewValueDialog}
          onClose={() => setShowNewValueDialog(false)}
          displayToast={props.displayToast}
          createValore={(valore) => {
            props.createValore(valore);
            setShowNewValueDialog(false);
          }}
          indicatori={props.indicatori}
          aziende={props.aziende}
        />
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    indicatori: state.indicatori,
    valori: state.valori,
    aziende: state.aziende,
  };
};

const mapDispatchToProps = {
  fetchSingleValore,
  resetSearchResults,
  fetchAllAzienda,
  fetchAllIndicatore,
  patchValore,
  deleteSingleValore,
  displayToast,
  createValore,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VisualizzaValoriIndicatoriPage);
