import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useLocation } from "react-router-dom";
import { textLabels } from "../../constants/miscConstants";
import { createTheme, Fab, MuiThemeProvider } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { fetchSingleAzienda } from "../../redux/aziende/backendActions";
import { connect, useDispatch, useSelector } from 'react-redux'
import { UserRoles } from "../../constants/authConstants";
import { fetchProfile } from "../../redux/auth/backendActions";
import { defaultRowOptions } from '../../constants/generalConstants'
import { setMainTableRows } from '../../redux/general/basicActions'

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: "100%",
    maxHeight: "100%",
  },
  table: {
    marginBottom: "0",
    overflowY: "auto",
    maxHeight: "100%",
  },
  fab: {
    position: "fixed",
    bottom: "50px",
    right: "8vw",
  },
}));

function ListaStallePage(props) {
  const dispatch = useDispatch();
  const mainTableRows = useSelector(
    (state) => state.appPreferences.mainWindowTableRows
  );
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles(props);
  const setTitle = props.setTitle;
  const [data, setData] = useState([]);
  const [theme, setTheme] = useState({});
  const [options, setOptions] = useState({});
  const columns = [
    {
      name: "id",
      options: {
        display: "excluded",
      },
    },
    { name: "codiceStalla", label: "Cod." },
    { name: "codiceAzienda", label: "Codice aziendale" },
    {
      name: "identificativoProprietario",
      label: "Identificativo proprietario",
    },
    { name: "codiceSpecieAllevata", label: "Codice specie" },
    { name: "comuneFlat", label: "Comune ubicazione" },
    { name: "indirizzo", label: "Indirizzo" },
    { name: "numeroCapi", label: "Numero capi" },
  ];
  const onRowClick = props.onRowClick;
  useEffect(() => {
    if (props.enableSelectStalla) {
      setOptions({
        elevation: 0,
        selectableRows: "none",
        filter: false,
        print: false,
        download: false,
        search: false,
        viewColumns: false,
        textLabels: {
          ...textLabels,
          body: {
            ...textLabels.body,
            noMatch: "Nessuna stalla presente",
          },
        },
        rowsPerPageOptions: defaultRowOptions,
        rowsPerPage: mainTableRows,
        onChangeRowsPerPage: (rows) => dispatch(setMainTableRows(rows)),
        setTableProps: () => {
          return {
            padding: "none",
          };
        },
        onRowClick: (rowData) => {
          onRowClick(rowData[0]);
        },
      });
    } else {
      setOptions({
        elevation: 0,
        selectableRows: "none",
        filter: false,
        print: false,
        download: false,
        search: false,
        viewColumns: false,
        textLabels: {
          ...textLabels,
          body: {
            ...textLabels.body,
            noMatch: "Nessuna stalla presente",
          },
        },
        rowsPerPageOptions: defaultRowOptions,
        rowsPerPage: mainTableRows,
        onChangeRowsPerPage: (rows) => dispatch(setMainTableRows(rows)),
        setTableProps: () => {
          return {
            padding: "none",
          };
        },
      });
    }
  }, [history, props.enableSelectStalla, props.match, props.loginData, onRowClick, mainTableRows, dispatch]);
  useEffect(() => {
    if (props.enableSelectStalla) {
      setTheme(
        createTheme({
          overrides: {
            MuiToolbar: {
              root: {
                backgroundColor: "#FEFDFD",
              },
            },
            MuiTable: {
              root: {
                backgroundColor: "#FEFDFD",
              },
            },
            MuiTableCell: {
              root: {
                height: "30px",
              },
            },
            MUIDataTableBodyRow: {
              root: {
                cursor: "pointer",
              },
            },
            MUIDataTableHeadCell: {
              fixedHeader: {
                backgroundColor: "#C4C4C4",
              },
            },
          },
        })
      );
    } else {
      setTheme(
        createTheme({
          overrides: {
            MuiToolbar: {
              root: {
                backgroundColor: "#FEFDFD",
              },
            },
            MuiTable: {
              root: {
                backgroundColor: "#FEFDFD",
              },
            },
            MuiTableCell: {
              root: {
                height: "30px",
              },
            },
            MUIDataTableHeadCell: {
              fixedHeader: {
                backgroundColor: "#C4C4C4",
              },
            },
          },
        })
      );
    }
  }, [props.enableSelectStalla]);
  const fetchSingleAzienda = props.fetchSingleAzienda;
  const fetchProfile = props.fetchProfile;
  useEffect(() => {
    if (props.loginData.userType !== UserRoles.ROLE_AZIENDA) {
      if (props.aziende.aziendeByIDs[props.match.params.id]) {
        setTitle(
          props.aziende.aziendeByIDs[props.match.params.id].denominazione +
            " - Lista stalle"
        );
      }
    } else {
      setTitle("Lista stalle");
    }
  }, [setTitle, props.aziende, props.loginData, props.match]);
  useEffect(() => {
    if (props.loginData.userType !== UserRoles.ROLE_AZIENDA) {
      fetchSingleAzienda(props.match.params.id);
    } else {
      fetchProfile();
    }
  }, [
    location,
    fetchSingleAzienda,
    fetchProfile,
    props.loginData,
    props.match,
  ]);
  useEffect(() => {
    if (props.loginData.userType === UserRoles.ROLE_AZIENDA) {
      if (props.loginTransientState.profile) {
        fetchSingleAzienda(props.loginTransientState.profile.id);
      }
    }
  }, [props.loginData, props.loginTransientState, fetchSingleAzienda]);
  useEffect(() => {
    let id;
    if (props.loginData.userType !== UserRoles.ROLE_AZIENDA) {
      id = props.match.params.id;
    } else {
      if (props.loginTransientState.profile) {
        id = props.loginTransientState.profile.id;
      } else {
        return;
      }
    }
    if (!props.aziende.aziendeByIDs[id]) {
      return;
    }
    const newData = props.aziende.aziendeByIDs[id].stalle.map((stalla) => {
      return {
        id: stalla.id,
        codiceStalla: stalla.codiceStalla,
        codiceAzienda: stalla.codiceAzienda,
        identificativoProprietario: stalla.identificativoProprietario,
        codiceSpecieAllevata: stalla.codiceSpecieAllevata,
        comuneFlat: stalla.comune.name,
        indirizzo: stalla.indirizzo,
        numeroCapi: stalla.numeroCapi,
      };
    });
    setData(newData);
  }, [props.aziende, props.loginTransientState, props.match, props.loginData]);
  return (
    <div className={classes.root}>
      <div className={classes.table}>
        <h6>Stalle</h6>
        <MuiThemeProvider theme={theme}>
          <MUIDataTable data={data} columns={columns} options={options} />
        </MuiThemeProvider>
      </div>
      <Fab
        color="primary"
        variant={"extended"}
        className={classes.fab}
        onClick={props.onCreateStallaClick}
      >
        Crea stalla
      </Fab>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    aziende: state.aziende,
    loginData: state.loginData,
    loginTransientState: state.loginTransientState,
  };
};

const mapDispatchToProps = {
  fetchSingleAzienda,
  fetchProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListaStallePage);
