import React from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { enteFornitorePaths } from "../constants/enteFornitoreConstants";
import VisualizzaValoriIndicatoriPage from "../Common/Pages/VisualizzaValoriIndicatoriPage";
import AnagraficaIndicatoriPage from "./Indicatori/AnagraficaIndicatoriPage";
import VisualizzaAnagraficaAziendePage from "../Common/Pages/VisualizzaAnagraficaAziendePage";
import AnagraficaEnteFornitorePage from "../Common/AnagraficaEnte/AnagraficaEnteFornitorePage";
import SingleIndicatorePage from "./Indicatori/SingleIndicatorePage";
import CaricaValoriIndicatoriPage from "./CaricamentoFile/CaricaValoriIndicatoriPage";
import ValoriAggregatiPage from "../Common/Pages/ValoriAggregatiPage";
import ImpostazioniUtentePage from "../Common/Pages/ImpostazioniUtentePage";

function EnteFornitoreDashboard(props) {
  return (
    <Switch>
      <Route
        exact
        path={enteFornitorePaths.anagraficaIndicatori.base}
        render={() => {
          return <AnagraficaIndicatoriPage setTitle={props.setTitle} />;
        }}
      />
      <Route
        exact
        path={enteFornitorePaths.valori.visualizzaValori}
        render={() => {
          return (
            <VisualizzaValoriIndicatoriPage
              setTitle={props.setTitle}
              editMode
            />
          );
        }}
      />
      <Route
        exact
        path={enteFornitorePaths.anagraficaAziende}
        render={() => {
          return <VisualizzaAnagraficaAziendePage setTitle={props.setTitle} />;
        }}
      />
      <Route
        exact
        path={enteFornitorePaths.anagraficaEnte}
        render={() => {
          return (
            <AnagraficaEnteFornitorePage
              setTitle={props.setTitle}
              enteFornitoreMode
            />
          );
        }}
      />
      <Route
        exact
        path={`${enteFornitorePaths.anagraficaIndicatori.creaIndicatore}`}
        render={() => {
          return <SingleIndicatorePage setTitle={props.setTitle} createMode />;
        }}
      />
      <Route
        exact
        path={`${enteFornitorePaths.valori.caricaValori}`}
        render={() => {
          return <CaricaValoriIndicatoriPage setTitle={props.setTitle} />;
        }}
      />
      <Route
        exact
        path={`${enteFornitorePaths.anagraficaIndicatori.base}/:id`}
        render={(routeProps) => {
          return (
            <SingleIndicatorePage setTitle={props.setTitle} {...routeProps} />
          );
        }}
      />
      <Route
        exact
        path={`${enteFornitorePaths.valoriAggregati}`}
        render={() => {
          return <ValoriAggregatiPage setTitle={props.setTitle} />;
        }}
      />
      <Route
        exact
        path={enteFornitorePaths.impostazioniUtente}
        render={() => {
          return <ImpostazioniUtentePage setTitle={props.setTitle} />;
        }}
      />
      <Redirect to={enteFornitorePaths.anagraficaIndicatori.base} />
    </Switch>
  );
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnteFornitoreDashboard);
