import React from "react";
import Modal from "react-bootstrap/Modal";
import {useHistory} from "react-router-dom";
import {authPaths} from "../../constants/authConstants";
import Button from '@material-ui/core/Button'

export function PasswordResetModalWindow(props) {
    const history = useHistory();
    return (
        <Modal show={props.show} centered backdrop="static">
            <Modal.Header>
                <Modal.Title>Password cambiata con successo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    La nuova password è ora attiva.
                </p>
                <p>
                    Puoi ora effettuare il login con la tua nuova password.
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button color="primary" onClick={() => history.push(authPaths.login)}>
                    Conferma e vai alla pagina di login
                </Button>
            </Modal.Footer>
        </Modal>
    );
}