export const RECEIVE_ALL_USERS = "RECEIVE_ALL_USERS";
export const RECEIVE_SINGLE_USER = "RECEIVE_SINGLE_USER";

export function receiveAllUsers(users) {
  return { type: RECEIVE_ALL_USERS, users };
}

export function receiveSingleUser(user) {
  return { type: RECEIVE_SINGLE_USER, user };
}
