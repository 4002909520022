import React, { useEffect, useState } from 'react'
import { IconButton, TableCell, TableRow } from '@material-ui/core'
import { Form, Formik } from 'formik'
import { ResetForm } from '../../Common/GenericFormComponents/ResetForm'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import CheckIcon from '@material-ui/icons/Check'
import CancelIcon from '@material-ui/icons/Cancel'
import { connect } from 'react-redux'
import { SimpleSelectField } from '../../Common/GenericFormComponents/SimpleSelectField'
import IndicatoreSelectField
  from '../../Common/GenericFormComponents/IndicatoreSelectField'
import {
  IndicatoreDipendenteRowData,
  IndicatoreDipendenteRowDataSchema,
  PriorityRatingOptions,
} from '../../constants/indicatoriConstants'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  input: {
    maxWidth: "80px"
  },
}));


function IndicatoriDipendentiRowEditable(props) {
  const [editMode, setEditMode] = useState(false);
  const [data, setData] = useState(new IndicatoreDipendenteRowData());
  const classes = useStyles();
  useEffect(() => {
    if (!props.rowData[1]){
      setEditMode(true);
    }
  }, [props.rowData]);
  useEffect(() => {
    let indDipData = new IndicatoreDipendenteRowData();
    indDipData.tempId = props.rowData[0];
    indDipData.indicatoreIdFlat = props.rowData[1];
    indDipData.codeFlat = props.rowData[2];
    indDipData.nameFlat = props.rowData[3];
    indDipData.boxFlat = props.rowData[4];
    indDipData.priorityRating = props.rowData[5];
    setData(indDipData);
  }, [props.rowData]);
  return (
    <Formik
      key={props.rowData[0]}
      initialValues={data}
      onSubmit={(values) => {
        props.onRowChange(values);
        setEditMode(false);
      }}
      validationSchema={IndicatoreDipendenteRowDataSchema}
      enableReinitialize
    >
      {
        (subFormik) => (
          <TableRow key={props.rowData[0]}>
            {editMode ? <Form id={"form-" + props.rowData[0]} /> : null}
            <TableCell>
              <ResetForm showedValues={data} />
              {
                editMode ? (
                  <IndicatoreSelectField
                    selectClassName={classes.input}
                    indicatoreIdField={"indicatoreIdFlat"}
                    nameField={"nameFlat"}
                    boxField={"boxFlat"}
                  />
                ) : (
                  props.rowData[2]
                )
              }
            </TableCell>
            <TableCell>
              {
              editMode ? (
                subFormik.values["nameFlat"]
                ) : (
                props.rowData[3]
              )
            }</TableCell>
            <TableCell>
              {
                editMode ? (
                  subFormik.values["boxFlat"]
                ) : (
                  props.rowData[4]
                )
              }
            </TableCell>
            <TableCell>
              {
                editMode ? (
                  <SimpleSelectField
                    selectClassName={classes.input}
                    name={"priorityRating"}
                    options={PriorityRatingOptions}
                  />
                ) : (
                  props.rowData[5]
                )
              }
            </TableCell>
            <TableCell>
              {!editMode ? (
                <>
                  <IconButton
                    key={"edit-" + props.rowData[0]}
                    type={"button"}
                    onClick={() => setEditMode(true)}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    key={"delete-" + props.rowData[0]}
                    type={"button"}
                    onClick={() => {
                      props.onDelete();
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </>
              ) : (
                <>
                  <IconButton
                    key={"save-" + props.rowData[0]}
                    type={"button"}
                    form={"form-" + props.rowData[0]}
                    onClick={() => {
                      subFormik.submitForm();
                    }}
                  >
                    <CheckIcon />
                  </IconButton>
                  <IconButton
                    key={"cancel-" + props.rowData[0]}
                    type={"button"}
                    onClick={() => {
                      setEditMode(false);
                      props.onReset();
                      if (!subFormik.values.indicatoreIdFlat){
                        props.onDelete();
                      }
                    }}
                  >
                    <CancelIcon />
                  </IconButton>
                </>
              )}
            </TableCell>
          </TableRow>
        )
      }
    </Formik>
  );
}

const mapStateToProps = (state) => {
  return {
    indicatori: state.indicatori,
  };
};

export default connect(mapStateToProps, null)(IndicatoriDipendentiRowEditable);
