import React, { useEffect, useState } from 'react'
import DeleteIcon from '@material-ui/icons/Delete'
import { IconButton, TableCell, TableRow } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import CheckIcon from '@material-ui/icons/Check'
import CancelIcon from '@material-ui/icons/Cancel'
import { SimpleInputField } from './GenericFormComponents/SimpleInputField'
import { Form, Formik } from 'formik'
import {
  AttrezzaturaStalla,
  AttrezzaturaStallaScheme,
} from '../constants/aziendaConstants'
import { ResetForm } from './GenericFormComponents/ResetForm'

export function AttrezzatureStallaRowEditable(props){
  const [editMode, setEditMode] = useState(false);
  const [data, setData] = useState(new AttrezzaturaStalla());
  useEffect(() => {
    let attrezzatura = new AttrezzaturaStalla();
    attrezzatura.id = props.rowData[0];
    attrezzatura.nomeAttrezzatura = props.rowData[1];
    attrezzatura.stalla = props.rowData[2];
    setData(attrezzatura);
  }, [props.rowData]);
  return (
    <Formik
      key={props.rowData[0]}
      initialValues={data}
      onSubmit={(values) => {
        props.onRowChange(values);
        setEditMode(false);
      }}
      validationSchema={AttrezzaturaStallaScheme}
      enableReinitialize
    >
      {
        (subFormik) => (
          <TableRow key={props.rowData[0]}>
            {editMode ? <Form id={"form-" + props.rowData[0]} /> : null}
            <TableCell>
              <ResetForm showedValues={data} />
              {
                editMode ? (
                  <SimpleInputField
                    className={props.inputClassName}
                    name={"nomeAttrezzatura"}
                    form={"form-" + props.rowData[0]}
                  />
                ) : (
                  props.rowData[1]
                )
              }
            </TableCell>
            <TableCell>
              {!editMode ? (
                <>
                  <IconButton
                    key={"edit-" + props.rowData[0]}
                    type={"button"}
                    onClick={() => setEditMode(true)}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    key={"delete-" + props.rowData[0]}
                    type={"button"}
                    onClick={() => props.onDelete(props.rowData[0])}
                  >
                    <DeleteIcon />
                  </IconButton>
                </>
              ) : (
                <>
                  <IconButton
                    key={"save-" + props.rowData[0]}
                    type={"button"}
                    form={"form-" + props.rowData[0]}
                    onClick={() => subFormik.submitForm()}
                  >
                    <CheckIcon />
                  </IconButton>
                  <IconButton
                    key={"cancel-" + props.rowData[0]}
                    type={"button"}
                    onClick={() => {
                      setEditMode(false);
                      props.onReset();
                    }}
                  >
                    <CancelIcon />
                  </IconButton>
                </>
              )}
            </TableCell>
          </TableRow>
        )
    }
    </Formik>
  );
}