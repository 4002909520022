import axios from "axios";
import {
  BACKEND_URL,
  genericCallStatus,
  toastTypes,
} from "../../constants/generalConstants";
import { displayToast, getStandardConfig } from "../general/backendActions";
import {
  receiveAllAlerts,
  receiveAllAzienda,
  receiveSingleAlert,
  receiveSingleAllevamento,
  receiveSingleAzienda,
  receiveSingleStalla,
} from "./basicActions";

const Paths = Object.freeze({
  azienda: "/azienda/",
  stalla: "/stalla/",
  alert: "/alert/",
  allevamento: "/allevamento/",
});

export function fetchAllAzienda() {
  return (dispatch, getState) => {
    return axios
      .get(BACKEND_URL + Paths.azienda, getStandardConfig(getState))
      .then((response) => {
        dispatch(receiveAllAzienda(response.data));
        return Promise.resolve(genericCallStatus.SUCCESS);
      })
      .catch(() => {
        dispatch(
          displayToast(toastTypes.ERROR, "Errore nello scaricamento dati")
        );
        return Promise.reject(genericCallStatus.GENERIC_ERROR);
      });
  };
}

export function fetchSingleAzienda(aziendaId) {
  return (dispatch, getState) => {
    return axios
      .get(BACKEND_URL + Paths.azienda + aziendaId, getStandardConfig(getState))
      .then((response) => {
        dispatch(receiveSingleAzienda(response.data));
      })
      .then(() => {
        return Promise.resolve(genericCallStatus.SUCCESS);
      })
      .catch(() => {
        dispatch(
          displayToast(toastTypes.ERROR, "Errore nello scaricamento dati")
        );
        return Promise.reject(genericCallStatus.GENERIC_ERROR);
      });
  };
}

export function editSingleAzienda(id, azienda) {
  return (dispatch, getState) => {
    return axios
      .put(
        BACKEND_URL + Paths.azienda + id,
        azienda,
        getStandardConfig(getState)
      )
      .then(() => {
        dispatch(
          displayToast(toastTypes.INFO, "Dati modificati correttamente")
        );
        return Promise.resolve(genericCallStatus.SUCCESS);
      })
      .catch(() => {
        dispatch(
          displayToast(toastTypes.ERROR, "Errore nella modifica dell'azienda")
        );
        return Promise.reject(genericCallStatus.GENERIC_ERROR);
      });
  };
}

export function patchSingleAzienda(id, azienda) {
  delete azienda.stalle;
  delete azienda.allevamenti;
  return (dispatch, getState) => {
    return axios
      .patch(
        BACKEND_URL + Paths.azienda + id,
        azienda,
        getStandardConfig(getState)
      )
      .then(() => {
        dispatch(
          displayToast(toastTypes.INFO, "Dati modificati correttamente")
        );
        return Promise.resolve(genericCallStatus.SUCCESS);
      })
      .catch(() => {
        dispatch(
          displayToast(toastTypes.ERROR, "Errore nella modifica dell'azienda")
        );
        return Promise.reject(genericCallStatus.GENERIC_ERROR);
      });
  };
}

export function editSingleStalla(id, stalla) {
  delete stalla.azienda.alerts;
  return (dispatch, getState) => {
    return axios
      .put(BACKEND_URL + Paths.stalla + id, stalla, getStandardConfig(getState))
      .then(() => {
        dispatch(
          displayToast(toastTypes.INFO, "Dati modificati correttamente")
        );
        return Promise.resolve(genericCallStatus.SUCCESS);
      })
      .catch(() => {
        dispatch(
          displayToast(toastTypes.ERROR, "Errore nella modifica dei dati")
        );
        return Promise.reject(genericCallStatus.GENERIC_ERROR);
      });
  };
}

export function createSingleStalla(stalla) {
  return (dispatch, getState) => {
    return axios
      .post(BACKEND_URL + Paths.stalla, stalla, getStandardConfig(getState))
      .then(() => {
        dispatch(displayToast(toastTypes.INFO, "Stalla creata correttamente"));
        return Promise.resolve(genericCallStatus.SUCCESS);
      })
      .catch(() => {
        dispatch(
          displayToast(
            toastTypes.ERROR,
            "Errore durante la creazione della stalla"
          )
        );
        return Promise.reject(genericCallStatus.GENERIC_ERROR);
      });
  };
}

export function fetchSingleStalla(stallaId) {
  return (dispatch, getState) => {
    return axios
      .get(BACKEND_URL + Paths.stalla + stallaId, getStandardConfig(getState))
      .then((response) => {
        dispatch(receiveSingleStalla(response.data));
      })
      .then(() => {
        return Promise.resolve(genericCallStatus.SUCCESS);
      })
      .catch(() => {
        dispatch(
          displayToast(toastTypes.ERROR, "Errore nello scaricamento dati")
        );
        return Promise.reject(genericCallStatus.GENERIC_ERROR);
      });
  };
}

export function deleteSingleStalla(stallaId) {
  return (dispatch, getState) => {
    return axios
      .delete(
        BACKEND_URL + Paths.stalla + stallaId,
        getStandardConfig(getState)
      )
      .then(() => {
        dispatch(
          displayToast(toastTypes.INFO, "Stalla eliminata correttamente")
        );
        return Promise.resolve(genericCallStatus.SUCCESS);
      })
      .catch(() => {
        dispatch(
          displayToast(
            toastTypes.ERROR,
            "Errore nell'eliminazione della stalla"
          )
        );
        return Promise.reject(genericCallStatus.GENERIC_ERROR);
      });
  };
}

export function editSingleAllevamento(id, allevamento) {
  delete allevamento.azienda.alerts;
  return (dispatch, getState) => {
    return axios
      .put(
        BACKEND_URL + Paths.allevamento + id,
        allevamento,
        getStandardConfig(getState)
      )
      .then(() => {
        dispatch(
          displayToast(toastTypes.INFO, "Dati modificati correttamente")
        );
        return Promise.resolve(genericCallStatus.SUCCESS);
      })
      .catch(() => {
        dispatch(
          displayToast(toastTypes.ERROR, "Errore nella modifica dei dati")
        );
        return Promise.reject(genericCallStatus.GENERIC_ERROR);
      });
  };
}

export function createSingleAllevamento(allevamento) {
  return (dispatch, getState) => {
    return axios
      .post(
        BACKEND_URL + Paths.allevamento,
        allevamento,
        getStandardConfig(getState)
      )
      .then(() => {
        dispatch(
          displayToast(toastTypes.INFO, "Allevamento creato correttamente")
        );
        return Promise.resolve(genericCallStatus.SUCCESS);
      })
      .catch(() => {
        dispatch(
          displayToast(
            toastTypes.ERROR,
            "Errore durante la creazione dell'allevamento"
          )
        );
        return Promise.reject(genericCallStatus.GENERIC_ERROR);
      });
  };
}

export function fetchSingleAllevamento(allevamentoID) {
  return (dispatch, getState) => {
    return axios
      .get(
        BACKEND_URL + Paths.allevamento + allevamentoID,
        getStandardConfig(getState)
      )
      .then((response) => {
        dispatch(receiveSingleAllevamento(response.data));
      })
      .then(() => {
        return Promise.resolve(genericCallStatus.SUCCESS);
      })
      .catch(() => {
        dispatch(
          displayToast(toastTypes.ERROR, "Errore nello scaricamento dati")
        );
        return Promise.reject(genericCallStatus.GENERIC_ERROR);
      });
  };
}

export function deleteSingleAllevamento(allevamentoID) {
  return (dispatch, getState) => {
    return axios
      .delete(
        BACKEND_URL + Paths.allevamento + allevamentoID,
        getStandardConfig(getState)
      )
      .then(() => {
        dispatch(
          displayToast(toastTypes.INFO, "Allevamento eliminato correttamente")
        );
        return Promise.resolve(genericCallStatus.SUCCESS);
      })
      .catch(() => {
        dispatch(
          displayToast(
            toastTypes.ERROR,
            "Errore durante l'eliminazione dell'allevamento"
          )
        );
        return Promise.reject(genericCallStatus.GENERIC_ERROR);
      });
  };
}

export function fetchAllAlerts() {
  return (dispatch, getState) => {
    return axios
      .get(
        BACKEND_URL +
          Paths.allevamento +
          getState().loginTransientState.selectedAllevamentoId +
          "/alerts",
        getStandardConfig(getState)
      )
      .then((response) => {
        dispatch(receiveAllAlerts(response.data));
        return Promise.resolve(genericCallStatus.SUCCESS);
      })
      .catch(() => {
        dispatch(
          displayToast(toastTypes.ERROR, "Errore nello scaricamento dati")
        );
        return Promise.reject(genericCallStatus.GENERIC_ERROR);
      });
  };
}

export function fetchAndSetAsShowedSingleAlert(alertId) {
  return (dispatch, getState) => {
    return axios
      .get(BACKEND_URL + Paths.alert + alertId, getStandardConfig(getState))
      .then((response) => {
        dispatch(receiveSingleAlert(response.data));
      })
      .then(() => {
        return Promise.resolve(genericCallStatus.SUCCESS);
      })
      .catch(() => {
        dispatch(
          displayToast(toastTypes.ERROR, "Errore nello scaricamento dati")
        );
        return Promise.reject(genericCallStatus.GENERIC_ERROR);
      });
  };
}
export function setAlertProtocolStatus(alertId, status) {
  return (dispatch, getState) => {
    return axios
      .post(
        BACKEND_URL + Paths.alert + alertId + "/protocol",
        status,
        getStandardConfig(getState)
      )
      .then(() => {
        dispatch(
          displayToast(toastTypes.INFO, "Stato esecuzione protocollo impostato")
        );
        return Promise.resolve(genericCallStatus.SUCCESS);
      })
      .catch(() => {
        dispatch(
          displayToast(toastTypes.ERROR, "Errore nel trasferimento dati")
        );
        return Promise.reject(genericCallStatus.GENERIC_ERROR);
      });
  };
}
