import axios from "axios";
import {
  BACKEND_URL,
  genericCallStatus,
  toastTypes,
} from "../../constants/generalConstants";
import { displayToast, getStandardConfig } from "../general/backendActions";
import { receiveAllIndicatore, receiveSingleIndicatore } from "./basicActions";

const Paths = Object.freeze({
  indicatore: "/indicatore/",
});

export function fetchAllIndicatore() {
  return (dispatch, getState) => {
    return axios
      .get(BACKEND_URL + Paths.indicatore, getStandardConfig(getState))
      .then((response) => {
        dispatch(receiveAllIndicatore(response.data));
        return Promise.resolve(genericCallStatus.SUCCESS);
      })
      .catch((error) => {
        dispatch(
          displayToast(toastTypes.ERROR, "Errore nello scaricamento dati")
        );
        return Promise.reject(genericCallStatus.GENERIC_ERROR);
      });
  };
}

export function fetchSingleIndicatore(indicatoreId) {
  return (dispatch, getState) => {
    return axios
      .get(
        BACKEND_URL + Paths.indicatore + indicatoreId,
        getStandardConfig(getState)
      )
      .then((response) => {
        dispatch(receiveSingleIndicatore(response.data));
      })
      .then(() => {
        return Promise.resolve(genericCallStatus.SUCCESS);
      })
      .catch((error) => {
        dispatch(
          displayToast(toastTypes.ERROR, "Errore nello scaricamento dati")
        );
        return Promise.reject(genericCallStatus.GENERIC_ERROR);
      });
  };
}

export function createIndicatore(indicatore) {
  return (dispatch, getState) => {
    return axios
      .post(
        BACKEND_URL + Paths.indicatore,
        indicatore,
        getStandardConfig(getState)
      )
      .then(() => {
        return dispatch(fetchAllIndicatore());
      })
      .then((response) => {
        dispatch(
          displayToast(toastTypes.SUCCESS, "Indicatore creato con successo")
        );
        return Promise.resolve(genericCallStatus.GENERIC_ERROR);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 409) {
            dispatch(displayToast(toastTypes.ERROR, "Codice indicatore già esistente"));
            return Promise.reject(genericCallStatus.GENERIC_ERROR);
          }
        }
        dispatch(
          displayToast(
            toastTypes.ERROR,
            "Errore durante la creazione dell'indicatore"
          )
        );
        return Promise.reject(genericCallStatus.GENERIC_ERROR);
      });
  };
}

export function editIndicatore(id, indicatore) {
  return (dispatch, getState) => {
    if (indicatore.box.indicatori){
      delete indicatore.box.indicatori;
    }
    return axios
      .put(
        BACKEND_URL + Paths.indicatore + id,
        indicatore,
        getStandardConfig(getState)
      )
      .then((response) => {
        dispatch(
          displayToast(toastTypes.INFO, "Modifiche salvate con successo")
        );
        return Promise.resolve(genericCallStatus.GENERIC_ERROR);
      })
      .catch((error) => {
        dispatch(
          displayToast(
            toastTypes.ERROR,
            "Errore durante il salvataggio delle modifiche"
          )
        );
        return Promise.reject(genericCallStatus.GENERIC_ERROR);
      });
  };
}
