export let BACKEND_URL = "https://" + window.location.hostname + "/api";

export const RECAPTCHA_API_KEY = "6LeaIWEbAAAAAAJrqtGxDsb3i9p1XM5YyttOabFU";

export const uploadResultTypes = Object.freeze({
    SUCCESS: "upload_successful",
    GENERIC_ERROR: "generic_error"
});

export const genericCallStatus = Object.freeze({
    SUCCESS: "success",
    GENERIC_ERROR: "generic_error"
});

export const filterTypes = Object.freeze({
    category: "cat",
    subCategory: "subCat",
    company: "company",
    tag: "tag",
    price: "price",
    province: "prov"
});

export class Filter {
    constructor(type, id, value, label) {
        this.type = type;
        this.id = id;
        this.value = value;
        this.label = label;
    }
}

export const defaultPageSizeOptions = Object.freeze({
    10: "10",
    20: "20",
    50: "50"
})

export const defaultSortOrderParameters = Object.freeze({
    INCREASING: "asc",
    DECREASING: "desc"
})

export const toastTypes = Object.freeze({
    INFO: "info",
    SUCCESS: "success",
    ERROR: "error"
})

export const defaultRowOptions = [10, 20, 50, 100];