import { combineReducers } from "redux";
import { loginData, loginTransientState } from "./auth/reducers";
import { appPreferences, provinceComuneSelectorData } from './general/reducers'
import { indicatori } from "./indicatori/reducers";
import { box } from './box/reducers'
import { aziende } from './aziende/reducers'
import { valori } from './valoriindicatori/reducers'
import { admin } from './admin/reducers'
import { enteFornitore } from './entefornitore/reducers'

const combinedReducers = combineReducers({
  loginData,
  loginTransientState,
  provinceComuneSelectorData,
  appPreferences,
  indicatori,
  box,
  aziende,
  valori,
  admin,
  enteFornitore
});

export default combinedReducers;
