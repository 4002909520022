import React, { useCallback, useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { SimpleSelectField } from "../../Common/GenericFormComponents/SimpleSelectField";
import {
  StringThresholdSchema,
  thresholdOperatorsTypes,
} from "../../constants/indicatoriConstants";
import { Formik, useFormikContext } from "formik";
import {
  VALUES_STRING,
  valuesStatuses,
  valuesStatusesLabels,
} from "../../constants/valoriIndicatoriConstants";

export function StringThresholdConditionsFields(props) {
  const formik = useFormikContext();
  const setFieldValue = formik.setFieldValue;
  const [fieldStatus, setFieldStatus] = useState({
    POSITIVA: valuesStatuses.GREEN,
    MIGLIORABILE: valuesStatuses.YELLOW,
    NEGATIVA: valuesStatuses.RED,
  });
  const greenFieldValue = formik.values["greenThresholdConditions"];
  const yellowFieldValue = formik.values["yellowThresholdConditions"];
  const redFieldValue = formik.values["redThresholdConditions"];
  const formConditionToSubForm = useCallback((status, condition) => {
    switch (condition.arg1) {
      case VALUES_STRING.POSITIVA:
        if (condition.thresholdOperator === thresholdOperatorsTypes.EQUAL) {
          setFieldStatus((oldStatus) => {
            return {
              ...oldStatus,
              POSITIVA: status,
            };
          });
        }
        break;
      case VALUES_STRING.MIGLIORABILE:
        if (condition.thresholdOperator === thresholdOperatorsTypes.EQUAL) {
          setFieldStatus((oldStatus) => {
            return {
              ...oldStatus,
              MIGLIORABILE: status,
            };
          });
        }
        break;
      case VALUES_STRING.NEGATIVA:
        if (condition.thresholdOperator === thresholdOperatorsTypes.EQUAL) {
          setFieldStatus((oldStatus) => {
            return {
              ...oldStatus,
              NEGATIVA: status,
            };
          });
        }
        break;
      default:
        break;
    }
  }, []);
  useEffect(() => {
    greenFieldValue.forEach(formConditionToSubForm.bind(null, valuesStatuses.GREEN));
    yellowFieldValue.forEach(
      formConditionToSubForm.bind(null, valuesStatuses.YELLOW)
    );
    redFieldValue.forEach(formConditionToSubForm.bind(null, valuesStatuses.RED));
  }, [
    greenFieldValue,
    yellowFieldValue,
    redFieldValue,
    formConditionToSubForm,
  ]);
  const subFormToForm = useCallback(
    (values) => {
      const greenConditions = [];
      const yellowConditions = [];
      const redConditions = [];
      Object.keys(values).forEach((key) => {
        switch (values[key]) {
          case valuesStatuses.GREEN:
            greenConditions.push({
              arg1: VALUES_STRING[key],
              arg2: 1,
              thresholdOperator: thresholdOperatorsTypes.EQUAL,
            });
            break;
          case valuesStatuses.YELLOW:
            yellowConditions.push({
              arg1: VALUES_STRING[key],
              arg2: 1,
              thresholdOperator: thresholdOperatorsTypes.EQUAL,
            });
            break;
          case valuesStatuses.RED:
            redConditions.push({
              arg1: VALUES_STRING[key],
              arg2: 1,
              thresholdOperator: thresholdOperatorsTypes.EQUAL,
            });
            break;
          default:
            break;
        }
        setFieldValue("greenThresholdConditions", greenConditions);
        setFieldValue("yellowThresholdConditions", yellowConditions);
        setFieldValue("redThresholdConditions", redConditions);
        setFieldValue("defaultThreshold", valuesStatuses.RED);
      });
    },
    [setFieldValue]
  );
  return (
    <>
      <Formik
        initialValues={fieldStatus}
        onSubmit={(values) => subFormToForm(values)}
        validationSchema={StringThresholdSchema}
        enableReinitialize
      >
        {(subFormik) => (
          <>
            <Grid className={props.elementClass} item container md={3} xs={6}>
              <Typography>
                Status associato al valore M/Ottimo/Positiva
              </Typography>
            </Grid>
            <Grid
              className={props.elementClass}
              item
              container
              md={3} xs={6}
              justify="center"
            >
              <div className={props.inputClass}>
                <SimpleSelectField
                  name={"POSITIVA"}
                  id="POSITIVA"
                  className={props.selectClass}
                  options={valuesStatusesLabels}
                  disabled={!props.editMode}
                  onChange={() => subFormik.submitForm()}
                />
              </div>
            </Grid>
            <Grid className={props.elementClass} item container md={3} xs={6}>
              <Typography>
                Status associato al valore I/Sufficiente/Migliorabile
              </Typography>
            </Grid>
            <Grid
              className={props.elementClass}
              item
              container
              md={3} xs={6}
              justify="center"
            >
              <div className={props.inputClass}>
                <SimpleSelectField
                  name={"MIGLIORABILE"}
                  id="MIGLIORABILE"
                  className={props.selectClass}
                  options={valuesStatusesLabels}
                  disabled={!props.editMode}
                  onChange={() => subFormik.submitForm()}
                />
              </div>
            </Grid>
            <Grid className={props.elementClass} item container md={3} xs={6}>
              <Typography>
                Status associato al valore P/Insufficiente/Negativa
              </Typography>
            </Grid>
            <Grid
              className={props.elementClass}
              item
              container
              md={3} xs={6}
              justify="center"
            >
              <div className={props.inputClass}>
                <SimpleSelectField
                  name={"NEGATIVA"}
                  id="NEGATIVA"
                  className={props.selectClass}
                  options={valuesStatusesLabels}
                  disabled={!props.editMode}
                  onChange={() => subFormik.submitForm()}
                />
              </div>
            </Grid>
            <Grid item container md={6} xs={0} />
          </>
        )}
      </Formik>
    </>
  );
}
