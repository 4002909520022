export const LOGGED_IN = "LOGGED_IN";
export const LOGGED_OUT = "LOGGED_OUT";
export const LOGGING_IN = "LOGGING_IN";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const RECEIVE_PROFILE = "RECEIVE_PROFILE";
export const RECEIVE_CURRENT_USER = "RECEIVE_CURRENT_USER";
export const SET_SELECTED_ALLEVAMENTO = "SET_SELECTED_ALLEVAMENTO";
export const RECEIVE_FRESH_TOKENS = "RECEIVE_FRESH_TOKENS";
export const REFRESHING_TOKENS = "REFRESHING_TOKENS";
export const FAILED_REFRESH_TOKENS = "FAILED_REFRESH_TOKENS";
export const SET_IS_WAITING = "SET_IS_WAITING";

export function loggedIn(
  username,
  userType,
  accessToken,
  accessTokenExpiration,
  refreshToken,
  refreshTokenExpiration
) {
  return {
    type: LOGGED_IN,
    username,
    userType,
    accessToken,
    accessTokenExpiration,
    refreshToken,
    refreshTokenExpiration,
  };
}

export function receiveFreshTokens(
  userType,
  accessToken,
  accessTokenExpiration,
  refreshToken,
  refreshTokenExpiration
) {
  return {
    type: RECEIVE_FRESH_TOKENS,
    userType,
    accessToken,
    accessTokenExpiration,
    refreshToken,
    refreshTokenExpiration,
  };
}

export function refreshingTokens(refreshPromise) {
  return { type: REFRESHING_TOKENS, refreshPromise };
}

export function failedRefreshTokens() {
  return { type: FAILED_REFRESH_TOKENS };
}

export function loggingIn() {
  return { type: LOGGING_IN };
}

export function loggedOut() {
  return { type: LOGGED_OUT };
}

export function loginFailed(loginStateType, message) {
  return { type: LOGIN_FAILED, loginStateType, message };
}

export function receiveProfile(profile) {
  return { type: RECEIVE_PROFILE, profile };
}

export function receiveCurrentUser(user) {
  return {
    type: RECEIVE_CURRENT_USER,
    user,
  };
}

export function setSelectedAllevamento(idAllevamento) {
  return {
    type: SET_SELECTED_ALLEVAMENTO,
    idAllevamento
  }
}

export function setIsWaiting(waiting){
  return {
    type: SET_IS_WAITING,
    waiting
  }
}
