import {
  RECEIVE_ALL_ALERTS,
  RECEIVE_ALL_AZIENDA,
  RECEIVE_SINGLE_ALERT, RECEIVE_SINGLE_ALLEVAMENTO,
  RECEIVE_SINGLE_AZIENDA,
  RECEIVE_SINGLE_STALLA,
} from './basicActions'
import { LOGGED_OUT } from '../auth/basicActions'

export function aziende(
  state = {
    aziendeByIDs: {},
    stalleByIDs: {},
    allevamentiByIDs: {},
    alertsByIDs: {},
    idIndicatoriWithAlerts: [],
  },
  action
) {
  switch (action.type) {
    case RECEIVE_ALL_AZIENDA:
      let newAziende = action.aziende.reduce(
        (obj, item) => Object.assign(obj, { [item.id]: item }),
        {}
      );
      return {
        ...state,
        aziendeByIDs: newAziende,
      };
    case RECEIVE_SINGLE_AZIENDA:
      return {
        ...state,
        aziendeByIDs: {
          ...state.aziendeByIDs,
          [action.azienda.id]: action.azienda,
        },
      };
    case RECEIVE_SINGLE_STALLA:
      return {
        ...state,
        stalleByIDs: {
          ...state.stalleByIDs,
          [action.stalla.id]: action.stalla,
        },
      };
    case RECEIVE_SINGLE_ALLEVAMENTO:
      return {
        ...state,
        allevamentiByIDs: {
          ...state.allevamentiByIDs,
          [action.allevamento.id]: action.allevamento,
        },
      };
    case RECEIVE_ALL_ALERTS:
      let newAlerts = action.alerts.reduce(
        (obj, item) => Object.assign(obj, { [item.id]: item }),
        {}
      );
      let newIdIndicatoriWithAlerts = action.alerts.map((alert) => alert.tipoIndicatoreCriticalInfo.tipoIndicatore.id);
      return {
        ...state,
        alertsByIDs: newAlerts,
        idIndicatoriWithAlerts: newIdIndicatoriWithAlerts
      };
    case RECEIVE_SINGLE_ALERT:
      let newIdArray = [...state.idIndicatoriWithAlerts];
      if (!state.idIndicatoriWithAlerts.includes(action.alert.tipoIndicatoreCriticalInfo.tipoIndicatore.id)){
        newIdArray.push(action.alert.tipoIndicatoreCriticalInfo.tipoIndicatore.id);
      }
      return {
        ...state,
        alertsByIDs: {
          ...state.alertsByIDs,
          [action.alert.id]: action.alert,
        },
        idIndicatoriWithAlerts: newIdArray
      };
    case LOGGED_OUT:
      return {
        aziendeByIDs: {},
        stalleByIDs: {},
        alertsByIDs: {},
        idIndicatoriWithAlerts: [],
        allevamentiByIDs: {},
      }
    default:
      return state;
  }
}
