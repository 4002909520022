export const RECEIVE_ALL_BOX = "RECEIVE_ALL_BOX";
export const RECEIVE_SINGLE_BOX = "RECEIVE_SINGLE_BOX";

export function receiveAllBox(boxes) {
  return { type: RECEIVE_ALL_BOX, boxes };
}

export function receiveSingleBox(box) {
  return { type: RECEIVE_SINGLE_BOX, box };
}
