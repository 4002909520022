import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from 'react-redux'
import { makeStyles } from "@material-ui/core/styles";
import { createTheme, MuiThemeProvider } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { useHistory, useLocation } from "react-router-dom";
import { fetchAllLastValoreOfIndicatoreAsAzienda } from "../../redux/valoriindicatori/backendActions";
import { fetchAllIndicatore } from "../../redux/indicatori/backendActions";
import CheckIcon from "@material-ui/icons/Check";
import { StatusValoreVisualizer } from "../../Common/StatusValoreVisualizer";
import { textLabels } from "../../constants/miscConstants";
import { aziendaPaths } from "../../constants/aziendaConstants";
import { frequencyTypesSelectValues } from "../../constants/indicatoriConstants";
import {
  getModifiedOnLabel,
  getPeriodLabel,
  getValueLabel,
  getValueState,
  valorePeriodSortCompare,
  valoreStateSortCompare,
  valoreValueSortCompare,
} from "../../constants/valoriIndicatoriConstants";
import { fetchAllAlerts } from "../../redux/aziende/backendActions";
import { defaultRowOptions } from '../../constants/generalConstants'
import { setMainTableRows } from '../../redux/general/basicActions'

const useStyles = makeStyles(() => ({
  alertRowText: {
    color: "red",
  },
  alertText: {
    color: "white",
    backgroundColor: "red",
    marginLeft: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
    fontWeight: "bold",
  },
  alertValueField: {
    display: "flex",
    flexDirection: "row",
  },
}));

const getMuiTheme = () =>
  createTheme({
    overrides: {
      MuiToolbar: {
        root: {
          backgroundColor: "#FEFDFD",
        },
      },
      MuiTable: {
        root: {
          backgroundColor: "#FEFDFD",
        },
      },
      MuiTableCell: {
        root: {
          height: "30px",
          padding: "0px 4px 0px 4px",
        },
      },
      MUIDataTableBodyRow: {
        root: {
          cursor: "pointer",
        },
      },
      MUIDataTableHeadCell: {
        fixedHeader: {
          backgroundColor: "#C4C4C4",
        },
      },
    },
  });

function IndicatoriOverviewPage(props) {
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const setTitle = props.setTitle;
  const fetchAllLastValoreOfIndicatoreAsAzienda =
    props.fetchAllLastValoreOfIndicatoreAsAzienda;
  const fetchAllIndicatore = props.fetchAllIndicatore;
  const [data, setData] = useState([]);
  const selectedAllevamentoId = useSelector(
    (state) => state.loginTransientState.selectedAllevamentoId
  );
  const dispatch = useDispatch();
  const mainTableRows = useSelector(
    (state) => state.appPreferences.mainWindowTableRows
  );
  useEffect(() => {
    setTitle("Visualizza gli indicatori dell'azienda");
  }, [setTitle]);
  useEffect(() => {
    fetchAllIndicatore();
  }, [location, fetchAllIndicatore]);
  useEffect(() => {
    fetchAllLastValoreOfIndicatoreAsAzienda();
  }, [fetchAllLastValoreOfIndicatoreAsAzienda, selectedAllevamentoId]);
  const fetchAllAlerts = props.fetchAllAlerts;
  useEffect(() => {
    fetchAllAlerts();
  }, [fetchAllAlerts, selectedAllevamentoId]);
  useEffect(() => {
    const newData = [];
    Object.keys(props.indicatori.indicatoriByIDs).forEach((id) => {
      let valore =
        props.valori.valoriByIDs[props.valori.lastValoreByTipoIndicatoreId[id]];
      if (valore) {
        let indicatore = props.indicatori.indicatoriByIDs[id];
        newData.push({
          id: indicatore.id,
          hasAlert: props.aziende.idIndicatoriWithAlerts.includes(
            indicatore.id
          ),
          codeFlat: indicatore.code,
          fonteFlat: indicatore.enteFornitore.denominazione,
          nameFlat: indicatore.name,
          boxFlat: indicatore.box.name,
          measureUnit: indicatore.measureUnit,
          frequency: frequencyTypesSelectValues[indicatore.frequency],
          modifiedOn: getModifiedOnLabel(valore.modifiedOn),
          period: getPeriodLabel(valore, indicatore),
          value: getValueLabel(valore.value, indicatore.valuesType),
          critical: indicatore.critical,
          state: getValueState(valore, indicatore),
          rawValore: valore,
        });
      }
    });
    setData(newData);
  }, [props.indicatori, props.valori, props.aziende.idIndicatoriWithAlerts]);
  const columns = [
    {
      name: "id",
      options: {
        display: "excluded",
      },
    },
    {
      name: "hasAlert",
      options: {
        display: "excluded",
      },
    },
    {
      name: "codeFlat",
      label: "Cod.",
      options: {
        customBodyRenderLite: (dataIndex) => {
          if (data[dataIndex].hasAlert === true) {
            return (
              <div className={classes.alertRowText}>
                {data[dataIndex].codeFlat}
              </div>
            );
          } else return <div>{data[dataIndex].codeFlat}</div>;
        },
      },
    },
    {
      name: "nameFlat",
      label: "Nome",
      options: {
        customBodyRenderLite: (dataIndex) => {
          if (data[dataIndex].hasAlert === true) {
            return (
              <div className={classes.alertRowText}>
                {data[dataIndex].nameFlat}
              </div>
            );
          } else return <div>{data[dataIndex].nameFlat}</div>;
        },
      },
    },
    {
      name: "fonteFlat",
      label: "Fonte",
      options: {
        customBodyRenderLite: (dataIndex) => {
          if (data[dataIndex].hasAlert === true) {
            return (
              <div className={classes.alertRowText}>
                {data[dataIndex].fonteFlat}
              </div>
            );
          } else return <div>{data[dataIndex].fonteFlat}</div>;
        },
      },
    },
    {
      name: "boxFlat",
      label: "Box",
      options: {
        customBodyRenderLite: (dataIndex) => {
          if (data[dataIndex].hasAlert === true) {
            return (
              <div className={classes.alertRowText}>
                {data[dataIndex].boxFlat}
              </div>
            );
          } else return <div>{data[dataIndex].boxFlat}</div>;
        },
      },
    },
    {
      name: "measureUnit",
      label: "Unità di misura",
      options: {
        customBodyRenderLite: (dataIndex) => {
          if (data[dataIndex].hasAlert === true) {
            return (
              <div className={classes.alertRowText}>
                {data[dataIndex].measureUnit}
              </div>
            );
          } else return <div>{data[dataIndex].measureUnit}</div>;
        },
      },
    },
    {
      name: "frequency",
      label: "Frequenza",
      options: {
        customBodyRenderLite: (dataIndex) => {
          if (data[dataIndex].hasAlert === true) {
            return (
              <div className={classes.alertRowText}>
                {data[dataIndex].frequency}
              </div>
            );
          } else return <div>{data[dataIndex].frequency}</div>;
        },
      },
    },
    {
      name: "modifiedOn",
      label: "Data ultima modifica",
      options: {
        customBodyRenderLite: (dataIndex) => {
          if (data[dataIndex].hasAlert === true) {
            return (
              <div className={classes.alertRowText}>
                {data[dataIndex].modifiedOn}
              </div>
            );
          } else return <div>{data[dataIndex].modifiedOn}</div>;
        },
      },
    },
    {
      name: "period",
      label: "Periodo ultimo valore",
      options: {
        // We need the index of rawValore
        sortCompare: valorePeriodSortCompare(13),
        customBodyRenderLite: (dataIndex) => {
          if (data[dataIndex].hasAlert === true) {
            return (
              <div className={classes.alertRowText}>
                {data[dataIndex].period}
              </div>
            );
          } else return <div>{data[dataIndex].period}</div>;
        },
      },
    },
    {
      name: "value",
      label: "Ultimo valore",
      options: {
        sortCompare: valoreValueSortCompare(13),
        customBodyRenderLite: (dataIndex) => {
          if (data[dataIndex].hasAlert === true) {
            return (
              <div className={classes.alertValueField}>
                <div className={classes.alertRowText}>
                  {data[dataIndex].value}
                </div>
                <div className={classes.alertText}>Alert</div>
              </div>
            );
          } else return <div>{data[dataIndex].value}</div>;
        },
      },
    },
    {
      name: "critical",
      label: "Critico",
      options: {
        customBodyRenderLite: (dataIndex) => {
          if (data[dataIndex].critical === true) {
            if (data[dataIndex].hasAlert) {
              return <CheckIcon className={classes.alertRowText} />;
            } else {
              return <CheckIcon />;
            }
          } else return null;
        },
      },
    },
    {
      name: "state",
      label: "Stato",
      options: {
        sortCompare: valoreStateSortCompare,
        customBodyRenderLite: (dataIndex) => (
          <StatusValoreVisualizer status={data[dataIndex].state} />
        ),
      },
    },
    {
      name: "rawValore",
      options: {
        display: "excluded",
      },
    },
  ];
  const options = {
    elevation: 0,
    selectableRows: "none",
    filter: false,
    print: false,
    download: false,
    search: false,
    viewColumns: false,
    textLabels: textLabels,
    rowsPerPageOptions: defaultRowOptions,
    rowsPerPage: mainTableRows,
    onChangeRowsPerPage: (rows) => dispatch(setMainTableRows(rows)),
    onRowClick: (rowData) => {
      history.push(`${aziendaPaths.indicatori}/${rowData[0]}`);
    },
  };
  return (
    <div>
      <h6>Indicatori con valori</h6>
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable data={data} columns={columns} options={options} />
      </MuiThemeProvider>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    valori: state.valori,
    indicatori: state.indicatori,
    aziende: state.aziende,
  };
};

const mapDispatchToProps = {
  fetchAllLastValoreOfIndicatoreAsAzienda,
  fetchAllIndicatore,
  fetchAllAlerts,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IndicatoriOverviewPage);
