import {
  RECEIVE_ALL_LAST_VALORE_OF_INDICATORE,
  RECEIVE_COMMITTED_VALORI,
  RECEIVE_CSV_ERROR,
  RECEIVE_FILTERED_VALORI,
  RECEIVE_INDICATORI_AVERAGES,
  RECEIVE_LAST_VALORE_OF_INDICATORE,
  RECEIVE_SINGLE_VALORE,
  RECEIVE_STAGED_VALORE,
  RECEIVE_VALORI_STAGING_AREA, RESET_LAST_INDICATORI_AVERAGES,
  RESET_SEARCH_RESULTS,
} from './basicActions'
import { LOGGED_OUT } from '../auth/basicActions'

export function valori(
  state = {
    valoriByIDs: {},
    lastValoriFilterResult: [],
    lastValoreByTipoIndicatoreId: {},
    valoriStagingAreaByIDs: {},
    stagedValoriByIDs: {},
    lastStagingAreaId: undefined,
    lastCommittedValoriIDs: [],
    lastCsvError: undefined,
    lastAveragesReceived: [],
  },
  action
) {
  switch (action.type) {
    case RECEIVE_FILTERED_VALORI:
      let filteredValoriByIDs = action.valori.reduce(
        (obj, item) => Object.assign(obj, { [item.id]: item }),
        {}
      );
      let updatedValori = Object.assign(
        {},
        state.valoriByIDs,
        filteredValoriByIDs
      );
      let newValoriIDFilterResult = [];
      action.valori.forEach((valore) =>
        newValoriIDFilterResult.push(valore.id)
      );
      return {
        ...state,
        valoriByIDs: updatedValori,
        lastValoriFilterResult: newValoriIDFilterResult,
      };
    case RECEIVE_SINGLE_VALORE:
      return {
        ...state,
        valoriByIDs: {
          ...state.valoriByIDs,
          [action.valore.id]: action.valore,
        },
      };
    case RESET_SEARCH_RESULTS:
      return {
        ...state,
        lastValoriFilterResult: [],
      };
    case RECEIVE_LAST_VALORE_OF_INDICATORE:
      return {
        ...state,
        valoriByIDs: {
          ...state.valoriByIDs,
          [action.valore.id]: action.valore,
        },
        lastValoreByTipoIndicatoreId: {
          ...state.lastValoreByTipoIndicatoreId,
          [action.tipoIndicatoreId]: action.valore.id,
        },
      };
    case RECEIVE_ALL_LAST_VALORE_OF_INDICATORE:
      let newValoriById = { ...state.valoriByIDs };
      let newLastValoreByTipoIndicatoreId = {};
      action.ultimiValori.forEach((ultimoValore) => {
        newValoriById[ultimoValore.valore.id] = ultimoValore.valore;
        newLastValoreByTipoIndicatoreId[ultimoValore.tipoIndicatore.id] =
          ultimoValore.valore.id;
      });
      return {
        ...state,
        valoriByIDs: newValoriById,
        lastValoreByTipoIndicatoreId: newLastValoreByTipoIndicatoreId,
      };
    case RECEIVE_VALORI_STAGING_AREA:
      let newStagedValori = action.stagingArea.valori.reduce(
        (obj, item) => Object.assign(obj, { [item.id]: item }),
        {}
      );
      return {
        ...state,
        lastCsvError: undefined,
        stagedValoriByIDs: {
          ...state.stagedValoriByIDs,
          ...newStagedValori,
        },
        valoriStagingAreaByIDs: {
          ...state.valoriStagingAreaByIDs,
          [action.stagingArea.id]: action.stagingArea,
        },
        lastStagingAreaId: action.stagingArea.id,
      };
    case RECEIVE_STAGED_VALORE:
      return {
        ...state,
        stagedValoriByIDs: {
          ...state.stagedValoriByIDs,
          [action.valore.id]: action.valore,
        },
      };
    case RECEIVE_CSV_ERROR:
      return {
        ...state,
        lastCsvError: action.error,
      };
    case RECEIVE_COMMITTED_VALORI:
      let newValori = action.valori.reduce(
        (obj, item) => Object.assign(obj, { [item.id]: item }),
        {}
      );
      return {
        ...state,
        valoriByIDs: {
          ...state.valoriByIDs,
          ...newValori,
        },
        lastCommittedValoriIDs: Object.keys(newValori),
      };
    case RECEIVE_INDICATORI_AVERAGES:
      return {
        ...state,
        lastAveragesReceived: action.averages,
      };
    case RESET_LAST_INDICATORI_AVERAGES:
      return {
        ...state,
        lastAveragesReceived: []
      }
    case LOGGED_OUT:
      return {
        valoriByIDs: {},
        lastValoriFilterResult: [],
        lastValoreByTipoIndicatoreId: {},
        valoriStagingAreaByIDs: {},
        stagedValoriByIDs: {},
        lastStagingAreaId: undefined,
        lastCommittedValoriIDs: [],
        lastCsvError: undefined,
        lastAveragesReceived: [],
      }
    default:
      return state;
  }
}
