import React from "react";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from 'react-bootstrap/Tooltip';

export function ErrorTooltip(props) {
    return (
        <Overlay target={props.target} show={props.show} placement="bottom">
            {(overlayProps) => (
                <Tooltip id="overlay-tooltip" {...overlayProps}>
                    {props.msg}
                </Tooltip>
            )}
        </Overlay>
    )
}