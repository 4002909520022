import React from "react";
import { Form, Formik } from "formik";
import { useHistory, Link } from "react-router-dom";
import * as Yup from "yup";
import { connect } from "react-redux";
import { authPaths, LoginStateTypes } from "../constants/authConstants";
import { doLogin } from "../redux/auth/backendActions";
import { displayToast } from "../redux/general/backendActions";
import { toastTypes } from "../constants/generalConstants";
import { SimpleInputField } from "../Common/GenericFormComponents/SimpleInputField";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { Image } from "react-bootstrap";

const useStyles = makeStyles((theme) => ({
  paper: {
    background: "#FAFAFA",
    borderRadius: "18px",
    width: "100%",
    height: "420px",
    marginTop: "-210px",
    maxWidth: "500px",
    position: "absolute",
    top: "50%",
  },
  container: {
    marginBottom: "10px",
    marginTop: "10px",
  },
  input: {
    height: "2em",
    width: "70%",
  },
  typography: {
    whiteSpace: "pre",
  },
}));

function Login(props) {
  const classes = useStyles(props);
  let history = useHistory();

  /** Check the login result and act accordingly
   *
   * @param result
   * @param result.loginState Login result
   * @param [result.userType] User type
   */
  const checkLoginCallResult = (result) => {
    switch (result.loginState) {
      case LoginStateTypes.SUCCESS:
        props.displayToast(toastTypes.SUCCESS, "Login eseguito con successo");
        switch (result.userType) {
          default:
            history.push("/");
            break;
        }
        break;
      case LoginStateTypes.NON_ACTIVE:
        props.displayToast(toastTypes.ERROR, "Account non attivo");
        break;
      case LoginStateTypes.GENERIC_ERROR:
      default:
        props.displayToast(toastTypes.ERROR, "Errore durante il login");
        break;
    }
  };
  return (
    <Paper elevation={3} className={classes.paper}>
      <Formik
        initialValues={{
          username: "",
          password: "",
        }}
        onSubmit={(values, formikHelpers) => {
          props
            .doLogin(values.username, values.password)
            .then(checkLoginCallResult);
          formikHelpers.setSubmitting(false);
        }}
        validationSchema={Yup.object({
          username: Yup.string()
            .strict(true)
            .trim("Non sono ammessi spazi")
            //.email("Inserire una email valida")
            .required("Campo obbligatorio"),
          password: Yup.string().required("Campo obbligatorio"),
        })}
      >
        <Form>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justify="center"
            className={classes.container}
          >
            <Grid item container xs={12} justify="center">
              <Image src={"/Logo.png"} />
            </Grid>
            <Grid item container xs={12} justify="center">
              <h5>Accedi</h5>
            </Grid>
            <Grid item container xs={6} justify="center">
              <Typography>{"Nome utente "}</Typography>
            </Grid>
            <Grid item container xs={6} justify="center">
              <SimpleInputField
                type="text"
                className={classes.input}
                placeholder="Email"
                name="username"
              />
            </Grid>
            <Grid item container xs={6} justify="center">
              <Typography>{"Password "}</Typography>
            </Grid>
            <Grid item container xs={6} justify="center">
              <SimpleInputField
                type="password"
                className={classes.input}
                name="password"
                placeholder="Password"
              />
            </Grid>
            <Grid item container xs={12} justify="center">
              <Button variant="contained" color="primary" type="submit">
                Entra
              </Button>
            </Grid>
            <Grid item container xs={12} justify="center">
              <Typography className={classes.typography}>
                {"Recupera password "}
              </Typography>
              <Link to={authPaths.forgotPassword}>QUI</Link>
            </Grid>
            <Grid item container xs={12} justify="center">
              <Typography className={classes.typography}>
                {"Torna al "}
              </Typography>
              <a href={authPaths.backToSite}>sito di presentazione</a>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </Paper>
  );
}

const mapStateToProps = (state) => {
  return {
    loginTransientState: state.loginTransientState,
  };
};

const mapDispatchToProps = {
  doLogin,
  displayToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
