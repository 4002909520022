import React from "react";
import { useFormikContext } from "formik";
import Spinner from "react-bootstrap/Spinner";
import Button from '@material-ui/core/Button'

export function SubmitButton(props) {
  const formik = useFormikContext();
  return (
    <Button
      className={props.className}
      type="button"
      name="submit"
      variant={props.variant ? props.variant : "contained"}
      color="primary"
      onClick={() => {
        formik.validateForm().then((errors) => {
          if (Object.keys(errors).length === 0) {
            formik.submitForm();
          } else {
            const fieldToTouch = JSON.parse(JSON.stringify(errors));
            setPropertiesValue(fieldToTouch, true);
            formik.setTouched(fieldToTouch, false);
            props.onFormError("I campi obbligatori devono essere compilati correttamente");
          }
        });
      }}
      disabled={props.disabled || props.busy}
    >
      {
        props.busy &&
        (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            className="mr-2"
          />
        )
      }
      {props.label}
    </Button>
  );
}

export const setPropertiesValue = (obj, value) => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      let property = obj[key];
      if (typeof property === "object") {
        setPropertiesValue(property, value);
      } else {
        obj[key] = true;
      }
    }
  }
};