import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { StagingAreaTreeView } from "./StagingAreaTreeView";
import { StagingAreaIndicatoreView } from "./StagingAreaIndicatoreView";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "stretch",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column"
    }
  },
  treeView: {
    minHeight: "100%",
    width: "50%",
    padding: "10px",
    borderRight: "1px solid #C4C4C4",
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      borderRight: "0",
      borderBottom: "1px solid #C4C4C4"
    }
  },
  list: {
    width: "50%",
    padding: "10px",
    [theme.breakpoints.down('sm')]: {
      width: "100%"
    }
  },
}));

export function StagingSection(props) {
  const [selectedIndicatoreId, setSelectedIndicatoreId] = useState("");
  const [selectedAllevamentoId, setSelectedAllevamentoId] = useState("");
  const [stagingArea, setStagingArea] = useState(undefined);
  const classes = useStyles();
  const fetchValoriStagingArea = props.fetchValoriStagingArea;
  useEffect(() => {
    fetchValoriStagingArea(props.lastStagingAreaId);
  }, [fetchValoriStagingArea, props.lastStagingAreaId]);
  useEffect(() => {
    setStagingArea(props.valoriStagingAreaByIDs[props.lastStagingAreaId]);
  }, [props.lastStagingAreaId, props.valoriStagingAreaByIDs]);
  return (
    <div className={`${props.className}`}>
      <h6>Anteprima dati</h6>
      Seleziona un indicatore per visualizzare i suoi valori
      <div className={classes.container}>
        <StagingAreaTreeView
          className={classes.treeView}
          stagingArea={stagingArea}
          onSelectedIndicatoreId={(id) => {
            props.fetchSingleIndicatore(id).then(() => {
              setSelectedIndicatoreId(id);
            });
          }}
          onSelectedAllevamentoId={(id) => {
            setSelectedAllevamentoId(id);
          }}
        />
        <StagingAreaIndicatoreView
          className={classes.list}
          selectedIndicatoreId={selectedIndicatoreId}
          selectedAllevamentoId={selectedAllevamentoId}
          stagingArea={stagingArea}
          editStagedValoreFunc={props.editStagedValoreFunc}
          stagedValoriByIDs={props.stagedValoriByIDs}
          fetchStagedValore={props.fetchStagedValore}
        />
      </div>
    </div>
  );
}
