import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { fetchAllIndicatore } from '../../redux/indicatori/backendActions'
import { createTheme, MuiThemeProvider } from '@material-ui/core'
import MUIDataTable from 'mui-datatables'
import { makeStyles } from '@material-ui/core/styles'
import CheckIcon from '@material-ui/icons/Check'
import { textLabels } from '../../constants/miscConstants'
import { useLocation } from 'react-router-dom'
import {
  frequencyTypesSelectValues,
  getThresholdLabel,
} from '../../constants/indicatoriConstants'
import { valuesStatuses } from '../../constants/valoriIndicatoriConstants'
import { defaultRowOptions } from '../../constants/generalConstants'
import { setMainTableRows } from '../../redux/general/basicActions'

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: "100%",
    maxHeight: "100%",
    overflow: "hidden"
  },
  mainContent: {
    marginBottom: "0",
    overflowY: "auto",
    maxHeight: "100%"
  },
}));

const getMuiTheme = () =>
  createTheme({
    overrides: {
      MuiToolbar: {
        root: {
          backgroundColor: "#FEFDFD",
        },
      },
      MuiTable: {
        root: {
          backgroundColor: "#FEFDFD",
        },
      },
      MuiTableCell: {
        root: {
          height: "30px",
          padding: "0px 4px 0px 4px"
        },
      },
      MUIDataTableHeadCell: {
        fixedHeader: {
          backgroundColor: "#C4C4C4",
        },
      },
    },
  });

function AnagraficaIndicatoriPage(props) {
  const location = useLocation();
  const [data, setData] = useState([]);
  const classes = useStyles();
  const setTitle = props.setTitle;
  const fetchAllIndicatore = props.fetchAllIndicatore;
  const dispatch = useDispatch();
  const mainTableRows = useSelector(
    (state) => state.appPreferences.mainWindowTableRows
  );
  useEffect(() => {
    setTitle("Visualizza tutti gli indicatori")
  }, [setTitle]);
  useEffect(() => {
    fetchAllIndicatore();
  }, [location, fetchAllIndicatore]);
  const columns = [
    {
      name: "id",
      options: {
        display: "excluded",
      },
    },
    { name: "code", label: "Cod." },
    { name: "name", label: "Nome" },
    { name: "boxFlat", label: "Box" },
    { name: "enteFornitore", label: "Fonte"},
    { name: "measureUnit", label: "Unità di misura" },
    { name: "frequency", label: "Frequenza" },
    { name: "redThresholdFlat", label: "Valore soglia ROSSO" },
    { name: "yellowThresholdFlat", label: "Valore soglia GIALLO" },
    { name: "greenThresholdFlat", label: "Valore soglia VERDE" },
    {
      name: "critical",
      label: "Critico",
      options: {
        customBodyRenderLite: (dataIndex) => {
          if (data[dataIndex].critical === true) {
            return <CheckIcon />;
          } else return null;
        },
      },
    },
  ];
  const options = {
    elevation: 0,
    selectableRows: "none",
    filter: false,
    print: false,
    download: false,
    search: false,
    viewColumns: false,
    textLabels: textLabels,
    rowsPerPageOptions: defaultRowOptions,
    rowsPerPage: mainTableRows,
    onChangeRowsPerPage: (rows) => dispatch(setMainTableRows(rows)),
  };
  useEffect(() => {
    let newData = Object.keys(props.indicatori.indicatoriByIDs).map((id) => {
      return {
        id: id,
        code: props.indicatori.indicatoriByIDs[id].code,
        name: props.indicatori.indicatoriByIDs[id].name,
        boxFlat: props.indicatori.indicatoriByIDs[id].box.name,
        enteFornitore: props.indicatori.indicatoriByIDs[id].enteFornitore.denominazione,
        measureUnit: props.indicatori.indicatoriByIDs[id].measureUnit,
        frequency:
          frequencyTypesSelectValues[
            props.indicatori.indicatoriByIDs[id].frequency
            ],
        redThresholdFlat: `${getThresholdLabel(
          props.indicatori.indicatoriByIDs[id],
          valuesStatuses.RED
        )}`,
        yellowThresholdFlat: `${getThresholdLabel(
          props.indicatori.indicatoriByIDs[id],
          valuesStatuses.YELLOW
        )}`,
        greenThresholdFlat: `${getThresholdLabel(
          props.indicatori.indicatoriByIDs[id],
          valuesStatuses.GREEN
        )}`,
        critical: props.indicatori.indicatoriByIDs[id].critical
      };
    });
    setData(newData);
  }, [props.indicatori]);
  return (
    <>
    {
      props.aziendaMode ? (
        <div>
            <h6>Indicatori</h6>
            <MuiThemeProvider theme={getMuiTheme()}>
              <MUIDataTable data={data} columns={columns} options={options} />
            </MuiThemeProvider>
          </div>
      ) : (
        <div className={classes.root}>
          <div className={classes.mainContent}>
            <h6>Indicatori</h6>
            <MuiThemeProvider theme={getMuiTheme()}>
              <MUIDataTable data={data} columns={columns} options={options} />
            </MuiThemeProvider>
          </div>
        </div>
      )
    }
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    indicatori: state.indicatori,
  };
};

const mapDispatchToProps = {
  fetchAllIndicatore
};

export default connect(mapStateToProps, mapDispatchToProps)(AnagraficaIndicatoriPage);