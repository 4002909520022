import React, { useEffect, useState } from 'react'
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error'

export function FileErrorSection (props) {
  const [errorOverview, setErrorOverview] = useState("");
  const [errorDetails, setErrorDetails] = useState(null);
  useEffect(() => {
    if (!props.errors){
      setErrorOverview("Nessun errore presente");
      setErrorDetails(null);
      return;
    }
    switch (props.errors.status) {
      case 400:
        switch (props.errors.message){
          case "Valori caricati non validi":
            setErrorOverview("Sono presenti nel file record non validi:");
            setErrorDetails((
              <List>
                {
                  props.errors.invalidItems.map(item => (
                    <ListItem key={item.recordNumber}>
                      <ListItemIcon>
                        <ErrorIcon />
                      </ListItemIcon>
                      <ListItemText>
                        {`Record ${item.recordNumber}: ${item.errorMessage}`}
                      </ListItemText>
                    </ListItem>
                  ))
                }
              </List>
            ));
            break;
          default:
            setErrorOverview(props.errors.message);
            setErrorDetails(null);
            break;
        }
        break;
      case 417:
        setErrorOverview("File selezionato troppo grande");
        setErrorDetails(null);
        break;
      case 500:
        setErrorOverview("Errore durante la lettura del file");
        setErrorDetails(null);
        break;
      default:
        setErrorOverview("Errore durante l'invio del file");
        setErrorDetails(null);
        break;
    }
  }, [props.errors]);
  return (
    <div className={props.className}>
      <h6>
        Errori
      </h6>
      <Typography>{errorOverview}</Typography>
      {errorDetails}
      {
        props.errors && (
          <Typography>
            Se necessario, ripetere la selezione ed il caricamento del file
          </Typography>
        )
      }
    </div>
  );
}