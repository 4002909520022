import { useHistory } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import React from 'react'
import Button from '@material-ui/core/Button'

export function ForgotPasswordModalWindow(props) {
  return (
    <Modal show={props.show} centered backdrop="static">
      <Modal.Header>
        <Modal.Title>Effettuata richiesta modifica password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Se presente, è stata inviata una email a <b>{props.email}</b> con le istruzioni ed il link per cambio della password
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button color="primary" onClick={props.onClose}>
          Conferma
        </Button>
      </Modal.Footer>
    </Modal>
  );
}