import React, { useCallback, useEffect, useState } from "react";
import {
  createTheme,
  MuiThemeProvider,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useFormikContext } from "formik";
import { textLabels } from "../../constants/miscConstants";
import { IndicatoriDipendentiRow } from "./IndicatoriDipendentiRow";
import { connect } from "react-redux";
import MUIDataTable from "mui-datatables";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import {
  Indicatore,
  TipoIndicatoreDipendentePriority,
} from "../../constants/indicatoriConstants";
import IndicatoriDipendentiRowEditable from "./IndicatoriDipendentiRowEditable";
import { v4 as uuidv4 } from "uuid";

const getMuiTheme = () =>
  createTheme({
    overrides: {
      MuiToolbar: {
        root: {
          backgroundColor: "#FEFDFD",
        },
      },
      MuiTable: {
        root: {
          backgroundColor: "#FEFDFD",
        },
      },
      MuiTableCell: {
        root: {
          height: "30px",
        },
      },
      MUIDataTableHeadCell: {
        fixedHeader: {
          backgroundColor: "#C4C4C4",
        },
      },
    },
  });

const useStyles = makeStyles(() => ({
  rowInput: {
    boxSizing: "border-box",
    height: "100%",
    width: "100%",
  },
}));

function IndicatoriDipendentiField(props) {
  const classes = useStyles(props);
  const formik = useFormikContext();
  const [data, setData] = useState([]);
  const [options, setOptions] = useState({});
  const [columns, setColumns] = useState([]);
  const [indDipFieldValue, setIndDipFieldValue] = useState([]);
  const [disableAddInd, setDisableAddInd] = useState(false);
  const setFieldValue = formik.setFieldValue;
  const mapFieldToTableData = useCallback(
    (indDipendente) => {
      let indicatore;
      let tempId;
      if (
        !indDipendente.tipoIndicatoreDipendente.id ||
        !props.indicatori.indicatoriByIDs[
          indDipendente.tipoIndicatoreDipendente.id
        ]
      ) {
        indicatore = new Indicatore();
      } else {
        indicatore =
          props.indicatori.indicatoriByIDs[
            indDipendente.tipoIndicatoreDipendente.id
          ];
      }
      if (!indDipendente.id) {
        tempId = uuidv4();
      } else {
        tempId = indDipendente.id;
      }
      return {
        tempId: tempId,
        indicatoreIdFlat: indicatore.id,
        codeFlat: indicatore.code,
        nameFlat: indicatore.name,
        boxFlat: indicatore.box.name,
        priorityRating: indDipendente.priorityRating,
      };
    },
    [props.indicatori]
  );
  const mapTableDataToField = useCallback(
    (indDipendenteRow) => {
      let indicatore;
      if (indDipendenteRow.indicatoreIdFlat !== "") {
        indicatore =
          props.indicatori.indicatoriByIDs[indDipendenteRow.indicatoreIdFlat];
      } else {
        indicatore = new Indicatore();
      }
      return {
        id: indDipendenteRow.id,
        priorityRating: indDipendenteRow.priorityRating,
        tipoIndicatoreDipendente: indicatore,
      };
    },
    [props.indicatori]
  );
  useEffect(() => {
    if (props.editMode) {
      const newColumns = [
        {
          name: "tempId",
          options: {
            display: "excluded",
          },
        },
        {
          name: "indicatoreIdFlat",
          options: {
            display: "excluded",
          },
        },
        { name: "codeFlat", label: "Cod." },
        { name: "nameFlat", label: "Nome" },
        { name: "boxFlat", label: "Box" },
        { name: "priorityRating", label: "Priorità" },
        {
          name: "actions",
          label: "Azioni",
        },
      ];
      setColumns(newColumns);
      setOptions({
        elevation: 0,
        selectableRows: "none",
        filter: false,
        print: false,
        download: false,
        search: false,
        viewColumns: false,
        pagination: false,
        textLabels: {
          ...textLabels,
          body: {
            ...textLabels.body,
            noMatch: "Nessuna indicatore dipendente impostato",
          },
        },
        rowsPerPageOptions: [10, 20, 50, 100],
        setTableProps: () => {
          return {
            padding: "none",
          };
        },
        customRowRender: (row, index) => {
          return (
            <IndicatoriDipendentiRowEditable
              inputClassName={classes.rowInput}
              key={row[0]}
              rowData={row}
              onRowChange={(values) => {
                let newData = JSON.parse(JSON.stringify(data));
                newData[index] = values;
                let newField = newData.map(mapTableDataToField);
                setFieldValue(props.name, newField);
              }}
              onDelete={() => {
                let newData = JSON.parse(JSON.stringify(data));
                newData.splice(index, 1);
                let newField = newData.map(mapTableDataToField);
                setFieldValue(props.name, newField);
              }}
              onReset={() => {
                let newData = indDipFieldValue.map(mapFieldToTableData);
                setData(newData);
              }}
            />
          );
        },
      });
    } else {
      const newColumns = [
        {
          name: "tempId",
          options: {
            display: "excluded",
          },
        },
        {
          name: "indicatoreIdFlat",
          options: {
            display: "excluded",
          },
        },
        { name: "codeFlat", label: "Cod." },
        { name: "nameFlat", label: "Nome" },
        { name: "boxFlat", label: "Box" },
        { name: "priorityRating", label: "Priorità" },
      ];
      setColumns(newColumns);
      setOptions({
        elevation: 0,
        selectableRows: "none",
        filter: false,
        print: false,
        download: false,
        search: false,
        viewColumns: false,
        pagination: false,
        textLabels: {
          ...textLabels,
          body: {
            ...textLabels.body,
            noMatch: "Nessuna indicatore dipendente impostato",
          },
        },
        rowsPerPageOptions: [10, 20, 50, 100],
        setTableProps: () => {
          return {
            padding: "none",
          };
        },
        customRowRender: (row) => {
          return <IndicatoriDipendentiRow key={row[0]} rowData={row} />;
        },
      });
    }
  }, [
    props.editMode,
    indDipFieldValue,
    setFieldValue,
    props.name,
    data,
    classes,
    mapFieldToTableData,
    mapTableDataToField,
  ]);
  useEffect(() => {
    let newFieldValue = props.name
      .split(".")
      .reduce((o, i) => o[i], formik.values);
    setIndDipFieldValue(newFieldValue);
  }, [props.name, formik.values]);
  useEffect(() => {
    if (indDipFieldValue) {
      let newData = JSON.parse(JSON.stringify(indDipFieldValue));
      let fixedNewData = newData.map(mapFieldToTableData);
      setData(fixedNewData);
    }
  }, [indDipFieldValue, mapFieldToTableData]);
  useEffect(() => {
    if (
      indDipFieldValue.find(
        (indDip) => indDip.tipoIndicatoreDipendente.id === ""
      )
    ) {
      setDisableAddInd(true);
    } else {
      setDisableAddInd(false);
    }
  }, [indDipFieldValue]);
  return (
    <>
      <Typography>Indicatori dipendenti</Typography>
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable data={data} columns={columns} options={options} />
      </MuiThemeProvider>
      {props.editMode ? (
        <Button
          variant="contained"
          color="primary"
          type="button"
          disabled={disableAddInd}
          onClick={() => {
            let newFieldValue = JSON.parse(JSON.stringify(indDipFieldValue));
            let newIndDip = new TipoIndicatoreDipendentePriority();
            newFieldValue.push(newIndDip);
            setFieldValue(props.name, newFieldValue);
          }}
        >
          <AddIcon />
          Aggiungi indicatore dipendente
        </Button>
      ) : null}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    indicatori: state.indicatori,
  };
};

export default connect(mapStateToProps, null)(IndicatoriDipendentiField);
