import { LoginStateTypes } from "../../constants/authConstants";
import {
  FAILED_REFRESH_TOKENS,
  LOGGED_IN,
  LOGGED_OUT,
  LOGGING_IN,
  LOGIN_FAILED,
  RECEIVE_CURRENT_USER,
  RECEIVE_FRESH_TOKENS,
  RECEIVE_PROFILE,
  REFRESHING_TOKENS,
  SET_IS_WAITING,
  SET_SELECTED_ALLEVAMENTO,
} from "./basicActions";

export function loginTransientState(
  state = {
    loggingIn: false,
    refreshTokenPromise: undefined,
    lastLoginStateType: LoginStateTypes.SUCCESS,
    lastLoginMessage: "",
    profile: undefined,
    currentUserAllevamentiByIds: {},
    selectedAllevamentoId: undefined,
    currentUser: undefined,
    isWaiting: false,
  },
  action
) {
  switch (action.type) {
    case LOGGING_IN:
      return Object.assign({}, state, {
        loggingIn: true,
      });
    case REFRESHING_TOKENS:
      return Object.assign({}, state, {
        refreshTokenPromise: action.refreshPromise,
      });
    case RECEIVE_FRESH_TOKENS:
    case FAILED_REFRESH_TOKENS:
      return Object.assign({}, state, {
        refreshTokenPromise: undefined,
      });
    case LOGGED_IN:
      return Object.assign({}, state, {
        loggingIn: false,
        lastLoginStateType: LoginStateTypes.SUCCESS,
      });
    case LOGGED_OUT:
      return {
        loggingIn: false,
        refreshTokenPromise: undefined,
        lastLoginStateType: LoginStateTypes.SUCCESS,
        lastLoginMessage: "",
        profile: undefined,
        currentUserAllevamentiByIds: {},
        selectedAllevamentoId: undefined,
        currentUser: undefined,
      };
    case LOGIN_FAILED:
      return Object.assign({}, state, {
        loggingIn: false,
        lastLoginStateType: action.loginStateType,
        lastLoginMessage: action.message,
      });
    case RECEIVE_PROFILE:
      let newAllevamenti = {};
      if (action.profile.allevamenti) {
        newAllevamenti = action.profile.allevamenti.reduce(
          (obj, item) => Object.assign(obj, { [item.id]: item }),
          {}
        );
      }
      return Object.assign({}, state, {
        ...state,
        profile: action.profile,
        currentUserAllevamentiByIds: newAllevamenti,
      });
    case RECEIVE_CURRENT_USER:
      return Object.assign({}, state, {
        ...state,
        user: action.user,
      });
    case SET_SELECTED_ALLEVAMENTO:
      return Object.assign({}, state, {
        ...state,
        selectedAllevamentoId: action.idAllevamento,
      });
    case SET_IS_WAITING:
      return Object.assign({}, state, {
        ...state,
        isWaiting: action.waiting,
      });
    default:
      return state;
  }
}

export function loginData(
  state = {
    isAuthenticated: false,
    user: undefined,
    accessToken: undefined,
    accessTokenExpiration: undefined,
    refreshToken: undefined,
    refreshTokenExpiration: undefined,
    userType: undefined,
  },
  action
) {
  switch (action.type) {
    case LOGGED_IN:
      return {
        isAuthenticated: true,
        user: action.username,
        accessToken: action.accessToken,
        accessTokenExpiration: action.accessTokenExpiration,
        refreshToken: action.refreshToken,
        refreshTokenExpiration: action.refreshTokenExpiration,
        userType: action.userType,
      };
    case LOGGED_OUT:
      return {
        isAuthenticated: false,
        user: undefined,
        accessToken: undefined,
        accessTokenExpiration: undefined,
        refreshToken: undefined,
        refreshTokenExpiration: undefined,
        userType: undefined,
      };
    case RECEIVE_FRESH_TOKENS:
      return {
        ...state,
        isAuthenticated: true,
        accessToken: action.accessToken,
        accessTokenExpiration: action.accessTokenExpiration,
        refreshToken: action.refreshToken,
        refreshTokenExpiration: action.refreshTokenExpiration,
        userType: action.userType,
      };
    default:
      return state;
  }
}
