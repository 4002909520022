import React from 'react';
import { valuesStatuses } from '../constants/valoriIndicatoriConstants'

export function StatusValoreVisualizer(props) {
  switch (props.status){
    case valuesStatuses.RED:
      return (
        <svg width={"52"} height={"21"} viewBox={"0 0 52 21"} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
          <rect x="0.5" y="0.5" width="51" height="20" fill="black" stroke="#BDBDBD"/>
          <ellipse cx="10" cy="10.5" rx="7" ry="7.5" fill="#C4C4C4"/>
          <ellipse cx="26" cy="10.5" rx="7" ry="7.5" fill="#C4C4C4"/>
          <ellipse cx="43" cy="10.5" rx="7" ry="7.5" fill="#FF0000"/>
        </svg>
      );
    case valuesStatuses.YELLOW:
      return (
        <svg width={"52"} height={"21"} viewBox={"0 0 52 21"} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
          <rect x="0.5" y="0.5" width="51" height="20" fill="black" stroke="#BDBDBD"/>
          <ellipse cx="10" cy="10.5" rx="7" ry="7.5" fill="#C4C4C4"/>
          <ellipse cx="26" cy="10.5" rx="7" ry="7.5" fill="#FFD600"/>
          <ellipse cx="43" cy="10.5" rx="7" ry="7.5" fill="#C4C4C4"/>
        </svg>
      );
    case valuesStatuses.GREEN:
      return (
        <svg width="52" height="21" viewBox="0 0 52 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
          <rect x="0.5" y="0.5" width="51" height="20" fill="black" stroke="#BDBDBD"/>
          <ellipse cx="10" cy="10.5" rx="7" ry="7.5" fill="#09CA3F"/>
          <ellipse cx="26" cy="10.5" rx="7" ry="7.5" fill="#C4C4C4"/>
          <ellipse cx="43" cy="10.5" rx="7" ry="7.5" fill="#C4C4C4"/>
        </svg>
      );
    default:
      return null;
  }
}