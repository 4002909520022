import {
  RECEIVE_ALL_INDICATORE,
  RECEIVE_SINGLE_INDICATORE,
} from "./basicActions";
import { LOGGED_OUT } from '../auth/basicActions'

export function indicatori(
  state = {
    indicatoriByIDs: {},
  },
  action
) {
  switch (action.type) {
    case RECEIVE_ALL_INDICATORE:
      let newIndicatori = action.indicatori.reduce(
        (obj, item) => Object.assign(obj, { [item.id]: item }),
        {}
      );
      return {
        ...state,
        indicatoriByIDs: newIndicatori,
      };
    case RECEIVE_SINGLE_INDICATORE:
      return {
        ...state,
        indicatoriByIDs: {
          ...state.indicatoriByIDs,
          [action.indicatore.id]: action.indicatore,
        },
      };
    case LOGGED_OUT:
      return {
        indicatoriByIDs: {},
      }
    default:
      return state;
  }
}
