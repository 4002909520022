export const enteControlloPaths = Object.freeze({
  anagraficaIndicatori: "/indicatori",
  anagraficaAziende: "/aziende",
  valoriAggregati: "/aggregati",
  impostazioniUtente: "/impostazioni",
});

export const enteControlloSidebarData = [
  {
    sidebarListLink: enteControlloPaths.anagraficaIndicatori,
    sidebarListText: "Anagrafica indicatori",
  },
  {
    sidebarListLink: enteControlloPaths.valoriAggregati,
    sidebarListText: "Visualizza valori aggregati",
  },
  {
    sidebarListLink: enteControlloPaths.anagraficaAziende,
    sidebarListText: "Visualizza anagrafiche aziende",
  },
  {
    sidebarListLink: enteControlloPaths.impostazioniUtente,
    sidebarListText: "Impostazioni utente",
  },
];