import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  changeUserEnabledStatus,
  deleteSingleUser,
} from "../../redux/admin/backendActions";
import { UserRolesLabels } from "../../constants/adminConstants";
import { ResetForm } from "../../Common/GenericFormComponents/ResetForm";
import { Formik, Form } from "formik";
import Grid from "@material-ui/core/Grid";
import { SimpleSelectField } from "../../Common/GenericFormComponents/SimpleSelectField";
import {
  booleanRadioLabels,
  booleanRadioValues,
} from "../../constants/miscConstants";
import { CustomRadioSelectorField } from "../../Common/GenericFormComponents/CustomRadioSelectorField";
import { SubmitButton } from "../../Common/GenericFormComponents/SubmitButton";
import { toastTypes } from "../../constants/generalConstants";
import { ScrollToError } from "../../Common/GenericFormComponents/ScrollToError";
import { Button } from "@material-ui/core";
import { UserDeleteConfirmDialog } from "./UserDeleteConfirmDialog";

const useStyles = makeStyles(() => ({
  element: {
    marginBottom: "35px",
  },
  input: {
    boxSizing: "border-box",
    height: "2em",
    width: "150px",
  },
  button: {
    marginLeft: "8px",
    marginRight: "8px"
  }
}));

export function AdminUserFieldsSection(props) {
  const classes = useStyles();
  const [scrollToError, setScrollToError] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showedValues, setShowedValues] = useState({
    roleName: "",
    enabled: "",
  });
  const dispatch = useDispatch();
  const admin = useSelector((state) => state.admin);
  useEffect(() => {
    if (admin.usersByIds[props.userId]) {
      setShowedValues({
        roleName: admin.usersByIds[props.userId].roleName,
        enabled: admin.usersByIds[props.userId].enabled,
      });
    }
  }, [admin, props.userId]);
  return (
    <div className={props.className}>
      <Formik
        initialValues={showedValues}
        onSubmit={(values, formikHelpers) => {
          dispatch(changeUserEnabledStatus(props.userId, values.enabled));
          formikHelpers.setSubmitting(false);
        }}
        enableReinitialize
      >
        <Form>
          <ResetForm showedValues={showedValues} />
          <h6>Informazioni utente</h6>
          <Grid container alignItems="center" justify="center">
            <Grid className={classes.element} item container md={3} xs={6}>
              Tipo utente
            </Grid>
            <Grid className={classes.element} item container md={3} xs={6}>
              <SimpleSelectField
                name={"roleName"}
                id="roleName"
                className={classes.input}
                options={UserRolesLabels}
                disabled
              />
            </Grid>
            <Grid className={classes.element} item container md={3} xs={6}>
              Abilitato
            </Grid>
            <Grid className={classes.element} item container md={3} xs={6}>
              <CustomRadioSelectorField
                id={"enabled"}
                optionsValues={booleanRadioValues}
                optionsLabels={booleanRadioLabels}
                field={"enabled"}
              />
            </Grid>
            <Grid
              className={`${classes.element}`}
              item
              container
              justify={"center"}
              xs={12}
            >
              <SubmitButton
                className={classes.button}
                onFormError={(error) => {
                  props.displayToast(toastTypes.ERROR, error);
                  setScrollToError(!scrollToError);
                }}
                label={"Applica modifiche"}
              />
              <Button
                className={classes.button}
                onClick={() => {
                  setShowDeleteDialog(true);
                }}
                variant="contained"
                color="primary"
              >
                Elimina utente
              </Button>
            </Grid>
            <UserDeleteConfirmDialog
              open={showDeleteDialog}
              onClose={() => setShowDeleteDialog(false)}
              onConfirm={() => {
                dispatch(deleteSingleUser(props.userId));
                setShowDeleteDialog(false);
                props.onDelete();
              }}
            />
            <ScrollToError scroll={scrollToError} />
          </Grid>
        </Form>
      </Formik>
    </div>
  );
}
