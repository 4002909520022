import React, { useEffect } from "react";
import { connect } from "react-redux";
import { doLogout } from "../redux/auth/backendActions";
import { displayToast } from "../redux/general/backendActions";
import { toastTypes } from "../constants/generalConstants";

function Logout(props) {
  useEffect(() => {
    props
      .doLogout()
      .then(() =>
        props.displayToast(toastTypes.INFO, "Logout eseguito correttamente")
      );
  });
  return null;
}

const mapDispatchToProps = {
  doLogout,
  displayToast,
};

export default connect(null, mapDispatchToProps)(Logout);
