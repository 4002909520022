import React, { useRef, useState } from "react";
import { Field } from "formik";
import { ErrorTooltip } from "./ErrorTooltip";

export function SimpleFileInputField(props) {
  const childProps = { ...props };
  const tooltipTarget = useRef(null);
  delete childProps.errorsDisabled;
  return (
    <Field name={props.name}>
      {({ field, meta, form }) => (
        <>
          <input
            name={props.name}
            type={"file"}
            {...childProps}
            onChange={(event) => {
                form.setFieldValue(props.name, event.currentTarget.files[0]);
                if (props.onChange) {
                    props.onChange(event);
                }
            }}
          />
          <ErrorTooltip
            target={tooltipTarget}
            show={Boolean(
              meta.touched && meta.error && props.errorsDisabled === false
            )}
            msg={meta.error}
          />
        </>
      )}
    </Field>
  );
}
