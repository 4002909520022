import React from "react";
import { SimpleInputField } from "../../Common/GenericFormComponents/SimpleInputField";
import {
  getThresholdArgFieldString,
  thresholdOperatorsTypes,
} from '../../constants/indicatoriConstants'
import { useFormikContext } from "formik";
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  inputGroup : {
    display: "flex",
    height: "30px",
    maxWidth: "200px",
    flexWrap: "nowrap",
    justifyContent: "space-evenly",
    alignItems: "baseline",
    boxSizing: "border-box",
  },
  inputArg : {
    width: "calc(50% - 5px)",
    height: "30px",
    boxSizing: "border-box",
  }
}));

export function ThresholdArgsField(props) {
  const classes = useStyles();
  const formik = useFormikContext();
  const thresholdOperator = formik.values["thresholdOperator"];
  if (props.editMode) {
    if (
      thresholdOperator === thresholdOperatorsTypes.OPEN_INTERVAL ||
      thresholdOperator === thresholdOperatorsTypes.RIGHT_CLOSE_INTERVAL ||
      thresholdOperator === thresholdOperatorsTypes.LEFT_CLOSE_INTERVAL ||
      thresholdOperator === thresholdOperatorsTypes.CLOSE_INTERVAL
    ) {
      return (
        <div className={classes.inputGroup}>
          <SimpleInputField className={classes.inputArg} name={"arg1"} />
          <Typography>{" - "}</Typography>
          <SimpleInputField className={classes.inputArg} name={"arg2"} />
        </div>
      );
    } else
      return (
          <SimpleInputField className={classes.inputGroup} name={"arg1"} />
      );
  } else {
    return getThresholdArgFieldString(props.rowData[3], props.rowData[1], props.rowData[2]);
  }
}
