import React, { useEffect, useState } from "react";
import { BoxRow, BoxRowSchema } from "../../constants/adminConstants";
import { Form, Formik } from "formik";
import { IconButton, TableCell, TableRow } from "@material-ui/core";
import { ResetForm } from "../../Common/GenericFormComponents/ResetForm";
import { SimpleSelectField } from "../../Common/GenericFormComponents/SimpleSelectField";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckIcon from "@material-ui/icons/Check";
import CancelIcon from "@material-ui/icons/Cancel";
import { SimpleInputField } from "../../Common/GenericFormComponents/SimpleInputField";
import { booleanRadioLabels } from "../../constants/miscConstants";

export function BoxRowEditable(props) {
  const [editMode, setEditMode] = useState(false);
  const [data, setData] = useState(new BoxRow());
  useEffect(() => {
    let boxRow = new BoxRow();
    boxRow.id = props.rowData[0];
    boxRow.name = props.rowData[1];
    boxRow.totIndicatori = props.rowData[2];
    boxRow.enabled = props.rowData[3];
    setData(boxRow);
  }, [props.rowData]);
  return (
    <Formik
      key={props.rowData[0]}
      initialValues={data}
      onSubmit={(values) => {
        props.onRowChange(values);
        setEditMode(false);
      }}
      validationSchema={BoxRowSchema}
      enableReinitialize
    >
      {(subFormik) => (
        <TableRow key={props.rowData[0]}>
          {editMode ? <Form id={"form-" + props.rowData[0]} /> : null}
          <TableCell>
            <ResetForm showedValues={data} />
            {editMode ? (
              <SimpleInputField name="name" type="text" />
            ) : (
              props.rowData[1]
            )}
          </TableCell>
          <TableCell>{props.rowData[2]}</TableCell>
          <TableCell>
            {editMode ? (
              <SimpleSelectField
                name={"enabled"}
                options={booleanRadioLabels}
              />
            ) : (
              <>{props.rowData[3] && <CheckIcon />}</>
            )}
          </TableCell>
          <TableCell>
            {!editMode ? (
              <>
                <IconButton
                  key={"edit-" + props.rowData[0]}
                  type={"button"}
                  onClick={() => setEditMode(true)}
                >
                  <EditIcon />
                </IconButton>
                {props.rowData[2] === 0 && (
                  <IconButton
                    key={"delete-" + props.rowData[0]}
                    type={"button"}
                    onClick={() => {
                      props.onDelete();
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </>
            ) : (
              <>
                <IconButton
                  key={"save-" + props.rowData[0]}
                  type={"button"}
                  form={"form-" + props.rowData[0]}
                  onClick={() => {
                    subFormik.submitForm();
                  }}
                >
                  <CheckIcon />
                </IconButton>
                <IconButton
                  key={"cancel-" + props.rowData[0]}
                  type={"button"}
                  onClick={() => {
                    setEditMode(false);
                    props.onReset();
                  }}
                >
                  <CancelIcon />
                </IconButton>
              </>
            )}
          </TableCell>
        </TableRow>
      )}
    </Formik>
  );
}
