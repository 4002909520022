import {
  RECEIVE_ALL_ENTE_FORNITORE,
  RECEIVE_SINGLE_ENTE_FORNITORE,
} from './basicActions'
import { LOGGED_OUT } from '../auth/basicActions'

export function enteFornitore(
  state = {
    enteFornitoreByIDs: {},
  },
  action
) {
  switch (action.type) {
    case RECEIVE_ALL_ENTE_FORNITORE:
      let newEnti = action.enti.reduce(
        (obj, item) => Object.assign(obj, { [item.id]: item }),
        {}
      );
      return {
        ...state,
        enteFornitoreByIDs: newEnti,
      };
    case RECEIVE_SINGLE_ENTE_FORNITORE:
      return {
        ...state,
        enteFornitoreByIDs: {
          ...state.enteFornitoreByIDs,
          [action.ente.id]: action.ente,
        },
      };
    case LOGGED_OUT:
      return {
        enteFornitoreByIDs: {},
      }
    default:
      return state;
  }
}
