import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from 'react-redux'
import { makeStyles } from "@material-ui/core/styles";
import { fetchSingleBox } from "../../redux/box/backendActions";
import { SingleBoxOverviewSection } from "./SingleBoxOverviewSection";
import {
  getModifiedOnLabel,
  getPeriodLabel,
  getValueLabel,
  getValueState,
  valorePeriodSortCompare,
  valoreStateSortCompare,
  valoreValueSortCompare,
  valuesStatuses,
} from '../../constants/valoriIndicatoriConstants'
import { fetchAllLastValoreOfIndicatoreAsAzienda } from "../../redux/valoriindicatori/backendActions";
import { createTheme, MuiThemeProvider } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { textLabels } from "../../constants/miscConstants";
import CheckIcon from "@material-ui/icons/Check";
import { useHistory } from "react-router-dom";
import { aziendaPaths } from "../../constants/aziendaConstants";
import { frequencyTypesSelectValues } from "../../constants/indicatoriConstants";
import { StatusValoreVisualizer } from "../../Common/StatusValoreVisualizer";
import { fetchAllAlerts } from "../../redux/aziende/backendActions";
import { defaultRowOptions } from '../../constants/generalConstants'
import { setMainTableRows } from '../../redux/general/basicActions'

const useStyles = makeStyles(() => ({
  overview: {
    marginBottom: "15px",
  },
  alertRowText: {
    color: "red",
  },
  alertText: {
    color: "white",
    backgroundColor: "red",
    marginLeft: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
    fontWeight: "bold",
  },
  alertValueField: {
    display: "flex",
    flexDirection: "row",
  },
  textOnRight: {
    textAlign: "right"
  }
}));

const getMuiTheme = () =>
  createTheme({
    overrides: {
      MuiToolbar: {
        root: {
          backgroundColor: "#FEFDFD",
        },
      },
      MuiTable: {
        root: {
          backgroundColor: "#FEFDFD",
        },
      },
      MuiTableCell: {
        root: {
          height: "30px",
        },
      },
      MUIDataTableBodyRow: {
        root: {
          cursor: "pointer",
        },
      },
      MUIDataTableHeadCell: {
        fixedHeader: {
          backgroundColor: "#C4C4C4",
        },
      },
    },
  });

function SingleBoxPage(props) {
  const history = useHistory();
  const classes = useStyles();
  const setTitle = props.setTitle;
  const fetchSingleBox = props.fetchSingleBox;
  const fetchAllLastValoreOfIndicatoreAsAzienda =
    props.fetchAllLastValoreOfIndicatoreAsAzienda;
  const [lastUpdate, setLastUpdate] = useState(new Date("1900-01-01"));
  const [greenStatuses, setGreenStatuses] = useState(0);
  const [yellowStatuses, setYellowStatuses] = useState(0);
  const [redStatuses, setRedStatuses] = useState(0);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const mainTableRows = useSelector(
    (state) => state.appPreferences.mainWindowTableRows
  );
  const selectedAllevamentoId = useSelector(
    (state) => state.loginTransientState.selectedAllevamentoId
  );
  useEffect(() => {
    fetchAllLastValoreOfIndicatoreAsAzienda();
  }, [fetchAllLastValoreOfIndicatoreAsAzienda, selectedAllevamentoId]);
  useEffect(() => {
    let greens = 0;
    let yellows = 0;
    let reds = 0;
    let box = props.box.boxByIds[props.match.params.id];
    if (!box) {
      return;
    }
    for (let i = 0; i < box.indicatori.length; i++) {
      let indicatore = box.indicatori[i];
      if (props.valori.lastValoreByTipoIndicatoreId[indicatore.id]) {
        let valore =
          props.valori.valoriByIDs[
            props.valori.lastValoreByTipoIndicatoreId[indicatore.id]
          ];
        let newLast = new Date(valore.modifiedOn);
        if (newLast > lastUpdate) {
          setLastUpdate(newLast);
        }
        let state = getValueState(valore, box.indicatori[i]);
        switch (state) {
          case valuesStatuses.GREEN:
            greens = greens + 1;
            break;
          case valuesStatuses.YELLOW:
            yellows = yellows + 1;
            break;
          case valuesStatuses.RED:
            reds = reds + 1;
            break;
          default:
            break;
        }
      }
    }
    setGreenStatuses(greens);
    setRedStatuses(reds);
    setYellowStatuses(yellows);
  }, [
    props.box,
    props.valori,
    lastUpdate,
    setLastUpdate,
    props.match.params.id,
  ]);
  useEffect(() => {
    fetchSingleBox(props.match.params.id);
  }, [props.match.params.id, fetchSingleBox]);
  useEffect(() => {
    let box = props.box.boxByIds[props.match.params.id];
    if (box) {
      setTitle("Box " + box.name);
    }
  }, [props.box, props.match.params.id, setTitle]);
  useEffect(() => {
    let box = props.box.boxByIds[props.match.params.id];
    if (!box) {
      return;
    }
    let newData = box.indicatori
      .filter((indicatore) => {
        return props.valori.lastValoreByTipoIndicatoreId[indicatore.id];
      })
      .map((indicatore) => {
        let valoreId = props.valori.lastValoreByTipoIndicatoreId[indicatore.id];
        let valore = props.valori.valoriByIDs[valoreId];
        return {
          id: indicatore.id,
          hasAlert: props.aziende.idIndicatoriWithAlerts.includes(
            indicatore.id
          ),
          codeFlat: indicatore.code,
          fonteFlat: indicatore.enteFornitore.denominazione,
          nameFlat: indicatore.name,
          measureUnit: indicatore.measureUnit,
          frequency: frequencyTypesSelectValues[indicatore.frequency],
          modifiedOn:getModifiedOnLabel(valore.modifiedOn),
          period: getPeriodLabel(valore, indicatore),
          value: getValueLabel(valore.value, indicatore.valuesType),
          critical: indicatore.critical,
          state: getValueState(valore, indicatore),
          rawValore: valore
        };
      });
    setData(newData);
  }, [
    props.valori,
    props.box,
    props.match.params.id,
    props.aziende.idIndicatoriWithAlerts,
  ]);
  const columns = [
    {
      name: "id",
      options: {
        display: "excluded",
      },
    },
    {
      name: "hasAlert",
      options: {
        display: "excluded",
      },
    },
    {
      name: "codeFlat",
      label: "Cod.",
      options: {
        customBodyRenderLite: (dataIndex) => {
          if (data[dataIndex].hasAlert === true) {
            return (
              <div className={classes.alertRowText}>
                {data[dataIndex].codeFlat}
              </div>
            );
          } else return <div>{data[dataIndex].codeFlat}</div>;
        },
      },
    },
    {
      name: "nameFlat",
      label: "Nome",
      options: {
        customBodyRenderLite: (dataIndex) => {
          if (data[dataIndex].hasAlert === true) {
            return (
              <div className={classes.alertRowText}>
                {data[dataIndex].nameFlat}
              </div>
            );
          } else return <div>{data[dataIndex].nameFlat}</div>;
        },
      },
    },
    {
      name: "fonteFlat",
      label: "Fonte",
      options: {
        customBodyRenderLite: (dataIndex) => {
          if (data[dataIndex].hasAlert === true) {
            return (
              <div className={classes.alertRowText}>
                {data[dataIndex].fonteFlat}
              </div>
            );
          } else return <div>{data[dataIndex].fonteFlat}</div>;
        },
      },
    },
    {
      name: "measureUnit",
      label: "Unità di misura",
      options: {
        customBodyRenderLite: (dataIndex) => {
          if (data[dataIndex].hasAlert === true) {
            return (
              <div className={classes.alertRowText}>
                {data[dataIndex].measureUnit}
              </div>
            );
          } else return <div>{data[dataIndex].measureUnit}</div>;
        },
      },
    },
    {
      name: "frequency",
      label: "Frequenza",
      options: {
        customBodyRenderLite: (dataIndex) => {
          if (data[dataIndex].hasAlert === true) {
            return (
              <div className={classes.alertRowText}>
                {data[dataIndex].frequency}
              </div>
            );
          } else return <div>{data[dataIndex].frequency}</div>;
        },
      },
    },
    {
      name: "modifiedOn",
      label: "Data ultima modifica",
      options: {
        customBodyRenderLite: (dataIndex) => {
          if (data[dataIndex].hasAlert === true) {
            return (
              <div className={classes.alertRowText}>
                {data[dataIndex].modifiedOn}
              </div>
            );
          } else return <div>{data[dataIndex].modifiedOn}</div>;
        },
      },
    },
    {
      name: "period",
      label: "Periodo ultimo valore",
      options: {
        // We need the index of rawValore
        sortCompare: valorePeriodSortCompare(12),
        customBodyRenderLite: (dataIndex) => {
          if (data[dataIndex].hasAlert === true) {
            return (
              <div className={classes.alertRowText}>
                {data[dataIndex].period}
              </div>
            );
          } else return <div>{data[dataIndex].period}</div>;
        },
      },
    },
    {
      name: "value",
      label: "Ultimo valore",
      options: {
        sortCompare: valoreValueSortCompare(12),
        customBodyRenderLite: (dataIndex) => {
          if (data[dataIndex].hasAlert === true) {
            return (
              <div className={`${classes.alertValueField} ${classes.textOnRight}`}>
                <div className={classes.alertRowText}>
                  {data[dataIndex].value}
                </div>
                <div className={classes.alertText}>Alert</div>
              </div>
            );
          } else return <div className={`${classes.textOnRight}`}>{data[dataIndex].value}</div>;
        },
      },
    },
    {
      name: "critical",
      label: "Critico",
      options: {
        customBodyRenderLite: (dataIndex) => {
          if (data[dataIndex].critical === true) {
            if (data[dataIndex].hasAlert) {
              return <CheckIcon className={classes.alertRowText} />;
            } else {
              return <CheckIcon />;
            }
          } else return null;
        },
      },
    },
    {
      name: "state",
      label: "Stato",
      options: {
        sortCompare: valoreStateSortCompare,
        customBodyRenderLite: (dataIndex) => (
          <StatusValoreVisualizer status={data[dataIndex].state} />
        ),
      },
    },
    {
      name: "rawValore",
      options: {
        display: "excluded",
      },
    },
  ];
  const fetchAllAlerts = props.fetchAllAlerts;
  useEffect(() => {
    fetchAllAlerts();
  }, [fetchAllAlerts]);
  const options = {
    elevation: 0,
    selectableRows: "none",
    filter: false,
    print: false,
    download: false,
    search: false,
    viewColumns: false,
    textLabels: textLabels,
    rowsPerPageOptions: defaultRowOptions,
    rowsPerPage: mainTableRows,
    onChangeRowsPerPage: (rows) => dispatch(setMainTableRows(rows)),
    setTableProps: () => {
      return {
        padding: "none",
      };
    },
    onRowClick: (rowData) => {
      history.push(`${aziendaPaths.indicatori}/${rowData[0]}`);
    },
  };
  return (
    <div>
      <SingleBoxOverviewSection
        box={props.box.boxByIds[props.match.params.id]}
        lastUpdate={lastUpdate}
        greenStatuses={greenStatuses}
        yellowStatuses={yellowStatuses}
        redStatuses={redStatuses}
        className={classes.overview}
      />
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable data={data} columns={columns} options={options} />
      </MuiThemeProvider>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    box: state.box,
    valori: state.valori,
    aziende: state.aziende,
  };
};

const mapDispatchToProps = {
  fetchSingleBox,
  fetchAllLastValoreOfIndicatoreAsAzienda,
  fetchAllAlerts,
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleBoxPage);
