import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useFormikContext } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import { TextAreaField } from "../../Common/GenericFormComponents/TextAreaField";
import IndicatoriDipendentiField from './IndicatoriDipendentiField'

const useStyles = makeStyles((theme) => ({
  input: {
    boxSizing: "border-box",
    height: "2em",
    width: "200px",
  },
  select: {
    minHeight: "2em",
    width: "200px",
  },
  element: {
    marginBottom: "35px",
  },
  textArea: {
    resize: "none",
    width: "100%",
    marginRight: "5px"
  },
}));

export function IndicatoreCriticoFields(props) {
  const formik = useFormikContext();
  const classes = useStyles();
  const isCritical = formik.values.critical;
  if (isCritical) {
    return (
      <>
        <h6>Proprietà indicatore critico</h6>
        <Grid
          container
          alignItems={"flex-start"}
          justify="center"
          className={props.classes.container}
        >
          <Grid
            className={classes.element}
            item
            container
            direction={"column"}
            md={6}
          >
            <Grid
              className={classes.element}
              item
              container
              direction={"row"}
              xs={12}
            >
              <Grid className={classes.element} item container md={6}>
                <Typography>Messaggio alert</Typography>
              </Grid>
              <Grid className={classes.element} item container md={6}>
                <TextAreaField
                  className={classes.textArea}
                  name={"tipoIndicatoreCriticalInfo.alertMessage"}
                  placeholder="Testo alert"
                  rows={5}
                  disabled={!props.editMode}
                />
              </Grid>
              <Grid className={classes.element} item container md={6}>
                <Typography>Descrizione protocollo alert</Typography>
              </Grid>
              <Grid className={classes.element} item container md={6}>
                <TextAreaField
                  className={classes.textArea}
                  name={"tipoIndicatoreCriticalInfo.alertProtocol"}
                  placeholder="Descrizione del protocollo"
                  rows={8}
                  disabled={!props.editMode}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            className={classes.element}
            item
            container
            direction={"column"}
            md={6}
          >
            <IndicatoriDipendentiField
              name={"tipoIndicatoreCriticalInfo.indicatoriDipendenti"}
              editMode={props.editMode}
            />
          </Grid>
        </Grid>
      </>
    );
  } else {
    return null;
  }
}
