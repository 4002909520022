import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { NumericThresholdConditionsField } from "./NumericThresholdConditionsField";
import { CustomRadioSelectorField } from "../../Common/GenericFormComponents/CustomRadioSelectorField";
import {
  valuesStatuses,
  valuesStatusesLabels,
} from "../../constants/valoriIndicatoriConstants";

export function NumericThresholdConditionsFields(props) {
  return (
    <>
      <Grid
        className={`${props.elementClass}`}
        item
        container
        direction={"column"}
        md={6} xs={12}
      >
        <NumericThresholdConditionsField
          name={"greenThresholdConditions"}
          label={"Condizioni soglia VERDE"}
          editMode={props.editMode}
        />
      </Grid>
      <Grid
        className={`${props.elementClass}`}
        item
        container
        direction={"column"}
        md={6} xs={12}
      >
        <NumericThresholdConditionsField
          name={"yellowThresholdConditions"}
          label={"Condizioni soglia GIALLO"}
          editMode={props.editMode}
        />
      </Grid>
      <Grid
        className={`${props.elementClass}`}
        item
        container
        direction={"column"}
        md={6} xs={12}
      >
        <NumericThresholdConditionsField
          name={"redThresholdConditions"}
          label={"Condizioni soglia ROSSO"}
          editMode={props.editMode}
        />
      </Grid>
      <Grid className={props.elementClass} item container md={3} xs={6}>
        <Typography>
          Soglia predefinita se nessuna delle condizioni precedenti si applica
        </Typography>
      </Grid>
      <Grid
        className={props.elementClass}
        item
        container
        md={3} xs={6}
        justify="center"
      >
        <div className={props.inputClass}>
          <CustomRadioSelectorField
            id={"defaultThreshold"}
            optionsValues={valuesStatuses}
            optionsLabels={valuesStatusesLabels}
            field={"defaultThreshold"}
            disabled={!props.editMode}
          />
        </div>
      </Grid>
    </>
  );
}
