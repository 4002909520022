import * as Yup from "yup";

export const enteFornitorePaths = Object.freeze({
  anagraficaIndicatori: {
    base: "/indicatori",
    creaIndicatore: "/indicatori/crea-indicatore",
  },
  valori: {
    base: "/valori",
    visualizzaValori: "/valori/visualizza-valori",
    caricaValori: "/valori/carica",
  },
  anagraficaAziende: "/aziende",
  valoriAggregati: "/aggregati",
  anagraficaEnte: "/ente",
  impostazioniUtente: "/impostazioni",
});

export const enteFornitoreSidebarData = [
  {
    sidebarListLink: enteFornitorePaths.anagraficaIndicatori.base,
    sidebarListText: "Anagrafica indicatori",
  },
  {
    sidebarListLink: "",
    sidebarListText: "Valori indicatori",
    id: "1",
    children: [
      {
        sidebarListLink: enteFornitorePaths.valori.visualizzaValori,
        sidebarListText: "Visualizza valori indicatori",
      },
      {
        sidebarListLink: enteFornitorePaths.valori.caricaValori,
        sidebarListText: "Carica valori indicatori tramite file",
      },
    ],
  },
  {
    sidebarListLink: enteFornitorePaths.anagraficaAziende,
    sidebarListText: "Anagrafica aziende",
  },
  {
    sidebarListLink: enteFornitorePaths.valoriAggregati,
    sidebarListText: "Visualizza valori aggregati",
  },
  {
    sidebarListLink: enteFornitorePaths.anagraficaEnte,
    sidebarListText: "Anagrafica ente fornitore",
  },
  {
    sidebarListLink: enteFornitorePaths.impostazioniUtente,
    sidebarListText: "Impostazioni utente",
  },
];

export class EnteFornitore {
  constructor() {
    this.id = undefined;
    this.denominazione = "";
    this.partitaIva = "";
    this.codiceFiscale = "";
    this.telefonoAzienda = "";
    this.emailAzienda = "";
    this.comune = {
      id: "",
      name: "",
      provincia: {
        id: "",
        name: "",
      },
    };
    this.indirizzo = "";
    this.cap = "";
    this.nomeReferente = "";
    this.cognomeReferente = "";
    this.telefonoReferente = "";
    this.emailReferente = "";
  }
}

export const enteFornitoreSchema = Yup.object().shape({
  denominazione: Yup.string()
    .strict(true)
    .trim("Non sono ammessi spazi")
    .required("Campo obbligatorio"),
  partitaIva: Yup.string()
    .strict(true)
    .trim("Non sono ammessi spazi")
    .matches(/^[0-9]*$/, "Solo numeri sono ammessi")
    .matches(/^(?:.{11})$/, "La partita iva deve essere di 11 caratteri")
    .required("Campo obbligatorio"),
  codiceFiscale: Yup.string()
    .strict(true)
    .trim("Non sono ammessi spazi")
    .matches(/^[a-zA-Z0-9]*$/, "Solo lettere o numeri sono ammessi")
    .matches(
      /^(?:.{11}|.{16})$/,
      "Il codice fiscale deve essere di 11 o 16 caratteri"
    )
    .required("Campo obbligatorio"),
  telefonoAzienda: Yup.number()
    .positive("Inserire un numero telefonico valido (solo numeri ammessi)")
    .typeError("Inserire un numero telefonico valido (solo numeri ammessi)")
    .required("Campo obbligatorio"),
  emailAzienda: Yup.string()
    .strict(true)
    .trim("Non sono ammessi spazi")
    .email("Inserire una email valida")
    .required("Campo obbligatorio"),
  comune: Yup.object({
    id: Yup.string().required("Campo obbligatorio"),
    provincia: Yup.object({
      id: Yup.string().required("Campo obbligatorio"),
    }),
  }),
  indirizzo: Yup.string()
    .strict(true)
    .trim("Non sono ammessi spazi")
    .required("Campo obbligatorio"),
  cap: Yup.number()
    .positive("Inserire un CAP valido (solo numeri ammessi)")
    .typeError("Inserire un CAP valido (solo numeri ammessi)")
    .required("Campo obbligatorio"),
  nomeReferente: Yup.string()
    .strict(true)
    .trim("Non sono ammessi spazi")
    .required("Campo obbligatorio"),
  cognomeReferente: Yup.string()
    .strict(true)
    .trim("Non sono ammessi spazi")
    .required("Campo obbligatorio"),
  telefonoReferente: Yup.number()
    .positive("Inserire un numero telefonico valido (solo numeri ammessi)")
    .typeError("Inserire un numero telefonico valido (solo numeri ammessi)")
    .required("Campo obbligatorio"),
  emailReferente: Yup.string()
    .strict(true)
    .trim("Non sono ammessi spazi")
    .email("Inserire una email valida")
    .required("Campo obbligatorio"),
});