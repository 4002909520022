import axios from "axios";
import {
  BACKEND_URL,
  toastTypes,
  uploadResultTypes,
} from "../../constants/generalConstants";
import {
  receiveComuneData,
  receiveProvinceData,
  receiveRegioniData,
} from "./basicActions";
import { toast } from "react-toastify";
import React from "react";
import { SuccessToast } from "../../Common/Toasts/SuccessToast";
import { InfoToast } from "../../Common/Toasts/InfoToast";
import { ErrorToast } from "../../Common/Toasts/ErrorToast";

const Paths = Object.freeze({
  uploadFile: "/uploadFile",
  province: "/provincia",
  regioni: "/regione",
});

export const getStandardConfig = (getState) => {
  return {
    headers: {
      Authorization: "Bearer " + getState().loginData.accessToken,
    },
    timeout: 60000,
  };
};

export const getStandardConfigForceJson = (getState) => {
  return {
    headers: {
      Authorization: "Bearer " + getState().loginData.accessToken,
      "Content-Type": "application/json",
    },
    timeout: 60000,
  };
};

export function uploadFile(file) {
  return (dispatch, getState) => {
    const formdata = new FormData();
    formdata.append("file", file);
    return axios
      .post(
        BACKEND_URL + Paths.uploadFile,
        formdata,
        getStandardConfig(getState)
      )
      .then((response) => {
        return {
          uploadResult: uploadResultTypes.SUCCESS,
          uploadUrl: response.data.fileDownloadUri,
        };
      })
      .catch(() => {
        return {
          uploadResult: uploadResultTypes.GENERIC_ERROR,
        };
      });
  };
}

export function fetchProvinceSelectorData() {
  return (dispatch) => {
    return axios
      .get(BACKEND_URL + Paths.province)
      .then((response) => {
        dispatch(receiveProvinceData(response.data));
      })
      .catch((err) => {
        console.log("Error receiving province data", err);
      });
  };
}

export function fetchComuniSelectorData(provinceID) {
  return (dispatch) => {
    return axios
      .get(BACKEND_URL + Paths.province + "/" + provinceID + "/comune")
      .then((response) => {
        dispatch(receiveComuneData(provinceID, response.data));
      })
      .catch((err) => {
        console.log("Error receiving comuni data", err);
      });
  };
}

export function fetchRegioniData() {
  return (dispatch) => {
    return axios
      .get(BACKEND_URL + Paths.regioni)
      .then((response) => {
        dispatch(receiveRegioniData(response.data));
      })
      .catch((err) => {
        console.log("Error receiving comuni data", err);
      });
  };
}

export function displayToast(type, message) {
  return () => {
    switch (type) {
      case toastTypes.INFO:
        toast.info(<InfoToast message={message} />);
        break;
      case toastTypes.SUCCESS:
        toast.success(<SuccessToast message={message} />);
        break;
      case toastTypes.ERROR:
        toast.error(<ErrorToast message={message} />);
        break;
      default:
        toast(message);
        break;
    }
  };
}
