import React, { useEffect, useState } from "react";
import {
  createIndicatore,
  editIndicatore,
  fetchAllIndicatore,
  fetchSingleIndicatore,
} from "../../redux/indicatori/backendActions";
import { displayToast } from "../../redux/general/backendActions";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation, useHistory } from "react-router-dom";
import { Form, Formik } from "formik";
import { enteFornitorePaths } from "../../constants/enteFornitoreConstants";
import {
  frequencyTypesSelectValues,
  Indicatore,
  indicatoreSchema,
  TipoIndicatoreCriticalInfo,
  valuesTypesSelect,
} from "../../constants/indicatoriConstants";
import { FormActionButtons } from "../../Common/FormActionButtons";
import { ScrollToError } from "../../Common/GenericFormComponents/ScrollToError";
import { toastTypes } from "../../constants/generalConstants";
import { Grid, Typography } from "@material-ui/core";
import { SimpleInputField } from "../../Common/GenericFormComponents/SimpleInputField";
import BoxSelectField from "../../Common/GenericFormComponents/BoxSelectField";
import { SimpleSelectField } from "../../Common/GenericFormComponents/SimpleSelectField";
import { CustomRadioSelectorField } from "../../Common/GenericFormComponents/CustomRadioSelectorField";
import {
  booleanRadioLabels,
  booleanRadioValues,
} from "../../constants/miscConstants";
import { ResetForm } from "../../Common/GenericFormComponents/ResetForm";
import { IndicatoreCriticoFields } from "./IndicatoreCriticoFields";
import { ThresholdConditionsFields } from "./ThresholdConditionsFields";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: "100%",
    maxHeight: "100%",
  },
  form: {
    marginBottom: "0",
    overflowY: "auto",
    maxHeight: "calc(100% - 100px)",
  },
  alwaysOnTopSection: {
    height: "80px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  input: {
    boxSizing: "border-box",
    height: "2em",
    width: "150px",
  },
  select: {
    minHeight: "2em",
    width: "150px",
  },
  element: {
    marginBottom: "35px",
  },
}));

function SingleIndicatorePage(props) {
  const location = useLocation();
  const history = useHistory();
  const setTitle = props.setTitle;
  const [editMode, setEditMode] = useState(false);
  const [scrollToError, setScrollToError] = useState(false);
  const [showedIndicatore, setShowedIndicatore] = useState(new Indicatore());
  const fetchSingleIndicatore = props.fetchSingleIndicatore;
  const fetchAllIndicatore = props.fetchAllIndicatore;
  useEffect(() => {
    fetchAllIndicatore();
  }, [fetchAllIndicatore]);
  useEffect(() => {
    if (props.createMode) {
      setEditMode(true);
      setTitle("Crea indicatore");
    } else {
      setEditMode(false);
      fetchSingleIndicatore(props.match.params.id);
    }
  }, [
    location,
    props.createMode,
    setTitle,
    props.match,
    fetchSingleIndicatore,
  ]);
  useEffect(() => {
    if (!props.createMode) {
      if (props.indicatori.indicatoriByIDs[props.match.params.id]) {
        let indicatore = JSON.parse(
          JSON.stringify(
            props.indicatori.indicatoriByIDs[props.match.params.id]
          )
        );
        if (!indicatore.tipoIndicatoreCriticalInfo)
          indicatore.tipoIndicatoreCriticalInfo = new TipoIndicatoreCriticalInfo();
        setShowedIndicatore(indicatore);
        setTitle(
          `Indicatore ${
            props.indicatori.indicatoriByIDs[props.match.params.id].code
          }`
        );
      } else {
        setShowedIndicatore(new Indicatore());
      }
    } else {
      setShowedIndicatore(new Indicatore());
    }
  }, [location, props.indicatori, props.match, props.createMode, setTitle]);
  const classes = useStyles();
  return (
    <Formik
      initialValues={showedIndicatore}
      onSubmit={(values) => {
        if (props.createMode) {
          props
            .createIndicatore(values)
            .then(() =>
              history.push(enteFornitorePaths.anagraficaIndicatori.base)
            );
        } else {
          props.editIndicatore(values.id, values).then(() => {
            fetchSingleIndicatore(props.match.params.id);
            setEditMode(false);
          });
        }
      }}
      validationSchema={indicatoreSchema}
      enableReinitialize
    >
      <Form className={classes.root}>
        <ResetForm showedValues={showedIndicatore} />
        <div className={classes.form}>
          <h6>Proprietà indicatore</h6>
          <Grid
            container
            alignItems="center"
            justify="center"
            className={classes.container}
          >
            <Grid className={classes.element} item container md={3} xs={6}>
              <Typography>Codice indicatore</Typography>
            </Grid>
            <Grid
              className={classes.element}
              item
              container
              md={3}
              xs={6}
              justify="center"
            >
              <SimpleInputField
                type="text"
                className={classes.input}
                name="code"
                disabled={!props.createMode}
              />
            </Grid>
            <Grid className={classes.element} item container md={3} xs={6}>
              <Typography>Nome</Typography>
            </Grid>
            <Grid
              className={classes.element}
              item
              container
              md={3}
              xs={6}
              justify="center"
            >
              <SimpleInputField
                type="text"
                className={classes.input}
                name="name"
                disabled={!editMode}
              />
            </Grid>
            <Grid className={classes.element} item container md={3} xs={6}>
              <Typography>Box</Typography>
            </Grid>
            <Grid
              className={classes.element}
              item
              container
              md={3}
              xs={6}
              justify="center"
            >
              <div className={classes.input}>
                <BoxSelectField
                  name={"box.id"}
                  className={classes.select}
                  disabled={!editMode}
                />
              </div>
            </Grid>
            <Grid className={classes.element} item container md={3} xs={6}>
              <Typography>Frequenza</Typography>
            </Grid>
            <Grid
              className={classes.element}
              item
              container
              md={3}
              xs={6}
              justify="center"
            >
              <div className={classes.input}>
                <SimpleSelectField
                  name={"frequency"}
                  id="frequency"
                  className={classes.select}
                  options={frequencyTypesSelectValues}
                  disabled={!editMode}
                />
              </div>
            </Grid>
            <Grid className={classes.element} item container md={3} xs={6}>
              <Typography>Unità di misura</Typography>
            </Grid>
            <Grid
              className={classes.element}
              item
              container
              md={3}
              xs={6}
              justify="center"
            >
              <SimpleInputField
                type="text"
                className={classes.input}
                name="measureUnit"
                disabled={!editMode}
              />
            </Grid>
            <Grid className={classes.element} item container md={3} xs={6}>
              <Typography>Tipo</Typography>
            </Grid>
            <Grid
              className={classes.element}
              item
              container
              md={3}
              xs={6}
              justify="center"
            >
              <div className={classes.input}>
                <SimpleSelectField
                  name={"valuesType"}
                  id="valuesType"
                  className={classes.select}
                  options={valuesTypesSelect}
                  disabled={!editMode}
                />
              </div>
            </Grid>
            <ThresholdConditionsFields
              editMode={editMode}
              elementClass={classes.element}
              inputClass={classes.input}
              selectClass={classes.select}
            />
            <Grid className={classes.element} item container md={3} xs={6}>
              <Typography>Indicatore critico?</Typography>
            </Grid>
            <Grid
              className={classes.element}
              item
              container
              md={3}
              xs={6}
              justify="center"
            >
              <div className={classes.input}>
                <CustomRadioSelectorField
                  id={"critical"}
                  optionsValues={booleanRadioValues}
                  optionsLabels={booleanRadioLabels}
                  field={"critical"}
                  disabled={!editMode}
                />
              </div>
            </Grid>
            <Grid className={classes.element} item container md={3} xs={6}>
              <Typography>Abilitato?</Typography>
            </Grid>
            <Grid
              className={classes.element}
              item
              container
              md={3}
              xs={6}
              justify="center"
            >
              <div className={classes.input}>
                <CustomRadioSelectorField
                  id={"enabled"}
                  optionsValues={booleanRadioValues}
                  optionsLabels={booleanRadioLabels}
                  field={"enabled"}
                  disabled={!editMode}
                />
              </div>
            </Grid>
          </Grid>
          <IndicatoreCriticoFields
            classes={classes}
            createMode={props.createMode}
            editMode={editMode}
          />
        </div>
        <div className={classes.alwaysOnTopSection}>
          <ScrollToError scroll={scrollToError} />
          <FormActionButtons
            createMode={props.createMode}
            editMode={editMode}
            onEditModeChange={(newValue) => {
              setEditMode(newValue);
              if (newValue === false) {
                props.fetchSingleIndicatore(props.match.params.id);
              }
            }}
            onCancelCreate={() =>
              history.push(enteFornitorePaths.anagraficaIndicatori.base)
            }
            onFormError={(error) => {
              props.displayToast(toastTypes.ERROR, error);
              setScrollToError(!scrollToError);
            }}
          />
        </div>
      </Form>
    </Formik>
  );
}

const mapStateToProps = (state) => {
  return {
    indicatori: state.indicatori,
  };
};

const mapDispatchToProps = {
  fetchSingleIndicatore,
  fetchAllIndicatore,
  displayToast,
  createIndicatore,
  editIndicatore,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleIndicatorePage);
