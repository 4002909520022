import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import { Azienda, aziendaSchema } from "../../constants/aziendaConstants";
import { fetchProfile } from "../../redux/auth/backendActions";
import { displayToast } from "../../redux/general/backendActions";
import {
  fetchSingleAzienda,
  patchSingleAzienda,
} from "../../redux/aziende/backendActions";
import { Form, Formik } from "formik";
import { ResetForm } from "../GenericFormComponents/ResetForm";
import { Grid, Typography } from "@material-ui/core";
import { SimpleInputField } from "../GenericFormComponents/SimpleInputField";
import ProvinciaComuneSelectFields from "../GenericFormComponents/ProvinciaComuneSelectFields";
import { ScrollToError } from "../GenericFormComponents/ScrollToError";
import { AnagraficaActionButtons } from "../AnagraficaActionButtons";
import { toastTypes } from "../../constants/generalConstants";
import { UserRoles } from "../../constants/authConstants";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: "100%",
    maxHeight: "100%",
  },
  form: {
    marginBottom: "0",
    overflowY: "auto",
    maxHeight: "100%",
  },
  actionButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "5px",
  },
  input: {
    boxSizing: "border-box",
    height: "2em",
    width: "150px",
  },
  select: {
    minHeight: "2em",
    width: "150px",
  },
  element: {
    marginBottom: "35px",
  },
  operatorField: {
    minHeight: "2em",
    minWidth: "150px",
  },
  operator: {
    minHeight: "2em",
    width: "50px",
  },
  threshold: {
    boxSizing: "border-box",
    height: "2em",
    width: "120px",
  },
}));

function AnagraficaAzienda(props) {
  const setTitle = props.setTitle;
  useEffect(() => {
    setTitle("Anagrafica azienda");
  }, [setTitle]);
  const classes = useStyles();
  const location = useLocation();
  const [editMode, setEditMode] = useState(false);
  const [scrollToError, setScrollToError] = useState(false);
  const [showedAzienda, setShowedAzienda] = useState(new Azienda());
  const fetchProfile = props.fetchProfile;
  const fetchSingleAzienda = props.fetchSingleAzienda;
  useEffect(() => {
    if (!props.aziendaMode) {
      if (props.aziendaId) {
        fetchSingleAzienda(props.aziendaId);
      }
    } else {
      fetchProfile();
    }
  }, [
    location,
    fetchProfile,
    fetchSingleAzienda,
    props.aziendaId,
    props.aziendaMode,
  ]);
  useEffect(() => {
    if (!props.aziendaMode) {
      if (!props.aziendaId || !props.aziende.aziendeByIDs[props.aziendaId]) {
        setShowedAzienda(new Azienda());
      } else {
        setShowedAzienda(props.aziende.aziendeByIDs[props.aziendaId]);
      }
    } else {
      if (!props.loginTransientState.profile) {
        setShowedAzienda(new Azienda());
      } else {
        setShowedAzienda(props.loginTransientState.profile);
      }
    }
  }, [
    location,
    props.aziende,
    props.loginTransientState.profile,
    props.aziendaId,
    props.aziendaMode,
  ]);
  return (
    <Formik
      initialValues={showedAzienda}
      onSubmit={(values) => {
        props.patchSingleAzienda(values.id, values).then(() => {
          setEditMode(false);
          if (props.aziendaId) {
            fetchSingleAzienda(props.aziendaId);
          } else {
            fetchProfile();
          }
        });
      }}
      validationSchema={aziendaSchema}
      enableReinitialize
    >
      <Form className={classes.root}>
        <ResetForm showedValues={showedAzienda} />
        <div className={classes.form}>
          <h6>Dati Azienda</h6>
          <Grid container alignItems="center" justify="center">
            <Grid className={classes.element} item container md={3} xs={6}>
              <Typography>Denominazione azienda</Typography>
            </Grid>
            <Grid
              className={classes.element}
              item
              container
              md={3}
              xs={6}
              justify="center"
            >
              <SimpleInputField
                type="text"
                className={classes.input}
                name="denominazione"
                disabled={!editMode}
              />
            </Grid>
            <Grid className={classes.element} item container md={3} xs={6}>
              <Typography>Partita IVA</Typography>
            </Grid>
            <Grid
              className={classes.element}
              item
              container
              md={3}
              xs={6}
              justify="center"
            >
              <SimpleInputField
                type="text"
                className={classes.input}
                name="partitaIva"
                disabled={!editMode}
              />
            </Grid>
            <Grid className={classes.element} item container md={3} xs={6}>
              <Typography>Codice fiscale</Typography>
            </Grid>
            <Grid
              className={classes.element}
              item
              container
              md={3}
              xs={6}
              justify="center"
            >
              <SimpleInputField
                type="text"
                className={classes.input}
                name="codiceFiscale"
                disabled={!editMode}
              />
            </Grid>
            <Grid className={classes.element} item container md={3} xs={6}>
              <Typography>N. Telefono</Typography>
            </Grid>
            <Grid
              className={classes.element}
              item
              container
              md={3}
              xs={6}
              justify="center"
            >
              <SimpleInputField
                type="text"
                className={classes.input}
                name="telefonoAzienda"
                disabled={!editMode}
              />
            </Grid>
            <Grid className={classes.element} item container md={3} xs={6}>
              <Typography>Email azienda</Typography>
            </Grid>
            <Grid
              className={classes.element}
              item
              container
              md={3}
              xs={6}
              justify="center"
            >
              <SimpleInputField
                type="text"
                className={classes.input}
                name="emailAzienda"
                disabled={!editMode}
              />
            </Grid>
            <Grid className={classes.element} item container xs={6} />
          </Grid>
          <h6>Indirizzo azienda</h6>
          <Grid container alignItems="center" justify="center">
            <ProvinciaComuneSelectFields
              gridClassName={classes.element}
              inputClassName={classes.input}
              selectClassName={classes.select}
              comuneIDField={"comune.id"}
              provinciaIDField={"comune.provincia.id"}
              disabled={!editMode}
            />
            <Grid className={classes.element} item container md={3} xs={6}>
              <Typography>Indirizzo</Typography>
            </Grid>
            <Grid
              className={classes.element}
              item
              container
              md={3}
              xs={6}
              justify="center"
            >
              <SimpleInputField
                type="text"
                className={classes.input}
                name="indirizzo"
                disabled={!editMode}
              />
            </Grid>
            <Grid className={classes.element} item container md={3} xs={6}>
              <Typography>CAP</Typography>
            </Grid>
            <Grid
              className={classes.element}
              item
              container
              md={3}
              xs={6}
              justify="center"
            >
              <SimpleInputField
                type="text"
                className={classes.input}
                name="cap"
                disabled={!editMode}
              />
            </Grid>
          </Grid>
          <h6>Referente azienda</h6>
          <Grid container alignItems="center" justify="center">
            <Grid className={classes.element} item container md={3} xs={6}>
              <Typography>Nome</Typography>
            </Grid>
            <Grid
              className={classes.element}
              item
              container
              md={3}
              xs={6}
              justify="center"
            >
              <SimpleInputField
                type="text"
                className={classes.input}
                name="nomeReferente"
                disabled={!editMode}
              />
            </Grid>
            <Grid className={classes.element} item container md={3} xs={6}>
              <Typography>Cognome</Typography>
            </Grid>
            <Grid
              className={classes.element}
              item
              container
              md={3}
              xs={6}
              justify="center"
            >
              <SimpleInputField
                type="text"
                className={classes.input}
                name="cognomeReferente"
                disabled={!editMode}
              />
            </Grid>
            <Grid className={classes.element} item container md={3} xs={6}>
              <Typography>Email referente</Typography>
            </Grid>
            <Grid
              className={classes.element}
              item
              container
              md={3}
              xs={6}
              justify="center"
            >
              <SimpleInputField
                type="text"
                className={classes.input}
                name="emailReferente"
                disabled={!editMode}
              />
            </Grid>
            <Grid className={classes.element} item container md={3} xs={6}>
              <Typography>Telefono referente</Typography>
            </Grid>
            <Grid
              className={classes.element}
              item
              container
              md={3}
              xs={6}
              justify="center"
            >
              <SimpleInputField
                type="text"
                className={classes.input}
                name="telefonoReferente"
                disabled={!editMode}
              />
            </Grid>
          </Grid>
          <div className={classes.actionButtons}>
            <ScrollToError scroll={scrollToError} />
            <AnagraficaActionButtons
              disableStalleAllevamentiEdit={
                props.loginData.userType === UserRoles.ROLE_AZIENDA
              }
              onEditStallaClick={props.onEditStallaClick}
              onEditAllevamentoClick={props.onEditAllevamentoClick}
              editMode={editMode}
              onEditModeChange={(newValue) => {
                setEditMode(newValue);
                if (newValue === false) {
                  if (props.aziendaId) {
                    fetchSingleAzienda(props.aziendaId);
                  } else {
                    fetchProfile();
                  }
                }
              }}
              onFormError={(error) => {
                props.displayToast(toastTypes.ERROR, error);
                setScrollToError(!scrollToError);
              }}
            />
          </div>
        </div>
      </Form>
    </Formik>
  );
}

const mapStateToProps = (state) => {
  return {
    loginTransientState: state.loginTransientState,
    aziende: state.aziende,
    loginData: state.loginData,
  };
};

const mapDispatchToProps = {
  fetchProfile,
  patchSingleAzienda,
  displayToast,
  fetchSingleAzienda,
};

export default connect(mapStateToProps, mapDispatchToProps)(AnagraficaAzienda);
