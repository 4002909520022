import React, { useEffect, useState } from "react";
import { SimpleSelectField } from "./SimpleSelectField";
import { connect } from "react-redux";
import {
  fetchComuniSelectorData,
  fetchProvinceSelectorData,
} from "../../redux/general/backendActions";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import { Grid, Typography } from "@material-ui/core";

function ProvinciaComuneSelectFields(props) {
  const formik = useFormikContext();
  const [selectedProvinciaIDs, setSelectedProvinciaIDs] = useState([]);
  const [comuneOptions, setComuneOptions] = useState({});
  const [provinceOptions, setProvinceOptions] = useState({});
  const fetchProvinceSelectorData = props.fetchProvinceSelectorData;
  useEffect(() => {
    fetchProvinceSelectorData();
  }, [fetchProvinceSelectorData]);
  useEffect(() => {
    const newProvinceOptions = {};
    Object.keys(props.provinceComuneSelectorData.provincesByIDs).forEach(
      (provinciaID) => {
        newProvinceOptions[provinciaID] =
          props.provinceComuneSelectorData.provincesByIDs[provinciaID].name;
      }
    );
    setProvinceOptions(newProvinceOptions);
  }, [props.provinceComuneSelectorData.provincesByIDs]);
  useEffect(() => {
    const newComuneOptions = {};
    selectedProvinciaIDs.forEach((id) => {
      if (props.provinceComuneSelectorData.comuneIDsByProvinceIDs[id]) {
        props.provinceComuneSelectorData.comuneIDsByProvinceIDs[id].forEach(
          (comuneID) => {
            newComuneOptions[comuneID] =
              props.provinceComuneSelectorData.comuneByIDs[comuneID].name;
          }
        );
        setComuneOptions(newComuneOptions);
      }
    });
  }, [
    selectedProvinciaIDs,
    props.provinceComuneSelectorData.comuneByIDs,
    props.provinceComuneSelectorData.comuneIDsByProvinceIDs,
  ]);
  const fetchComuniSelectorData = props.fetchComuniSelectorData;
  useEffect(() => {
    selectedProvinciaIDs.forEach((provinceID) => {
      if (
        !props.provinceComuneSelectorData.comuneIDsByProvinceIDs[provinceID]
      ) {
        fetchComuniSelectorData(provinceID);
      }
    });
  }, [
    selectedProvinciaIDs,
    fetchComuniSelectorData,
    props.provinceComuneSelectorData.comuneIDsByProvinceIDs,
  ]);
  useEffect(() => {
    if (props.selectedProvinciaIDs) {
      setSelectedProvinciaIDs(props.selectedProvinciaIDs);
    }
  }, [props.selectedProvinciaIDs]);
  useEffect(() => {
    if (props.provinciaIDField) {
      const provinceID = props.provinciaIDField
        .split(".")
        .reduce((o, i) => o[i], formik.values);
      if (provinceID !== "") {
        setSelectedProvinciaIDs([provinceID]);
      }
    }
  }, [formik.values, props.provinciaIDField]);
  return (
    <>
      {!props.selectedProvinciaIDs && (
        <>
          <Grid className={props.gridClassName} item container md={3} xs={6}>
            <Typography>Provincia</Typography>
          </Grid>
          <Grid
            className={props.gridClassName}
            item
            container
            justify="center"
            md={3} xs={6}
          >
            <div className={props.inputClassName}>
              <SimpleSelectField
                name={props.provinciaIDField}
                id="provinceID"
                className={props.selectClassName}
                options={provinceOptions}
                disabled={props.disabled}
                onChange={(event) => {
                  let provinceID = event.target.value;
                  if (props.provinciaNameField) {
                    formik.setFieldValue(
                      props.provinciaNameField,
                      props.provinceComuneSelectorData.provincesByIDs[
                        provinceID
                      ].name
                    );
                  }
                  if (props.comuneNameField) {
                    formik.setFieldValue(props.comuneNameField, "");
                  }
                  if (props.comuneIDField) {
                    formik.setFieldValue(props.comuneIDField, "");
                  }
                }}
                sortByLabel
                allowEmptyValues={props.allowEmptyValues}
              />
            </div>
          </Grid>
        </>
      )}
      <Grid className={props.gridClassName} item container md={3} xs={6}>
        <Typography>Comune</Typography>
      </Grid>
      <Grid
        className={props.gridClassName}
        item
        container
        justify="center"
        md={3} xs={6}
      >
        <div className={props.inputClassName}>
          <SimpleSelectField
            name={props.comuneIDField}
            id="comune.comuneID"
            className={props.selectClassName}
            options={comuneOptions}
            disabled={props.disabled}
            onChange={(event) => {
              if (props.comuneNameField) {
                formik.setFieldValue(
                  props.comuneNameField,
                  props.provinceComuneSelectorData.comuneByIDs[
                    event.target.value
                  ].name
                );
              }
            }}
            sortByLabel
            allowEmptyValues={props.allowEmptyValues}
          />
        </div>
      </Grid>
    </>
  );
}

ProvinciaComuneSelectFields.propTypes = {
  provinciaIDField: PropTypes.string,
  comuneIDField: PropTypes.string.isRequired,
  provinciaNameField: PropTypes.string,
  comuneNameField: PropTypes.string,
  selectedProvinciaIDs: PropTypes.array,
};

const mapStateToProps = (state) => {
  return {
    provinceComuneSelectorData: state.provinceComuneSelectorData,
  };
};

const mapDispatchToProps = {
  fetchProvinceSelectorData,
  fetchComuniSelectorData,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProvinciaComuneSelectFields);
