import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { createTheme, Fab, MuiThemeProvider } from '@material-ui/core'
import { useHistory, useLocation } from 'react-router-dom'
import CheckIcon from '@material-ui/icons/Check'
import { textLabels } from '../../constants/miscConstants'
import { useDispatch, useSelector } from 'react-redux'
import { adminPaths, UserRolesLabels } from '../../constants/adminConstants'
import { fetchAllUsers } from '../../redux/admin/backendActions'
import MUIDataTable from 'mui-datatables'
import AddIcon from '@material-ui/icons/Add'
import { defaultRowOptions } from '../../constants/generalConstants'
import { setMainTableRows } from '../../redux/general/basicActions'

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  table: {
    marginBottom: "0",
    overflowY: "auto",
  },
  fab: {
    position: "fixed",
    bottom: "50px",
    right: "8vw"
  },
}));

const getMuiTheme = () =>
  createTheme({
    overrides: {
      MuiToolbar: {
        root: {
          backgroundColor: "#FEFDFD",
        },
      },
      MuiTable: {
        root: {
          backgroundColor: "#FEFDFD",
        },
      },
      MuiTableCell: {
        root: {
          height: "30px",
          padding: "0px 4px 0px 4px"
        },
      },
      MUIDataTableBodyRow: {
        root: {
          cursor: "pointer",
        },
      },
      MUIDataTableHeadCell: {
        fixedHeader: {
          backgroundColor: "#C4C4C4",
        },
      },
    },
  });

export function UserListPage(props){
  const location = useLocation();
  const history = useHistory();
  const [data, setData] = useState([]);
  const mainTableRows = useSelector(
    (state) => state.appPreferences.mainWindowTableRows
  );
  const admin = useSelector(state => state.admin);
  const classes = useStyles();
  const setTitle = props.setTitle;
  const dispatch = useDispatch();
  useEffect(() => {
    setTitle("Lista utenti");
  }, [setTitle]);
  useEffect(() => {
    dispatch(fetchAllUsers());
  }, [location, dispatch]);
  const columns = [
    {
      name: "id",
      options: {
        display: "excluded",
      },
    },
    { name: "username", label: "Nome utente (email)" },
    { name: "userType", label: "Tipo utente" },
    {
      name: "enabled",
      label: "Abilitato",
      options: {
        customBodyRenderLite: (dataIndex) => {
          if (data[dataIndex].enabled === true) {
            return <CheckIcon />;
          } else return null;
        },
      },
    },
  ];
  const options = {
    elevation: 0,
    selectableRows: "none",
    filter: false,
    print: false,
    download: false,
    search: false,
    viewColumns: false,
    textLabels: textLabels,
    rowsPerPageOptions: defaultRowOptions,
    rowsPerPage: mainTableRows,
    onChangeRowsPerPage: (rows) => dispatch(setMainTableRows(rows)),
    onRowClick: (rowData) => {
      history.push(
        `${adminPaths.listaUtenti}/${rowData[0]}`
      );
    },
  };
  useEffect(() => {
    let newData = Object.keys(admin.usersByIds).map((id) => {
      return {
        id: id,
        username: admin.usersByIds[id].username,
        userType: UserRolesLabels[admin.usersByIds[id].roleName],
        enabled: admin.usersByIds[id].enabled
      };
    });
    setData(newData);
  }, [admin]);
  return (
    <div className={classes.root}>
      <div className={classes.table}>
        <h6>Lista utenti</h6>
        <MuiThemeProvider theme={getMuiTheme()}>
          <MUIDataTable data={data} columns={columns} options={options} />
        </MuiThemeProvider>
        <Fab
          color="primary"
          variant={"extended"}
          className={classes.fab}
          onClick={() =>
            history.push(adminPaths.creaUtente)
          }
        >
          <AddIcon />
          Crea utente
        </Fab>
      </div>
    </div>
  );
}