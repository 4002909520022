import axios from "axios";
import {
  BACKEND_URL,
  genericCallStatus,
  toastTypes,
} from "../../constants/generalConstants";
import {
  displayToast,
  getStandardConfig,
  getStandardConfigForceJson,
} from "../general/backendActions";
import { receiveAllUsers, receiveSingleUser } from "./basicActions";
import { UserRoles } from "../../constants/authConstants";

const Paths = Object.freeze({
  user: "/user/",
});

export function fetchAllUsers() {
  return (dispatch, getState) => {
    return axios
      .get(BACKEND_URL + Paths.user, getStandardConfig(getState))
      .then((response) => {
        dispatch(receiveAllUsers(response.data));
        return Promise.resolve(genericCallStatus.SUCCESS);
      })
      .catch(() => {
        dispatch(
          displayToast(toastTypes.ERROR, "Errore nello scaricamento dati")
        );
        return Promise.reject(genericCallStatus.GENERIC_ERROR);
      });
  };
}

export function createUser(user) {
  return (dispatch, getState) => {
    // Ugly workaround for creating a user without comune/provincia
    if (user.userRole === UserRoles.ROLE_AZIENDA || user.userRole === UserRoles.ROLE_ENTE_FORNITORE) {
      user = {
        ...user,
        comune: {
          id: "054039",
          provincia: {
            id: "054",
          },
        },
      };
    }
    return axios
      .post(BACKEND_URL + Paths.user, user, getStandardConfig(getState))
      .then(() => {
        dispatch(displayToast(toastTypes.INFO, "Utente creato correttamente"));
        return Promise.resolve(genericCallStatus.SUCCESS);
      })
      .catch(() => {
        dispatch(
          displayToast(
            toastTypes.ERROR,
            "Errore durante la creazione dell'utente"
          )
        );
        return Promise.reject(genericCallStatus.GENERIC_ERROR);
      });
  };
}

export function fetchSingleUser(userId) {
  return (dispatch, getState) => {
    return axios
      .get(BACKEND_URL + Paths.user + userId, getStandardConfig(getState))
      .then((response) => {
        dispatch(receiveSingleUser(response.data));
      })
      .then(() => {
        return Promise.resolve(genericCallStatus.SUCCESS);
      })
      .catch(() => {
        dispatch(
          displayToast(toastTypes.ERROR, "Errore nello scaricamento dati")
        );
        return Promise.reject(genericCallStatus.GENERIC_ERROR);
      });
  };
}

export function deleteSingleUser(userId) {
  return (dispatch, getState) => {
    return axios
      .delete(BACKEND_URL + Paths.user + userId, getStandardConfig(getState))
      .then(() => {
        dispatch(
          displayToast(toastTypes.INFO, "Utente eliminato correttamente")
        );
        return Promise.resolve(genericCallStatus.SUCCESS);
      })
      .catch(() => {
        dispatch(
          displayToast(
            toastTypes.ERROR,
            "Errore durante l'eliminazione dell'utente"
          )
        );
        return Promise.reject(genericCallStatus.GENERIC_ERROR);
      });
  };
}

export function changeUsernameAsAdmin(userId, newUsername) {
  return (dispatch, getState) => {
    return axios
      .post(
        BACKEND_URL + Paths.user + userId + "/username",
        newUsername,
        getStandardConfigForceJson(getState)
      )
      .then(() => {
        dispatch(
          displayToast(toastTypes.INFO, "Username cambiato correttamente")
        );
        return Promise.resolve(genericCallStatus.SUCCESS);
      })
      .then(() => {
        dispatch(fetchSingleUser(userId));
        return Promise.resolve(genericCallStatus.SUCCESS);
      })
      .catch((error) => {
        if (error.response && error.response.status === 409) {
          dispatch(
            displayToast(toastTypes.ERROR, "Username già presente nel sistema")
          );
        } else {
          dispatch(
            displayToast(
              toastTypes.ERROR,
              "Errore durante il cambio dell'username"
            )
          );
        }
        dispatch(fetchSingleUser(userId));
        return Promise.reject(genericCallStatus.GENERIC_ERROR);
      });
  };
}

export function changeUserEnabledStatus(userId, enabled) {
  return (dispatch, getState) => {
    console.log(enabled.toString());
    return axios
      .post(
        BACKEND_URL + Paths.user + userId + "/enabled",
        enabled.toString(),
        getStandardConfigForceJson(getState)
      )
      .then(() => {
        dispatch(
          displayToast(toastTypes.INFO, "Stato utente cambiato correttamente")
        );
        return Promise.resolve(genericCallStatus.SUCCESS);
      })
      .then(() => {
        dispatch(fetchSingleUser(userId));
        return Promise.resolve(genericCallStatus.SUCCESS);
      })
      .catch(() => {
        dispatch(
          displayToast(
            toastTypes.ERROR,
            "Errore nel cambio di stato dell'utente"
          )
        );
        dispatch(fetchSingleUser(userId));
        return Promise.reject(genericCallStatus.GENERIC_ERROR);
      });
  };
}

export function changePasswordAsAdmin(userId, newPassword) {
  return (dispatch, getState) => {
    const data = {
      newPassword: newPassword,
    };
    return axios
      .post(
        BACKEND_URL + Paths.user + userId + "/password",
        data,
        getStandardConfig(getState)
      )
      .then(() => {
        dispatch(
          displayToast(toastTypes.INFO, "Password cambiata correttamente")
        );
        return Promise.resolve(genericCallStatus.SUCCESS);
      })
      .catch((err) => {
        if (err.response && err.response.status === 403) {
          dispatch(
            displayToast(toastTypes.ERROR, "Password inserita non valida")
          );
        } else {
          dispatch(
            displayToast(
              toastTypes.ERROR,
              "Errore durante il cambio della password"
            )
          );
        }
        return Promise.reject(genericCallStatus.GENERIC_ERROR);
      });
  };
}
