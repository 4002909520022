import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from 'react-redux'
import MUIDataTable from "mui-datatables";
import { makeStyles } from "@material-ui/core/styles";
import { textLabels } from "../../constants/miscConstants";
import { createTheme, Fab, MuiThemeProvider } from '@material-ui/core'
import { fetchAllIndicatore } from "../../redux/indicatori/backendActions";
import { useLocation, useHistory } from "react-router-dom";
import { displayToast } from "../../redux/general/backendActions";
import CheckIcon from "@material-ui/icons/Check";
import { enteFornitorePaths } from "../../constants/enteFornitoreConstants";
import {
  frequencyTypesSelectValues,
  getThresholdLabel,
} from "../../constants/indicatoriConstants";
import { valuesStatuses } from '../../constants/valoriIndicatoriConstants'
import AddIcon from '@material-ui/icons/Add'
import { defaultRowOptions } from '../../constants/generalConstants'
import { setMainTableRows } from '../../redux/general/basicActions'

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  table: {
    marginBottom: "0",
    overflowY: "auto",
  },
  fab: {
    position: "fixed",
    bottom: "50px",
    right: "8vw"
  },
}));

const getMuiTheme = () =>
  createTheme({
    overrides: {
      MuiToolbar: {
        root: {
          backgroundColor: "#FEFDFD",
        },
      },
      MuiTable: {
        root: {
          backgroundColor: "#FEFDFD",
        },
      },
      MuiTableCell: {
        root: {
          height: "30px",
          padding: "0px 4px 0px 4px"
        },
      },
      MUIDataTableBodyRow: {
        root: {
          cursor: "pointer",
        },
      },
      MUIDataTableHeadCell: {
        fixedHeader: {
          backgroundColor: "#C4C4C4",
        },
      },
    },
  });

function AnagraficaIndicatoriPage(props) {
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles(props);
  const setTitle = props.setTitle;
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const mainTableRows = useSelector(
    (state) => state.appPreferences.mainWindowTableRows
  );
  const columns = [
    {
      name: "id",
      options: {
        display: "excluded",
      },
    },
    { name: "code", label: "Cod." },
    { name: "name", label: "Nome" },
    { name: "boxFlat", label: "Box" },
    { name: "measureUnit", label: "Unità di misura" },
    { name: "frequency", label: "Frequenza" },
    { name: "redThresholdFlat", label: "Valore soglia ROSSO" },
    { name: "yellowThresholdFlat", label: "Valore soglia GIALLO" },
    { name: "greenThresholdFlat", label: "Valore soglia VERDE" },
    {
      name: "critical",
      label: "Critico",
      options: {
        customBodyRenderLite: (dataIndex) => {
          if (data[dataIndex].critical === true) {
            return <CheckIcon />;
          } else return null;
        },
      },
    },
  ];
  const options = {
    elevation: 0,
    selectableRows: "none",
    filter: false,
    print: false,
    download: false,
    search: false,
    viewColumns: false,
    textLabels: textLabels,
    rowsPerPageOptions: defaultRowOptions,
    rowsPerPage: mainTableRows,
    onChangeRowsPerPage: (rows) => dispatch(setMainTableRows(rows)),
    onRowClick: (rowData) => {
      history.push(
        `${enteFornitorePaths.anagraficaIndicatori.base}/${rowData[0]}`
      );
    },
  };
  useEffect(() => {
    setTitle("Anagrafica indicatori");
  }, [setTitle]);
  const fetchAllIndicatore = props.fetchAllIndicatore;
  useEffect(() => {
    fetchAllIndicatore();
  }, [location, fetchAllIndicatore]);
  useEffect(() => {
    let newData = Object.keys(props.indicatori.indicatoriByIDs).map((id) => {
      return {
        id: id,
        code: props.indicatori.indicatoriByIDs[id].code,
        name: props.indicatori.indicatoriByIDs[id].name,
        boxFlat: props.indicatori.indicatoriByIDs[id].box.name,
        measureUnit: props.indicatori.indicatoriByIDs[id].measureUnit,
        frequency:
          frequencyTypesSelectValues[
            props.indicatori.indicatoriByIDs[id].frequency
          ],
        redThresholdFlat: `${getThresholdLabel(
          props.indicatori.indicatoriByIDs[id],
          valuesStatuses.RED
        )}`,
        yellowThresholdFlat: `${getThresholdLabel(
          props.indicatori.indicatoriByIDs[id],
          valuesStatuses.YELLOW
        )}`,
        greenThresholdFlat: `${getThresholdLabel(
          props.indicatori.indicatoriByIDs[id],
          valuesStatuses.GREEN
        )}`,
        critical: props.indicatori.indicatoriByIDs[id].critical
      };
    });
    setData(newData);
  }, [props.indicatori]);
  return (
    <div className={classes.root}>
      <div className={classes.table}>
        <h6>Anagrafica indicatori</h6>
        <MuiThemeProvider theme={getMuiTheme()}>
          <MUIDataTable data={data} columns={columns} options={options} />
        </MuiThemeProvider>
        <Fab
          color="primary"
          variant={"extended"}
          className={classes.fab}
          onClick={() =>
            history.push(enteFornitorePaths.anagraficaIndicatori.creaIndicatore)
          }
        >
          <AddIcon />
          Crea indicatore
        </Fab>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    indicatori: state.indicatori,
  };
};

const mapDispatchToProps = {
  fetchAllIndicatore,
  displayToast,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnagraficaIndicatoriPage);
