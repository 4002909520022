import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchSingleUser } from "../../redux/admin/backendActions";
import { UserRoles } from "../../constants/authConstants";
import { AdminChangeUsernameSection } from "../components/AdminChangeUsernameSection";
import { AdminChangePasswordSection } from "../components/AdminChangePasswordSection";
import AnagraficaAzienda from "../../Common/Pages/AnagraficaAzienda";
import { adminPaths } from "../../constants/adminConstants";
import AnagraficaEnteFornitorePage from "../../Common/AnagraficaEnte/AnagraficaEnteFornitorePage";
import { AdminUserFieldsSection } from "../components/AdminUserFieldsSection";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: "100%",
    maxHeight: "100%",
  },
  mainContent: {
    marginBottom: "0",
    overflowY: "auto",
  },
}));

export function SingleUserPage(props) {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const setTitle = props.setTitle;
  const [userType, setUserType] = useState("");
  const [userDataId, setUserDataId] = useState(0);
  const admin = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchSingleUser(props.match.params.id));
  }, [location, setTitle, props.match, dispatch]);
  useEffect(() => {
    if (admin.usersByIds[props.match.params.id]) {
      setUserType(admin.usersByIds[props.match.params.id].roleName);
      setTitle("Utente " + admin.usersByIds[props.match.params.id].username);
    }
  }, [location, admin, props.match, setTitle]);
  useEffect(() => {
    switch (userType) {
      case UserRoles.ROLE_AZIENDA:
      case UserRoles.ROLE_ENTE_FORNITORE:
        setUserDataId(admin.usersByIds[props.match.params.id].userDataId);
        break;
      default:
        break;
    }
  }, [userType, admin, props.match, dispatch]);
  return (
    <div className={classes.root}>
      <div className={classes.mainContent}>
        <AdminChangeUsernameSection userId={props.match.params.id} />
        <AdminChangePasswordSection userId={props.match.params.id} />
        <AdminUserFieldsSection
          userId={props.match.params.id}
          onDelete={() => history.push(adminPaths.listaUtenti)}
        />
        {userType === UserRoles.ROLE_AZIENDA && (
          <AnagraficaAzienda
            setTitle={() => null}
            aziendaId={userDataId}
            onEditStallaClick={() => {
              history.push(
                `${adminPaths.azienda}/${userDataId}${adminPaths.stalleSubpath}`
              );
            }}
            onEditAllevamentoClick={() => {
              history.push(
                `${adminPaths.azienda}/${userDataId}${adminPaths.allevamentiSubpath}`
              );
            }}
          />
        )}
        {userType === UserRoles.ROLE_ENTE_FORNITORE && (
          <AnagraficaEnteFornitorePage
            setTitle={() => null}
            enteFornitoreId={userDataId}
          />
        )}
      </div>
    </div>
  );
}
