export const RECEIVE_ALL_AZIENDA = "RECEIVE_ALL_AZIENDA";
export const RECEIVE_SINGLE_AZIENDA = "RECEIVE_SINGLE_AZIENDA";
export const RECEIVE_SINGLE_STALLA = "RECEIVE_SINGLE_STALLA";
export const RECEIVE_SINGLE_ALLEVAMENTO = "RECEIVE_SINGLE_ALLEVAMENTO";
export const RECEIVE_ALL_ALERTS = "RECEIVE_ALL_ALERTS";
export const RECEIVE_SINGLE_ALERT = "RECEIVE_SINGLE_ALERT";

export function receiveAllAzienda(aziende) {
  return { type: RECEIVE_ALL_AZIENDA, aziende };
}

export function receiveSingleAzienda(azienda) {
  return { type: RECEIVE_SINGLE_AZIENDA, azienda };
}

export function receiveSingleStalla(stalla) {
  return { type: RECEIVE_SINGLE_STALLA, stalla };
}

export function receiveSingleAllevamento(allevamento) {
  return { type: RECEIVE_SINGLE_ALLEVAMENTO, allevamento };
}

export function receiveAllAlerts(alerts) {
  return { type: RECEIVE_ALL_ALERTS, alerts}
}

export function receiveSingleAlert(alert) {
  return { type: RECEIVE_SINGLE_ALERT, alert}
}
