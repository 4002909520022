import React, { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import { SimpleSelectField } from "./GenericFormComponents/SimpleSelectField";
import { useSelector } from "react-redux";

export function AllevamentoField(props) {
  const [allevamentoDenAziendaMap, setAllevamentoDenAziendaMap] = useState();
  const aziendeByIds = useSelector((state) => state.aziende.aziendeByIDs);
  useEffect(() => {
    const newAllevamentoDenAziendaMap = {};
    Object.values(aziendeByIds).flatMap((azienda) =>
      azienda.allevamenti.map((allevamento) => {
        return { [allevamento.id]: azienda.denominazione };
      })
    );
    setAllevamentoDenAziendaMap(newAllevamentoDenAziendaMap);
  }, [aziendeByIds]);
  const formik = useFormikContext();
  return (
    <SimpleSelectField
      className={props.inputClassName}
      name={props.name}
      id={props.id}
      options={props.allevamentoOptions}
      form={props.form}
      onChange={(event) => {
        formik.setFieldValue(
          props.denominazioneAziendaField,
          allevamentoDenAziendaMap[event.target.value]
        );
      }}
    />
  );
}
