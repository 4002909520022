import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  adminPaths,
  User,
  UserRolesLabels,
  UserSchema,
} from "../../constants/adminConstants";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from 'react-router-dom'
import { createUser } from "../../redux/admin/backendActions";
import { ResetForm } from "../../Common/GenericFormComponents/ResetForm";
import Grid from "@material-ui/core/Grid";
import { SimpleInputField } from "../../Common/GenericFormComponents/SimpleInputField";
import { SimpleSelectField } from "../../Common/GenericFormComponents/SimpleSelectField";
import { CustomRadioSelectorField } from "../../Common/GenericFormComponents/CustomRadioSelectorField";
import {
  booleanRadioLabels,
  booleanRadioValues,
} from "../../constants/miscConstants";
import { SubmitButton } from "../../Common/GenericFormComponents/SubmitButton";
import { toastTypes } from "../../constants/generalConstants";
import { ScrollToError } from "../../Common/GenericFormComponents/ScrollToError";
import { displayToast } from '../../redux/general/backendActions'

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: "100%",
    maxHeight: "100%",
  },
  form: {
    marginBottom: "0",
    overflowY: "auto",
    maxHeight: "100%",
  },
  actionButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  input: {
    boxSizing: "border-box",
    height: "2em",
    width: "150px",
  },
  select: {
    minHeight: "2em",
    width: "150px",
  },
  element: {
    marginBottom: "35px",
  },
  operatorField: {
    minHeight: "2em",
    minWidth: "150px",
  },
  operator: {
    minHeight: "2em",
    width: "50px",
  },
  threshold: {
    boxSizing: "border-box",
    height: "2em",
    width: "120px",
  },
}));

export function CreateUserPage(props) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const setTitle = props.setTitle;
  const [scrollToError, setScrollToError] = useState(false);
  const [showedUser, setShowedUser] = useState(new User());
  const dispatch = useDispatch();
  useEffect(() => {
    setTitle("Crea nuovo utente");
  }, [setTitle]);
  useEffect(() => {
    setShowedUser(new User());
  }, [location])
  return (
    <Formik
      initialValues={showedUser}
      onSubmit={(values) => {
        dispatch(createUser(values)).then(() =>
          history.push(adminPaths.listaUtenti)
        );
      }}
      validationSchema={UserSchema}
      enableReinitialize
    >
      <Form className={classes.root}>
        <ResetForm showedValues={showedUser} />
        <div className={classes.form}>
          <h6>Dati utente</h6>
          <Grid container alignItems="center" justify="center">
            <Grid className={classes.element} item container md={3} xs={6}>
              Email utenza (nome utente)
            </Grid>
            <Grid className={classes.element} item container md={3} xs={6}>
              <SimpleInputField
                name="username"
                type="text"
                className={classes.input}
              />
            </Grid>
            <Grid className={classes.element} item container md={3} xs={6}>
              Password
            </Grid>
            <Grid className={classes.element} item container md={3} xs={6}>
              <SimpleInputField
                name="password"
                type="password"
                className={classes.input}
              />
            </Grid>
            <Grid className={classes.element} item container md={3} xs={6}>
              Tipo utente
            </Grid>
            <Grid className={classes.element} item container md={3} xs={6}>
              <SimpleSelectField
                name={"userRole"}
                id="userRole"
                className={classes.input}
                options={UserRolesLabels}
              />
            </Grid>
            <Grid className={classes.element} item container md={3} xs={6}>
              Abilitato
            </Grid>
            <Grid className={classes.element} item container md={3} xs={6}>
              <CustomRadioSelectorField
                id={"enabled"}
                optionsValues={booleanRadioValues}
                optionsLabels={booleanRadioLabels}
                field={"enabled"}
              />
            </Grid>
            <Grid
              className={`${classes.element}`}
              item
              container
              justify={"center"}
              xs={12}
            >
              <p>Dopo aver creato un utente è possibile modificare le relative anagrafiche dalla lista utenti.</p>
              <p>Per ogni utenza di tipo azienda deve essere impostato almeno un allevamento</p>
            </Grid>
            <Grid
              className={`${classes.element}`}
              item
              container
              justify={"center"}
              xs={12}
            >
              <SubmitButton
                onFormError={(error) => {
                  dispatch(displayToast(toastTypes.ERROR, error));
                  setScrollToError(!scrollToError);
                }}
                label={"Crea utente"}
              />
            </Grid>
            <ScrollToError scroll={scrollToError} />
          </Grid>
        </div>
      </Form>
    </Formik>
  );
}
