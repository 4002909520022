import React, { useEffect, useState } from 'react'
import {
  setAlertProtocolStatus,
} from '../redux/aziende/backendActions'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Form, Formik } from 'formik'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import { ResetForm } from '../Common/GenericFormComponents/ResetForm'
import { Grid, Typography } from '@material-ui/core'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import { SubmitButton } from '../Common/GenericFormComponents/SubmitButton'
import { toastTypes } from '../constants/generalConstants'
import Dialog from '@material-ui/core/Dialog'
import { ProtocolDialogScheme } from '../constants/aziendaConstants'
import { displayToast } from '../redux/general/backendActions'
import {
  booleanRadioLabels,
  booleanRadioValues,
} from '../constants/miscConstants'
import { CustomRadioSelectorField } from '../Common/GenericFormComponents/CustomRadioSelectorField'

const useStyles = makeStyles((theme) => ({
  inputText: {
    marginRight: "15px"
  },
}));

function ProtocolloDialog(props) {
  const classes = useStyles();
  const [showedForm, setShowedForm] = useState({protocolExecuted: false});
  useEffect(() => {
    if (props.alert){
      setShowedForm({protocolExecuted: props.alert.protocolExecuted});
    }
  }, [props.alert, setShowedForm])
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
    >
      <Formik
        initialValues={showedForm}
        validationSchema={ProtocolDialogScheme}
        onSubmit={(values) => {
          props.setAlertProtocolStatus(props.alert.id, values);
        }}
        enableReinitialize
      >
        {(formik) => (
          <Form>
            <DialogTitle id="form-dialog-title">
              Protocollo alert
            </DialogTitle>
            <DialogContent>
              <ResetForm showedValues={showedForm} />
              <Grid
                container
                alignItems="center"
                justify="center"
              >
                <Grid item container xs={12}>
                  <Typography>{props.criticalInfo.alertProtocol}</Typography>
                </Grid>
                <Grid item container xs={12}>
                  <Typography className={classes.inputText} >
                    <b>
                      Il protocollo è stato eseguito?
                    </b>
                  </Typography>
                  <CustomRadioSelectorField
                    id={"protocolExecuted"}
                    optionsValues={booleanRadioValues}
                    optionsLabels={booleanRadioLabels}
                    field={"protocolExecuted"}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={props.onClose} color="primary">
                Annulla
              </Button>
              <SubmitButton
                onClick={props.onClose}
                variant={"text"}
                color="primary"
                onFormError={(error) => {
                  props.displayToast(toastTypes.ERROR, error);
                }}
                label={"Conferma"}
              />
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
  };
};

const mapDispatchToProps = {
  displayToast
};

export default connect(mapStateToProps, mapDispatchToProps)(ProtocolloDialog);