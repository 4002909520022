import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import HomeIcon from '@material-ui/icons/Home';
import PrintIcon from '@material-ui/icons/Print';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "nowrap"
  },
}));

export function TopBarControls(props) {
  const classes = useStyles();
  const history = useHistory();
  return (
      <div className={classes.container}>
        <IconButton color={'primary'} onClick={() => history.goBack()}>
          <ArrowBackIcon/>
        </IconButton>
        <IconButton color={'primary'} onClick={() => history.goForward()}>
          <ArrowForwardIcon/>
        </IconButton>
        <IconButton color={'primary'} onClick={() => history.push("/")}>
          <HomeIcon />
        </IconButton>
        <IconButton color={'primary'} onClick={() => window.print()}>
          <PrintIcon />
        </IconButton>
      </div>
  )
}

