import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { Form, Formik } from "formik";
import {
  ValoreIndicatore,
  valoreIndicatoreSchema,
} from '../constants/valoriIndicatoriConstants'
import { ResetForm } from "./GenericFormComponents/ResetForm";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { SubmitButton } from "./GenericFormComponents/SubmitButton";
import { toastTypes } from "../constants/generalConstants";
import { SimpleSelectField } from "./GenericFormComponents/SimpleSelectField";
import PeriodSelectorField from '../EnteFornitore/Valori/PeriodSelectorField'
import ValueField from '../EnteFornitore/Valori/ValueField'
import { allevamentoString } from '../constants/aziendaConstants'

const useStyles = makeStyles((theme) => ({
  input: {
    boxSizing: "border-box",
    height: "2em",
    width: "150px",
  },
  select: {
    minHeight: "2em",
    width: "150px",
  },
  element: {
    marginBottom: "35px",
  },
}));

export function CreateNewValueDialog(props) {
  const classes = useStyles();
  const [showedValore, setShowedValore] = useState(new ValoreIndicatore());
  const [codeOptions, setCodeOptions] = useState({});
  const [allevamentoOptions, setAllevamentoOptions] = useState({});
  useEffect(() => {
    const newOptions = {};
    Object.values(props.indicatori.indicatoriByIDs).forEach((indicatore) => {
      newOptions[indicatore.id] = indicatore.code + " - " + indicatore.name;
    });
    setCodeOptions(newOptions);
  }, [props.indicatori]);
  useEffect(() => {
    const newOptions = {};
    Object.values(props.aziende.aziendeByIDs).forEach((azienda) => {
      azienda.allevamenti.forEach(allevamento => {
        newOptions[allevamento.id] = allevamentoString(allevamento);
      })
    });
    setAllevamentoOptions(newOptions);
  }, [props.aziende]);
  useEffect(() => {
    setShowedValore(new ValoreIndicatore());
  }, [props.open]);
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
    >
      <Formik
        initialValues={showedValore}
        validationSchema={valoreIndicatoreSchema}
        onSubmit={(values) => {
          props.createValore(values);
        }}
        enableReinitialize
      >
        {(formik) => (
          <Form>
            <DialogTitle id="form-dialog-title">
              Aggiungi valore indicatore
            </DialogTitle>
            <DialogContent>
              <ResetForm showedValues={showedValore} />
              <Grid
                container
                alignItems="center"
                justify="center"
                className={classes.container}
              >
                <Grid className={classes.element} item container xs={6}>
                  <Typography>Cod. indicatore</Typography>
                </Grid>
                <Grid
                  className={classes.element}
                  item
                  container
                  xs={6}
                  justify="center"
                >
                  <SimpleSelectField
                    name={"tipoIndicatore.id"}
                    id="code"
                    className={classes.select}
                    options={codeOptions}
                  />
                </Grid>
                <Grid className={classes.element} item container xs={6}>
                  <Typography>Allevamento</Typography>
                </Grid>
                <Grid
                  className={classes.element}
                  item
                  container
                  xs={6}
                  justify="center"
                >
                  <SimpleSelectField
                    name={"allevamento.id"}
                    id="allevamento"
                    className={classes.select}
                    options={allevamentoOptions}
                  />
                </Grid>
                <Grid className={classes.element} item container xs={6}>
                  <Typography>Periodo</Typography>
                </Grid>
                <Grid
                  className={classes.element}
                  item
                  container
                  xs={6}
                  justify="center"
                >
                  <PeriodSelectorField
                    name={"period"}
                    className={classes.input}
                    indicatoreId={formik.values.tipoIndicatore.id}
                  />
                </Grid>
                <Grid className={classes.element} item container xs={6}>
                  <Typography>Valore</Typography>
                </Grid>
                <Grid
                  className={classes.element}
                  item
                  container
                  xs={6}
                  justify="center"
                >
                  <ValueField
                    className={classes.input}
                    name={"value"}
                    indicatoreId={formik.values.tipoIndicatore.id}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={props.onClose} color="primary">
                Annulla
              </Button>
              <SubmitButton
                onClick={props.onClose}
                variant={"text"}
                color="primary"
                onFormError={(error) => {
                  props.displayToast(toastTypes.ERROR, error);
                }}
                label={"Aggiungi"}
              />
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
