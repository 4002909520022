import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { aziendaExternalLinks } from "../constants/aziendaConstants";

const useStyles = makeStyles(() => ({
  button: {
    marginLeft: "20px",
    marginRight: "20px",
  },
}));

export function ExternalLinksButtons(props) {
  const classes = useStyles();
  return (
    <div className={props.className}>
      <a href={aziendaExternalLinks.siAlleva}>
        <Button
          key="siAlleva"
          className={classes.button}
          variant="contained"
          color="primary"
          type="button"
        >
          Apri Si@alleva
        </Button>
      </a>
      <a href={aziendaExternalLinks.costoMalattie}>
        <Button
          key="costoMalattie"
          className={classes.button}
          variant="contained"
          color="primary"
          type="button"
        >
          Apri Costo Malattie
        </Button>
      </a>
      <a href={aziendaExternalLinks.sensoriUniPG}>
        <Button
          key="datiSensori"
          className={classes.button}
          variant="contained"
          color="primary"
          type="button"
        >
          Apri dati sensori
        </Button>
      </a>
    </div>
  );
}
