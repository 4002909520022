import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { SimpleSelectField } from './SimpleSelectField'
import { useFormikContext } from 'formik'

function IndicatoreSelectField(props) {
  const formik = useFormikContext();
  const [codeOptions, setCodeOptions] = useState({});
  useEffect(() => {
    const newOptions = {};
    Object.values(props.indicatori.indicatoriByIDs).forEach((indicatore) => {
      newOptions[indicatore.id] = indicatore.code + " - " + indicatore.name;
    });
    setCodeOptions(newOptions);
  }, [props.indicatori]);
  return (
    <SimpleSelectField
      name={props.indicatoreIdField}
      id="indicatoreIdSelect"
      className={props.selectClassName}
      options={codeOptions}
      disabled={props.disabled}
      onChange={(event) => {
        let indicatoreId = event.target.value;
        if (props.nameField) {
          formik.setFieldValue(props.nameField, props.indicatori.indicatoriByIDs[indicatoreId].name);
        }
        if (props.boxField) {
          formik.setFieldValue(props.boxField, props.indicatori.indicatoriByIDs[indicatoreId].box.name);
        }
      }}
    />
  )
}

const mapDispatchToProps = {
};

const mapStateToProps = (state) => {
  return {
    indicatori: state.indicatori,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IndicatoreSelectField);