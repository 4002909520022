import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchAllAzienda } from "../redux/aziende/backendActions";
import { fetchAllIndicatore } from "../redux/indicatori/backendActions";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  SearchForm,
  searchFormSchema,
} from "../constants/indicatoriConstants";
import { Form, Formik } from "formik";
import { ResetForm } from "./GenericFormComponents/ResetForm";
import { SimpleInputField } from "./GenericFormComponents/SimpleInputField";
import { SimpleSelectField } from "./GenericFormComponents/SimpleSelectField";
import { useLocation } from "react-router-dom";
import { fetchFilteredValori } from "../redux/valoriindicatori/backendActions";
import { SubmitButton } from "./GenericFormComponents/SubmitButton";
import { displayToast } from "../redux/general/backendActions";
import { toastTypes } from "../constants/generalConstants";
import { ResubmitForm } from './GenericFormComponents/ResubmitForm'
import { allevamentoString } from '../constants/aziendaConstants'

const useStyles = makeStyles((theme) => ({
  input: {
    boxSizing: "border-box",
    height: "2em",
    width: "200px",
  },
  select: {
    minHeight: "2em",
    width: "200px",
  },
  element: {
    marginBottom: "35px",
  },
}));

function ValoriSearchForm(props) {
  const location = useLocation();
  const classes = useStyles();
  const fetchAllAzienda = props.fetchAllAzienda;
  const fetchAllIndicatore = props.fetchAllIndicatore;
  const [searchForm, setSearchForm] = useState(new SearchForm());
  const [codeOptions, setCodeOptions] = useState({});
  const [allevamentoOptions, setAllevamentoOptions] = useState({});
  useEffect(() => {
    setSearchForm(new SearchForm());
  }, [location]);
  useEffect(() => {
    fetchAllIndicatore();
    fetchAllAzienda();
  }, [fetchAllIndicatore, fetchAllAzienda]);
  useEffect(() => {
    const newOptions = {};
    Object.values(props.indicatori.indicatoriByIDs).forEach((indicatore) => {
      newOptions[indicatore.id] = indicatore.code + " - " + indicatore.name;
    });
    setCodeOptions(newOptions);
  }, [props.indicatori]);
  useEffect(() => {
    const newOptions = {};
    Object.values(props.aziende.aziendeByIDs).forEach((azienda) => {
      azienda.allevamenti.forEach(allevamento => {
        newOptions[allevamento.id] = allevamentoString(allevamento);
      })
    });
    setAllevamentoOptions(newOptions);
  }, [props.aziende]);
  return (
    <Formik
      initialValues={searchForm}
      onSubmit={(values) =>
        props.fetchFilteredValori(
          values.code,
          values.allevamento,
          values.fromDay,
          values.toDay
        )
      }
      validationSchema={searchFormSchema}
      enableReinitialize
    >
      <Form>
        <ResetForm showedValues={searchForm} />
        <ResubmitForm resubmitFlag={props.resubmitFlag} />
        <Grid
          container
          alignItems="center"
          justify="center"
          className={classes.container}
        >
          <Grid className={classes.element} item container md={3} xs={6}>
            <Typography>Codice indicatore</Typography>
          </Grid>
          <Grid
            className={classes.element}
            item
            container
            md={3} xs={6}
            justify="center"
          >
            <SimpleSelectField
              name={"code"}
              id="code"
              className={classes.select}
              options={codeOptions}
            />
          </Grid>
          <Grid className={classes.element} item container md={3} xs={6}>
            <Typography>Allevamento</Typography>
          </Grid>
          <Grid
            className={classes.element}
            item
            container
            md={3} xs={6}
            justify="center"
          >
            <SimpleSelectField
              name={"allevamento"}
              id="allevamento"
              className={classes.select}
              options={allevamentoOptions}
              allowEmptyValues
            />
          </Grid>
          <Grid className={classes.element} item container md={3} xs={6}>
            <Typography>Valori a partire da</Typography>
          </Grid>
          <Grid
            className={classes.element}
            item
            container
            md={3} xs={6}
            justify="center"
          >
            <SimpleInputField
              type="date"
              className={classes.input}
              name="fromDay"
            />
          </Grid>
          <Grid className={classes.element} item container md={3} xs={6}>
            <Typography>Valori fino a</Typography>
          </Grid>
          <Grid
            className={classes.element}
            item
            container
            md={3} xs={6}
            justify="center"
          >
            <SimpleInputField
              type="date"
              className={classes.input}
              name="toDay"
            />
          </Grid>
          <Grid
            className={classes.element}
            item
            container
            md={3} xs={6}
            justify="center"
          >
            <SubmitButton
              label={"Cerca"}
              onFormError={(error) => {
                props.displayToast(toastTypes.ERROR, error);
              }}
            />
          </Grid>
        </Grid>
      </Form>
    </Formik>
  );
}

const mapStateToProps = (state) => {
  return {
    indicatori: state.indicatori,
    aziende: state.aziende,
  };
};

const mapDispatchToProps = {
  fetchAllAzienda,
  fetchAllIndicatore,
  fetchFilteredValori,
  displayToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(ValoriSearchForm);
