import { applyMiddleware, createStore } from "redux";
import combinedReducers from "./combinedReducers";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { jwt } from './middleware'
import { logger } from 'redux-logger/src'

const persistConfig = {
  key: "root",
  storage,
  blacklist: [
    "loginTransientState",
    "provinceComuneSelectorData",
    "indicatori",
    "box",
    "aziende",
    "valori",
    "admin"
  ],
};

const persistedReducer = persistReducer(persistConfig, combinedReducers);

export const store = createStore(
  persistedReducer,
  applyMiddleware(jwt, thunk)
);

// Persist the redux state in localstorage
export const persistor = persistStore(store);
