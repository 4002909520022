import { RECEIVE_ALL_USERS, RECEIVE_SINGLE_USER } from './basicActions'

export function admin(
  state = {
    usersByIds: {}
  },
  action
) {
  switch (action.type) {
    case RECEIVE_ALL_USERS:
      let newUsers = action.users.reduce(
        (obj, item) => Object.assign(obj, { [item.id]: item }),
        {}
      );
      return {
        ...state,
        usersByIds: newUsers,
      };
    case RECEIVE_SINGLE_USER:
      return {
        ...state,
        usersByIds: {
          ...state.usersByIds,
          [action.user.id]: action.user,
        },
      };
    default:
      return state;
  }
}
