import React from "react";
import TableCell from "@material-ui/core/TableCell";
import { TableRow } from "@material-ui/core";

export function IndicatoriDipendentiRow(props) {
  return (
    <TableRow key={props.rowData[0]}>
      <TableCell>{props.rowData[2]}</TableCell>
      <TableCell>{props.rowData[3]}</TableCell>
      <TableCell>{props.rowData[4]}</TableCell>
      <TableCell>{props.rowData[5]}</TableCell>
    </TableRow>
  );
}
