import React, { useEffect, useState } from 'react'
import { fetchAllIndicatore } from '../redux/indicatori/backendActions'
import {
  fetchIndicatoriAverages,
} from '../redux/valoriindicatori/backendActions'
import { displayToast } from '../redux/general/backendActions'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { useLocation } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { ResetForm } from './GenericFormComponents/ResetForm'
import { Grid, Typography } from '@material-ui/core'
import { SimpleSelectField } from './GenericFormComponents/SimpleSelectField'
import { SimpleInputField } from './GenericFormComponents/SimpleInputField'
import { SubmitButton } from './GenericFormComponents/SubmitButton'
import { toastTypes } from '../constants/generalConstants'
import {
  AverageSearchForm,
  averageSearchFormSchema,
} from '../constants/valoriIndicatoriConstants'
import BoxSelectField from './GenericFormComponents/BoxSelectField'
import ProvinciaComuneSelectFields
  from './GenericFormComponents/ProvinciaComuneSelectFields'

const useStyles = makeStyles((theme) => ({
  input: {
    boxSizing: "border-box",
    height: "2em",
    width: "150px",
  },
  select: {
    minHeight: "2em",
    width: "150px",
  },
  element: {
    marginBottom: "35px",
  },
}));

function AggregatiAverageSearchForm(props) {
  const location = useLocation();
  const classes = useStyles();
  const fetchAllIndicatore = props.fetchAllIndicatore;
  const [searchForm, setSearchForm] = useState(new AverageSearchForm());
  const [codeOptions, setCodeOptions] = useState({});
  useEffect(() => {
    setSearchForm(new AverageSearchForm());
  }, [location]);
  useEffect(() => {
    fetchAllIndicatore();
  }, [fetchAllIndicatore]);
  useEffect(() => {
    const newOptions = {};
    Object.values(props.indicatori.indicatoriByIDs).forEach((indicatore) => {
      newOptions[indicatore.id] = indicatore.code + " - " + indicatore.name;
    });
    setCodeOptions(newOptions);
  }, [props.indicatori]);
  return (
    <Formik
      initialValues={searchForm}
      onSubmit={(values) =>
        props.fetchIndicatoriAverages(
          values.tipoId,
          values.boxId,
          values.fromPeriod,
          values.toPeriod,
          values.provinciaId,
          values.comuneId
        )
      }
      validationSchema={averageSearchFormSchema}
      enableReinitialize
    >
      <Form>
        <ResetForm showedValues={searchForm} />
        <Grid
          container
          alignItems="center"
          justify="center"
          className={classes.container}
        >
          <Grid className={classes.element} item container md={3} xs={6}>
            <Typography>Codice indicatore</Typography>
          </Grid>
          <Grid
            className={classes.element}
            item
            container
            md={3} xs={6}
            justify="center"
          >
            <SimpleSelectField
              name={"tipoId"}
              id="code"
              className={classes.select}
              options={codeOptions}
              allowEmptyValues
            />
          </Grid>
          <Grid className={classes.element} item container md={3} xs={6}>
            <Typography>Box</Typography>
          </Grid>
          <Grid
            className={classes.element}
            item
            container
            md={3} xs={6}
            justify="center"
          >
            <BoxSelectField
              name={"boxId"}
              className={classes.select}
              allowEmptyValues
            />
          </Grid>
          <Grid className={classes.element} item container md={3} xs={6}>
            <Typography>Valori a partire da</Typography>
          </Grid>
          <Grid
            className={classes.element}
            item
            container
            md={3} xs={6}
            justify="center"
          >
            <SimpleInputField
              type="date"
              className={classes.input}
              name="fromPeriod"
            />
          </Grid>
          <Grid className={classes.element} item container md={3} xs={6}>
            <Typography>Valori fino a</Typography>
          </Grid>
          <Grid
            className={classes.element}
            item
            container
            md={3} xs={6}
            justify="center"
          >
            <SimpleInputField
              type="date"
              className={classes.input}
              name="toPeriod"
            />
          </Grid>
          <ProvinciaComuneSelectFields
            gridClassName={classes.element}
            inputClassName={classes.input}
            selectClassName={classes.select}
            comuneIDField={"comuneId"}
            provinciaIDField={"provinciaId"}
            allowEmptyValues
          />
          <Grid
            className={classes.element}
            item
            container
            xs={6}
            justify="center"
          >
            <SubmitButton
              id={"findAverages"}
              label={"Cerca"}
              onFormError={(error) => {
                props.displayToast(toastTypes.ERROR, error);
              }}
            />
          </Grid>
        </Grid>
      </Form>
    </Formik>
  );
}

const mapStateToProps = (state) => {
  return {
    indicatori: state.indicatori,
  };
};

const mapDispatchToProps = {
  fetchAllIndicatore,
  fetchIndicatoriAverages,
  displayToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(AggregatiAverageSearchForm);